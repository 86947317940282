import {
    CREATE_CITY,
    CREATE_CITY_SUCCESS,
    EDIT_CITY,
    EDIT_CITY_SUCCESS,
    FIND_BY_ID_CITY,
    FIND_BY_ID_CITY_SUCCESS,
    CREATE_OR_EDIT_CITY_API_ERROR
} from "./actionTypes"


export const createCity = (params, history) => {
    return {
        type: CREATE_CITY,
        payload: {params, history},
    }
}

export const createCitySuccess = params => {
    return {
        type: CREATE_CITY_SUCCESS,
        payload: {params},
    }
}

export const editCity = (params, history) => {
    return {
        type: EDIT_CITY,
        payload: {params, history},
    }
}

export const editCitySuccess = result => {
    return {
        type: EDIT_CITY_SUCCESS,
        payload: {result},
    }
}

export const findByIdCity = id => {
    return {
        type: FIND_BY_ID_CITY,
        payload: {id},
    }
}

export const findCityIdSuccess = result => {
    return {
        type: FIND_BY_ID_CITY_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditCityError = error => {
    return {
        type: CREATE_OR_EDIT_CITY_API_ERROR,
        payload: error,
    }
}

