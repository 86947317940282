import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {connect} from "react-redux";
import {google_maps} from "../../../config";
import {searchAddress, reverseCoordinates, createCompany, uploadCompany} from "../../../store/actions"
import {findPostalCode} from "../../../helpers/viacep_helper"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import InputMask from "react-input-mask";
import Dropzone from "react-dropzone";
import GenericsServiceAPI from "../../../../src/helpers/GenericsServicesAPI";
import {NotificationManager} from "react-notifications";

const LoadingContainer = () => <div>Loading...</div>

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            cnpj: '',
            image: '',
            postal_code: '',
            phone: '',

            place: '',
            district: '',
            number: '',
            region: '',
            address: '',

            file: null,
            existsFile: false,
            isFindCep: false,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.find_postal_code = this.find_postal_code.bind(this);

    }

    componentDidMount() {


    }

   /* sendUpload(formData, url) {

        GenericsServiceAPI.upload(formData, url)
            .then(response => {
                const {message} = response;

                this.props.existsFiles = false
                this.props.loading = false
                NotificationManager.info(message, 'Aviso!');
                history.goBack()

            })
            .catch(err => {
                this.props.loading = false
                NotificationManager.warning(err, 'Ops!', 3000);
            })
            .then(response => {

            });
    }*/

    find_postal_code() {

        const {postal_code} = this.state

        if (postal_code && postal_code.replace(/\D/g, '').length == 8) {


            this.setState({
                isFindCep: true
            })

            findPostalCode(postal_code.replace(/\D/g, ''))
                .then(response => response.json())
                .then(response => {

                    this.setState({
                        isFindCep: false,
                        postal_code: response.cep,
                        address: response.logradouro,
                        district: response.bairro,
                        city: response.localidade,
                        state: response.uf,
                        region: response.localidade + '-' + response.uf,

                    })

                    this.props.searchAddress(response.cep +
                        ',' + response.logradouro +
                        ',' + response.bairro +
                        ',' + response.localidade +
                        '-' + response.uf)

                    let number = document.querySelector('[name="number"]')
                    if (number) {
                        number.focus()
                    }

                })


        }

    }


    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

  /*  handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)

    }*/

    handleValidSubmit(event, values) {
        this.props.createCompany({
            fantasy_name: values.fantasy_name,
            company_name: values.company_name,
            cnpj: this.state.cnpj,
            email: values.email,
            phone: this.state.phone,

            postal_code: this.state.postal_code,
            address: this.state.address,
            district: this.state.district,
            number: values.number,
            region: this.state.region,

            city: this.state.city,
            state: this.state.state,
            status: this.state.status,
            lat: this.props.coordinates.lat,
            lng: this.props.coordinates.lng
        }, this.state.existsFile,  this.props.history)

    }

    render() {
        console.log('EXISTS ',this.props.existsFiles )
        console.log('EXISTS ID ',this.props.id )

      /*  if(this.props.existsFiles === true && this.props.id > 0){

            var dataForm = new FormData();
                dataForm.append('id',this.props.id);
                dataForm.append('files', this.state.file);

            this.sendUpload(dataForm, '/api/v1/companies/upload')
          //  var dataForm = new FormData();
          //  dataForm.append('id',this.props.id);
          //  dataForm.append('files', this.state.file);
           // dataForm.append('teste', 'teste');

           // this.props.uploadCompany(dataForm, this.props.history)

        }*/

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Company Register")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Company")}
                                     breadcrumbItem={this.props.t("Register")}/>
                        <Row>
                            <Col sm={12}>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={this.handleValidSubmit}>
                                    <Row>
                                        <Col sm={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Form")}</span>
                                                                <p className="card-title-desc mt-2">Preencha os campos
                                                                    abaixo, para
                                                                    concluir o cadastro.</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>


                                                            <Row>
                                                                <Col sm={8} className="mb-2">
                                                                    <AvField
                                                                        name="company_name"
                                                                        label={this.props.t("Company Name")}
                                                                        className="form-control"
                                                                        placeholder="Digite a razão social"
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={4} className="mb-2">
                                                                    <AvField
                                                                        value={this.state.status}
                                                                        onChange={this.handleFields}
                                                                        type="select" name="select"
                                                                        label={this.props.t("Status")}>
                                                                        <option value="1">Ativo</option>
                                                                        <option value="0">Inativo</option>
                                                                    </AvField>
                                                                </Col>
                                                                <Col sm={4} className="mb-2">
                                                                    <div className="form-group">
                                                                        <label>CNPJ</label>
                                                                        <InputMask
                                                                            className="form-control"
                                                                            mask={"99.999.999/9999-99"}
                                                                            name="cnpj"
                                                                            onChange={this.handleFields}
                                                                            value={this.state.cnpj}/>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={8} className="mb-2">
                                                                    <AvField
                                                                        name="fantasy_name"
                                                                        label={this.props.t("Fantasy Name")}
                                                                        className="form-control"
                                                                        placeholder="Digite o nome fantasia"
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </Col>

                                                                <Col sm={6} className="mb-2">
                                                                    <AvField
                                                                        name="email"
                                                                        label={this.props.t("E-mail")}
                                                                        type="text"
                                                                        placeholder="Digite o e-mail"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={6} className="mb-2">

                                                                    <div className="form-group">
                                                                        <label>Telefone</label>
                                                                        <InputMask
                                                                            className="form-control"
                                                                            mask="(99) 99999-9999"
                                                                            name="phone"
                                                                            onChange={this.handleFields}
                                                                            value={this.state.phone}/>
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm={7}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Address")}</span>
                                                                <p className="card-title-desc mt-2">Preencha os campos
                                                                    abaixo, com o endereço</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>

                                                            <Row>
                                                                <Col sm={3} className="mb-2">
                                                                    <AvField
                                                                        name="postal_code"
                                                                        label={this.props.t("Postal Code")}
                                                                        className="form-control"
                                                                        placeholder="Digite cep"
                                                                        onChange={this.handleFields}
                                                                        onBlur={this.find_postal_code}
                                                                        value={this.state.postal_code}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                    {
                                                                        this.state.isFindCep
                                                                        &&
                                                                        <Spinner size="sm" animation="border"
                                                                                 variant="dark"/>
                                                                    }

                                                                </Col>

                                                                <Col sm={9} className="mb-2">
                                                                    <AvField
                                                                        name="address"
                                                                        label={this.props.t("Address")}
                                                                        className="form-control"
                                                                        placeholder="Digite o logradouro"
                                                                        type="text"
                                                                        value={this.state.address}
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={3} className="mb-2">
                                                                    <AvField
                                                                        name="number"
                                                                        label={this.props.t("Number")}
                                                                        className="form-control"
                                                                        placeholder="Nº"
                                                                        value={this.state.number}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </Col>

                                                                <Col sm={9} className="mb-2">
                                                                    <AvField
                                                                        name="district"
                                                                        label={this.props.t("District")}
                                                                        type="text"
                                                                        value={this.state.district}
                                                                        placeholder="Digite o bairro"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={12} className="mb-2">
                                                                    <AvField
                                                                        name="region"
                                                                        label={this.props.t("Region")}
                                                                        type="text"
                                                                        value={this.state.region}
                                                                        placeholder="Digite a cidade/estado uf"
                                                                        required
                                                                    />
                                                                </Col>

                                                                <Col sm={12} className="pt-3">
                                                                    <ButtonCustom
                                                                        isLoading={this.props.loading}
                                                                        size="sm"
                                                                        clazzContainer="text-center"
                                                                        clazz="btn btn-info w-md"
                                                                        message_loaging="">
                                                                        <button
                                                                            className="btn btn-info w-md"
                                                                            type="submit">
                                                                            Salvar
                                                                        </button>
                                                                    </ButtonCustom>
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={5}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Map")}</span>
                                                                <p className="card-title-desc mt-3">Acompanhe a
                                                                    localidade no
                                                                    mapa</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <div id="gmaps-makers" className="gmaps"
                                                                 style={{position: 'relative'}}>
                                                                {
                                                                    this.props.coordinates.lat != 0 &&
                                                                    <Map
                                                                        google={this.props.google}
                                                                        style={{width: '100%', height: '100%'}}
                                                                        zoom={9}
                                                                        loaded={this.props.loading_map}
                                                                        center={this.props.coordinates}
                                                                        initialCenter={this.props.coordinates}
                                                                    >
                                                                        <Marker
                                                                            position={this.props.coordinates}
                                                                        />
                                                                    </Map>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    google: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    address: PropTypes.any,
    id: PropTypes.number,

    existsFiles: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    uploadCompany: PropTypes.func,
    searchAddress: PropTypes.func,
    reverseCoordinates: PropTypes.func,
    createCompany: PropTypes.func,
}


const mapStateToProps = state => {
    const {loading_map, address, coordinates} = state.GeoLocation
    const {loading, error, message,  id, existsFiles} = state.CreateOrEditCompanies
    return {loading_map, address, coordinates, loading, error, message,  id, existsFiles}
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    reverseCoordinates,
    searchAddress,
    createCompany,
    uploadCompany
})(
    GoogleApiWrapper({
        apiKey: google_maps.token,
        LoadingContainer: LoadingContainer,
        v: "3",
    })(Index)
)))

