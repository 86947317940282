import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_SIZES,
    FILTER_PAGINATE_SIZES,
    DELETE_SIZES,
} from "./actionTypes"

import {
    allSizePaginateSuccess,
    filtersAllSizePaginateSuccess,
    deleteSizeSuccess,
    apiPaginateSizeError
} from "./actions"


import {
    getSizesPaginate,
    getFilterSizesPaginate,
    delSizes
} from "../../../../../helpers/sizes_helper"
import {NotificationManager} from "react-notifications";

function* deleteSize({payload: {id, history}}) {
    try {

        const response = yield call(delSizes, id)

        yield put(deleteSizeSuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateSizeError(error))
    }
}

function* paginateSize({payload: {params}}) {
    try {

        const response = yield call(getSizesPaginate, params)

        yield put(allSizePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSizeError(error))
    }
}

function* filtersPaginateSize({payload: {params}}) {
    try {

        const response = yield call(getFilterSizesPaginate, params)

        yield put(filtersAllSizePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSizeError(error))
    }
}


function* SizesSaga() {
    yield takeEvery(PAGINATE_SIZES, paginateSize)
    yield takeEvery(FILTER_PAGINATE_SIZES, filtersPaginateSize)
    yield takeEvery(DELETE_SIZES, deleteSize)
}

export default SizesSaga
