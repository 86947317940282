import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_PRODUCTS,
    FILTER_PAGINATE_PRODUCTS,
    DELETE_PRODUCTS,
} from "./actionTypes"

import {
    allProductPaginateSuccess,
    filtersAllProductPaginateSuccess,
    deleteProductSuccess,
    apiPaginateProductError
} from "./actions"


import {
    getProductsPaginate,
    getFilterProductsPaginate,
    delProducts
} from "../../../../../helpers/products_helper"
import {NotificationManager} from "react-notifications";

function* deleteSize({payload: {id, history}}) {
    try {

        const response = yield call(delProducts, id)

        yield put(deleteProductSuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateProductError(error))
    }
}

function* paginateSize({payload: {params}}) {
    try {

        const response = yield call(getProductsPaginate, params)

        yield put(allProductPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateProductError(error))
    }
}

function* filtersPaginateSize({payload: {params}}) {
    try {

        const response = yield call(getFilterProductsPaginate, params)

        yield put(filtersAllProductPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateProductError(error))
    }
}


function* ProductsSaga() {
    yield takeEvery(PAGINATE_PRODUCTS, paginateSize)
    yield takeEvery(FILTER_PAGINATE_PRODUCTS, filtersPaginateSize)
    yield takeEvery(DELETE_PRODUCTS, deleteSize)
}

export default ProductsSaga
