import {
    CREATE_COUNTRY,
    CREATE_COUNTRY_SUCCESS,
    EDIT_COUNTRY,
    EDIT_COUNTRY_SUCCESS,
    FIND_BY_ID_COUNTRY,
    FIND_BY_ID_COUNTRY_SUCCESS,
    CREATE_OR_EDIT_COUNTRY_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',
    name: '',
    prefix: '',
    code: '',
    ddi: '',
    status: 1,
    id: 0,
    lat: 0,
    lng: 0
}

const createOrEditCountry = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_COUNTRY:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_COUNTRY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case EDIT_COUNTRY:
            state = { ...state, loading: true }
            break
        case EDIT_COUNTRY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_COUNTRY:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_COUNTRY_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                name: action.payload.result.name,
                prefix: action.payload.result.prefix,
                status: action.payload.result.status,
                lat: action.payload.result.lat,
                lng: action.payload.result.lng,
                code: action.payload.result.code,
                ddi: action.payload.result.ddi,
                id: action.payload.result.id,
            }
            break
        case CREATE_OR_EDIT_COUNTRY_API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default createOrEditCountry
