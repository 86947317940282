import React from 'react';
import {Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import ButtonCustom from "../../../../components/ButtonCustom";
import {connect} from "react-redux";
import {createDriversCompany} from "../../../../store/companies/options/drivers/actions"
import {NavItem, NavLink} from "reactstrap";
import classnames from "classnames"
import {NotificationManager} from "react-notifications";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import {Form} from "react-bootstrap";
import Table from "../drivers/table/tableVehicles";


class FormVehiclesDrivers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            plate: '',
            model: '',
            activeTab: "1",

            columns: [
                {
                    id: 1,
                    name: '#',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Plate',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Model',
                    status: 1
                },
                {
                    id: 6,
                    name: 'Ações',
                    status: 1
                },
            ],

        }
        this.handleFields = this.handleFields.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);

    }


    componentDidMount() {
       // const {entity_id} = this.props.match.params

    }

    handleValidSubmit() {
        const {entity_id} = this.props.match.params
        console.log('submnit vehicle')
       
        this.createDriversCompany({
            plate: this.state.plate,
            model: this.state.model,
            driver_id: entity_id
        }, this.props.history)

    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }


    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }
  

    render() {
        return (
            <Card>
                <CardBody>
                    <Row className="align-items-md-center  mb-3">
                        <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                                <h4 className="card-title mb-4">{this.props.t("Vehicles")}</h4>
                            </div>
                            <div className="text-md-right ms-auto p-2">
                                <i onClick={this.props.closeLayoutDrawer} className="bx bx-x"></i>
                            </div>
                        </Col>
                    </Row>

                    <ul className="nav nav-tabs nav-tabs-custom">
                      {/*  <NavItem>
                            <NavLink
                             onClick={() => {
                                    if(this.state.activeTab === "2"){
                                        this.toggleTab("1")
                                    } 
                                }}
                                className={classnames({
                                    active: this.state.activeTab === "1",
                                })}
                            >
                                {this.props.t("Listing")}
                            </NavLink>
                        </NavItem>*/}
                        <NavItem>
                            <NavLink
                             onClick={() => {
                                    if(this.state.activeTab === "1"){
                                        this.toggleTab("2")
                                    } 
                                }}
                                className={classnames({
                                    active: this.state.activeTab === "1",
                                })}
                            >
                                {this.props.t("Form")}
                            </NavLink>
                        </NavItem>

                    </ul>
                    <TabContent
                        activeTab={this.state.activeTab}
                        className="py-4"
                    >
                      <TabPane tabId="1">
                           
                             <PerfectScrollbar style={{height: "410px"}}>
                                 <Row>
                                            <Col sm={6}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor=""
                                                           className="control-label">{this.props.t("Plate")}</label>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Informe a placa"
                                                        name="plate"
                                                        onChange={this.handleFields}
                                                        value={this.state.plate}/>

                                                    <ButtonCustom
                                                        isLoading={this.props.loading}
                                                        size="sm"
                                                        clazzContainer=" m-2"
                                                        clazz="btn btn-dark btn-sm m-2"
                                                        message_loaging="">
                                                        <button
                                                            className="btn btn-dark btn-sm"
                                                            onClick={(e) => {
                                                                   this.handleValidSubmit() 

                                                            }}>
                                                            Salvar
                                                        </button>
                                                    </ButtonCustom>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor=""
                                                           className="control-label">{this.props.t(" Brand and Modal")}</label>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Informe o Modelo"
                                                        name="model"
                                                        onChange={this.handleFields}
                                                        value={this.state.model}/>
                                                   
                                                </div>
                                            </Col>
                                          
                                        </Row>
                            </PerfectScrollbar>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>

        )
    }


}

FormVehiclesDrivers.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,

    rows: PropTypes.array,
    id: PropTypes.any,
    menu_category_id: PropTypes.any,
    createDriversCompany: PropTypes.func,

}
const mapStateToProps = state => {

    const {
        loading,
        refresh,
        id
    } = state.CreateOrEditDriversCompany
    return {
        loading,
        refresh,
        rows
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createDriversCompany,

})(
    (FormVehiclesDrivers)
)))
