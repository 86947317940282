import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TRIB_IPI,
    FILTER_PAGINATE_NF_TRIB_IPI,
    DELETE_NF_TRIB_IPI,
} from "./actionTypes"

import {
    allNFTribIPIPaginateSuccess,
    filtersAllNFTribIPIPaginateSuccess,
    deleteNFTribIPISuccess,
    apiPaginateNFTribIPIError
} from "./actions"


import {
    getNFTribIPIPaginate,
    getFilterNFTribIPIPaginate,
    delNFTribIPI
} from "../../../helpers/nf_ipi_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTribIPI({payload: {id, history}}) {
    try {

        const response = yield call(delNFTribIPI, id)

        yield put(deleteNFTribIPISuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTribIPIError(error))
    }
}

function* paginateNFTribIPI({payload: {params}}) {
    try {

        const response = yield call(getNFTribIPIPaginate, params)

        yield put(allNFTribIPIPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribIPIError(error))
    }
}

function* filtersPaginateNFTribIPI({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTribIPIPaginate, params)

        yield put(filtersAllNFTribIPIPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribIPIError(error))
    }
}


function* NFTribIPISaga() {
    yield takeEvery(PAGINATE_NF_TRIB_IPI, paginateNFTribIPI)
    yield takeEvery(FILTER_PAGINATE_NF_TRIB_IPI, filtersPaginateNFTribIPI)
    yield takeEvery(DELETE_NF_TRIB_IPI, deleteNFTribIPI)
}

export default NFTribIPISaga
