export const CREATE_PROMOTIONS = "CREATE_PROMOTIONS"
export const CREATE_PROMOTIONS_SUCCESS = "CREATE_PROMOTIONS_SUCCESS"

export const EDIT_PROMOTIONS = "EDIT_PROMOTIONS"
export const EDIT_PROMOTIONS_SUCCESS = "EDIT_PROMOTIONS_SUCCESS"

export const FIND_BY_ID_PROMOTIONS = "FIND_BY_ID_PROMOTIONS"
export const FIND_BY_ID_PROMOTIONS_SUCCESS = "FIND_BY_ID_PROMOTIONS_SUCCESS"

export const UPLOAD_PROMOTIONS = "UPLOAD_PROMOTIONS"
export const UPLOAD_PROMOTIONS_SUCCESS = "UPLOAD_PROMOTIONS_SUCCESS"

export const CREATE_OR_EDIT_PROMOTIONS_API_ERROR = "CREATE_OR_EDIT_PROMOTIONS_API_ERROR"
