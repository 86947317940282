import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_DISCOUNT_COUPONS_COMPANY,
    FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY,
    DELETE_DISCOUNT_COUPONS_COMPANY,
} from "./actionTypes"

import {
    allCouponsDiscountCompanyPaginateSuccess,
    filtersAllCouponsDiscountCompanyPaginateSuccess,
    deleteCouponsDiscountCompanySuccess,
    apiPaginateCouponsDiscountCompanyError
} from "./actions"


import {
    getDiscountCouponsCompanyPaginate,
    getFilterDiscountCouponsCompanyPaginate,
    delDiscountCouponsCompany
} from "../../../../../helpers/coupons_discount_company_helper"
import {NotificationManager} from "react-notifications";

function* deleteDiscountCouponsCompany({payload: {id, history}}) {
    try {

        const response = yield call(delDiscountCouponsCompany, id)

        yield put(deleteCouponsDiscountCompanySuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateCouponsDiscountCompanyError(error))
    }
}

function* paginateDiscountCouponsCompany({payload: {params}}) {
    try {

        const response = yield call(getDiscountCouponsCompanyPaginate, params)

        yield put(allCouponsDiscountCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCouponsDiscountCompanyError(error))
    }
}

function* filtersPaginateDiscountCouponsCompany({payload: {params}}) {
    try {

        const response = yield call(getFilterDiscountCouponsCompanyPaginate, params)

        yield put(filtersAllCouponsDiscountCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCouponsDiscountCompanyError(error))
    }
}


function* DiscountCouponsCompanysSaga() {
    yield takeEvery(PAGINATE_DISCOUNT_COUPONS_COMPANY, paginateDiscountCouponsCompany)
    yield takeEvery(FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY, filtersPaginateDiscountCouponsCompany)
    yield takeEvery(DELETE_DISCOUNT_COUPONS_COMPANY, deleteDiscountCouponsCompany)
}

export default DiscountCouponsCompanysSaga
