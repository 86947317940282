import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_STATE,
    EDIT_STATE,
    FIND_BY_ID_STATE
} from "./actionTypes"


import {
    createStateSuccess,
    editStateSuccess,
    findStateIdSuccess,
    apiCreateOrEditStateError
} from "./actions"


import {
    putEditState,
    postCreateState,
    getStateById
} from "../../helpers/state_helper"

function* findByIdState({payload: {id}}) {
    try {

        const response = yield call(getStateById, id)

        yield put(findStateIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditStateError(error))
    }
}

function* createState({payload: {params, history}}) {
    try {

        const response = yield call(postCreateState, params)

        yield put(createStateSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditStateError(error))
    }
}

function* editState({payload: {params, history}}) {
    try {

        const response = yield call(putEditState, params)

        yield put(editStateSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditStateError(error))
    }
}


function* createOrEditStateSaga() {
    yield takeEvery(CREATE_STATE, createState)
    yield takeEvery(EDIT_STATE, editState)
    yield takeEvery(FIND_BY_ID_STATE, findByIdState)
}

export default createOrEditStateSaga
