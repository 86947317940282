import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Table from "./table/index";
import { createServiceRadiusCompany } from "../../../../store/companies/options/service-radius/actions";
import { Form } from "react-bootstrap";
import ButtonCustom from "../../../../components/ButtonCustom";
import { AvField, AvForm } from "availity-reactstrap-validation";

//import IntlCurrencyInput from "react-intl-currency-input"
import InputMask from 'react-input-mask';

/*const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};*/
//import {google_maps} from "../../../../config";
//import {GoogleApiWrapper, Map, Marker, Polygon} from "google-maps-react";


/*import Leaflet from "leaflet";
import {Map, Marker, TileLayer} from "leaflet";
import "leaflet/dist/leaflet.css";

Leaflet.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png";

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
});*/

/*const LoadingContainer = () => <div>Loading...</div>
const center = { lat: 24.886, lng: -70.268 }

const paths = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.19 }
]

const options = {
    fillColor: "lightblue",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}*/
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            price: 0,
            company_id: 0,
            columns: [
                {
                    id: 1,
                    name: '#',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Price',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Time Wait',
                    status: 1
                },
                {
                    id: 4,
                    name: 'Ações',
                    status: 1
                },
            ],
            /* lat: 51.505,
              lng: -0.09,
              zoom: 13,*/

        }
        this.handleFields = this.handleFields.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);

    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }

    handleValidSubmit(event, values) {
        const { entity_id } = this.props.match.params

        console.log("VALORES ", values)
        this.props.createServiceRadiusCompany({
            company_id: entity_id,
            price: this.state.price,
            radius: values.select,
            time_wait: values.time_wait
        }, this.props.history)

    }


    render() {
        //const position = [{lat: this.state.lat, lng: this.state.lng}];

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Service Radius")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Service Radius Company")}
                            breadcrumbItem={this.props.t("Radius")} />
                        <Row>
                            <Col sm={8}>
                                <Card>
                                    <CardBody>
                                        <Table
                                            route_edit="/edit-category"
                                            columns={this.state.columns}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-3">
                                                        Informe o ráio e valores das taxas de entrega
                                                    </p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <span> Voltar</span>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>
                                                    <Row>
                                                        <Col sm={6} className="mb-2">
                                                            <AvField
                                                                id="radius"
                                                                value={this.props.radius}
                                                                onChange={this.handleFields}
                                                                type="select" name="select"
                                                                label={this.props.t("Radius")}>
                                                                <option value="1000">1km</option>
                                                                <option value="2000">2km</option>
                                                                <option value="3000">3km</option>
                                                                <option value="4000">4km</option>
                                                                <option value="5000">5km</option>
                                                                <option value="8000">8km</option>
                                                                <option value="12000">12km</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={6} className="mb-2">
                                                            <AvField
                                                                type="number"
                                                                className="form-control"
                                                                name="time_wait"
                                                                label={this.props.t("Time Wait")}
                                                                value={this.props.time_wait} />
                                                        </Col>
                                                        <Col sm={6} className="mb-2">
                                                            <div className="form-group">
                                                                <label>{this.props.t("Price")}</label>
                                                                {/*<IntlCurrencyInput
                                                                    className="mb-2 form-control"
                                                                    currency="BRL"
                                                                    config={currencyConfig}
                                                                    name="price"
                                                                    onChange={(e, value, maskedValue) => {
                                                                            const {name} = e.target
                                                                            this.setState({price: value});
                                                            }}
                                                                    value={this.state.price}/>*/}

                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="00,00"
                                                                    name="price"
                                                                    as={InputMask}
                                                                    mask={"99,99"}
                                                                    onChange={(e, value, maskedValue) => {
                                                                        const { name } = e.target
                                                                        this.setState({ price: value });
                                                                    }}
                                                                    value={parseFloat(this.state.price).toFixed(2)} />
                                                            </div>
                                                        </Col>


                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Adicionar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    google: PropTypes.object,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    radius: PropTypes.any,
    price: PropTypes.any,
    time_wait: PropTypes.any,
    company_id: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh
    } = state.CreateOrEditCompaniesServiceRadius
    return {
        loading,
        refresh,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createServiceRadiusCompany,

})(Index)))
