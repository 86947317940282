import {
    CREATE_DRIVERS_COMPANY,
    CREATE_DRIVERS_COMPANY_SUCCESS,
    EDIT_DRIVERS_COMPANY,
    EDIT_DRIVERS_COMPANY_SUCCESS,
    CREATE_OR_EDIT_DRIVERS_COMPANY_API_ERROR
} from "./actionTypes"

export const createDriversCompany = (params, history) => {
    return {
        type: CREATE_DRIVERS_COMPANY,
        payload: {params, history},
    }
}

export const createDriversCompanySuccess = params => {
    return {
        type: CREATE_DRIVERS_COMPANY_SUCCESS,
        payload: params,
    }
}

export const editDriversCompany = (params, history) => {
    return {
        type: EDIT_DRIVERS_COMPANY,
        payload: {params, history},
    }
}

export const editDriversCompanySuccess = result => {
    return {
        type: EDIT_DRIVERS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditDriversCompanyError = error => {
    return {
        type: CREATE_OR_EDIT_DRIVERS_COMPANY_API_ERROR,
        payload: error,
    }
}

