import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getDiscountCouponsById = data => get(url.GET_DISCOUNT_COUPOM_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delDiscountCoupons = data => del(url.DELETE_DISCOUNT_COUPOM.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateDiscountCoupons = data => post(url.POST_CREATE_DISCOUNT_COUPOM, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadDiscountCoupons = data => post(url.POST_UPLOAD_DISCOUNT_COUPOM, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditDiscountCoupons = data => put(url.PUT_EDIT_DISCOUNT_COUPOM, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getDiscountCouponsPaginate = ({page, limit}) => get(url.GET_DISCOUNT_COUPOM_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterDiscountCouponsPaginate = data => get(url.GET_DISCOUNT_COUPOM_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchDiscountCoupons = params => get(url.GET_SEARCH_DISCOUNT_COUPOM, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getDiscountCouponsPaginate,
    getFilterDiscountCouponsPaginate,
    putEditDiscountCoupons,
    postCreateDiscountCoupons,
    delDiscountCoupons,
    postUploadDiscountCoupons,
    getDiscountCouponsById,
    getSearchDiscountCoupons
}
