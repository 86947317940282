import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";
import {GET_PRODUCTS_PIZZA_BY_ID} from "./url_helper";

const getProductGenericById = data => get(url.GET_PRODUCTS_GENERIC_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getProductPizzaById = data => get(url.GET_PRODUCTS_PIZZA_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delProducts = data => del(url.DELETE_PRODUCT.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateProduct = data => post(url.POST_CREATE_PRODUCT, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadProduct = data => post(url.POST_UPLOAD_PRODUCT, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditProduct = data => put(url.PUT_EDIT_PRODUCT, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getProductsPaginate = ({menu_category_id,page, limit}) => get(url.GET_PRODUCTS_PAGINATE, { params: { menu_category_id,page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterProductsPaginate = data => get(url.GET_PRODUCTS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchProducts = params => get(url.GET_SEARCH_PRODUCT, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getProductsPaginate,
    getFilterProductsPaginate,
    putEditProduct,
    postCreateProduct,
    delProducts,
    postUploadProduct,
    getProductPizzaById,
    getProductGenericById,
    getSearchProducts
}
