import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import Table from "./table/index";
import {createDriversCompany} from "../../../../store/companies/options/drivers/actions";
import {Form} from "react-bootstrap";
import ButtonCustom from "../../../../components/ButtonCustom";
import {AvField, AvForm} from "availity-reactstrap-validation";


//import {google_maps} from "../../../../config";
//import {GoogleApiWrapper, Map, Marker, Polygon} from "google-maps-react";


/*import Leaflet from "leaflet";
import {Map, Marker, TileLayer} from "leaflet";
import "leaflet/dist/leaflet.css";

Leaflet.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png";

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
});*/

/*const LoadingContainer = () => <div>Loading...</div>
const center = { lat: 24.886, lng: -70.268 }

const paths = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.19 }
]

const options = {
    fillColor: "lightblue",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}*/
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            price: 0,
            company_id: 0,
            columns: [
                {
                    id: 1,
                    name: '#',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Name',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Contact',
                    status: 1
                },
                {
                    id: 4,
                    name: 'Ações',
                    status: 1
                },
            ],

        }
        this.handleFields = this.handleFields.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);

    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }

    handleValidSubmit(event, values) {
        const {entity_id} = this.props.match.params

       this.props.createDriversCompany({
            company_id: entity_id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
        }, this.props.history)

    }


    render() {
        //const position = [{lat: this.state.lat, lng: this.state.lng}];

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Drivers")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Drivers Company")}
                                     breadcrumbItem={this.props.t("Drivers")}/>
                        <Row>
                            <Col sm={8}>
                                <Card>
                                    <CardBody>
                                        <Table
                                            route_edit="/edit-category"
                                            columns={this.state.columns}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-3">
                                                        Preencha os campos com os dados do piloto Entregador(a)
                                                    </p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <span> Voltar</span>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>
                                                    <Row>
                                                        <Col sm={12} className="mb-2">
                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                name="first_name"
                                                                label={this.props.t("First Name")}
                                                                value={this.props.first_name}/>
                                                        </Col>
                                                         <Col sm={12} className="mb-2">
                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                name="last_name"
                                                                label={this.props.t("Last Name")}
                                                                value={this.props.last_name}/>
                                                        </Col>
                                                        <Col sm={12} className="mb-2">
                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                name="email"
                                                                label={this.props.t("E-mail")}
                                                                value={this.props.email}/>
                                                        </Col>
                                                        <Col sm={12} className="mb-2">
                                                            <AvField
                                                                type="text"
                                                                className="form-control"
                                                                name="phone"
                                                                label={this.props.t("Phone")}
                                                                value={this.props.phone}/>
                                                        </Col>


                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Adicionar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    google: PropTypes.object,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    radius: PropTypes.any,
    price: PropTypes.any,
    time_wait: PropTypes.any,
    company_id: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh
    } = state.CreateOrEditCompaniesDrivers
    return {
        loading,
        refresh,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createDriversCompany,

})(Index)))
