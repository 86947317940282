import {
    PAGINATE_SERVICE_RADIUS_COMPANY,
    PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS,
    FILTER_PAGINATE_SERVICE_RADIUS_COMPANY,
    FILTER_PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS,
    DELETE_SERVICE_RADIUS_COMPANY_SUCCESS,
    DELETE_SERVICE_RADIUS_COMPANY,
    API_ERROR_LIST_SERVICE_RADIUS_COMPANY,
} from "./actionTypes"


export const filtersAllServiceRadiusCompanyPaginate = params => {
    return {
        type: FILTER_PAGINATE_SERVICE_RADIUS_COMPANY,
        payload: {params},
    }
}

export const filtersAllServiceRadiusCompanyPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS,
        payload: {params},
    }
}

export const allServiceRadiusCompanyPaginate = params => {
    return {
        type: PAGINATE_SERVICE_RADIUS_COMPANY,
        payload: {params},
    }
}

export const allServiceRadiusCompanyPaginateSuccess = result => {
    return {
        type: PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const deleteServiceRadiusCompany = (id, history) => {
  return {
    type: DELETE_SERVICE_RADIUS_COMPANY,
    payload: {id, history} ,
  }
}

export const deleteServiceRadiusCompanySuccess = message => {
    return {
        type: DELETE_SERVICE_RADIUS_COMPANY_SUCCESS,
        payload: message,
    }
}

export const apiPaginateServiceRadiusCompanyError = error => {
  return {
    type: API_ERROR_LIST_SERVICE_RADIUS_COMPANY,
    payload: error,
  }
}

