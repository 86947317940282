import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_MODELS_SEGUIMENTS,
    EDIT_MODELS_SEGUIMENTS,
    FIND_BY_ID_MODELS_SEGUIMENTS,
    UPLOAD_MODELS_SEGUIMENTS
} from "./actionTypes"


import {
    createModelsSeguimentsSuccess,
    editModelsSeguimentsSuccess,
    findModelsSeguimentsIdSuccess,
    uploadModelsSeguimentsSuccess,
    apiCreateOrEditModelsSeguimentsError
} from "./actions"


import {
    putEditModelsSeguiments,
    postCreateModelsSeguiments,
    getModelsSeguimentsById,
    postUploadModelsSeguiments
} from "../../helpers/models_seguiments_helper"

function* findByIdModelsSeguiments({payload: {id}}) {
    try {

        const response = yield call(getModelsSeguimentsById, id)

        yield put(findModelsSeguimentsIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditModelsSeguimentsError(error))
    }
}

function* createModelsSeguiments({payload: {params, existsFiles,  history}}) {
    try {

        const response = yield call(postCreateModelsSeguiments, params)
        const {message, id, error} = response;

        yield put(createModelsSeguimentsSuccess({message, id, error, existsFiles}))


        NotificationManager.info(message, 'Aviso');

        if(!existsFiles){
            history.goBack()
        }



    } catch (error) {
        yield put(apiCreateOrEditModelsSeguimentsError(error))
    }
}

function* editModelsSeguiments({payload: {params, existsFiles, files,  history}}) {
    try {

        const response = yield call(putEditModelsSeguiments, params)

        yield put(editModelsSeguimentsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        if(!existsFiles){
            history.goBack()
        }


    } catch (error) {
        yield put(apiCreateOrEditModelsSeguimentsError(error))
    }
}

function* uploadModelsSeguiments({payload: {dataForm,  history}}) {
    try {



        console.log('uploadModelsSeguiments Data EXISTS ID ',dataForm )

        const response = yield call(postUploadModelsSeguiments, dataForm)

        yield put(uploadModelsSeguimentsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditModelsSeguimentsError(error))
    }
}


function* createOrEditCompaniesSaga() {
    yield takeEvery(CREATE_MODELS_SEGUIMENTS, createModelsSeguiments)
    yield takeEvery(EDIT_MODELS_SEGUIMENTS, editModelsSeguiments)
    yield takeEvery(FIND_BY_ID_MODELS_SEGUIMENTS, findByIdModelsSeguiments)
    yield takeLatest(UPLOAD_MODELS_SEGUIMENTS, uploadModelsSeguiments)
}

export default createOrEditCompaniesSaga
