import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getNFCnaeById = data => get(url.GET_NF_CNAE_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFCnae = data => del(url.DELETE_NF_CNAE.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFCnae = data => post(url.POST_CREATE_NF_CNAE, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFCnae = data => put(url.PUT_EDIT_NF_CNAE, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFCnaePaginate = ({page, limit}) => get(url.GET_NF_CNAE_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFCnaePaginate = data => get(url.GET_NF_CNAE_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFCnae = params => get(url.GET_SEARCH_NF_CNAE, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFCnaePaginate,
    getFilterNFCnaePaginate,
    putEditNFCnae,
    postCreateNFCnae,
    delNFCnae,
    getNFCnaeById,
    getSearchNFCnae
}
