import {
    SEARCH_COMPANIES,
    SEARCH_COMPANIES_SUCCESS,
    API_COMPANIES_ERROR,
} from "./actionTypes"


const initialState = {
    loading_company: false,
    data: []
}

const search_companies = (state = initialState, action) => {

    switch (action.type) {
        case SEARCH_COMPANIES:
            state = {...state, loading_company: true}
            break
        case SEARCH_COMPANIES_SUCCESS:
            state = {
                ...state,
                data: action.payload.data,
                loading_company: false
            }
            break
        case API_COMPANIES_ERROR:
            state = {...state, error: action.payload, loading_company: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default search_companies
