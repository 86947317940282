import {
    PAGINATE_NF_TRIB_COFINS,
    PAGINATE_NF_TRIB_COFINS_SUCCESS,
    FILTER_PAGINATE_NF_TRIB_COFINS,
    FILTER_PAGINATE_NF_TRIB_COFINS_SUCCESS,
    DELETE_NF_TRIB_COFINS_SUCCESS,
    DELETE_NF_TRIB_COFINS,
    API_ERROR_NF_TRIB_COFINS,
} from "./actionTypes"


export const filtersAllNFTribConfinsPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_COFINS,
        payload: {params},
    }
}

export const filtersAllNFTribConfinsPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_COFINS_SUCCESS,
        payload: {params},
    }
}

export const allNFTribConfinsPaginate = params => {
    return {
        type: PAGINATE_NF_TRIB_COFINS,
        payload: {params},
    }
}

export const allNFTribConfinsPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TRIB_COFINS_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTribConfins = (id, history) => {
  return {
    type: DELETE_NF_TRIB_COFINS,
    payload: {id, history} ,
  }
}

export const deleteNFTribConfinsSuccess = message => {
    return {
        type: DELETE_NF_TRIB_COFINS_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTribConfinsError = error => {
  return {
    type: API_ERROR_NF_TRIB_COFINS,
    payload: error,
  }
}

