import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getSchedulerCompanyById = data => get(url.GET_SCHEDULER_COMPANY_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delSchedulerCompany = data => del(url.DELETE_SCHEDULER_COMPANY.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateSchedulerCompany = data => post(url.POST_CREATE_SCHEDULER_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditSchedulerCompany = data => put(url.PUT_EDIT_SCHEDULER_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSchedulerCompanyPaginate = ({company_id, page, limit}) => get(url.GET_SCHEDULER_COMPANY_PAGINATE, { params: {company_id, page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterSchedulerCompanyPaginate = data => get(url.GET_SCHEDULER_COMPANY_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getSchedulerCompanyPaginate,
    getFilterSchedulerCompanyPaginate,
    putEditSchedulerCompany,
    postCreateSchedulerCompany,
    delSchedulerCompany,
    getSchedulerCompanyById
}
