import React from 'react';
import {Card, CardBody, CardHeader, CardTitle, Col, Row, Spinner} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    allProductPaginate,
    deleteProduct,
    apiPaginateProductError
} from "../../../../../store/actions";
import PropTypes from "prop-types";
import NumberFormat from "../../../../../components/NumberFormat";
import ReactDrawer from 'react-drawer';
import FormProductGeneric from "../FormProductGeneric";
import FormProductPizza from "../FormProductPizza";
import FormEditProductPizza from "../FormEditProductPizza";
import FormEditProductGeneric from "../FormEditProductGeneric";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            position: 'right',
            noOverlay: false,
            isEdit: false,
            id_edit: 0,
            has_addons: 0
        };

        this._delete_item = this._delete_item.bind(this);
        this._showingpaginate = this._showingpaginate.bind(this);
        this.handleClickPagination = this.handleClickPagination.bind(this);
        this._showingstatuslabel = this._showingstatuslabel.bind(this);

        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.setNoOverlay = this.setNoOverlay.bind(this);
        this.editEntity = this.editEntity.bind(this);
    }

    componentDidMount() {
        this._all_by_paginate(1, 10)
    }

    editEntity() {
        this.setState({isEdit: !this.state.isEdit})
    }

    setPosition(e) {
        this.setState({position: e.target.value});
    }

    setNoOverlay(e) {
        this.setState({noOverlay: e.target.checked});
    }

    toggleDrawer() {
        this.setState({open: !this.state.open, isEdit: false});
    }

    closeDrawer() {
        this.setState({open: false, openEdit: false, isEdit: false});
    }

    onDrawerClose() {
        this.setState({open: false, isEdit: false});
    }

    _showingstatuslabel(value) {
        switch (parseInt(value)) {
            case  0:
                return (<span className="badge pill bg-danger p-1" style={{fontSize: '90%'}}>Inativo</span>);
            case 1:
                return (<span className="badge pill bg-success p-1" style={{fontSize: '90%'}}> Ativo </span>);
            default:
                return (<span className="badge pill bg-success p-1" style={{fontSize: '90%'}}> Ativo </span>);
        }

    }

    async handleClickPagination(value) {
        await this._all_by_paginate(value, 10)
    }

    async _delete_item(id) {
        this.props.deleteProduct(id, this.props.history)

    }

    async _all_by_paginate(page, limit) {
        const {entity_id} = this.props.match.params

        this.props.allProductPaginate({
            page, limit, menu_category_id: entity_id
        })
    }

    _showingpaginate(current_page, last_page, total, from, to) {
        let pages = [];

        if (to <= total) {

            for (let i = 1; i <= last_page; i++) {

                pages.push(i)

            }

        }
        if (current_page <= last_page) {
            return (
                <div className="clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                        <li className="page-item" key={11 + 'ss'}>
                            <button disabled={1 === current_page}
                                    className={`page-link ${1 === current_page ? 'text-light bg-dark' : ''}`}
                                    onClick={() => this.handleClickPagination(1)}>Primeiro
                            </button>
                        </li>
                        {
                            pages.map((page, index) => {
                                return (<li key={index} className="page-item">
                                    <button disabled={page === current_page}
                                            className={`page-link ${page === current_page ? 'text-light bg-dark' : ''}`}
                                            onClick={() => this.handleClickPagination(page)}>{page}</button>
                                </li>)
                            })
                        }
                        <li key={12 + 'ss'} className="page-item ">
                            <button disabled={last_page === current_page}
                                    className={`page-link ${last_page === current_page ? 'text-light bg-dark' : ''}`}
                                    onClick={() => this.handleClickPagination(last_page)}>Último
                            </button>
                        </li>
                    </ul>
                </div>
            )
        }
    }

    render() {
        const {columns} = this.props;
        const {model_entity_id} = this.props.match.params
        if (this.props.refresh) {
            this._all_by_paginate(1, 10)
        }
        if (this.props.loading_delete) {
            this._all_by_paginate(1, 10)
        }

        return (
            <div>
                {
                    this.props.loading
                        ?
                        <div className="text-center" style={{width: '100%', position: 'absolute', paddingBottom: 20}}>
                            <Spinner size="sm" animation="border"
                                     style={{marginBottom: 20}}
                                     variant="success"/>
                        </div>
                        :
                        <>
                            <Row className="align-items-md-center  mb-3">
                                <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                         <span className="text-muted">
                                            Listagem
                                         </span>
                                    </div>
                                    <div className="text-md-right ms-auto">
                                        <button
                                            onClick={this.toggleDrawer}
                                            disabled={this.state.open && !this.state.noOverlay}
                                            className="btn btn-dark btn-sm text-light"
                                        >
                                            <i className="bx bx-plus"/>
                                            <span> Novo Cadastro</span>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            {columns.map(
                                                (column, index) => {
                                                    return (
                                                        <th key={index} className="text-center">{column.name}</th>
                                                    )
                                                })
                                            }

                                        </tr>
                                        </thead>
                                        <tbody>

                                        {

                                            this.props.rows &&
                                            this.props.rows.length > 0
                                                ?
                                                this.props.rows.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center">{row.sku}</td>
                                                            <td className="text-center">{row.name}</td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <Row>
                                                                        <Col sm={2} className="m-1">
                                                                            <a onClick={() =>
                                                                                this.setState({
                                                                                    openEdit: !this.state.openEdit,
                                                                                    isEdit: !this.state.isEdit,
                                                                                    id_edit: row.id,
                                                                                    has_addons: row.has_addons
                                                                                })
                                                                            }
                                                                               className="btn btn-success btn-sm">
                                                                                <i className="fas fa-pencil-alt text-light"></i>
                                                                            </a>
                                                                        </Col>
                                                                        <Col sm={2} className="m-1">
                                                                            <a onClick={() => confirm('Você tem certeza?') ? this._delete_item(row.id) : ''}
                                                                               className="btn btn-danger btn-sm">
                                                                                <i className="fas fa-trash text-light"></i>
                                                                            </a>
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    {columns.map(item => <td></td>)}
                                                </tr>
                                        }


                                        </tbody>
                                    </table>
                                </Col>

                            </Row>
                            <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                        <span className="mt-1 text-muted">
                                            {this.props.from} de {this.props.total} - página {this.props.current_page} de {this.props.last_page}
                                        </span>
                                        <div className="text-md-left ms-auto mt-2">
                                            <a
                                                onClick={() => {
                                                    this.props.history.goBack()
                                                }}
                                                className="btn btn-dark btn-sm text-light"
                                            >
                                                <i className="bx bx-left-arrow-circle"/>
                                                <span> Voltar</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="text-md-right ms-auto">
                                        {this._showingpaginate(
                                            this.props.current_page, this.props.last_page, this.props.total,
                                            this.props.from, this.props.to)}
                                    </div>
                                </Col>
                            </Row>
                        </>
                }
                <ReactDrawer
                    open={this.state.open}
                    position={this.state.position}
                    onClose={this.onDrawerClose}
                    noOverlay={this.state.noOverlay}>

                    {

                        parseInt(model_entity_id) === 1
                            ?
                            <FormProductPizza
                                closeLayoutDrawer={this.closeDrawer}
                            />
                            :
                            <FormProductGeneric
                                isEditing={this.state.isEdit}
                                id_edit={this.state.id_edit}
                                has_addons={this.state.has_addons}
                                closeLayoutDrawer={this.closeDrawer}
                            />

                    }


                </ReactDrawer>
                <ReactDrawer
                    open={this.state.openEdit}
                    position={this.state.position}
                    onClose={this.onDrawerClose}
                    noOverlay={this.state.noOverlay}>

                    {

                        parseInt(model_entity_id) === 1
                            ?
                            <FormEditProductPizza
                                isEditing={this.state.isEdit}
                                entity_id={this.state.id_edit}
                                funceditEntity={this.editEntity}
                                closeLayoutDrawer={this.closeDrawer}
                            />
                            :
                            <FormEditProductGeneric
                                isEditing={this.state.isEdit}
                                entity_id={this.state.id_edit}
                                has_addons={this.state.has_addons}
                                funceditEntity={this.editEntity}
                                closeLayoutDrawer={this.closeDrawer}
                            />

                    }


                </ReactDrawer>
            </div>


        )
    }


}

Index.propTypes = {
    apiPaginateProductError: PropTypes.any,
    error: PropTypes.any,
    loading: PropTypes.any,
    // refresh: PropTypes.any,
    loading_delete: PropTypes.any,
    rows: PropTypes.any,
    current_page: PropTypes.number,
    last_page: PropTypes.number,
    total: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,
    allProductPaginate: PropTypes.func,
    deleteProduct: PropTypes.func
}

const mapStateToProps = state => {
    const {
        loading,
        // refresh,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_delete
    } = state.Products
    return {
        loading,
        //refresh,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_delete
    }
}

export default withRouter(
    connect(mapStateToProps, {
        allProductPaginate,
        deleteProduct,
        apiPaginateProductError

    })(Index)
)
