import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { createDiscountCoupons } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import { Form } from "react-bootstrap";
//import IntlCurrencyInput from "react-intl-currency-input";

/*const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};*/

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: null,
            type: 1
        }
        this.handleRadioButtom = this.handleRadioButtom.bind(this)
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
    }

    componentDidMount() {


    }

    handleRadioButtom(event) {

        console.log('radio btn ',event.target.value);
        this.setState({ type: event.target.value })

    }
    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }


    handleValidSubmit(event, values) {
        this.props.createDiscountCoupons({
            title: values.name,
            percentage: values.percentage,
            code: values.code,
            type: this.state.type,
            status: this.state.status ? this.state.status : this.props.status,
        }, this.props.history)


    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Cupom de desconto</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Cupom de desconto"
                            breadcrumbItem="Cadastro" />
                        <Row>
                            <Col sm={12}>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={this.handleValidSubmit}>
                                    <Row>
                                        <Col sm={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Form")}</span>
                                                                <p className="card-title-desc mt-2">Preencha os campos
                                                                    abaixo, para
                                                                    concluir o cadastro.</p>
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <a
                                                                    onClick={() => {
                                                                        this.props.history.goBack()
                                                                    }}
                                                                    className="btn btn-dark btn-sm text-light"
                                                                >
                                                                    <i className="bx bx-left-arrow-circle" />
                                                                    <span> Voltar</span>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <Row>
                                                                <Col sm={10}>
                                                                    <AvField
                                                                        name="name"
                                                                        label={this.props.t("Title")}
                                                                        value={this.props.title}
                                                                        className="form-control"
                                                                        placeholder="Digite o título do cupom"
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <AvField
                                                                        value={this.state.status}
                                                                        onChange={this.handleFields}
                                                                        type="select" name="select"
                                                                        label={this.props.t("Status")}>
                                                                        <option value="1">Ativo</option>
                                                                        <option value="0">Inativo</option>
                                                                    </AvField>
                                                                </Col>
                                                                <Col sm={2} className="mb-2">
                                                                    <AvField
                                                                        name="percentage"
                                                                        label="Perc. % Desc."
                                                                        className="form-control"
                                                                        value={this.state.percentage}
                                                                        type="number"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={2} className="mb-2">
                                                                    <AvField
                                                                        name="code"
                                                                        label={this.props.t("Code")}
                                                                        className="form-control"
                                                                        value={this.state.code}
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </Col>

                                                                <Col sm={12} >
                                                                    <Row>
                                                                        <Col xl={6} sm={6}>
                                                                            <div className="mt-2" onChange={this.handleRadioButtom}>
                                                                                <h5 className="font-size-14 mb-4">Tipo de aplicação</h5>
                                                                                <div className="form-check mb-1">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="type"
                                                                                        id="type"
                                                                                        value="1"
                                                                                        defaultChecked
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="type"
                                                                                    >
                                                                                        Produtos
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check mb-1">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="type"
                                                                                        id="type"
                                                                                        value="2"
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="type"
                                                                                    >
                                                                                        Taxa de entrega
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="type"
                                                                                        id="type"
                                                                                        value="3"
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="type"
                                                                                    >
                                                                                        Cupons turno diurno / noturno
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </Col>

                                                                    </Row>
                                                                </Col>

                                                                <Col sm={12} className="pt-3">
                                                                    <ButtonCustom
                                                                        isLoading={this.props.loading}
                                                                        size="sm"
                                                                        clazzContainer="text-center"
                                                                        clazz="btn btn-info w-md"
                                                                        message_loaging="">
                                                                        <button
                                                                            className="btn btn-info w-md"
                                                                            type="submit">
                                                                            Salvar
                                                                        </button>
                                                                    </ButtonCustom>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {

    t: PropTypes.any,
    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    description: PropTypes.any,
    expiration: PropTypes.any,
    percentage: PropTypes.any,
    code: PropTypes.any,
    qtd: PropTypes.any,
    type: PropTypes.any,
    status: PropTypes.any,

    id: PropTypes.any,
    createPaymentMethod: PropTypes.func,
}


const mapStateToProps = state => {
    const {
        loading,
        loading_form,
        error,
        message,
        description,
        expiration,
        percentage,
        code,
        qtd,
        type,
        status
    } = state.CreateOrEditDiscountCoupons
    return {
        loading_form,
        loading,
        error,
        message,
        description,
        expiration,
        percentage,
        code,
        qtd,
        type,
        status
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    createDiscountCoupons,
})(
    (Index)
)))


