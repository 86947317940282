import {
    CREATE_PRODUCTS_COMPLEMENTS,
    CREATE_PRODUCTS_COMPLEMENTS_SUCCESS,
    EDIT_PRODUCTS_COMPLEMENTS,
    EDIT_PRODUCTS_COMPLEMENTS_SUCCESS,
    FIND_PRODUCTS_COMPLEMENTS_BY_PROD,
    FIND_PRODUCTS_COMPLEMENTS_BY_PROD_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_COMPLEMENTS_API_ERROR
} from "./actionTypes"


export const createProductComplement = (params, history) => {
    return {
        type: CREATE_PRODUCTS_COMPLEMENTS,
        payload: {params, history},
    }
}

export const createProductComplementSuccess = params => {
    return {
        type: CREATE_PRODUCTS_COMPLEMENTS_SUCCESS,
        payload: {params},
    }
} 

export const editProductComplement = (params, history) => {
    return {
        type: EDIT_PRODUCTS_COMPLEMENTS,
        payload: {params, history},
    }
}

export const editProductComplementSuccess = result => {
    return {
        type: EDIT_PRODUCTS_COMPLEMENTS_SUCCESS,
        payload: {result},
    }
}

export const findProductComplements = id => {
    console.log('chamou aqui11 ', id)
    return {
        type: FIND_PRODUCTS_COMPLEMENTS_BY_PROD,
        payload: {id},
    }
}

export const findProductComplementsSuccess = result => {
    return {
        type: FIND_PRODUCTS_COMPLEMENTS_BY_PROD_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditProductComplementError = error => {
    return {
        type: CREATE_OR_EDIT_PRODUCTS_COMPLEMENTS_API_ERROR,
        payload: error,
    }
}

