import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getCompanyById = data => get(url.GET_COMPANIES_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const getCompanyAboutUS = data => get(url.GET_COMPANIES_ABOUT_US.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const getCompanyCrt = data => get(url.GET_COMPANIES_CRT.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delCompany = data => del(url.DELETE_COMPANY.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateCompany = data => post(url.POST_CREATE_COMPANY, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateCrtCompany = data => post(url.POST_CREATE_CRT_COMPANY, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadCompany = data => post(url.POST_UPLOAD_COMPANY, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditCompany = data => put(url.PUT_EDIT_COMPANY, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getCompaniesPaginate = ({ page, limit }) => get(url.GET_COMPANIES_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterCompaniesPaginate = data => get(url.GET_COMPANIES_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchCompanies = params => get(url.GET_SEARCH_COMPANY, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getCompaniesPaginate,
    getFilterCompaniesPaginate,
    putEditCompany,
    postCreateCompany,
    postCreateCrtCompany,
    delCompany,
    postUploadCompany,
    getCompanyById,
    getCompanyAboutUS,
    getCompanyCrt,
    getSearchCompanies
}
