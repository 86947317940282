import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../../components/Breadcrumb";
import {connect} from "react-redux";
import {createSettings} from "../../../../store/actions"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../../components/ButtonCustom";
import Dropzone from "react-dropzone";
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            description: null,
            image: '',
            file: null,
            existsFile: false,
            displayColorPicker: false,
            displayBtnColorPicker: false,
            displayTextColorPicker: false,
            bg_color: '#000',
            text_color: '#000',
            btn_color: '#000'

        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleFieldsSelect = this.handleFieldsSelect.bind(this)
        this.handleDrop = this.handleDrop.bind(this);
        this.handleChangePickerColor = this.handleChangePickerColor.bind(this)
        this.handleClosePickerColor = this.handleClosePickerColor.bind(this)
        this.handleClickPickerColor = this.handleClickPickerColor.bind(this)

        this.handleChangePickerBtnColor = this.handleChangePickerBtnColor.bind(this)
        this.handleClosePickerBtnColor = this.handleClosePickerBtnColor.bind(this)
        this.handleClickPickerBtnColor = this.handleClickPickerBtnColor.bind(this)

        this.handleChangePickerTextColor = this.handleChangePickerTextColor.bind(this)
        this.handleClosePickerTextColor = this.handleClosePickerTextColor.bind(this)
        this.handleClickPickerTextColor = this.handleClickPickerTextColor.bind(this)


    }

    componentDidMount() {


    }

    handleClickPickerColor(){
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    handleClosePickerColor(){
        this.setState({ displayColorPicker: false })
    }
    handleChangePickerColor(color) {
        this.setState({ bg_color: color.hex })
    }

    handleClickPickerBtnColor(){
        this.setState({ displayBtnColorPicker: !this.state.displayBtnColorPicker })
    }

    handleClosePickerBtnColor(){
        this.setState({ displayBtnColorPicker: false })
    }
    handleChangePickerBtnColor(color) {
        this.setState({ btn_color: color.hex })
    }

    handleClickPickerTextColor(){
        this.setState({ displayTextColorPicker: !this.state.displayTextColorPicker })
    }

    handleClosePickerTextColor(){
        this.setState({ displayTextColorPicker: false })
    }
    handleChangePickerTextColor(color) {
        this.setState({ text_color: color.hex })
    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }
    handleFieldsSelect(event) {

        const { value} = event.target

        this.setState({status: value})
    }
    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)
        const {entity_id} = this.props



    }
    handleValidSubmit(event, values) {
        this.props.createSettings({
            key: 'intro_app_mobile',
            type: 1,
            title: values.title,
            value: {
                "title": values.title,
                "description": this.state.description,
                "bg_color": this.state.bg_color,
                "btn_color": this.state.btn_color,
                "text_color": this.state.text_color,
            },
            description: this.state.description,
            status: this.state.status,
            existsFile: this.state.existsFile,
            files: this.state.file,
        }, this.props.history)

    }

    render() {
        const styles_bg = reactCSS({
            'default': {
                color: {
                    width: '100px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.bg_color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    marginBottom: '5px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        const styles_btn = reactCSS({
            'default': {
                color: {
                    width: '100px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.btn_color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    marginBottom: '5px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        const styles_text_color = reactCSS({
            'default': {
                color: {
                    width: '100px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.text_color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    marginBottom: '5px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Intro Register")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Intro App")}
                                     breadcrumbItem={this.props.t("Register")}/>
                        <Row>
                            <Col sm={7}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos abaixo, para concluir o cadastro.</p>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>

                                                    <Row>
                                                        <Col sm={8}>
                                                            <AvField
                                                                name="title"
                                                                label={this.props.t("Title")}
                                                                className="form-control"
                                                                placeholder="Digite o título"
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>

                                                        <Col sm={4}>
                                                            <AvField
                                                                value={this.state.status} onChange={this.handleFieldsSelect}
                                                                type="select" name="select"
                                                                label={this.props.t("Status")} >
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <div className="form-group">
                                                                <label htmlFor=""  className="control-label">{this.props.t("Description")}</label>
                                                                <textarea
                                                                     name="description"
                                                                     className="form-control"
                                                                     value={this.state.description ? this.state.description : this.props.description}
                                                                      placeholder="Digite uma descrição"
                                                                     onChange={this.handleFields}
                                                                     rows={3}
                                                                     required
                                                                 />
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className='form-group mt-2'>
                                                                <label>{this.props.t("bg Color")}</label><br />
                                                                <div style={ styles_bg.swatch } onClick={ this.handleClickPickerColor }>
                                                                    <div style={ styles_bg.color } /></div>
                                                                { this.state.displayColorPicker ? <div style={ styles_bg.popover }>
                                                                    <div style={ styles_bg.cover } onClick={ this.handleClosePickerColor }/>
                                                                    <SketchPicker color={ this.state.bg_color } onChange={ this.handleChangePickerColor } />
                                                                </div> : null }
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className='form-group mt-2'>
                                                                <label>{this.props.t("Button Color")}</label><br />
                                                                <div style={ styles_btn.swatch } onClick={ this.handleClickPickerBtnColor }>
                                                                    <div style={ styles_btn.color } /></div>
                                                                { this.state.displayBtnColorPicker ? <div style={ styles_btn.popover }>
                                                                    <div style={ styles_btn.cover } onClick={ this.handleClosePickerBtnColor }/>
                                                                    <SketchPicker color={ this.state.btn_color } onChange={ this.handleChangePickerBtnColor } />
                                                                </div> : null }
                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className='form-group mt-2'>
                                                                <label>{this.props.t("Text Color")}</label><br />
                                                                <div style={ styles_text_color.swatch } onClick={ this.handleClickPickerTextColor }>
                                                                    <div style={ styles_text_color.color } /></div>
                                                                { this.state.displayTextColorPicker ? <div style={ styles_text_color.popover }>
                                                                    <div style={ styles_text_color.cover } onClick={ this.handleClosePickerTextColor }/>
                                                                    <SketchPicker color={ this.state.text_color } onChange={ this.handleChangePickerTextColor } />
                                                                </div> : null }
                                                            </div>
                                                        </Col>

                                                        <Col sm={12}  className="mt-3">
                                                                <ButtonCustom
                                                                    isLoading={this.props.loading}
                                                                    size="sm"
                                                                    clazzContainer="text-center"
                                                                    clazz="btn btn-info w-md"
                                                                    message_loaging="">
                                                                    <button
                                                                        className="btn btn-info w-md"
                                                                        type="submit">
                                                                        Salvar
                                                                    </button>
                                                                </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={5}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Image")}</span>
                                                    <p className="card-title-desc mt-3">Informe a imagem de introduçao</p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <button
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <i className="bx bx-left-arrow-circle"/>
                                                        <span> Voltar</span>
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <Dropzone onDrop={this.handleDrop}>

                                                    {({getRootProps, getInputProps}) => (

                                                        <div {...getRootProps({className: "dropzone"})}>
                                                            <input {...getInputProps()} />
                                                            {this.state.image !== ''
                                                                ?
                                                                <img style={{width: '100%', height: '100%'}}
                                                                     src={this.state.image}/>
                                                                : <p style={{marginTop: '30%'}}
                                                                     className="text-center">Arraste e
                                                                    solte ou
                                                                    clique
                                                                    para adicionar a imagem</p>
                                                            }

                                                        </div>

                                                    )}

                                                </Dropzone>
                                             </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    address: PropTypes.any,

    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    createSettings: PropTypes.func,
}


const mapStateToProps = state => {
    const {loading, error, message} = state.CreateOrEditSetting
    return {loading, error, message}
}

export default withTranslation()(withRouter(
    connect(mapStateToProps, {
        createSettings

    })(Index)
))
