import {
    CREATE_EDGES,
    CREATE_EDGES_SUCCESS,
    EDIT_EDGES,
    EDIT_EDGES_SUCCESS,
    CREATE_OR_EDIT_EDGES_API_ERROR
} from "./actionTypes"


export const createEdge = (params, history) => {
    return {
        type: CREATE_EDGES,
        payload: {params, history},
    }
}

export const createEdgeSuccess = params => {
    return {
        type: CREATE_EDGES_SUCCESS,
        payload: params,
    }
}

export const editEdge = (params, history) => {
    return {
        type: EDIT_EDGES,
        payload: {params, history},
    }
}

export const editEdgeSuccess = result => {
    return {
        type: EDIT_EDGES_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditEdgeError = error => {
    return {
        type: CREATE_OR_EDIT_EDGES_API_ERROR,
        payload: error,
    }
}

