import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

import Login from "./auth/login/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"

import Companies from "./companies/table/reducer"
import CreateOrEditCompanies from "./companies/reducer"

import CompaniesDiscountCoupons from "./companies/options/discount-coupons/table/reducer"
import CreateOrEditCompaniesDiscountCoupons from "./companies/options/discount-coupons/reducer"
import CompaniesPaymentMethods from "./companies/options/payment-methods/table/reducer"
import CreateOrEditCompaniesPaymentMethods from "./companies/options/payment-methods/reducer"

import CompaniesScheduler from "./companies/options/scheduler-company/table/reducer"
import CreateOrEditCompaniesScheduler from "./companies/options/scheduler-company/reducer"

import CompaniesServiceRadius from "./companies/options/service-radius/table/reducer"
import CreateOrEditCompaniesServiceRadius from "./companies/options/service-radius/reducer"

import CompaniesDrivers from "./companies/options/drivers/table/reducer"
import CreateOrEditCompaniesDrivers from "./companies/options/drivers/reducer"

import ModelsSeguiments from "./models-seguiments/table/reducer"
import CreateOrEditModelsSeguiments from "./models-seguiments/reducer"
import Categories from "./categories/table/reducer"
import CreateOrEditCategories from "./categories/reducer"
import GeoLocation from "./search/location/reducer"
import SearchCompanies from "./search/companies/reducer"
import SearchCategories from "./search/categories/reducer"

import Edges from "./categories/options/edges/table/reducer"
import CreateOrEditEdges from "./categories/options/edges/reducer"
import Pastas from "./categories/options/pastas/table/reducer"
import CreateOrEditPastas from "./categories/options/pastas/reducer"
import Sizes from "./categories/options/sizes/table/reducer"
import CreateOrEditSizes from "./categories/options/sizes/reducer"
import Products from "./categories/options/products/table/reducer"
import ProductsSizes from "./categories/options/products/sizes/reducer"
import CreateOrEditProducts from "./categories/options/products/reducer"
import CreateOrEditProductsComplementsGroup from "./categories/options/products/complements/reducer"

import Country from "./country/table/reducer"
import CreateOrEditCountry from "./country/reducer"
import State from "./state/table/reducer"
import CreateOrEditState from "./state/reducer"
import City from "./city/table/reducer"
import CreateOrEditCity from "./city/reducer"


import Promotions from "./promotions/table/reducer"
import CreateOrEditPromotions from "./promotions/reducer"
import PaymentsMethods from "./payments/methods/table/reducer"
import CreateOrEditPaymentMethod from "./payments/methods/reducer"
import DiscountCoupons from "./discount-coupons/table/reducer"
import CreateOrEditDiscountCoupons from "./discount-coupons/reducer"

import Confins from "./fiscal/cofins/reducer"
import Csosn from "./fiscal/csosn/reducer"
import IcmsCst from "./fiscal/icmscst/reducer"
import Ipi from "./fiscal/ipi/reducer"
import ModBcIcms from "./fiscal/modbcicms/reducer"
import ModBcIcmSt from "./fiscal/modbcicmsst/reducer"
import NfModelo from "./fiscal/modelo/reducer"
import NfOrigem from "./fiscal/origem/reducer"
import Pis from "./fiscal/pis/reducer"
import CFOP from "./fiscal/cfop/reducer"
import IBPT from "./fiscal/ibpt/reducer"
import TipoOperacao from "./fiscal/tipooperacao/reducer"
import CRT from "./fiscal/crt/reducer"
import CNAE from "./fiscal/cnae/reducer"
import CEST from "./fiscal/cest/reducer"
import SPEDITEM from "./fiscal/speditem/reducer"
import ClassificacaoProduto from "./fiscal/classificacao/produtos/reducer"
import Setting from "./settings/table/reducer"
import CreateOrEditSetting from "./settings/reducer"
import CreateOrEditTipoNF from "./fiscal/tiponf/reducer"

import CATEGORYMETA from "./social-midia/categoryMeta/reducer"
import CATEGORYGOOGLE from "./social-midia/categoryGoogle/reducer"

const rootReducer = combineReducers({
    Layout,
    Login,
    ForgetPassword,
    Companies,
    CreateOrEditCompanies,
    CompaniesDiscountCoupons,
    CreateOrEditCompaniesDiscountCoupons,
    CompaniesPaymentMethods,
    CreateOrEditCompaniesPaymentMethods,
    CompaniesScheduler,
    CreateOrEditCompaniesScheduler,
    CompaniesServiceRadius,
    CreateOrEditCompaniesServiceRadius,
    CompaniesDrivers,
    CreateOrEditCompaniesDrivers,
    GeoLocation,
    SearchCompanies,
    SearchCategories,
    ModelsSeguiments,
    CreateOrEditModelsSeguiments,
    Categories,
    CreateOrEditCategories,
    Edges,
    CreateOrEditEdges,
    Pastas,
    CreateOrEditPastas,
    Sizes,
    CreateOrEditSizes,
    Products,
    ProductsSizes,
    CreateOrEditProducts,
    CreateOrEditProductsComplementsGroup,
    PaymentsMethods,
    CreateOrEditPaymentMethod,
    DiscountCoupons,
    CreateOrEditDiscountCoupons,
    CreateOrEditPromotions,
    Promotions,
    Confins,
    Csosn,
    IcmsCst,
    Ipi,
    ModBcIcms,
    ModBcIcmSt,
    NfModelo,
    NfOrigem,
    Pis,
    CFOP,
    IBPT,
    CEST,
    TipoOperacao,
    CRT,
    CNAE,
    SPEDITEM,
    Country,
    CreateOrEditCountry,
    State,
    CreateOrEditState,
    City,
    CreateOrEditCity,
    Setting,
    CreateOrEditSetting,

    CreateOrEditTipoNF,
    ClassificacaoProduto,
    CATEGORYMETA,
    CATEGORYGOOGLE

})

export default rootReducer
