import React from 'react';
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ButtonCustom from "../../../../components/ButtonCustom";
import { connect } from "react-redux";
import { createProduct, findProductSizes, editProduct, uploadProducts } from "../../../../store/categories/options/products/actions"
import { createProductSize } from "../../../../store/categories/options/products/sizes/actions"
import { findSizesByIdCategories } from "../../../../store/actions"
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames"
//import IntlCurrencyInput from "react-intl-currency-input"
import InputMask from 'react-input-mask';
import { NotificationManager } from "react-notifications";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Form } from "react-bootstrap";

import Dropzone from "react-dropzone";
import GenericsServiceAPI from "../../../../helpers/GenericsServicesAPI";



/*const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};*/

class FormEditProductPizza extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            sku: '',
            image: '',
            product_id: 0,
            is_product: 1,
            activeTab: "1",
            prices: [],
            file: null,
            existsFile: false,
            sizes_removed: [],

            path_file_before_image: '',
            file_image: null,
            existsFile_image: false

        }
        this.handleFields = this.handleFields.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);

    }


    componentDidMount() {

        const { entity_id, isEditing } = this.props

        if (isEditing) {
            this.props.findProductSizes(entity_id)
            this.props.funceditEntity()

        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { entity_id, isEditing } = this.props

        if (isEditing) {
            this.props.findProductSizes(entity_id)
            this.props.funceditEntity()

        }


    }

    handleValidSubmit() {
        const { entity_id } = this.props.match.params
        const { sizes_removed } = this.state;

        let rowsSizes = [];
        this.props.sizes.map((item, index) => {
            // if(item.isSelected){
            rowsSizes.push(
                {
                    // product_id: this.props.product_id,
                    id: item.id,
                    isSelected: true,
                    product_id: item.product_id,
                    price: item.price ? item.price : 0
                }
            )
            //  }
        })
        if (sizes_removed.lenght > 0) {
            sizes_removed.map((item, index) => {
                rowsSizes.push(
                    {
                        // product_id: this.props.product_id,
                        id: item.id,
                        isSelected: false,
                        product_id: item.product_id,
                        price: item.price ? item.price : 0
                    }
                )
            })
        }

        this.props.editProduct({
            id: this.props.id,
            sku: this.state.sku,
            is_product: this.state.is_product,
            description: this.state.description,
            menu_category_id: entity_id,
            name: this.state.name,
            sizes: rowsSizes
        }, this.props.history)

        //console.log('PROPS ', this.props.product_id)
        //  console.log('PROPS ID ', this.props.id)

        // if(this.state.product_id > 0){

        //}
        /* } else {
             NotificationManager.info('Informe o tamanho do produto.', 'Aviso');
         }*/

    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)
        const { entity_id } = this.props
        var data = new FormData();
        data.append('id', entity_id);
        data.append('type_dir_file', 'image');
        data.append('files', file);
        data.append('path_file_before', this.state.path_file_before_image !== '' ? this.state.path_file_before_image : this.props.image);
        this.props.uploadProducts(data)

        //this.sendUpload(data, 'upload/products')

    }
    /* sendUpload(formData, url) {
  
         GenericsServiceAPI.upload(formData, url)
             .then(response => {
                 const { message } = response;
                 const { type_dir_file, front_cover, image } = response.data;
 
                 switch (type_dir_file) {
 
                     case 'image':
                         const { url_image } = response.data;
                         //this.props.url_image = url_image;
                         this.setState({ image: url_image, path_file_before_image: image });
                         break;
 
                 }
 
                 //   this.props.existsFiles = false
                 // this.props.loading = false
                 NotificationManager.info(message, 'Aviso!');
                 //history.goBack()
 
             })
             .catch(err => {
                 console.log('error upload ', err)
                 //  this.props.loading = false
                 // NotificationManager.warning(err, 'Ops!', 3000);
             })
             .then(response => {
 
             });
     }*/
    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }


    render() {
        const { url_image, loading_form } = this.props;

        return (
            <Card>
                <CardBody>
                    <Row className="align-items-md-center  mb-3">
                        <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                                <h4 className="card-title mb-4">{this.state.name ? this.state.name : this.props.name}</h4>
                            </div>
                            <div className="text-md-right ms-auto p-2">
                                <i onClick={this.props.closeLayoutDrawer} className="bx bx-x"></i>
                            </div>
                        </Col>
                    </Row>

                    <ul className="nav nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: parseInt(this.state.activeTab) === 1,
                                })}
                            >
                                Detalhe
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: parseInt(this.state.activeTab) === 2,
                                })}
                            >
                                Preço
                            </NavLink>
                        </NavItem>

                    </ul>
                    <TabContent
                        activeTab={this.state.activeTab}
                        className="py-4"
                    >
                        <TabPane tabId="1">
                            <PerfectScrollbar style={{ height: "410px" }}>
                                <Row>
                                    <Col sm={7}>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="form-group mt-2">
                                                    <label htmlFor=""
                                                        className="control-label">Sabor ou Nome</label>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Informe o sabor"
                                                        name="name"
                                                        onChange={this.handleFields}
                                                        value={this.props.name ? this.props.name : this.state.name} />

                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="form-group mt-2 mb-2">
                                                    <label htmlFor=""
                                                        className="control-label">Descrição</label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={8}
                                                        className="form-control"
                                                        placeholder="Descrição do produto"
                                                        name="description"
                                                        onChange={this.handleFields}
                                                        value={this.state.description ? this.state.description : this.props.description} />

                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={5}>
                                        <Row>
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <label
                                                        className="control-label">Foto</label>

                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <Dropzone onDrop={this.handleDrop}>

                                                    {({ getRootProps, getInputProps }) => (

                                                        <div {...getRootProps({ className: "dropzone" })}>
                                                            <input {...getInputProps()} />
                                                            {url_image !== ''
                                                                ?
                                                                <img className="thumbnail img-responsive img-icon-prod"
                                                                    src={url_image ? this.state.image !== '' ? this.state.image : url_image : this.state.image} />
                                                                : <p style={{ marginTop: '40%' }}
                                                                    className="text-center">Arraste e
                                                                    solte ou
                                                                    clique
                                                                    para adicionar a imagem</p>
                                                            }

                                                        </div>

                                                    )}

                                                </Dropzone>
                                            </Col>
                                            <Col sm={12}>
                                                <div className="form-group mt-2 mb-2">
                                                    <label htmlFor=""
                                                        className="control-label">{this.props.t("SKU")}</label>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="123"
                                                        name="sku"
                                                        onChange={this.handleFields}
                                                        value={this.state.sku ? this.state.sku : this.props.sku} />

                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </PerfectScrollbar>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>

                                {
                                    this.props.sizes &&
                                    this.props.sizes.map((item, index) => {

                                        return (
                                            <Col sm={this.props.sizes.length > 1 ? 6 : 8}>
                                                <Card>
                                                    <CardImg src="https://via.placeholder.com/100x100" />
                                                    <CardBody>
                                                        <Row>
                                                            <Col>

                                                                <a onClick={() => {
                                                                    this.state.sizes_removed.push(this.props.sizes[index])
                                                                    // this.setState({sizes_removed: [...this.state.sizes_removed, this.props.sizes[index]]})
                                                                    this.props.sizes.splice(index, 1)
                                                                    this.forceUpdate()

                                                                }}>

                                                                    <h4 className="text-start text-danger mt-1">
                                                                        <i className="mdi mdi-trash-can mdi-16px" />
                                                                        <small className="text-center text-muted">{item.name}</small>
                                                                    </h4>
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                        <div className="form-group mt-2 mb-2">
                                                            <div className="form-group mt-2 mb-2">
                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="00,00"
                                                                    name="price"
                                                                    as={InputMask}
                                                                    mask={"99,99"}
                                                                    onChange={(event) => {
                                                                        // console.log('ID ',this.state.data[index]['id'])

                                                                        const { name, value } = event.target
                                                                        this.props.sizes[index]['price'] = value;

                                                                        console.log({ name, value })
                                                                        console.log("======================")
                                                                        console.log('ID ', this.props.sizes[index]['price'])



                                                                    }}
                                                                    value={item.price} />
                                                                {/*<IntlCurrencyInput
                                                                className="mb-2 form-control"
                                                                currency="BRL"
                                                                config={currencyConfig}
                                                                name="price"
                                                                onChange={(e, value, maskedValue) => {
                                                                   // console.log('ID ',this.state.data[index]['id'])
                                                                   // console.log(this.state.data[index][name])

                                                                    const {name} = e.target
                                                                    this.props.sizes[index][name]= value;

                                                                   // console.log("======================")
                                                                  //  console.log('ID ',this.state.data[index]['id'])
                                                                   // console.log(this.state.data[index][name])


                                                                }}
                                                                value={item.price}/>*/}
                                                            </div>

                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )

                                    })
                                }

                            </Row>
                        </TabPane>
                    </TabContent>
                    <Row className="text-center fixed-bottom">
                        <Col className="p-2 mb-2">
                            <button
                                className="btn btn-danger w-md"
                                onClick={() => {
                                    if (parseInt(this.state.activeTab) === 2) {
                                        this.toggleTab("1")
                                    } else {
                                        this.props.closeLayoutDrawer()
                                    }
                                }}>
                                {parseInt(this.state.activeTab) === 2 ? 'Voltar' : 'Cancelar'}
                            </button>
                        </Col>
                        <Col className="d-inline">
                            <ButtonCustom
                                isLoading={this.props.loading}
                                size="sm"
                                clazzContainer="text-center"
                                clazz="btn btn-dark w-md"
                                message_loaging="">
                                <button
                                    className="btn btn-dark w-md"
                                    onClick={() => {
                                        if (parseInt(this.state.activeTab) === 2) {
                                            this.handleValidSubmit()
                                        } else {
                                            this.toggleTab("2")
                                        }

                                    }}>
                                    {parseInt(this.state.activeTab) === 2 ? 'Salvar' : 'Continuar'}
                                </button>
                            </ButtonCustom>

                        </Col>


                    </Row>
                </CardBody>
            </Card>

        )
    }


}

FormEditProductPizza.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    prod_size_created: PropTypes.any,

    rows: PropTypes.array,
    product_id: PropTypes.any,
    name: PropTypes.any,
    description: PropTypes.any,
    sku: PropTypes.any,
    status: PropTypes.any,
    id: PropTypes.any,
    menu_category_id: PropTypes.any,
    createProduct: PropTypes.func,
    createProductSize: PropTypes.func,
    editProduct: PropTypes.func,
    findSizesByIdCategories: PropTypes.func,
    findProductSizes: PropTypes.func,
    funceditEntity: PropTypes.func,
    uploadProducts: PropTypes.func,
    isEditing: PropTypes.any,
    entity_id: PropTypes.any,

    image: PropTypes.any,
    sizes: PropTypes.any,
    url_image: PropTypes.any,
    type_dir_file: PropTypes.any,


}
const mapStateToProps = state => {


    const {
        loading,
        refresh,
        id,
        name,
        sku,
        description,
        image,
        status,
        menu_category_id,
        sizes,
        product_id,
        url_image,
        type_dir_file
    } = state.CreateOrEditProducts

    //  console.log("NAME ", name)

    /* const {
         //  name,
         // sku,
         qtd_flavors,
         qtd_pieces,
         // status,
         // menu_category_id,
         rows,
         prod_size_created
     } = state.CreateOrEditSizes*/
    return {
        //prod_size_created,
        description,
        image,
        sizes,
        loading,
        refresh,
        name,
        product_id,
        id,
        sku,
        url_image,
        type_dir_file,
        //  qtd_flavors,
        //   qtd_pieces,
        status,
        menu_category_id,
        //    rows,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createProduct,
    editProduct,
    createProductSize,
    findSizesByIdCategories,
    findProductSizes,
    uploadProducts

})(
    (FormEditProductPizza)
)))
