import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavItem, NavLink, TabContent, TabPane, Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Breadcrumb";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classnames from "classnames"
import TableCofins from "./tableCofins";
import TableModelo from './tableModelo';
import TableOrigem from './tableOrigem';
import TableCSOSN from './tableCSOSN';
import TableICMSCST from './tableICMSCST';
import TableModBcICMS from './tableModBcICMS';
import TableModBcICMSST from './tableModBcICMSST';
import TablePIS from './tablePIS';
import TableIPI from './tableIPI';
import TableCFOP from './tableCFOP';
import TableCrt from './tableCrt';
import TableTipoOperacao from './tableTipoOperacao';
import TableCNAE from './tableCNAE';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            tabs: [
                {
                    id: 1,
                    name: 'MODELO',
                    status: 1
                },
                {
                    id: 2,
                    name: 'ORIGEM',
                    status: 1
                },
                {
                    id: 3,
                    name: 'COFINS',
                    status: 1
                },
                {
                    id: 4,
                    name: 'CSOSN',
                    status: 1
                },
                {
                    id: 5,
                    name: 'ICMS ST',
                    status: 1
                },
                {
                    id: 6,
                    name: 'MOD. BC. ICMS',
                    status: 1
                },
                {
                    id: 7,
                    name: 'MOD BC. ICMS ST',
                    status: 1
                },
                {
                    id: 8,
                    name: 'PIS',
                    status: 1
                },
                {
                    id: 9,
                    name: 'IPI',
                    status: 1
                },

                {
                    id: 10,
                    name: 'Tipo Operação',
                    status: 1
                },
                {
                    id: 11,
                    name: 'Cód. Regime Trib. (CRT)',
                    status: 1
                },
                {
                    id: 12,
                    name: 'CNAE',
                    status: 1
                },
                {
                    id: 13,
                    name: 'CFOP',
                    status: 1
                },

            ],
            columns: [
                {
                    id: 1,
                    name: 'Name',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Status',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Ações',
                    status: 1
                },
            ],
        }

    }

    componentDidMount() {

    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tributação</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Tributação" breadcrumbItem={this.props.t("List")} />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>

                                        <ul className="nav nav-tabs nav-tabs-custom">
                                            {
                                                this.state.tabs.map((item, index) => {
                                                    return (
                                                        <NavItem key={index}>
                                                            <NavLink

                                                                onClick={(e) => {
                                                                    console.log('clicou ', e)
                                                                    console.log('clicou ', item)
                                                                    console.log('clicou ', index)
                                                                    this.setState({ activeTab: index })
                                                                }}
                                                                className={classnames({
                                                                    active: this.state.activeTab === index,
                                                                })}
                                                            >
                                                                {item.name}
                                                            </NavLink>
                                                        </NavItem>
                                                    )
                                                })
                                            }


                                        </ul>
                                        <div style={{ paddingBottom: 20 }}>
                                            <TabContent
                                                activeTab={this.state.activeTab}
                                                className="p-2 "
                                            >
                                                <TabPane tabId={0}>
                                                    <TableModelo
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={1}>
                                                    <TableOrigem
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <TableCofins
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <TableCSOSN
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <TableICMSCST
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={5}>
                                                    <TableModBcICMS
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={6}>
                                                    <TableModBcICMSST
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={7}>
                                                    <TablePIS
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={8}>
                                                    <TableIPI
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>

                                                <TabPane tabId={9}>
                                                    <TableTipoOperacao
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={10}>
                                                    <TableCrt
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={11}>
                                                    <TableCNAE
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={12}>
                                                    <TableCFOP
                                                        route_edit=""
                                                        columns={this.state.columns}
                                                    />
                                                </TabPane>

                                            </TabContent >
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
}
export default withRouter(withTranslation()(Index))
