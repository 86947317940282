import {
    PAGINATE_NF_TRIB_IPI,
    PAGINATE_NF_TRIB_IPI_SUCCESS,
    FILTER_PAGINATE_NF_TRIB_IPI,
    FILTER_PAGINATE_NF_TRIB_IPI_SUCCESS,
    DELETE_NF_TRIB_IPI_SUCCESS,
    DELETE_NF_TRIB_IPI,
    API_ERROR_NF_TRIB_IPI,
} from "./actionTypes"


export const filtersAllNFTribIPIPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_IPI,
        payload: {params},
    }
}

export const filtersAllNFTribIPIPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_IPI_SUCCESS,
        payload: {params},
    }
}

export const allNFTribIPIPaginate = params => {
    return {
        type: PAGINATE_NF_TRIB_IPI,
        payload: {params},
    }
}

export const allNFTribIPIPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TRIB_IPI_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTribIPI = (id, history) => {
  return {
    type: DELETE_NF_TRIB_IPI,
    payload: {id, history} ,
  }
}

export const deleteNFTribIPISuccess = message => {
    return {
        type: DELETE_NF_TRIB_IPI_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTribIPIError = error => {
  return {
    type: API_ERROR_NF_TRIB_IPI,
    payload: error,
  }
}

