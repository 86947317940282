export const CREATE_PRODUCTS_COMPLEMENTS = "CREATE_PRODUCTS_COMPLEMENTS"
export const CREATE_PRODUCTS_COMPLEMENTS_SUCCESS = "CREATE_PRODUCTS_COMPLEMENTS_SUCCESS"

export const EDIT_PRODUCTS_COMPLEMENTS = "EDIT_PRODUCTS_COMPLEMENTS"
export const EDIT_PRODUCTS_COMPLEMENTS_SUCCESS = "EDIT_PRODUCTS_COMPLEMENTS_SUCCESS"

export const FIND_PRODUCTS_COMPLEMENTS_BY_PROD = "FIND_PRODUCTS_COMPLEMENTS_BY_PROD"
export const FIND_PRODUCTS_COMPLEMENTS_BY_PROD_SUCCESS = "FIND_PRODUCTS_COMPLEMENTS_BY_PROD_SUCCESS"

export const CREATE_OR_EDIT_PRODUCTS_COMPLEMENTS_API_ERROR = "CREATE_OR_EDIT_PRODUCTS_COMPLEMENTS_API_ERROR"
