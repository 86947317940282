import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { editDiscountCoupons, findByIdDiscountCoupons } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
//import IntlCurrencyInput from "react-intl-currency-input";

/*const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};*/

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: null,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findByIdDiscountCoupons(entity_id)


    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleValidSubmit(event, values) {
        this.props.editDiscountCoupons({
            id: this.props.id,
            title: values.name,
            percentage: values.percentage,
            description: values.description,
            status: this.state.status ? this.state.status : this.props.status,
        }, this.props.history)

    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Cupom de desconto</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Cupom de desconto"
                            breadcrumbItem="Editar" />
                        <Row>
                            {
                                this.props.loading_form
                                    ?
                                    <Col className="col-12">
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            paddingBottom: 20
                                                        }}>
                                                            <Spinner size="sm" animation="border"
                                                                style={{ marginBottom: 20 }}
                                                                variant="success" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <Col sm={12}>
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={this.handleValidSubmit}>
                                            <Row>
                                            <Col sm={10} className="mt-2">
                                                    <AvField
                                                        name="name"
                                                        label={this.props.t("Title")}
                                                        value={this.props.title}
                                                        className="form-control"
                                                        placeholder="Digite o título do cupom"
                                                        type="text"
                                                        required
                                                    />
                                                </Col>
                                                <Col sm={2} className="mb-2">
                                                    <AvField
                                                        value={this.props.status ? this.props.status : this.state.status}
                                                        onChange={this.handleFields}
                                                        type="select" name="select"
                                                        label={this.props.t("Status")}>
                                                        <option value="1">Ativo</option>
                                                        <option value="0">Inativo</option>
                                                    </AvField>
                                                </Col>
                                                <Col sm={2} className="mb-2">
                                                    <AvField
                                                        name="percentage"
                                                        label="Perc. % Desc."
                                                        value={this.props.percentage}
                                                        className="form-control"
                                                        placeholder="5%"
                                                        type="number"
                                                        required
                                                    />
                                                </Col>
                                                <Col sm={2} className="mb-2">
                                                    <AvField
                                                        name="code"
                                                        label={this.props.t("Code")}
                                                        className="form-control"
                                                        value={this.props.code}
                                                        type="text"
                                                        required
                                                    />
                                                </Col>
                                                <Col sm={12} className="pt-3">
                                                    <ButtonCustom
                                                        isLoading={this.props.loading}
                                                        size="sm"
                                                        clazzContainer="text-center"
                                                        clazz="btn btn-info w-md"
                                                        message_loaging="">
                                                        <button
                                                            className="btn btn-info w-md"
                                                            type="submit">
                                                            Salvar
                                                        </button>
                                                    </ButtonCustom>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </Col>
                            }

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,


    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    description: PropTypes.any,
    expiration: PropTypes.any,
    percentage: PropTypes.any,
    code: PropTypes.any,
    qtd: PropTypes.any,
    type: PropTypes.any,
    status: PropTypes.any,

    editDiscountCoupons: PropTypes.func,
    findByIdDiscountCoupons: PropTypes.func,

}


const mapStateToProps = state => {

    const {
        loading,
        loading_form,
        error,
        message,
        percentage,
        code,
        type,
        status
    } = state.CreateOrEditDiscountCoupons
    return {
        loading_form,
        loading,
        error,
        message,
        percentage,
        code,
        type,
        status
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    editDiscountCoupons,
    findByIdDiscountCoupons
})(
    (Index)
)))


