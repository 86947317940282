import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const importMetaFacebookAPI = data => post(url.IMPORT_META_FACEBOOK, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getMetaFacebookById = data => get(url.GET_META_FACEBOOK_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delMetaFacebook = data => del(url.DELETE_META_FACEBOOK.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateMetaFacebook = data => post(url.POST_CREATE_META_FACEBOOK, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditMetaFacebook = data => put(url.PUT_EDIT_META_FACEBOOK, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getMetaFacebookPaginate = ({ page, limit }) => get(url.GET_META_FACEBOOK_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterMetaFacebookPaginate = data => get(url.GET_META_FACEBOOK_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchMetaFacebook = params => get(url.GET_SEARCH_META_FACEBOOK, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getMetaFacebookPaginate,
    getFilterMetaFacebookPaginate,
    putEditMetaFacebook,
    postCreateMetaFacebook,
    delMetaFacebook,
    getMetaFacebookById,
    getSearchMetaFacebook,
    importMetaFacebookAPI
}
