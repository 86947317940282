import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_PASTAS,
    EDIT_PASTAS,
} from "./actionTypes"


import {
    createPastaSuccess,
    editPastaSuccess,
    apiCreateOrEditPastaError
} from "./actions"


import {
    putEditPastas,
    postCreatePastas,
} from "../../../../helpers/pastas_helper"


function* createPastas({payload: {params,  history}}) {
    try {

        const response = yield call(postCreatePastas, params)
        const {message, id, error} = response;

        yield put(createPastaSuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditPastaError(error))
    }
}

function* editPastas({payload: {params,   history}}) {
    try {

        const response = yield call(putEditPastas, params)

        yield put(editPastaSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditPastaError(error))
    }
}



function* createOrEditPastasSaga() {
    yield takeEvery(CREATE_PASTAS, createPastas)
    yield takeEvery(EDIT_PASTAS, editPastas)
}

export default createOrEditPastasSaga
