import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TIPO_OPERACAO,
    FILTER_PAGINATE_NF_TIPO_OPERACAO,
    DELETE_NF_TIPO_OPERACAO,
} from "./actionTypes"

import {
    allNFTipoOperacaoPaginateSuccess,
    filtersAllNFTipoOperacaoPaginateSuccess,
    deleteNFTipoOperacaoSuccess,
    apiPaginateNFTipoOperacaoError
} from "./actions"


import {
    getNFTipoOperacaoPaginate,
    getFilterNFTipoOperacaoPaginate,
    delNFTipoOperacao
} from "../../../helpers/nf_tipo_operacao_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTipoOperacao({payload: {id, history}}) {
    try {

        const response = yield call(delNFTipoOperacao, id)

        yield put(deleteNFTipoOperacaoSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTipoOperacaoError(error))
    }
}

function* paginateNFTipoOperacao({payload: {params}}) {
    try {

        const response = yield call(getNFTipoOperacaoPaginate, params)

        yield put(allNFTipoOperacaoPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTipoOperacaoError(error))
    }
}

function* filtersPaginateNFTipoOperacao({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTipoOperacaoPaginate, params)

        yield put(filtersAllNFTipoOperacaoPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTipoOperacaoError(error))
    }
}


function* NFTipoOperacaoSaga() {
    yield takeEvery(PAGINATE_NF_TIPO_OPERACAO, paginateNFTipoOperacao)
    yield takeEvery(FILTER_PAGINATE_NF_TIPO_OPERACAO, filtersPaginateNFTipoOperacao)
    yield takeEvery(DELETE_NF_TIPO_OPERACAO, deleteNFTipoOperacao)
}

export default NFTipoOperacaoSaga
