export const PAGINATE_DRIVERS_COMPANY = "PAGINATE_DRIVERS_COMPANY"
export const PAGINATE_DRIVERS_COMPANY_SUCCESS = "PAGINATE_DRIVERS_COMPANY_SUCCESS"

export const FILTER_PAGINATE_DRIVERS_COMPANY = "FILTER_PAGINATE_DRIVERS_COMPANY"
export const FILTER_PAGINATE_DRIVERS_COMPANY_SUCCESS = "FILTER_PAGINATE_DRIVERS_COMPANY_SUCCESS"

export const DELETE_DRIVERS_COMPANY = "DELETE_DRIVERS_COMPANY"
export const DELETE_DRIVERS_COMPANY_SUCCESS = "DELETE_DRIVERS_COMPANY_SUCCESS"

export const API_ERROR_LIST_DRIVERS_COMPANY = "PAGINATE_DRIVERS_COMPANY_API_ERROR"
