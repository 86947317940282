import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getPaymentMethodById = data => get(url.GET_PAYMENT_METHOD_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delPaymentMethod = data => del(url.DELETE_PAYMENT_METHOD.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreatePaymentMethod = data => post(url.POST_CREATE_PAYMENT_METHOD, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadPaymentMethod = data => post(url.POST_UPLOAD_PAYMENT_METHOD, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditPaymentMethod = data => put(url.PUT_EDIT_PAYMENT_METHOD, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getPaymentMethodsPaginate = ({page, limit}) => get(url.GET_PAYMENT_METHOD_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterPaymentMethodsPaginate = data => get(url.GET_PAYMENT_METHOD_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchPaymentMethods = params => get(url.GET_SEARCH_PAYMENT_METHOD, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getPaymentMethodsPaginate,
    getFilterPaymentMethodsPaginate,
    putEditPaymentMethod,
    postCreatePaymentMethod,
    delPaymentMethod,
    postUploadPaymentMethod,
    getPaymentMethodById,
    getSearchPaymentMethods
}
