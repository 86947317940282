import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const importTabIBPTAPI = data => post(url.IMPORT_TAB_IBPT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getTabIBPTById = data => get(url.GET_TAB_IBPT_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delTabIBPT = data => del(url.DELETE_TAB_IBPT.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateTabIBPT = data => post(url.POST_CREATE_TAB_IBPT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditTabIBPT = data => put(url.PUT_EDIT_TAB_IBPT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getTabIBPTPaginate = ({ page, limit }) => get(url.GET_TAB_IBPT_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterTabIBPTPaginate = data => get(url.GET_TAB_IBPT_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchTabIBPT = params => get(url.GET_SEARCH_TAB_IBPT, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getTabIBPTPaginate,
    getFilterTabIBPTPaginate,
    putEditTabIBPT,
    postCreateTabIBPT,
    delTabIBPT,
    getTabIBPTById,
    getSearchTabIBPT,
    importTabIBPTAPI
}
