import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_CITY,
    EDIT_CITY,
    FIND_BY_ID_CITY
} from "./actionTypes"


import {
    createCitySuccess,
    editCitySuccess,
    findCityIdSuccess,
    apiCreateOrEditCityError
} from "./actions"


import {
    putEditCity,
    postCreateCity,
    getCityById
} from "../../helpers/city_helper"

function* findByIdCity({payload: {id}}) {
    try {

        const response = yield call(getCityById, id)

        yield put(findCityIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditCityError(error))
    }
}

function* createCity({payload: {params, history}}) {
    try {

        const response = yield call(postCreateCity, params)

        yield put(createCitySuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditCityError(error))
    }
}

function* editCity({payload: {params, history}}) {
    try {

        const response = yield call(putEditCity, params)

        yield put(editCitySuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditCityError(error))
    }
}


function* createOrEditCitySaga() {
    yield takeEvery(CREATE_CITY, createCity)
    yield takeEvery(EDIT_CITY, editCity)
    yield takeEvery(FIND_BY_ID_CITY, findByIdCity)
}

export default createOrEditCitySaga
