import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TRIB_ICMSCST,
    FILTER_PAGINATE_NF_TRIB_ICMSCST,
    DELETE_NF_TRIB_ICMSCST,
} from "./actionTypes"

import {
    allNFTribICMSCSTPaginateSuccess,
    filtersAllNFTribICMSCSTPaginateSuccess,
    deleteNFTribICMSCSTSuccess,
    apiPaginateNFTribICMSCSTError
} from "./actions"


import {
    getNFTribICMSCSTPaginate,
    getFilterNFTribICMSCSTPaginate,
    delNFTribICMSCST
} from "../../../helpers/nf_icms_cst_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTribICMSCST({payload: {id, history}}) {
    try {

        const response = yield call(delNFTribICMSCST, id)

        yield put(deleteNFTribICMSCSTSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTribICMSCSTError(error))
    }
}

function* paginateNFTribICMSCST({payload: {params}}) {
    try {

        const response = yield call(getNFTribICMSCSTPaginate, params)

        yield put(allNFTribICMSCSTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribICMSCSTError(error))
    }
}

function* filtersPaginateNFTribICMSCST({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTribICMSCSTPaginate, params)

        yield put(filtersAllNFTribICMSCSTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribICMSCSTError(error))
    }
}


function* NFTribICMSCSTSaga() {
    yield takeEvery(PAGINATE_NF_TRIB_ICMSCST, paginateNFTribICMSCST)
    yield takeEvery(FILTER_PAGINATE_NF_TRIB_ICMSCST, filtersPaginateNFTribICMSCST)
    yield takeEvery(DELETE_NF_TRIB_ICMSCST, deleteNFTribICMSCST)
}

export default NFTribICMSCSTSaga
