import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_PRODUCTS_SIZES,
    EDIT_PRODUCTS_SIZES,
} from "./actionTypes"


import {
    createProductSizeSuccess,
    editProductSizeSuccess,
    apiCreateOrEditProductSizeError
} from "./actions"


import {
    putEditProductSize,
    postCreateProductSize,
} from "../../../../../helpers/products_size_helper"


function* createProductsSize({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateProductSize, params)
        const {message, id, error} = response;

        yield put(createProductSizeSuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        //history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditProductSizeError(error))
    }
}

function* editProductsSize({payload: {params,   history}}) {
    try {

        const response = yield call(putEditProductSize, params)

        yield put(editProductSizeSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditProductSizeError(error))
    }
}



function* createOrEditProductsSizeSaga() {
    yield takeEvery(CREATE_PRODUCTS_SIZES, createProductsSize)
    yield takeEvery(EDIT_PRODUCTS_SIZES, editProductsSize)
}

export default createOrEditProductsSizeSaga
