import {
    CREATE_PAYMENT_METHODS_COMPANY,
    CREATE_PAYMENT_METHODS_COMPANY_SUCCESS,
    EDIT_PAYMENT_METHODS_COMPANY,
    EDIT_PAYMENT_METHODS_COMPANY_SUCCESS,
    CREATE_OR_EDIT_PAYMENT_METHODS_COMPANY_API_ERROR
} from "./actionTypes"


export const createPaymentMethodsCompany = (params, history) => {
    return {
        type: CREATE_PAYMENT_METHODS_COMPANY,
        payload: {params, history},
    }
}

export const createPaymentMethodsCompanySuccess = params => {
    return {
        type: CREATE_PAYMENT_METHODS_COMPANY_SUCCESS,
        payload: params,
    }
}

export const editPaymentMethodsCompany = (params, history) => {
    return {
        type: EDIT_PAYMENT_METHODS_COMPANY,
        payload: {params, history},
    }
}

export const editPaymentMethodsCompanySuccess = result => {
    return {
        type: EDIT_PAYMENT_METHODS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditPaymentMethodsCompanyError = error => {
    return {
        type: CREATE_OR_EDIT_PAYMENT_METHODS_COMPANY_API_ERROR,
        payload: error,
    }
}

