import {
    PAGINATE_CLASSIFICACAO_PRODUTO,
    PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS,
    FILTER_PAGINATE_CLASSIFICACAO_PRODUTO,
    FILTER_PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS,
    DELETE_CLASSIFICACAO_PRODUTO_SUCCESS,
    DELETE_CLASSIFICACAO_PRODUTO,
    CREATE_CLASSIFICACAO_PRODUTO,
    CREATE_CLASSIFICACAO_PRODUTO_SUCCESS,
    EDIT_CLASSIFICACAO_PRODUTO,
    EDIT_CLASSIFICACAO_PRODUTO_SUCCESS,
    FIND_BY_ID_CLASSIFICACAO_PRODUTO,
    FIND_BY_ID_CLASSIFICACAO_PRODUTO_SUCCESS,
    API_ERROR_CLASSIFICACAO_PRODUTO,
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_delete: false,
    loading_form: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0,

    id: 0,
    product: '',
    company: '',
    origem: '',
    cofins: '',
    cofins_ent: '',
    csosn: '',
    icmscst: '',
    ipi: '',
    modbcicms: '',
    modbcicmsst: '',
    pis: '',
    pis_ent: '',
    cfop_na_uf: '',
    cfop_fora_uf: '',
    ent_cfop_na_uf: '',
    ent_cfop_fora_uf: '',
    unmedida: '',
    cest: '',
    speditem: '',
    ncm: '',
    gtin_ean: '',
    gtin_ean_trib: '',

}

const classificacaoProduto = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_CLASSIFICACAO_PRODUTO:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_CLASSIFICACAO_PRODUTO_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case EDIT_CLASSIFICACAO_PRODUTO:
            state = { ...state, loading: true }
            break
        case EDIT_CLASSIFICACAO_PRODUTO_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_CLASSIFICACAO_PRODUTO:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_CLASSIFICACAO_PRODUTO_SUCCESS:
            console.log('find_class ', action.payload.result.id)
            state = {
                ...state,
                loading: false,
                loading_form: false,

                id: action.payload.result.id,
                product: action.payload.result.product.text,
                company: action.payload.result.company.text,
                origem: action.payload.result.nf_origem.text,
                cofins: action.payload.result.s_cofins.text,
                cofins_ent: action.payload.result.e_cofins.text,
                csosn: action.payload.result.csosn.text,
                icmscst: action.payload.result.icms_cst.text,
                ipi: action.payload.result.ipi.text,
                modbcicms: action.payload.result.mod_bc_icms.text,
                modbcicmsst: action.payload.result.mod_bc_icms_st.text,
                pis: action.payload.result.s_pis.text,
                pis_ent: action.payload.result.e_pis.text,
                cfop_na_uf: action.payload.result.s_cfop_na_uf.text,
                cfop_fora_uf: action.payload.result.s_cfop_fora_uf.text,
                ent_cfop_na_uf: action.payload.result.e_cfop_na_uf.text,
                ent_cfop_fora_uf: action.payload.result.e_cfop_fora_uf.text,
                unmedida: action.payload.result.un_medida.text,
                cest: action.payload.result.cest.text,
                speditem: action.payload.result.sped_item.text,
                ncm: action.payload.result.ncm.text,
                gtin_ean: action.payload.result.gtin_ean,
                gtin_ean_trib: action.payload.result.gtin_ean_trib,

            }
            break
        case PAGINATE_CLASSIFICACAO_PRODUTO:
            state = {
                ...state,
                loading: true
            }
            break
        case PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.result.current_page,
                last_page: action.payload.result.last_page,
                total: action.payload.result.total,
                from: action.payload.result.from,
                to: action.payload.result.to,
                rows: action.payload.result.data,
                loading: false
            }
            break

        case FILTER_PAGINATE_CLASSIFICACAO_PRODUTO:
            state = { ...state, loading: true }
            break
        case FILTER_PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS:
            state = { ...state, loading: false }
            break
        case DELETE_CLASSIFICACAO_PRODUTO:
            state = { ...state, loading: false, loading_delete: true }
            break
        case DELETE_CLASSIFICACAO_PRODUTO_SUCCESS:
            state = { ...state, message: action.payload, loading: false, loading_delete: false }
            break
        case API_ERROR_CLASSIFICACAO_PRODUTO:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default classificacaoProduto
