export const PAGINATE_SERVICE_RADIUS_COMPANY = "PAGINATE_SERVICE_RADIUS_COMPANY"
export const PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS = "PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS"

export const FILTER_PAGINATE_SERVICE_RADIUS_COMPANY = "FILTER_PAGINATE_SCHEDULER_COMPANY"
export const FILTER_PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS = "FILTER_PAGINATE_SERVICE_RADIUS_COMPANY_SUCCESS"

export const DELETE_SERVICE_RADIUS_COMPANY = "DELETE_SERVICE_RADIUS_COMPANY"
export const DELETE_SERVICE_RADIUS_COMPANY_SUCCESS = "DELETE_SERVICE_RADIUS_COMPANY_SUCCESS"

export const API_ERROR_LIST_SERVICE_RADIUS_COMPANY = "PAGINATE_SERVICE_RADIUS_COMPANY_API_ERROR"
