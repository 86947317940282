import React, { Component } from 'react'
import MetaTags from 'react-meta-tags';
import {
  Container
} from "reactstrap"

export default class index extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard </title>
          </MetaTags>
          <Container fluid>
            <h3>Dashboard</h3>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
