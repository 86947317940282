export const PAGINATE_SCHEDULER_COMPANY = "PAGINATE_SCHEDULER_COMPANY"
export const PAGINATE_SCHEDULER_COMPANY_SUCCESS = "PAGINATE_SCHEDULER_COMPANY_SUCCESS"

export const FILTER_PAGINATE_SCHEDULER_COMPANY = "FILTER_PAGINATE_SCHEDULER_COMPANY"
export const FILTER_PAGINATE_SCHEDULER_COMPANY_SUCCESS = "FILTER_PAGINATE_SCHEDULER_COMPANY_SUCCESS"

export const DELETE_SCHEDULER_COMPANY = "DELETE_SCHEDULER_COMPANY"
export const DELETE_SCHEDULER_COMPANY_SUCCESS = "DELETE_SCHEDULER_COMPANY_SUCCESS"

export const API_ERROR_LIST_SCHEDULER_COMPANY = "PAGINATE_SCHEDULER_COMPANY_API_ERROR"
