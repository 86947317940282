export const PAGINATE_SETTINGS = "PAGINATE_SETTINGS"
export const PAGINATE_SETTINGS_SUCCESS = "PAGINATE_SETTINGS_SUCCESS"

export const FILTER_PAGINATE_SETTINGS = "FILTER_PAGINATE_SETTINGS"
export const FILTER_PAGINATE_SETTINGS_SUCCESS = "FILTER_PAGINATE_SETTINGS_SUCCESS"

export const DELETE_SETTINGS = "DELETE_SETTINGS"
export const DELETE_SETTINGS_SUCCESS = "DELETE_SETTINGS_SUCCESS"

export const API_ERROR_SETTINGS = "PAGINATE_SETTINGS_API_ERROR"
