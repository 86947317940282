import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import {connect} from "react-redux";
import { createModelsSeguiments, uploadModelsSeguiments} from "../../../store/actions"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import Dropzone from "react-dropzone";
import GenericsServiceAPI from "../../../../src/helpers/GenericsServicesAPI";
import {NotificationManager} from "react-notifications";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            name: '',
            image: '',
            description: '',

            file: null,
            existsFile: false,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleDrop = this.handleDrop.bind(this);
        this.sendUpload = this.sendUpload.bind(this)

    }

    componentDidMount() {


    }

    sendUpload(formData, url) {

        GenericsServiceAPI.upload(formData, url)
            .then(response => {
                const {message} = response;

                this.props.existsFiles = false
                this.props.loading = false
                NotificationManager.info(message, 'Aviso!');
                history.goBack()

            })
            .catch(err => {
                this.props.loading = false
                NotificationManager.warning(err, 'Ops!', 3000);
            })
            .then(response => {

            });
    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)

    }

    handleValidSubmit(event, values) {
        this.props.createModelsSeguiments({
            name: values.name,
            description: values.description,

            status: this.state.status,

        }, this.state.existsFile,  this.props.history)


    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Models Seguiments Register")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Models Seguiments ")}
                                     breadcrumbItem={this.props.t("Register")}/>
                        <Row>
                            <Col sm={12}>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={this.handleValidSubmit}>
                                    <Row>
                                        <Col sm={7}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Form")}</span>
                                                                <p className="card-title-desc mt-2">Preencha os campos
                                                                    abaixo, para
                                                                    concluir o cadastro.</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>


                                                            <Row>
                                                                <Col sm={8} className="mb-2">
                                                                    <AvField
                                                                        name="name"
                                                                        label={this.props.t("Name")}
                                                                        className="form-control"
                                                                        placeholder="Digite nome"
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={4} className="mb-2">
                                                                    <AvField
                                                                        value={this.state.status}
                                                                        onChange={this.handleFields}
                                                                        type="select" name="select"
                                                                        label={this.props.t("Status")}>
                                                                        <option value="1">Ativo</option>
                                                                        <option value="0">Inativo</option>
                                                                    </AvField>
                                                                </Col>
                                                                <Col sm={12} className="mb-2">
                                                                    <AvField
                                                                        name="description"
                                                                        label={this.props.t("Description")}
                                                                        className="form-control"
                                                                        placeholder="Digite uma descrição"
                                                                        type="textarea"
                                                                        rows={5}
                                                                        required
                                                                    />
                                                                </Col>

                                                                <Col sm={12} className="pt-3">
                                                                    <ButtonCustom
                                                                        isLoading={this.props.loading}
                                                                        size="sm"
                                                                        clazzContainer="text-center"
                                                                        clazz="btn btn-info w-md"
                                                                        message_loaging="">
                                                                        <button
                                                                            className="btn btn-info w-md"
                                                                            type="submit">
                                                                            Salvar
                                                                        </button>
                                                                    </ButtonCustom>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={5}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Image")}</span>
                                                                <p className="card-title-desc mt-3">Informe o Icone</p>
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <a
                                                                    onClick={() => {
                                                                        this.props.history.goBack()
                                                                    }}
                                                                    className="btn btn-dark btn-sm text-light"
                                                                >
                                                                    <i className="bx bx-left-arrow-circle"/>
                                                                    <span> Voltar</span>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <Dropzone onDrop={this.handleDrop}>

                                                                {({getRootProps, getInputProps}) => (

                                                                    <div {...getRootProps({className: "dropzone"})}>
                                                                        <input {...getInputProps()} />
                                                                        {this.state.image !== ''
                                                                            ?
                                                                            <img style={{width: '100%', height: '100%'}}
                                                                                 src={this.state.image}/>
                                                                            : <p style={{marginTop: '30%'}}
                                                                                 className="text-center">Arraste e
                                                                                solte ou
                                                                                clique
                                                                                para adicionar a imagem</p>
                                                                        }

                                                                    </div>

                                                                )}

                                                            </Dropzone>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {

    t: PropTypes.any,

    name: PropTypes.any,
    description: PropTypes.any,
    company_id: PropTypes.any,
    status: PropTypes.any,

    image: PropTypes.any,
    id: PropTypes.any,

    existsFiles: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    uploadModelsSeguiments: PropTypes.func,
    createModelsSeguiments: PropTypes.func,
}


const mapStateToProps = state => {
    const {
        loading,
        loading_form,
        error,
        message,
        status,
        name,
        company_id,
        description,
        image,
        id
    } = state.CreateOrEditModelsSeguiments
    return {
        name,
        loading_form,
        description,
        loading,
        company_id,
        error,
        message,
        status,
        image,
        id
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    createModelsSeguiments,
    uploadModelsSeguiments
})(
    (Index)
)))


