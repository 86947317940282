import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TRIB_CFOP,
    FILTER_PAGINATE_NF_TRIB_CFOP,
    DELETE_NF_TRIB_CFOP,
} from "./actionTypes"

import {
    allNFTribCFOPPaginateSuccess,
    filtersAllNFTribCFOPPaginateSuccess,
    deleteNFTribCFOPSuccess,
    apiPaginateNFTribCFOPError
} from "./actions"


import {
    getNFTribCFOPPaginate,
    getFilterNFTribCFOPPaginate,
    delNFTribCFOP
} from "../../../helpers/nf_cfop_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTribCFOP({payload: {id, history}}) {
    try {

        const response = yield call(delNFTribCFOP, id)

        yield put(deleteNFTribCFOPSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTribCFOPError(error))
    }
}

function* paginateNFTribCFOP({payload: {params}}) {
    try {

        const response = yield call(getNFTribCFOPPaginate, params)

        yield put(allNFTribCFOPPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribCFOPError(error))
    }
}

function* filtersPaginateNFTribCFOP({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTribCFOPPaginate, params)

        yield put(filtersAllNFTribCFOPPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribCFOPError(error))
    }
}


function* NFTribCFOPSaga() {
    yield takeEvery(PAGINATE_NF_TRIB_CFOP, paginateNFTribCFOP)
    yield takeEvery(FILTER_PAGINATE_NF_TRIB_CFOP, filtersPaginateNFTribCFOP)
    yield takeEvery(DELETE_NF_TRIB_CFOP, deleteNFTribCFOP)
}

export default NFTribCFOPSaga
