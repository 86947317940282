export const PAGINATE_NF_TRIB_CFOP = "PAGINATE_NF_TRIB_CFOP"
export const PAGINATE_NF_TRIB_CFOP_SUCCESS = "PAGINATE_NF_TRIB_CFOP_SUCCESS"

export const FILTER_PAGINATE_NF_TRIB_CFOP = "FILTER_PAGINATE_NF_TRIB_CFOP"
export const FILTER_PAGINATE_NF_TRIB_CFOP_SUCCESS = "FILTER_PAGINATE_NF_TRIB_CFOP_SUCCESS"

export const DELETE_NF_TRIB_CFOP = "DELETE_NF_TRIB_CFOP"
export const DELETE_NF_TRIB_CFOP_SUCCESS = "DELETE_NF_TRIB_CFOP_SUCCESS"

export const API_ERROR_NF_TRIB_CFOP = "PAGINATE_NF_TRIB_CFOP_API_ERROR"
