export const PAGINATE_TAB_CEST = "PAGINATE_TAB_CEST"
export const PAGINATE_TAB_CEST_SUCCESS = "PAGINATE_TAB_CEST_SUCCESS"

export const IMPORT_TAB_CEST = "IMPORT_TAB_CEST"
export const IMPORT_TAB_CEST_SUCCESS = "IMPORT_TAB_CEST_SUCCESS"


export const FILTER_PAGINATE_TAB_CEST = "FILTER_PAGINATE_TAB_CEST"
export const FILTER_PAGINATE_TAB_CEST_SUCCESS = "FILTER_PAGINATE_TAB_CEST_SUCCESS"

export const DELETE_TAB_CEST = "DELETE_TAB_CEST"
export const DELETE_TAB_CEST_SUCCESS = "DELETE_TAB_CEST_SUCCESS"

export const API_ERROR_TAB_CEST = "PAGINATE_TAB_CEST_API_ERROR"
