export const PAGINATE_TAB_IBPT = "PAGINATE_TAB_IBPT"
export const PAGINATE_TAB_IBPT_SUCCESS = "PAGINATE_TAB_IBPT_SUCCESS"

export const IMPORT_TAB_IBPT = "IMPORT_TAB_IBPT"
export const IMPORT_TAB_IBPT_SUCCESS = "IMPORT_TAB_IBPT_SUCCESS"


export const FILTER_PAGINATE_TAB_IBPT = "FILTER_PAGINATE_TAB_IBPT"
export const FILTER_PAGINATE_TAB_IBPT_SUCCESS = "FILTER_PAGINATE_TAB_IBPT_SUCCESS"

export const DELETE_TAB_IBPT = "DELETE_TAB_IBPT"
export const DELETE_TAB_IBPT_SUCCESS = "DELETE_TAB_IBPT_SUCCESS"

export const API_ERROR_TAB_IBPT = "PAGINATE_TAB_IBPT_API_ERROR"
