import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TRIB_MOD_BC_ICMS,
    FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS,
    DELETE_NF_TRIB_MOD_BC_ICMS,
} from "./actionTypes"

import {
    allNFTribModBCICMSPaginateSuccess,
    filtersAllNFTribModBCICMSPaginateSuccess,
    deleteNFTribModBCICMSSuccess,
    apiPaginateNFTribModBCICMSError
} from "./actions"


import {
    getNFTribModBCICMSPaginate,
    getFilterNFTribModBCICMSPaginate,
    delNFTribModBCICMS
} from "../../../helpers/nf_mod_bc_icms_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTribModBCICMS({payload: {id, history}}) {
    try {

        const response = yield call(delNFTribModBCICMS, id)

        yield put(deleteNFTribModBCICMSSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTribModBCICMSError(error))
    }
}

function* paginateNFTribModBCICMS({payload: {params}}) {
    try {

        const response = yield call(getNFTribModBCICMSPaginate, params)

        yield put(allNFTribModBCICMSPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribModBCICMSError(error))
    }
}

function* filtersPaginateNFTribModBCICMS({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTribModBCICMSPaginate, params)

        yield put(filtersAllNFTribModBCICMSPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribModBCICMSError(error))
    }
}


function* NFTribModBCICMSSaga() {
    yield takeEvery(PAGINATE_NF_TRIB_MOD_BC_ICMS, paginateNFTribModBCICMS)
    yield takeEvery(FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS, filtersPaginateNFTribModBCICMS)
    yield takeEvery(DELETE_NF_TRIB_MOD_BC_ICMS, deleteNFTribModBCICMS)
}

export default NFTribModBCICMSSaga
