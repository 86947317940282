import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_PAYMENT_METHODS_COMPANY,
    FILTER_PAGINATE_PAYMENT_METHODS_COMPANY,
    DELETE_PAYMENT_METHODS_COMPANY,
} from "./actionTypes"

import {
    allPaymentMethodsCompanyPaginateSuccess,
    filtersAllPaymentMethodsCompanyPaginateSuccess,
    deletePaymentMethodsCompanySuccess,
    apiPaginatePaymentMethodsCompanyError
} from "./actions"


import {
    getPaymentMethodsCompanyPaginate,
    getFilterPaymentMethodsCompanyPaginate,
    delPaymentMethodsCompany
} from "../../../../../helpers/payment_methods_company_helper"
import {NotificationManager} from "react-notifications";

function* deletePaymentMethodsCompany({payload: {id, history}}) {
    try {

        const response = yield call(delPaymentMethodsCompany, id)

        yield put(deletePaymentMethodsCompanySuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginatePaymentMethodsCompanyError(error))
    }
}

function* paginatePaymentMethodsCompany({payload: {params}}) {
    try {

        const response = yield call(getPaymentMethodsCompanyPaginate, params)

        yield put(allPaymentMethodsCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePaymentMethodsCompanyError(error))
    }
}

function* filtersPaginatePaymentMethodsCompany({payload: {params}}) {
    try {

        const response = yield call(getFilterPaymentMethodsCompanyPaginate, params)

        yield put(filtersAllPaymentMethodsCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePaymentMethodsCompanyError(error))
    }
}


function* PaymentMethodsCompanysSaga() {
    yield takeEvery(PAGINATE_PAYMENT_METHODS_COMPANY, paginatePaymentMethodsCompany)
    yield takeEvery(FILTER_PAGINATE_PAYMENT_METHODS_COMPANY, filtersPaginatePaymentMethodsCompany)
    yield takeEvery(DELETE_PAYMENT_METHODS_COMPANY, deletePaymentMethodsCompany)
}

export default PaymentMethodsCompanysSaga
