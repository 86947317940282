import {
    PAGINATE_COUNTRY,
    PAGINATE_COUNTRY_SUCCESS,
    FILTER_PAGINATE_COUNTRY,
    FILTER_PAGINATE_COUNTRY_SUCCESS,
    DELETE_COUNTRY_SUCCESS,
    DELETE_COUNTRY,
    API_ERROR,
} from "./actionTypes"


export const filtersAllCountryPaginate = params => {
    return {
        type: FILTER_PAGINATE_COUNTRY,
        payload: {params},
    }
}

export const filtersAllCountryPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_COUNTRY_SUCCESS,
        payload: {params},
    }
}

export const allCountryPaginate = params => {
    return {
        type: PAGINATE_COUNTRY,
        payload: {params},
    }
}

export const allCountryPaginateSuccess = result => {
    return {
        type: PAGINATE_COUNTRY_SUCCESS,
        payload: {result},
    }
}

export const deleteCountry = (id, history) => {
  return {
    type: DELETE_COUNTRY,
    payload: {id, history} ,
  }
}

export const deleteCountrySuccess = message => {
    return {
        type: DELETE_COUNTRY_SUCCESS,
        payload: message,
    }
}

export const apiPaginateCountryError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

