export const PAGINATE_COUNTRY = "PAGINATE_COUNTRY"
export const PAGINATE_COUNTRY_SUCCESS = "PAGINATE_COUNTRY_SUCCESS"

export const FILTER_PAGINATE_COUNTRY = "FILTER_PAGINATE_COUNTRY"
export const FILTER_PAGINATE_COUNTRY_SUCCESS = "FILTER_PAGINATE_COUNTRY_SUCCESS"

export const DELETE_COUNTRY = "DELETE_COUNTRY"
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS"

export const API_ERROR = "PAGINATE_COUNTRY_API_ERROR"
