import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getNFTribModBCICMSSTById = data => get(url.GET_NF_TRIB_MOD_BC_ICMS_ST_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFTribModBCICMSST = data => del(url.DELETE_NF_TRIB_MOD_BC_ICMS_ST.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFTribModBCICMSST = data => post(url.POST_CREATE_NF_TRIB_MOD_BC_ICMS_ST, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFTribModBCICMSST = data => put(url.PUT_EDIT_NF_TRIB_MOD_BC_ICMS_ST, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFTribModBCICMSSTPaginate = ({page, limit}) => get(url.GET_NF_TRIB_MOD_BC_ICMS_ST_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFTribModBCICMSSTPaginate = data => get(url.GET_NF_TRIB_MOD_BC_ICMS_ST_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFTribModBCICMSST = params => get(url.GET_SEARCH_NF_TRIB_MOD_BC_ICMS_ST, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFTribModBCICMSSTPaginate,
    getFilterNFTribModBCICMSSTPaginate,
    putEditNFTribModBCICMSST,
    postCreateNFTribModBCICMSST,
    delNFTribModBCICMSST,
    getNFTribModBCICMSSTById,
    getSearchNFTribModBCICMSST
}
