export const PAGINATE_META_FACEBOOK = "PAGINATE_META_FACEBOOK"
export const PAGINATE_META_FACEBOOK_SUCCESS = "PAGINATE_META_FACEBOOK_SUCCESS"

export const IMPORT_META_FACEBOOK = "IMPORT_META_FACEBOOK"
export const IMPORT_META_FACEBOOK_SUCCESS = "IMPORT_META_FACEBOOK_SUCCESS"


export const FILTER_PAGINATE_META_FACEBOOK = "FILTER_PAGINATE_META_FACEBOOK"
export const FILTER_PAGINATE_META_FACEBOOK_SUCCESS = "FILTER_PAGINATE_META_FACEBOOK_SUCCESS"

export const DELETE_META_FACEBOOK = "DELETE_META_FACEBOOK"
export const DELETE_META_FACEBOOK_SUCCESS = "DELETE_META_FACEBOOK_SUCCESS"

export const API_ERROR_META_FACEBOOK = "PAGINATE_META_FACEBOOK_API_ERROR"
