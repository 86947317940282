import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Table from "./table/index";
import ButtonCustom from "../../../../components/ButtonCustom";
import { connect } from "react-redux";
import { createPasta } from "../../../../store/categories/options/pastas/actions"
import { Form, Spinner } from "react-bootstrap";

//import IntlCurrencyInput from "react-intl-currency-input"
import InputMask from 'react-input-mask';


/*const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            },
        },
    },
};*/

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            price: null,
            menu_category_id: 0,
            columns: [
                {
                    id: 1,
                    name: 'NOME',
                    status: 1
                },
                {
                    id: 2,
                    name: 'PREÇO',
                    status: 1
                },
                {
                    id: 3,
                    name: 'AÇÕES',
                    status: 1
                },
            ],
        }

        this.sendForm = this.sendForm.bind(this)

    }


    async componentDidMount() {
        this.handleFields = this.handleFields.bind(this);

    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }


    async sendForm() {
        const { entity_id } = this.props.match.params
        this.props.createPasta({
            menu_category_id: entity_id,
            name: this.state.name,
            price: this.state.price,
            status: this.state.status,
        })
    }




    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Pastas")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Pasta")} breadcrumbItem={this.props.t("Register")} />
                        <Row>
                            <Col sm={8}>
                                <Card>
                                    <CardBody>

                                        <Table
                                            refresh={this.props.refresh}
                                            route_edit="/edit-category"
                                            columns={this.state.columns}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center ">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos
                                                        abaixo, para incluir um tipo de massa.</p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <span> Voltar</span>
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col sm={12} className="mb-2">
                                                        <div className="form-group">
                                                            <label htmlFor="" className="control-label">{this.props.t("Name")}</label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Informe o nome"
                                                                name="name"
                                                                onChange={this.handleFields}
                                                                value={this.state.name} />

                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-2">
                                                        <div className="form-group">
                                                            <label>{this.props.t("Price")}</label>

                                                            <Form.Control
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="00,00"
                                                                name="price"
                                                                as={InputMask} 
                                                                mask={"99,99"}
                                                                onChange={this.handleFields}
                                                                value={this.state.price} />

                                                            {/* <IntlCurrencyInput
                                                                className="mb-2 form-control"
                                                                currency="BRL"
                                                                config={currencyConfig}
                                                                onChange={(event, value, maskedValue) => {

                                                                    const { name } = event.target
                                                                    this.setState({ price: value });
                                                                }}
                                                                defaultValue={0}
                                                                value={this.state.price} />*/}
                                                        </div>
                                                    </Col>


                                                    <Col sm={12} className="pt-3">
                                                        <ButtonCustom
                                                            isLoading={this.props.loading}
                                                            size="sm"
                                                            clazzContainer="text-center"
                                                            clazz="btn btn-info w-md"
                                                            message_loaging="">
                                                            <button
                                                                onClick={() => this.sendForm()}
                                                                className="btn btn-info w-md"
                                                                type="submit">
                                                                Adicionar
                                                            </button>
                                                        </ButtonCustom>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh
    } = state.CreateOrEditPastas
    return {
        loading,
        refresh,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createPasta,

})(
    (Index)
)))
