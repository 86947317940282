export const PAGINATE_CITY = "PAGINATE_CITY"
export const PAGINATE_CITY_SUCCESS = "PAGINATE_CITY_SUCCESS"

export const FILTER_PAGINATE_CITY = "FILTER_PAGINATE_CITY"
export const FILTER_PAGINATE_CITY_SUCCESS = "FILTER_PAGINATE_CITY_SUCCESS"

export const DELETE_CITY = "DELETE_CITY"
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS"

export const API_ERROR_CITY = "PAGINATE_CITY_API_ERROR"
