export const CREATE_MODELS_SEGUIMENTS = "CREATE_MODELS_SEGUIMENTS"
export const CREATE_MODELS_SEGUIMENTS_SUCCESS = "CREATE_MODELS_SEGUIMENTS_SUCCESS"

export const EDIT_MODELS_SEGUIMENTS = "EDIT_MODELS_SEGUIMENTS"
export const EDIT_MODELS_SEGUIMENTS_SUCCESS = "EDIT_MODELS_SEGUIMENTS_SUCCESS"

export const FIND_BY_ID_MODELS_SEGUIMENTS = "FIND_BY_ID_MODELS_SEGUIMENTS"
export const FIND_BY_ID_MODELS_SEGUIMENTS_SUCCESS = "FIND_BY_ID_MODELS_SEGUIMENTS_SUCCESS"

export const UPLOAD_MODELS_SEGUIMENTS = "UPLOAD_MODELS_SEGUIMENTS"
export const UPLOAD_MODELS_SEGUIMENTS_SUCCESS = "UPLOAD_MODELS_SEGUIMENTS_SUCCESS"

export const CREATE_OR_EDIT_MODELS_SEGUIMENTS_API_ERROR = "CREATE_OR_EDIT_MODELS_SEGUIMENTS_API_ERROR"
