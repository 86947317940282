import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Table from "./table/index";
import ButtonCustom from "../../../../components/ButtonCustom";
import {connect} from "react-redux";
import {createPaymentMethodsCompany} from "../../../../store/companies/options/payment-methods/actions"



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            price: 0,
            menu_category_id: 0,
            columns: [
                {
                    id: 1,
                    name: 'NOME',
                    status: 1
                },
                {
                    id: 2,
                    name: 'STATUS',
                    status: 1
                },
            ],
        }

    }



    render() {

        return (
           <React.Fragment>
               <div className="page-content">
                   <MetaTags>
                       <title>{this.props.t("Payment Methods Company")}</title>
                   </MetaTags>
                   <Container fluid>
                       <Breadcrumbs title={this.props.t("Payment Methods Company")} breadcrumbItem={this.props.t("Register")}/>
                       <Card>
                           <CardBody>

                               <Table
                                   refresh={this.props.refresh}
                                   route_edit="/edit-category"
                                   columns={this.state.columns}
                               />
                           </CardBody>
                       </Card>
                   </Container>

               </div>
           </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh
    } = state.CreateOrEditCompaniesPaymentMethods
    return {
        loading,
        refresh,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createPaymentMethodsCompany,

})(
    (Index)
)))
