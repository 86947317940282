import {
    CREATE_SCHEDULER_COMPANY,
    CREATE_SCHEDULER_COMPANY_SUCCESS,
    EDIT_SCHEDULER_COMPANY,
    EDIT_SCHEDULER_COMPANY_SUCCESS,
    CREATE_OR_EDIT_SCHEDULER_COMPANY_API_ERROR
} from "./actionTypes"


export const createSchedulerCompany = (params, history) => {
    return {
        type: CREATE_SCHEDULER_COMPANY,
        payload: {params, history},
    }
}

export const createSchedulerCompanySuccess = params => {
    return {
        type: CREATE_SCHEDULER_COMPANY_SUCCESS,
        payload: params,
    }
}

export const editSchedulerCompany = (params, history) => {
    return {
        type: EDIT_SCHEDULER_COMPANY,
        payload: {params, history},
    }
}

export const editSchedulerCompanySuccess = result => {
    return {
        type: EDIT_SCHEDULER_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditSchedulerCompanyError = error => {
    return {
        type: CREATE_OR_EDIT_SCHEDULER_COMPANY_API_ERROR,
        payload: error,
    }
}

