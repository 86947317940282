import {
    PAGINATE_NF_TRIB_MOD_BC_ICMS,
    PAGINATE_NF_TRIB_MOD_BC_ICMS_SUCCESS,
    FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS,
    FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_SUCCESS,
    DELETE_NF_TRIB_MOD_BC_ICMS_SUCCESS,
    DELETE_NF_TRIB_MOD_BC_ICMS,
    API_ERROR_NF_TRIB_MOD_BC_ICMS,
} from "./actionTypes"


export const filtersAllNFTribModBCICMSPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS,
        payload: {params},
    }
}

export const filtersAllNFTribModBCICMSPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_SUCCESS,
        payload: {params},
    }
}

export const allNFTribModBCICMSPaginate = params => {
    return {
        type: PAGINATE_NF_TRIB_MOD_BC_ICMS,
        payload: {params},
    }
}

export const allNFTribModBCICMSPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TRIB_MOD_BC_ICMS_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTribModBCICMS = (id, history) => {
  return {
    type: DELETE_NF_TRIB_MOD_BC_ICMS,
    payload: {id, history} ,
  }
}

export const deleteNFTribModBCICMSSuccess = message => {
    return {
        type: DELETE_NF_TRIB_MOD_BC_ICMS_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTribModBCICMSError = error => {
  return {
    type: API_ERROR_NF_TRIB_MOD_BC_ICMS,
    payload: error,
  }
}

