import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_PRODUCTS_COMPLEMENTS,
    EDIT_PRODUCTS_COMPLEMENTS,
    FIND_PRODUCTS_COMPLEMENTS_BY_PROD
} from "./actionTypes"


import {
    createProductComplementSuccess,
    editProductComplementSuccess,
    findProductComplementsSuccess,
    apiCreateOrEditProductComplementError
} from "./actions"


import {
    putEditProductComplement,
    postCreateProductComplement,
    getProductComplementByProductId
} from "../../../../../helpers/products_complement_helper"


function* createProductsComplement({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateProductComplement, params)
        const {message, id, error} = response;

        yield put(createProductComplementSuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        //history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditProductComplementError(error))
    }
}

function* editProductsComplement({payload: {params,   history}}) {
    try {

        const response = yield call(putEditProductComplement, params)

        yield put(editProductComplementSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditProductComplementError(error))
    }
} 
function* findProductComplements({payload: {id}}) {
    try {

        const response = yield call(getProductComplementByProductId, id)

        console.log('chamou produtos comple ', response)
        yield put(findProductComplementsSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditProductComplementError(error))
    }
}



function* createOrEditProductsComplementSaga() {
    yield takeEvery(FIND_PRODUCTS_COMPLEMENTS_BY_PROD, findProductComplements)
    yield takeEvery(CREATE_PRODUCTS_COMPLEMENTS, createProductsComplement)
    yield takeEvery(EDIT_PRODUCTS_COMPLEMENTS, editProductsComplement)
}

export default createOrEditProductsComplementSaga
