import {
    CREATE_TIPO_NF,
    CREATE_TIPO_NF_SUCCESS,
    EDIT_TIPO_NF,
    EDIT_TIPO_NF_SUCCESS,
    PAGINATE_TIPO_NF,
    PAGINATE_TIPO_NF_SUCCESS,
    FILTER_PAGINATE_TIPO_NF,
    FILTER_PAGINATE_TIPO_NF_SUCCESS,
    DELETE_TIPO_NF_SUCCESS,
    DELETE_TIPO_NF,
    FIND_BY_ID_TIPO_NF,
    FIND_BY_ID_TIPO_NF_SUCCESS,
    API_ERROR_TIPO_NF,
} from "./actionTypes"

export const findByIdTipoNF = id => {
    return {
        type: FIND_BY_ID_TIPO_NF,
        payload: {id},
    }
}

export const findTipoNFIdSuccess = result => {
    return {
        type: FIND_BY_ID_TIPO_NF_SUCCESS,
        payload: result,
    }
}

export const createTipoNF = (params, history) => {
    return {
        type: CREATE_TIPO_NF,
        payload: { params, history },
    }
}

export const createTipoNFSuccess = params => {
    return {
        type: CREATE_TIPO_NF_SUCCESS,
        payload: { params },
    }
}

export const editTipoNF = (params, history) => {
    return {
        type: EDIT_TIPO_NF,
        payload: { params, history },
    }
}

export const editTipoNFSuccess = result => {
    return {
        type: EDIT_TIPO_NF_SUCCESS,
        payload: { result },
    }
}
export const filtersAllTipoNFPaginate = params => {
    return {
        type: FILTER_PAGINATE_TIPO_NF,
        payload: { params },
    }
}

export const filtersAllTipoNFPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_TIPO_NF_SUCCESS,
        payload: { params },
    }
}

export const allTipoNFPaginate = params => {
    return {
        type: PAGINATE_TIPO_NF,
        payload: { params },
    }
}

export const allTipoNFPaginateSuccess = result => {
    return {
        type: PAGINATE_TIPO_NF_SUCCESS,
        payload: { result },
    }
}

export const deleteTipoNF = (id, history) => {
    return {
        type: DELETE_TIPO_NF,
        payload: { id, history },
    }
}

export const deleteTipoNFSuccess = message => {
    return {
        type: DELETE_TIPO_NF_SUCCESS,
        payload: message,
    }
}

export const apiPaginateTipoNFError = error => {
    return {
        type: API_ERROR_TIPO_NF,
        payload: error,
    }
}

