import {
    CREATE_DRIVERS_COMPANY,
    CREATE_DRIVERS_COMPANY_SUCCESS,
    EDIT_DRIVERS_COMPANY,
    EDIT_DRIVERS_COMPANY_SUCCESS,
    CREATE_OR_EDIT_DRIVERS_COMPANY_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    refresh: false,
    error: false,
    message: '',

    name: '',
    menu_category_id: null,
    price: 0,
    status: 1,

    id: 0,
}

const createOrEditDriversCompany = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_DRIVERS_COMPANY:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case CREATE_DRIVERS_COMPANY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                refresh: true,
                loading: false
            }
            break
        case EDIT_DRIVERS_COMPANY:
            state = {...state, loading: true}
            break
        case EDIT_DRIVERS_COMPANY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case CREATE_OR_EDIT_DRIVERS_COMPANY_API_ERROR:
            state = {...state, error: action.payload, loading: false,  refresh: false,}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditDriversCompany
