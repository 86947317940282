import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../../../components/Breadcrumb";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../../../components/ButtonCustom";
import Autocomplete from "../../../../../components/Autocomplete";
import classnames from "classnames"
import { editClassificacaoProduto, findByIdClassificacaoProduto } from "../../../../../store/actions"

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,

            product: null,
            modelo: null,
            origem: null,
            cofins: null,
            cofins_ent: null,
            csosn: null,
            icmscst: null,
            ipi: null,
            modbcicms: null,
            modbcicmsst: null,
            pis: null,
            pis_ent: null,
            cfop_na_uf: null,
            cfop_fora_uf: null,
            ent_cfop_na_uf: null,
            ent_cfop_fora_uf: null,
            unmedida: null,
            cest: null,
            speditem: null,
            ncm: null,
            tabs: [
                {
                    id: 1,
                    name: 'PRODUTO',
                    status: 1
                },
                {
                    id: 2,
                    name: 'ICMS',
                    status: 1
                },
                {
                    id: 3,
                    name: 'ICMS ST',
                    status: 1
                },
                {
                    id: 4,
                    name: 'IPI',
                    status: 1
                },
                {
                    id: 5,
                    name: 'CFOP SAÍDA',
                    status: 1
                },
                {
                    id: 6,
                    name: 'CFOP ENTRADA',
                    status: 1
                }

            ],
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)

    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findByIdClassificacaoProduto(entity_id);

    }
    handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data

            let newState = {}

            newState[name] = value
            newState[name_entity] = code

            this.setState(newState)
        }
    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }


    handleValidSubmit(event, values) {
        this.props.editClassificacaoProduto({
            id: this.props.id,
            company_id: this.state.company_id,
            status: this.state.status,
            product_id: this.state.product_id,
            gtin_ean_trib: values.gtin_ean_trib,
            gtin_ean: values.gtin_ean,
            un_medida_id: this.state.un_medida_id,
            ncm_tab_ibpt_id: this.state.ncm_tab_ibpt_id,
            cest_id: this.state.cest_id,
            sped_item_id: this.state.sped_item_id,
            nf_origem_id: this.state.nf_origem_id,
            nf_trib_icms_cst_id: this.state.nf_trib_icms_cst_id,
            nf_trib_mod_bc_icms_id: this.state.nf_trib_mod_bc_icms_id,
            nf_trib_csosn_id: this.state.nf_trib_csosn_id,
            nf_trib_mod_bc_icms_st_id: this.state.nf_trib_mod_bc_icms_st_id,
            nf_trib_cofins_id: this.state.nf_trib_cofins_id,
            nf_trib_pis_id: this.state.nf_trib_pis_id,
            nf_trib_ipi_id: this.state.nf_trib_ipi_id,
            nf_cfop_na_uf_id: this.state.cfop_na_uf_id,
            nf_cfop_fora_uf_id: this.state.cfop_fora_uf_id,

            nf_ent_trib_cofins_id: this.state.nf_ent_trib_cofins_id,
            nf_ent_trib_pis_id: this.state.nf_ent_trib_pis_id,

            nf_ent_cfop_na_uf_id: this.state.ent_cfop_na_uf_id,
            nf_ent_cfop_fora_uf_id: this.state.ent_cfop_fora_uf_id
        }, this.props.history)

    }

    render() {
        console.log('props_edit ', this.props)

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Classificação Fiscal</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Classificação Fiscal"
                            breadcrumbItem="Cadastrar" />
                        <Row>
                            <Col sm={12}>

                                <Row>
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>

                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <span
                                                                className="h4 card-title">{this.props.t("Form")}</span>
                                                            <p className="card-title-desc mt-2">Preencha os campos
                                                                abaixo, para
                                                                concluir o cadastro.</p>
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <button
                                                                onClick={() => {
                                                                    this.props.history.goBack()
                                                                }}
                                                                className="btn btn-dark btn-sm text-light"
                                                            >
                                                                <i className="bx bx-left-arrow-circle" />
                                                                <span> Voltar</span>
                                                            </button>
                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <AvForm
                                                            className="form-horizontal"
                                                            onValidSubmit={this.handleValidSubmit}>
                                                            <ul className="nav nav-tabs nav-tabs-custom">
                                                                {
                                                                    this.state.tabs.map((item, index) => {
                                                                        return (
                                                                            <NavItem key={index}>
                                                                                <NavLink

                                                                                    onClick={(e) => {
                                                                                        this.setState({ activeTab: index })
                                                                                    }}
                                                                                    className={classnames({
                                                                                        active: this.state.activeTab === index,
                                                                                    })}
                                                                                >
                                                                                    {item.name}
                                                                                </NavLink>
                                                                            </NavItem>
                                                                        )
                                                                    })
                                                                }


                                                            </ul>
                                                            <div style={{ paddingBottom: 20 }}>
                                                                <TabContent
                                                                    activeTab={this.state.activeTab}
                                                                    className="p-2 "
                                                                >
                                                                    <TabPane tabId={0}>
                                                                        <Row>
                                                                            <Col sm={10} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'company_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'company',
                                                                                        label: "Empresa",
                                                                                        placeholder: "Pesquisar empresa",
                                                                                        value: this.state.company ? this.state.company : this.props.company,
                                                                                        url: 'search/companies'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={2} className="mb-2">
                                                                                <AvField
                                                                                    value={this.state.status ? this.state.status : this.props.status}
                                                                                    onChange={this.handleFields}
                                                                                    type="select" name="select"
                                                                                    label={this.props.t("Status")}>
                                                                                    <option value="1">Ativo</option>
                                                                                    <option value="0">Inativo</option>
                                                                                </AvField>
                                                                            </Col>

                                                                            <Col sm={6} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'product_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'product',
                                                                                        label: "Produto",
                                                                                        placeholder: "Pesquisar produto",
                                                                                        value: this.state.product ? this.state.product : this.props.product,
                                                                                        url: 'search/products'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={3} className="mb-2">
                                                                                <AvField
                                                                                    name="gtin_ean"
                                                                                    label="GTIN / EAN"
                                                                                    className="form-control"
                                                                                    placeholder="Cód. barras"
                                                                                    value={this.state.gtin_ean ? this.state.gtin_ean : this.props.gtin_ean}
                                                                                    type="text"
                                                                                    required
                                                                                />
                                                                            </Col>
                                                                            <Col sm={3} className="mb-2">
                                                                                <AvField
                                                                                    name="gtin_ean_trib"
                                                                                    label="GTIN / EAN Tributário"
                                                                                    className="form-control"
                                                                                    placeholder="Cód. barras"
                                                                                    value={this.state.gtin_ean_trib ? this.state.gtin_ean_trib : this.props.gtin_ean_trib}
                                                                                    type="text"
                                                                                    required
                                                                                />
                                                                            </Col>
                                                                            <Col sm={2} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'un_medida_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'unmedida',
                                                                                        label: "UN. Medida",
                                                                                        placeholder: "Pesquisar...",
                                                                                        value: this.state.unmedida ? this.state.unmedida : this.props.unmedida,
                                                                                        url: 'search/un-medida'
                                                                                    }} />
                                                                            </Col>


                                                                            <Col sm={6} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'ncm_tab_ibpt_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'ncm',
                                                                                        label: "NCM",
                                                                                        placeholder: "Pesquisar...",
                                                                                        value: this.state.ncm ? this.state.ncm : this.props.ncm,
                                                                                        url: 'search/ncm'
                                                                                    }} />
                                                                            </Col>
                                                                            {
                                                                                this.state.ncm_tab_ibpt_id > 0 &&
                                                                                <Col sm={4} className="mb-2">
                                                                                    <Autocomplete
                                                                                        onSourceSelect={this.handleAutocomplete}
                                                                                        nameFieldSelect={'cest_id'}
                                                                                        limit={10}
                                                                                        inputProps={{
                                                                                            id: 'cest',
                                                                                            label: "CEST",
                                                                                            placeholder: "Pesquisar...",
                                                                                            value: this.state.cest ? this.state.cest : this.props.cest,
                                                                                            param_id: (this.state.ncm_tab_ibpt_id ? this.state.ncm_tab_ibpt_id : null),
                                                                                            url: 'search/cest'
                                                                                        }} />
                                                                                </Col>
                                                                            }
                                                                            <Col sm={4} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'sped_item_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'speditem',
                                                                                        label: "Sped Item",
                                                                                        placeholder: "Pesquisar...",
                                                                                        value: this.state.speditem ? this.state.speditem : this.props.speditem,
                                                                                        url: 'search/sped-item'
                                                                                    }} />
                                                                            </Col>

                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId={1}>
                                                                        <Row>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_origem_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'origem',
                                                                                        label: "Origem",
                                                                                        placeholder: "Pesquisar origem",
                                                                                        value: this.state.origem ? this.state.origem : this.props.origem,
                                                                                        url: 'search/tributacao/origem'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_trib_icms_cst_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'icmscst',
                                                                                        label: "Cód. Sit. Tributária ICMS",
                                                                                        placeholder: "Pesquisar icms cst",
                                                                                        value: this.state.icmscst ? this.state.icmscst : this.props.icmscst,
                                                                                        url: 'search/tributacao/icmscst'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_trib_mod_bc_icms_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'modbcicms',
                                                                                        label: "Modalidade Base Calc. ICMS",
                                                                                        placeholder: "Pesquisar modalidade Base Calc. ICMS",
                                                                                        value: this.state.modbcicms ? this.state.modbcicms : this.props.modbcicms,
                                                                                        url: 'search/tributacao/modbcicms'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_trib_csosn_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'csosn',
                                                                                        label: "CSOSN",
                                                                                        placeholder: "Pesquisar CSOSN",
                                                                                        value: this.state.csosn ? this.state.csosn : this.props.csosn,
                                                                                        url: 'search/tributacao/csosn'
                                                                                    }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId={2}>
                                                                        <Row>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_trib_mod_bc_icms_st_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'modbcicmsst',
                                                                                        label: "Modalidade Base Calc. ICMS ST",
                                                                                        placeholder: "Pesquisar Modalidade Base Calc. ICMS ST",
                                                                                        value: this.state.modbcicmsst ? this.state.modbcicmsst : this.props.modbcicmsst,
                                                                                        url: 'search/tributacao/modbcicmsst'
                                                                                    }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId={3}>
                                                                        <Row>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_trib_ipi_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'ipi',
                                                                                        label: "Cód. Sit. Tributária IPI",
                                                                                        placeholder: "Pesquisar Cód. Sit. Tributária IPI",
                                                                                        value: this.state.ipi ? this.state.ipi : this.props.ipi,
                                                                                        url: 'search/tributacao/ipi'
                                                                                    }} />
                                                                            </Col>

                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId={4}>
                                                                        <Row>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_cfop_na_uf_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'cfop_na_uf',
                                                                                        label: "CFOP na UF",
                                                                                        placeholder: "Pesquisar CFOP na UF",
                                                                                        value: this.state.cfop_na_uf ? this.state.cfop_na_uf : this.props.cfop_na_uf,
                                                                                        url: 'search/tributacao/cfop'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_cfop_fora_uf_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'cfop_fora_uf',
                                                                                        label: "CFOP fora da UF",
                                                                                        placeholder: "Pesquisar CFOP fora da UF",
                                                                                        value: this.state.cfop_fora_uf ? this.state.cfop_fora_uf : this.props.cfop_fora_uf,
                                                                                        url: 'search/tributacao/cfop'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_trib_cofins_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'cofins',
                                                                                        label: "Cód. Sit. Tributária COFINS",
                                                                                        placeholder: "Pesquisar Cód. Sit. Tributária COFINS",
                                                                                        value: this.state.cofins ? this.state.cofins : this.props.cofins,
                                                                                        url: 'search/tributacao/cofins'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_trib_pis_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'pis',
                                                                                        label: "Cód. Sit. Tributária PIS",
                                                                                        placeholder: "Pesquisar Cód. Sit. Tributária PIS",
                                                                                        value: this.state.pis ? this.state.pis : this.props.pis,
                                                                                        url: 'search/tributacao/pis'
                                                                                    }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId={5}>
                                                                        <Row>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_ent_cfop_na_uf_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'ent_cfop_na_uf',
                                                                                        label: "CFOP na UF",
                                                                                        placeholder: "Pesquisar CFOP na UF",
                                                                                        value: this.state.ent_cfop_na_uf ? this.state.ent_cfop_na_uf : this.props.ent_cfop_na_uf,
                                                                                        url: 'search/tributacao/cfop'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_ent_cfop_fora_uf_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'ent_cfop_fora_uf',
                                                                                        label: "CFOP fora da UF",
                                                                                        placeholder: "Pesquisar CFOP fora da UF",
                                                                                        value: this.state.ent_cfop_fora_uf ? this.state.ent_cfop_fora_uf : this.props.ent_cfop_fora_uf,
                                                                                        url: 'search/tributacao/cfop'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_ent_trib_cofins_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'cofins_ent',
                                                                                        label: "Cód. Sit. Tributária COFINS",
                                                                                        placeholder: "Pesquisar Cód. Sit. Tributária COFINS",
                                                                                        value: this.state.cofins_ent ? this.state.cofins_ent : this.props.cofins_ent,
                                                                                        url: 'search/tributacao/cofins'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mb-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'nf_ent_trib_pis_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'pis_ent',
                                                                                        label: "Cód. Sit. Tributária PIS",
                                                                                        placeholder: "Pesquisar Cód. Sit. Tributária PIS",
                                                                                        value: this.state.pis_ent ? this.state.pis_ent : this.props.pis_ent,
                                                                                        url: 'search/tributacao/pis'
                                                                                    }} />
                                                                            </Col>
                                                                        </Row>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </div>
                                                            <Col sm={12} className="pt-3">
                                                                <ButtonCustom
                                                                    isLoading={this.props.loading}
                                                                    size="sm"
                                                                    clazzContainer="text-center"
                                                                    clazz="btn btn-info w-md"
                                                                    message_loaging="">
                                                                    <button
                                                                        className="btn btn-info w-md"
                                                                        type="submit">
                                                                        Salvar
                                                                    </button>
                                                                </ButtonCustom>
                                                            </Col>
                                                        </AvForm>                                                        </Col>

                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,
    id: PropTypes.number,
    loading_form: PropTypes.any,
    loading: PropTypes.any,

  
    error: PropTypes.any,
    message: PropTypes.any,
    createClassificacaoProduto: PropTypes.func,
    findByIdClassificacaoProduto: PropTypes.func,

    product: PropTypes.any,
    origem: PropTypes.any,
    cofins: PropTypes.any,
    cofins_ent: PropTypes.any,
    csosn: PropTypes.any,
    icmscst: PropTypes.any,
    ipi: PropTypes.any,
    modbcicms: PropTypes.any,
    modbcicmsst: PropTypes.any,
    pis: PropTypes.any,
    pis_ent: PropTypes.any,
    cfop_na_uf: PropTypes.any,
    cfop_fora_uf: PropTypes.any,
    ent_cfop_na_uf: PropTypes.any,
    ent_cfop_fora_uf: PropTypes.any,
    unmedida: PropTypes.any,
    cest: PropTypes.any,
    speditem: PropTypes.any,
    ncm: PropTypes.any,
    gtin_ean: PropTypes.any,
    gtin_ean_trib: PropTypes.any,
    company: PropTypes.any,
   
}


const mapStateToProps = state => {
    const {
        loading,
        loading_form,
        id,
        product,
        company,
        modelo,
        origem,
        cofins,
        cofins_ent,
        csosn,
        icmscst,
        ipi,
        modbcicms,
        modbcicmsst,
        pis,
        pis_ent,
        cfop_na_uf,
        cfop_fora_uf,
        ent_cfop_na_uf,
        ent_cfop_fora_uf,
        unmedida,
        cest,
        speditem,
        ncm,
        gtin_ean,
        gtin_ean_trib,
    } = state.ClassificacaoProduto

    return {
        loading,
        loading_form,
        id,
        product,
        company,
        modelo,
        origem,
        cofins,
        cofins_ent,
        csosn,
        icmscst,
        ipi,
        modbcicms,
        modbcicmsst,
        pis,
        pis_ent,
        cfop_na_uf,
        cfop_fora_uf,
        ent_cfop_na_uf,
        ent_cfop_fora_uf,
        unmedida,
        cest,
        speditem,
        ncm,
        gtin_ean,
        gtin_ean_trib,
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    findByIdClassificacaoProduto,
    editClassificacaoProduto
})(
    (Index)
)))

