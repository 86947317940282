import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    PAGINATE_TIPO_NF,
    FILTER_PAGINATE_TIPO_NF,
    DELETE_TIPO_NF,
    CREATE_TIPO_NF,
    FIND_BY_ID_TIPO_NF,
    EDIT_TIPO_NF,
} from "./actionTypes"

import {
    allTipoNFPaginateSuccess,
    filtersAllTipoNFPaginateSuccess,
    deleteTipoNFSuccess,
    findTipoNFIdSuccess,
    editTipoNFSuccess,
    createTipoNFSuccess,
    apiPaginateTipoNFError
} from "./actions"


import {
    getTipoNFById,
    putEditTipoNF,
    postCreateTipoNF,
    getTipoNFPaginate,
    getFilterTipoNFPaginate,
    delTipoNF
} from "../../../helpers/tipo_nf_helper"
import { NotificationManager } from "react-notifications";

function* findByIdTipoNF({payload: {id}}) {
    try {

        const response = yield call(getTipoNFById, id)

        yield put(findTipoNFIdSuccess(response))

    } catch (error) {
        yield put(apiPaginateTipoNFError(error))
    }
}
function* createTipoNF({payload: {params, history}}) {
    try {

        const response = yield call(postCreateTipoNF, params)

        yield put(createTipoNFSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiPaginateTipoNFError(error))
    }
}

function* editTipoNF({payload: {params, history}}) {
    try {

        const response = yield call(putEditTipoNF, params)

        yield put(editTipoNFSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiPaginateTipoNFError(error))
    }
}
function* deleteTipoNF({ payload: { id, history } }) {
    try {

        const response = yield call(delTipoNF, id)

        yield put(deleteTipoNFSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTipoNFError(error))
    }
}

function* paginateTipoNF({ payload: { params } }) {
    try {

        const response = yield call(getTipoNFPaginate, params)

        yield put(allTipoNFPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTipoNFError(error))
    }
}

function* filtersPaginateTipoNF({ payload: { params } }) {
    try {

        const response = yield call(getFilterTipoNFPaginate, params)

        yield put(filtersAllTipoNFPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTipoNFError(error))
    }
}


function* TipoNFSaga() {
    yield takeEvery(PAGINATE_TIPO_NF, paginateTipoNF)
    yield takeEvery(FILTER_PAGINATE_TIPO_NF, filtersPaginateTipoNF)
    yield takeEvery(DELETE_TIPO_NF, deleteTipoNF)
    yield takeEvery(CREATE_TIPO_NF, createTipoNF)
    yield takeEvery(EDIT_TIPO_NF, editTipoNF)
    yield takeEvery(FIND_BY_ID_TIPO_NF, findByIdTipoNF)
}

export default TipoNFSaga
