import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_COUNTRY,
    FILTER_PAGINATE_COUNTRY,
    DELETE_COUNTRY,
} from "./actionTypes"

import {
    allCountryPaginateSuccess,
    filtersAllCountryPaginateSuccess,
    deleteCountrySuccess,
    apiPaginateCountryError
} from "./actions"


import {
    getCountriesPaginate,
    getFilterCountriesPaginate,
    delCountry
} from "../../../helpers/country_helper"
import {NotificationManager} from "react-notifications";

function* deleteCountry({payload: {id, history}}) {
    try {

        const response = yield call(delCountry, id)

        yield put(deleteCountrySuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateCountryError(error))
    }
}

function* paginateCountries({payload: {params}}) {
    try {

        const response = yield call(getCountriesPaginate, params)

        yield put(allCountryPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCountryError(error))
    }
}

function* filtersPaginateCountries({payload: {params}}) {
    try {

        const response = yield call(getFilterCountriesPaginate, params)

        yield put(filtersAllCountryPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCountryError(error))
    }
}


function* countrySaga() {
    yield takeEvery(PAGINATE_COUNTRY, paginateCountries)
    yield takeEvery(FILTER_PAGINATE_COUNTRY, filtersPaginateCountries)
    yield takeEvery(DELETE_COUNTRY, deleteCountry)
}

export default countrySaga
