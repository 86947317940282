import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { editCategories, findByIdCategories } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import Autocomplete from "../../../components/Autocomplete";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: null,
            availability: null,
            model_menu_id: 0,
            company_id: null,
            model: null,
            company: null,
            promotions: null,
        }
 
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleRadioButtom = this.handleRadioButtom.bind(this)
        this.handleRadioButtomPriceType = this.handleRadioButtomPriceType.bind(this)
        this.handleRadioButtomPromotion = this.handleRadioButtomPromotion.bind(this)
    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findByIdCategories(entity_id)


    }

    handleRadioButtomPromotion(event) {

        this.setState({ promotions: event.target.value })

    }

    handleRadioButtom(event) {

        const { name, value } = event.target

        this.setState({ availability: value })

    }

    handleRadioButtomPriceType(event) {

        console.log(event.target.value);
        this.setState({ price_type: event.target.value })

    }

    handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data

            let newState = {}

            newState[name] = value
            newState[name_entity] = code

            //  console.log('newState ', newState)
            //   console.log('name_entity ', name_entity)

            this.setState(newState)
        }
    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleValidSubmit(event, values) {
        this.props.editCategories({
            id: this.props.id,
            name: values.name,
            promotions: this.state.promotions ? this.state.promotions : this.props.promotions,
            description: values.description,
            model_menu_id: this.state.model_menu_id ? this.state.model_menu_id : this.props.model_menu_id,
            company_id: this.state.company_id ? this.state.company_id : this.props.company_id,
            status: this.state.status ? this.state.status : this.props.status,
            availability: this.state.availability ? this.state.availability : this.props.availability,
            price_type: this.state.price_type ? this.state.price_type : this.props.price_type,
        }, this.props.history)

    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Editar Categoria</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Categoria"
                            breadcrumbItem="Editar" />
                        <Row>
                            {
                                this.props.loading_form
                                    ?
                                    <Col className="col-12">
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            paddingBottom: 20
                                                        }}>
                                                            <Spinner size="sm" animation="border"
                                                                style={{ marginBottom: 20 }}
                                                                variant="success" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <>
                                        <Col sm={this.props.promotions === 0 ? 7 : 12}>
                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={this.handleValidSubmit}>
                                                <Row>
                                                    <Col sm={12}>
                                                        <Card>
                                                            <CardBody>
                                                                <Row className="align-items-md-center  mb-3">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <span
                                                                                className="h4 card-title">{this.props.t("Form")}</span>
                                                                            <p className="card-title-desc mt-2">Preencha
                                                                                os
                                                                                campos
                                                                                abaixo, para
                                                                                atualizar o cadastro.</p>
                                                                        </div>
                                                                        {/*<div className="text-md-right ms-auto">
                                                                            <a
                                                                                onClick={() => {
                                                                                    this.props.history.goBack()
                                                                                }}
                                                                                className="btn btn-dark btn-sm text-light"
                                                                            >
                                                                                <i className="bx bx-left-arrow-circle"/>
                                                                                <span> Voltar</span>
                                                                            </a>
                                                                        </div>*/}
                                                                    </Col>
                                                                    <Col sm={12}>

                                                                        <Row>
                                                                            <Col sm={8} className="mb-2">
                                                                                <AvField
                                                                                    name="name"
                                                                                    label={this.props.t("Name")}
                                                                                    value={this.props.name}
                                                                                    className="form-control"
                                                                                    placeholder="Digite o nome"
                                                                                    type="text"
                                                                                    required
                                                                                />
                                                                            </Col>
                                                                            <Col sm={2} className="mb-2">
                                                                                <AvField
                                                                                    value={this.state.status}
                                                                                    onChange={this.handleFields}
                                                                                    type="select" name="select"
                                                                                    label={this.props.t("Status")}>
                                                                                    <option value="1">Ativo</option>
                                                                                    <option value="0">Inativo</option>
                                                                                </AvField>
                                                                            </Col>
                                                                            <Col sm={6} className="mt-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'company_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'company',
                                                                                        label: this.props.t("Company"),
                                                                                        placeholder: this.props.t("Company to search"),
                                                                                        value: this.state.company ? this.state.company : this.props.company,
                                                                                        url: 'search/companies'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={6} className="mt-2">
                                                                                <Autocomplete
                                                                                    onSourceSelect={this.handleAutocomplete}
                                                                                    nameFieldSelect={'model_menu_id'}
                                                                                    limit={10}
                                                                                    inputProps={{
                                                                                        id: 'model',
                                                                                        label: this.props.t("Model"),
                                                                                        placeholder: this.props.t("Model to search"),
                                                                                        value: this.state.model ? this.state.model : this.props.model_menu,
                                                                                        url: 'search/models'
                                                                                    }} />
                                                                            </Col>
                                                                            <Col sm={12} className="mt-2">
                                                                                <AvField
                                                                                    name="description"
                                                                                    label={this.props.t("Description")}
                                                                                    className="form-control"
                                                                                    value={this.props.description}
                                                                                    placeholder="Digite uma descrição"
                                                                                    type="textarea"
                                                                                    rows={5}
                                                                                    required
                                                                                />
                                                                            </Col>

                                                                            <Col sm={12} className="mt-2">
                                                                                <Row>
                                                                                    {
                                                                                        this.props.promotions === 0 &&
                                                                                        <>

                                                                                            <Col xl={8} sm={6}>
                                                                                                <div className="mt-4" onChange={this.handleRadioButtomPriceType}>
                                                                                                    <h5 className="font-size-14 mb-4">Tipo de precificação</h5>
                                                                                                    <div className="form-check mb-3">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="price_type"
                                                                                                            id="price_type"
                                                                                                            value="0"
                                                                                                            checked={((parseInt(this.state.price_type) === 0 || parseInt(this.props.price_type) === 0))}
                                                                                                        />
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor="price_type"
                                                                                                        >
                                                                                                            Considerar valor dos produtos.
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="form-check">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="price_type"
                                                                                                            id="price_type"
                                                                                                            value="1"
                                                                                                            checked={((parseInt(this.state.price_type) === 1 || parseInt(this.props.price_type) === 1))}
                                                                                                        />
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor="price_type"
                                                                                                        >
                                                                                                            Considerar o valor dos complementos.
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>
                                                                                            <Col xl={4} sm={6}
                                                                                                onChange={this.handleRadioButtom}>
                                                                                                <div className="mt-4">
                                                                                                    <h5 className="font-size-14 mb-4">{this.props.t("Availability")}</h5>
                                                                                                    <div
                                                                                                        className="form-check mb-3">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="availability"
                                                                                                            id="availability"
                                                                                                            value="1"
                                                                                                            checked={(!!(parseInt(this.state.availability) === 1 || parseInt(this.props.availability) === 1))}

                                                                                                        />
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor="availability"
                                                                                                        >
                                                                                                            {this.props.t("Always available")}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className="form-check">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="availability"
                                                                                                            id="availability"
                                                                                                            checked={(!!(parseInt(this.state.availability) === 2 || parseInt(this.props.availability) === 2))}
                                                                                                            value="2"
                                                                                                        />
                                                                                                        <label
                                                                                                            className="form-check-label"
                                                                                                            htmlFor="availability"
                                                                                                        >
                                                                                                            {this.props.t("Scheduled Date")}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>

                                                                                        </>
                                                                                    }


                                                                                    <Col xl={3} sm={6}>
                                                                                        <div className="mt-4" onChange={this.handleRadioButtomPromotion}>
                                                                                            <h5 className="font-size-14 mb-4">Campanha Promocional</h5>
                                                                                            <div className="form-check mb-3">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="promotions"
                                                                                                    id="promotions"
                                                                                                    value="1"
                                                                                                    checked={(!!(parseInt(this.state.promotions) === 1 || parseInt(this.props.promotions) === 1))}

                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor="promotions"
                                                                                                >
                                                                                                    Sim
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="form-check">
                                                                                                <input
                                                                                                    className="form-check-input"
                                                                                                    type="radio"
                                                                                                    name="promotions"
                                                                                                    id="promotions"
                                                                                                    value="0"
                                                                                                    checked={(!!(parseInt(this.state.promotions) === 0 || parseInt(this.props.promotions) === 0))}
                                                                                                />
                                                                                                <label
                                                                                                    className="form-check-label"
                                                                                                    htmlFor="promotions"
                                                                                                >
                                                                                                    Não
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>

                                                                            <Col sm={12} className="pt-3">
                                                                                <ButtonCustom
                                                                                    isLoading={this.props.loading}
                                                                                    size="sm"
                                                                                    clazzContainer="text-center"
                                                                                    clazz="btn btn-info w-md"
                                                                                    message_loaging="">
                                                                                    <button
                                                                                        className="btn btn-info w-md"
                                                                                        type="submit">
                                                                                        Salvar
                                                                                    </button>
                                                                                </ButtonCustom>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>

                                                </Row>
                                            </AvForm>
                                        </Col>
                                        {
                                            this.props.promotions === 0 &&
                                            <Col sm={5}>
                                                <Card>
                                                    <CardBody>
                                                        <Row className="align-items-md-center  mb-3">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <span
                                                                        className="h4 card-title">{this.props.t("Options")}</span>
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <a
                                                                        onClick={() => {
                                                                            this.props.history.goBack()
                                                                        }}
                                                                        className="btn btn-dark btn-sm text-light"
                                                                    >
                                                                        <i className="bx bx-left-arrow-circle" />
                                                                        <span> Voltar</span>
                                                                    </a>
                                                                </div>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <div className="list-group mt-2">
                                                                    {
                                                                        (parseInt(this.props.availability) === 2 || parseInt(this.state.availability) === 2) &&
                                                                        <Link to={{ pathname: '' }}
                                                                            className="list-group-item">
                                                                            {this.props.t("Availability")}
                                                                            <span
                                                                                className="badge rounded-pill bg-dark float-end">Adicionar</span>
                                                                        </Link>
                                                                    }

                                                                    {
                                                                        (parseInt(this.props.model_menu_id) === 1 || parseInt(this.state.model_menu_id) === 1) &&
                                                                        <>
                                                                            <Link
                                                                                to={{ pathname: '/' + this.props.id + '/edges' }}
                                                                                className="list-group-item">
                                                                                {this.props.t("Edges")}
                                                                                <span
                                                                                    className="badge rounded-pill bg-dark float-end">Adicionar</span>
                                                                            </Link>
                                                                            <Link
                                                                                to={{ pathname: '/' + this.props.id + '/pastas' }}
                                                                                className="list-group-item">
                                                                                {this.props.t("Pastas")}
                                                                                <span
                                                                                    className="badge rounded-pill bg-dark float-end">Adicionar</span>
                                                                            </Link>
                                                                            <Link
                                                                                to={{ pathname: '/' + this.props.id + '/sizes' }}
                                                                                className="list-group-item">
                                                                                {this.props.t("Sizes")}
                                                                                <span
                                                                                    className="badge rounded-pill bg-dark float-end">Adicionar</span>
                                                                            </Link>

                                                                        </>
                                                                    }
                                                                    <Link
                                                                        to={{ pathname: '/' + this.props.id + '/' + (this.state.model_menu_id ? this.state.model_menu_id : this.props.model_menu_id) + '/products' }}
                                                                        className="list-group-item">
                                                                        {this.props.t("Products")}
                                                                        <span
                                                                            className="badge rounded-pill bg-dark float-end">Adicionar</span>
                                                                    </Link>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        }
                                    </>
                            }

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,


    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    promotions: PropTypes.any,
    name: PropTypes.any,
    description: PropTypes.any,
    company_id: PropTypes.any,
    model_menu_id: PropTypes.any,
    company: PropTypes.any,
    model_menu: PropTypes.any,
    status: PropTypes.any,
    availability: PropTypes.any,
    id: PropTypes.any,
    price_type: PropTypes.any,
    editCategories: PropTypes.func,
    findByIdCategories: PropTypes.func,

}


const mapStateToProps = state => {

    const {
        loading,
        loading_form,
        error,
        message,
        promotions,
        status,
        availability,
        name,
        company_id,
        model_menu_id,
        company,
        model_menu,
        description,
        image,
        id,
        price_type
    } = state.CreateOrEditCategories
    return {
        name,
        promotions,
        loading_form,
        description,
        availability,
        loading,
        company_id,
        model_menu_id,
        company,
        model_menu,
        error,
        message,
        status,
        image,
        id,
        price_type
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    editCategories,
    findByIdCategories
})(
    (Index)
)))


