import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    IMPORT_META_FACEBOOK,
    PAGINATE_META_FACEBOOK,
    FILTER_PAGINATE_META_FACEBOOK,
    DELETE_META_FACEBOOK,
} from "./actionTypes"

import {
    allTabMetaFacebookPaginateSuccess,
    filtersAllTabMetaFacebookPaginateSuccess,
    deleteTabMetaFacebookSuccess,
    importTabMetaFacebookSuccess,
    apiPaginateTabMetaFacebookError
} from "./actions"


import {
    getMetaFacebookPaginate,
    getFilterMetaFacebookPaginate,
    importMetaFacebookAPI,
    delMetaFacebook
} from "../../../helpers/tab_category_meta_facebook_helper"
import { NotificationManager } from "react-notifications";

function* importMetaFacebook({ payload: { params } }) {
    try {

        const response = yield call(importMetaFacebookAPI, params)

        yield put(importTabMetaFacebookSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabMetaFacebookError(error))
    }
}

function* deleteMetaFacebook({ payload: { id, history } }) {
    try {

        const response = yield call(delMetaFacebook, id)

        yield put(deleteTabMetaFacebookSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabMetaFacebookError(error))
    }
}

function* paginateMetaFacebook({ payload: { params } }) {
    try {

        const response = yield call(getMetaFacebookPaginate, params)

        yield put(allTabMetaFacebookPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabMetaFacebookError(error))
    }
}

function* filtersPaginateMetaFacebook({ payload: { params } }) {
    try {

        const response = yield call(getFilterMetaFacebookPaginate, params)

        yield put(filtersAllTabMetaFacebookPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabMetaFacebookError(error))
    }
}


function* MetaFacebookSaga() {
    yield takeEvery(PAGINATE_META_FACEBOOK, paginateMetaFacebook)
    yield takeEvery(FILTER_PAGINATE_META_FACEBOOK, filtersPaginateMetaFacebook)
    yield takeEvery(DELETE_META_FACEBOOK, deleteMetaFacebook)
    yield takeEvery(IMPORT_META_FACEBOOK, importMetaFacebook)
}

export default MetaFacebookSaga
