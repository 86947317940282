import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    PAGINATE_CLASSIFICACAO_PRODUTO,
    FIND_BY_ID_CLASSIFICACAO_PRODUTO,
    FILTER_PAGINATE_CLASSIFICACAO_PRODUTO,
    DELETE_CLASSIFICACAO_PRODUTO,
    CREATE_CLASSIFICACAO_PRODUTO,
    EDIT_CLASSIFICACAO_PRODUTO,
} from "./actionTypes"

import {
    findClassificacaoProdutoIdSuccess,
    allClassificacaoProdutoPaginateSuccess,
    filtersAllClassificacaoProdutoPaginateSuccess,
    deleteClassificacaoProdutoSuccess,
    editClassificacaoProdutoSuccess,
    createClassificacaoProdutoSuccess,
    apiPaginateClassificacaoProdutoError
} from "./actions"


import {
    getClassificacaoProdutoById,
    putEditClassificacaoProduto,
    postCreateClassificacaoProduto,
    getClassificacaoProdutoPaginate,
    getFilterClassificacaoProdutoPaginate,
    delClassificacaoProduto
} from "../../../../helpers/classificacao_produto_helper"
import { NotificationManager } from "react-notifications";

function* createClassificacaoProduto({ payload: { params, history } }) {
    try {

        const response = yield call(postCreateClassificacaoProduto, params)

        yield put(createClassificacaoProdutoSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiPaginateClassificacaoProdutoError(error))
    }
}

function* editClassificacaoProduto({ payload: { params, history } }) {
    try {

        const response = yield call(putEditClassificacaoProduto, params)

        yield put(editClassificacaoProdutoSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiPaginateClassificacaoProdutoError(error))
    }
}
function* deleteClassificacaoProduto({ payload: { id, history } }) {
    try {

        const response = yield call(delClassificacaoProduto, id)

        yield put(deleteClassificacaoProdutoSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateClassificacaoProdutoError(error))
    }
}

function* findByIdClassificacaoProduto({ payload: { id } }) {
    try {

        const response = yield call(getClassificacaoProdutoById, id)

        yield put(findClassificacaoProdutoIdSuccess(response))

    } catch (error) {
        yield put(apiPaginateClassificacaoProdutoError(error))
    }
}

function* paginateClassificacaoProduto({ payload: { params } }) {
    try {

        const response = yield call(getClassificacaoProdutoPaginate, params)

        yield put(allClassificacaoProdutoPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateClassificacaoProdutoError(error))
    }
}

function* filtersPaginateClassificacaoProduto({ payload: { params } }) {
    try {

        const response = yield call(getFilterClassificacaoProdutoPaginate, params)

        yield put(filtersAllClassificacaoProdutoPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateClassificacaoProdutoError(error))
    }
}


function* ClassificacaoProdutoSaga() {
    yield takeEvery(PAGINATE_CLASSIFICACAO_PRODUTO, paginateClassificacaoProduto)
    yield takeEvery(FILTER_PAGINATE_CLASSIFICACAO_PRODUTO, filtersPaginateClassificacaoProduto)
    yield takeEvery(DELETE_CLASSIFICACAO_PRODUTO, deleteClassificacaoProduto)
    yield takeEvery(CREATE_CLASSIFICACAO_PRODUTO, createClassificacaoProduto)
    yield takeEvery(EDIT_CLASSIFICACAO_PRODUTO, editClassificacaoProduto)
    yield takeEvery(FIND_BY_ID_CLASSIFICACAO_PRODUTO, findByIdClassificacaoProduto)
}

export default ClassificacaoProdutoSaga
