export const CREATE_CITY = "CREATE_CITY"
export const CREATE_CITY_SUCCESS = "CREATE_CITY_SUCCESS"

export const EDIT_CITY = "EDIT_CITY"
export const EDIT_CITY_SUCCESS = "EDIT_CITY_SUCCESS"

export const FIND_BY_ID_CITY = "FIND_BY_ID_CITY"
export const FIND_BY_ID_CITY_SUCCESS = "FIND_BY_ID_CITY_SUCCESS"

export const CREATE_OR_EDIT_CITY_API_ERROR = "CREATE_OR_EDIT_CITY_API_ERROR"
