export const PAGINATE_SPED_ITEM = "PAGINATE_SPED_ITEM"
export const PAGINATE_SPED_ITEM_SUCCESS = "PAGINATE_SPED_ITEM_SUCCESS"

export const IMPORT_SPED_ITEM = "IMPORT_SPED_ITEM"
export const IMPORT_SPED_ITEM_SUCCESS = "IMPORT_SPED_ITEM_SUCCESS"


export const FILTER_PAGINATE_SPED_ITEM = "FILTER_PAGINATE_SPED_ITEM"
export const FILTER_PAGINATE_SPED_ITEM_SUCCESS = "FILTER_PAGINATE_SPED_ITEM_SUCCESS"

export const DELETE_SPED_ITEM = "DELETE_SPED_ITEM"
export const DELETE_SPED_ITEM_SUCCESS = "DELETE_SPED_ITEM_SUCCESS"

export const API_ERROR_SPED_ITEM = "PAGINATE_SPED_ITEM_API_ERROR"
