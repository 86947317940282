import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_PAYMENT_METHOD,
    FILTER_PAGINATE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD,
} from "./actionTypes"

import {
    allPaymentMethodsPaginateSuccess,
    filtersAllPaymentMethodsPaginateSuccess,
    deletePaymentMethodsSuccess,
    apiPaginatePaymentMethodsError
} from "./actions"


import {
    getPaymentMethodsPaginate,
    getFilterPaymentMethodsPaginate,
    delPaymentMethod
} from "../../../../helpers/payment_method_helper"
import {NotificationManager} from "react-notifications";

function* deletePaymentMethods({payload: {id, history}}) {
    try {

        const response = yield call(delPaymentMethod, id)

        yield put(deletePaymentMethodsSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginatePaymentMethodsError(error))
    }
}

function* paginatePaymentMethods({payload: {params}}) {
    try {

        const response = yield call(getPaymentMethodsPaginate, params)

        yield put(allPaymentMethodsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePaymentMethodsError(error))
    }
}

function* filtersPaginatePaymentMethods({payload: {params}}) {
    try {

        const response = yield call(getFilterPaymentMethodsPaginate, params)

        yield put(filtersAllPaymentMethodsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePaymentMethodsError(error))
    }
}


function* PaymentMethodsSaga() {
    yield takeEvery(PAGINATE_PAYMENT_METHOD, paginatePaymentMethods)
    yield takeEvery(FILTER_PAGINATE_PAYMENT_METHOD, filtersPaginatePaymentMethods)
    yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethods)
}

export default PaymentMethodsSaga
