import {
    CREATE_MODELS_SEGUIMENTS,
    CREATE_MODELS_SEGUIMENTS_SUCCESS,
    EDIT_MODELS_SEGUIMENTS,
    EDIT_MODELS_SEGUIMENTS_SUCCESS,
    FIND_BY_ID_MODELS_SEGUIMENTS,
    FIND_BY_ID_MODELS_SEGUIMENTS_SUCCESS,
    UPLOAD_MODELS_SEGUIMENTS,
    UPLOAD_MODELS_SEGUIMENTS_SUCCESS,
    CREATE_OR_EDIT_MODELS_SEGUIMENTS_API_ERROR
} from "./actionTypes"


export const createModelsSeguiments = (params, existsFiles,   history) => {
    return {
        type: CREATE_MODELS_SEGUIMENTS,
        payload: {params,existsFiles,  history},
    }
}

export const createModelsSeguimentsSuccess = params => {
    return {
        type: CREATE_MODELS_SEGUIMENTS_SUCCESS,
        payload: params,
    }
}

export const editModelsSeguiments = (params, history) => {
    return {
        type: EDIT_MODELS_SEGUIMENTS,
        payload: {params, history},
    }
}

export const editModelsSeguimentsSuccess = result => {
    return {
        type: EDIT_MODELS_SEGUIMENTS_SUCCESS,
        payload: {result},
    }
}

export const findByIdModelsSeguiments = id => {
    return {
        type: FIND_BY_ID_MODELS_SEGUIMENTS,
        payload: {id},
    }
}

export const findModelsSeguimentsIdSuccess = result => {
    return {
        type: FIND_BY_ID_MODELS_SEGUIMENTS_SUCCESS,
        payload: result,
    }
}

export const uploadModelsSeguiments = ( dataForm,  history) => {
    return {
        type: UPLOAD_MODELS_SEGUIMENTS,
        payload: {dataForm, history},
    }
}

export const uploadModelsSeguimentsSuccess = result => {
    return {
        type: UPLOAD_MODELS_SEGUIMENTS_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditModelsSeguimentsError = error => {
    return {
        type: CREATE_OR_EDIT_MODELS_SEGUIMENTS_API_ERROR,
        payload: error,
    }
}

