import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_SCHEDULER_COMPANY,
    EDIT_SCHEDULER_COMPANY,
} from "./actionTypes"


import {
    createSchedulerCompanySuccess,
    editSchedulerCompanySuccess,
    apiCreateOrEditSchedulerCompanyError
} from "./actions"


import {
    putEditSchedulerCompany,
    postCreateSchedulerCompany,
} from "../../../../helpers/scheduler_company_helper.js"


function* createSchedulerCompany({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateSchedulerCompany, params)
        const {message, id, error} = response;

        yield put(createSchedulerCompanySuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditSchedulerCompanyError(error))
    }
}

function* editSchedulerCompany({payload: {params,   history}}) {
    try {

        const response = yield call(putEditSchedulerCompany, params)

        yield put(editSchedulerCompanySuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditSchedulerCompanyError(error))
    }
}



function* createOrEditSchedulerCompanySaga() {
    yield takeEvery(CREATE_SCHEDULER_COMPANY, createSchedulerCompany)
    yield takeEvery(EDIT_SCHEDULER_COMPANY, editSchedulerCompany)
}

export default createOrEditSchedulerCompanySaga
