import React from 'react';
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ButtonCustom from "../../../../../components/ButtonCustom";
import { connect } from "react-redux";
import { uploadProducts, createProduct } from "../../../../../store/categories/options/products/actions"
import { createProductSize } from "../../../../../store/categories/options/products/sizes/actions"
import { findSizesByIdCategories } from "../../../../../store/actions"
import { findProductComplements } from "../../../../../store/categories/options/products/complements/actions"


import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames"
//import IntlCurrencyInput from "react-intl-currency-input"
import { NotificationManager } from "react-notifications";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Form, Stack } from "react-bootstrap";

import Dropzone from "react-dropzone";
//import GenericsServiceAPI from "../../../../../helpers/GenericsServicesAPI";
import InputMask from 'react-input-mask';

/*const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};*/

class FormEditProductComplement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            name: '',
            mandatory: 0,
            product_id: 0,
            min_select: 0,
            max_select: 1,
            has_addons: null,
            activeTab: "1",
            isEnableButtonNew: false,
            complements: []


        }

        this.handleFields = this.handleFields.bind(this);
        this.handleFieldsComplementsList = this.handleFieldsComplementsList.bind(this);
        this.handleRadioFields = this.handleRadioFields.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleAddNewComplement = this.handleAddNewComplement.bind(this);
        this.handleSaveNewComplement = this.handleSaveNewComplement.bind(this);
        this.handleRemoveComplement = this.handleRemoveComplement.bind(this);
        this.handleUpdateComplement = this.handleUpdateComplement.bind(this);
        this.handleEditComplement = this.handleEditComplement.bind(this);
        this.handleSub = this.handleSub.bind(this);
        this.handleDrop = this.handleDrop.bind(this);

    }


    componentDidMount() {
        const { entity_id, isEditing } = this.props
        if (parseInt(entity_id) > 0) {
            this.props.findProductComplements(entity_id)

        }
    }
    componentDidUpdate() {

        if (this.state.complements.length === 0) {
            let complement_news = []

            if (this.props.complements.length > 0) {
                this.props.complements.map((item, index) => {
                    complement_news.push({
                        id: item.id,
                        sku: '',
                        name: item.name,
                        isCreated: true,
                        isEdited: false,
                        isUpdated: false,
                        description: item.description,
                        image: item.url_image,
                        status: 1,
                        price: item.price,
                        file: null,
                        existsFile: false,
                    })
                })

                this.setState({
                    id: this.props.id,
                    name: this.props.name,
                    mandatory: this.props.mandatory,
                    product_id: this.props.product_id,
                    min_select: this.props.min_select,
                    max_select: this.props.max_select,
                    activeTab: "1",
                    isEnableButtonNew: true,
                    complements: complement_news
                })
            }
        }
    }

    mountDataForm() {
        const { id, name, mandatory, product_id, min_select, max_select, activeTab, isEnableButtonNew, complements } = this.state
        return [{ id, name, mandatory, product_id, min_select, max_select, activeTab, isEnableButtonNew, complements }];

    }

    handleAdd(e, type) {

        if (type === 1) {
            this.setState({ min_select: (this.state.min_select + 1) })
        }
        if (type === 2) {
            this.setState({ max_select: (this.state.max_select + 1) })
        }

    }

    handleDrop(e, index) {

        let reader = new FileReader();
        let file = e[0];

        const { complements } = this.state;
        var data = new FormData();
        data.append('id', complements[index]['id']);
        data.append('type_dir_file', 'image');
        data.append('files', file);
        data.append('path_file_before', complements[index]['image']);
        this.props.uploadProducts(data)
        reader.onloadend = () => {

            complements[index]['file'] = file;
            complements[index]['existsFile'] = true;
            complements[index]['image'] = reader.result;

            this.setState({ complements });
        }

        reader.readAsDataURL(file)


    }

    handleSub(e, type) {
        if (type === 1) {
            if (this.state.min_select > 0) {
                this.setState({ min_select: (this.state.min_select - 1) })
            } else {
                this.setState({ min_select: 0 })
            }

        }
        if (type === 2) {
            if (this.state.max_select > 0) {
                this.setState({ max_select: (this.state.max_select - 1) })
            } else {
                this.setState({ max_select: 0 })
            }

        }
    }

    handleUpdateComplement(event, index) {
        const { complements } = this.state;
        console.log('eeee ', complements[index])
        complements[index]['isCreated'] = false;
        complements[index]['isEdited'] = false;
        complements[index]['isUpdated'] = true;
        console.log('aaa ', complements[index])
        this.setState({ complements, isEnableButtonNew: true })
    }

    handleSaveNewComplement(event, index) {
        const { complements } = this.state;
        complements[index]['isCreated'] = true;

        this.setState({ complements, isEnableButtonNew: true })
    }

    handleAddNewComplement(event) {
        const { complements } = this.state;
        complements.push({
            id: 0,
            sku: '',
            name: '',
            isCreated: false,
            isEdited: false,
            description: '',
            image: null,
            status: 1,
            price: 0,

        });

        this.setState({ complements, isEnableButtonNew: false })
    }

    handleRemoveComplement(event, index) {
        const { complements } = this.state;
        if (complements.length > 1) {
            complements.splice(index, 1);
        } else {
            complements[index]['isCreated'] = false;
        }
        if (complements.length === 1) {
            complements[index]['isCreated'] = false;
        }

        this.setState({ complements })
    }

    handleEditComplement(event, index) {
        const { complements } = this.state;

        complements[index]['isEdited'] = true;
        complements[index]['isCreated'] = false;

        this.setState({ complements })
    }

    handleRadioFields(event) {
        const { name, value } = event.target
        if (parseInt(value) === 1) {
            this.setState({ min_select: 1 })
        }
        if (parseInt(value) === 0) {
            this.setState({ min_select: 0 })
        }
        this.setState({ mandatory: parseInt(value) })

    }

    handleFieldsComplementsList(index, e) {
        const { name, value } = e.target
        let complements = [...this.state.complements];
        let item = { ...complements[index] };
        item[name] = value;
        complements[index] = item;
        this.setState({ complements });
    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }

    render() {
        const { complements } = this.state;


        return (
            <Card>
                <CardBody>
                    <Row className="align-items-md-center  mb-3">
                        <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                                <h4 className="card-title mb-4">{this.state.name ? this.state.name : 'Novo grupo de complementos'}</h4>
                            </div>
                            <div className="text-md-right ms-auto p-2">
                                <i onClick={this.props.closeLayoutDrawer} className="bx bx-x"></i>
                            </div>
                        </Col>
                    </Row>

                    <ul className="nav nav-tabs nav-tabs-custom">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "1",
                                })}
                            >
                                Detalhe
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: this.state.activeTab === "2",
                                })}
                            >
                                Complementos
                            </NavLink>
                        </NavItem>

                    </ul>
                    <TabContent
                        activeTab={this.state.activeTab}
                        className="py-4"
                    >
                        <TabPane tabId="1">
                            <PerfectScrollbar style={{ height: "410px" }}>
                                <Row>
                                    <Col sm={12}>
                                        <div className="form-group mt-2">
                                            <label htmlFor=""
                                                className="control-label">Nome do grupo de complemento <small
                                                    className="text-muted"> Obrigatório</small></label>
                                            <Form.Control
                                                type="text"
                                                className="form-control"
                                                placeholder="Informe o nome"
                                                name="name"
                                                onChange={this.handleFields}
                                                value={this.state.name} />

                                        </div>
                                    </Col>
                                    <Col sm={12} className="mt-2">
                                        <div className="card-header rounded">
                                            <div className="d-inline">
                                                <span className="h3 card-title">Obrigatoriedade</span>
                                                <p className="card-title-desc mt-2">Indique se essa categoria é
                                                    necessária para pedir o prato</p>
                                            </div>

                                            <div className="form-group mt-2">
                                                <Form.Check
                                                    type="radio"
                                                    checked={(this.state.mandatory === 0 ? true : false)}
                                                    label="Opcional"
                                                    name="mandatory"
                                                    className="card-title-desc"
                                                    onChange={this.handleRadioFields}
                                                    value={0} />
                                            </div>
                                            <div className="form-group">
                                                <Form.Check
                                                    type="radio"
                                                    checked={(this.state.mandatory === 1 ? true : false)}
                                                    label="Obrigatório"
                                                    className="card-title-desc"
                                                    name="mandatory"
                                                    onChange={this.handleRadioFields}
                                                    value={1} />

                                            </div>
                                        </div>
                                    </Col>

                                    <Col sm={12}>
                                        <div className="card-header rounded mt-1">
                                            <div className="d-inline">
                                                <span className="h3 card-title">Quantidade permitida</span>
                                                <p className="card-title-desc mt-2">Indique quantos itens podem ser
                                                    selecionados</p>
                                            </div>
                                            <Row>
                                                <Col sm={6}>
                                                    <Row>
                                                        <Col>
                                                            <div className="form-group">
                                                                <label
                                                                    className="control-label">{this.props.t("Minimum")}</label>
                                                                <div disabled={(this.state.mandatory === 1)}
                                                                    className={(this.state.mandatory === 1) ? "rounded border border-dark text-center pt-2" : "rounded border-1 text-muted text-center  pt-2"}>
                                                                    <a disabled={(this.state.mandatory === 1)}
                                                                        onClick={(this.state.mandatory === 1) && ((e) => this.handleSub(e, 1))}
                                                                        className="p-2"><i
                                                                            className="bx bx-plus"></i></a>
                                                                    <label disabled={(this.state.mandatory === 1)}
                                                                        className="p-1">{this.state.min_select}</label>
                                                                    <a disabled={(this.state.mandatory === 1)}
                                                                        onClick={(this.state.mandatory === 1) && ((e) => this.handleAdd(e, 1))}
                                                                        className="p-2"><i
                                                                            className="bx bx-plus"></i></a>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form-group ">
                                                                <label
                                                                    className="control-label">{this.props.t("Maximum")}</label>
                                                                <div
                                                                    className="rounded border border-dark text-center pt-2">
                                                                    <a onClick={(e) => this.handleSub(e, 2)}
                                                                        className="p-2"><i
                                                                            className="bx bx-plus"></i></a>
                                                                    <label
                                                                        className="p-1">{this.state.max_select}</label>
                                                                    <a onClick={(e) => this.handleAdd(e, 2)}
                                                                        className="p-2"><i
                                                                            className="bx bx-plus"></i></a>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>
                            </PerfectScrollbar>
                        </TabPane>
                        <TabPane tabId="2">
                            <PerfectScrollbar style={{ height: "410px" }}>
                                <div>

                                    {
                                        complements.map((item, index) =>
                                        (<Row key={index} className="border rounded p-1 m-2">
                                            <Col sm={12}>
                                                {complements.length > 0 &&
                                                    (
                                                        <Row>
                                                            <Col sm={6}>
                                                                <div className="text-left ms-auto p-2">
                                                                    <i onClick={(e) => this.handleRemoveComplement(e, index)}
                                                                        className="bx bx-x"></i>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <div className="text-right text-end ms-auto p-2">
                                                                    <i onClick={(e) => { this.handleEditComplement(e, index) }}
                                                                        className="bx bx-pencil"></i>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    )

                                                }
                                            </Col>
                                            <Col sm={4}>

                                                <Col sm={12} className="mt-2"
                                                >
                                                    <Dropzone
                                                        onDrop={(event) => this.handleDrop(event, index)}>

                                                        {({ getRootProps, getInputProps }) => (

                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />
                                                                {this.state.complements[index]['image'] !== '' && this.state.complements[index]['image'] !== null
                                                                    ?
                                                                    <img style={{ width: '100%', height: '225px' }}
                                                                        src={this.state.complements[index]['image']} />
                                                                    : <p style={{ marginTop: '40%' }}
                                                                        className="text-center">Arraste e
                                                                        solte ou
                                                                        clique
                                                                        para adicionar a imagem</p>
                                                                }

                                                            </div>

                                                        )}

                                                    </Dropzone>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2 mb-2">
                                                        <label htmlFor=""
                                                            className="control-label">{this.props.t("SKU")}</label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="123"
                                                            name="sku"
                                                            disabled={this.state.complements[index]['isCreated']}
                                                            onChange={(e) => { this.handleFieldsComplementsList(index, e) }}
                                                            value={this.state.complements[index]['sku']} />

                                                    </div>
                                                </Col>

                                            </Col>
                                            <Col sm={8}>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2">
                                                        <label htmlFor=""
                                                            className="control-label">Sabor ou nome</label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Informe o sabor"
                                                            name="name"
                                                            disabled={this.state.complements[index]['isCreated']}
                                                            onChange={(e) => this.handleFieldsComplementsList(index, e)}
                                                            value={this.state.complements[index]['name']} />

                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2">
                                                        <label htmlFor=""
                                                            className="control-label">Descrição</label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={4}
                                                            className="form-control"
                                                            placeholder="Descrição do produto"
                                                            name="description"
                                                            disabled={this.state.complements[index]['isCreated']}
                                                            onChange={(e) => this.handleFieldsComplementsList(index, e)}
                                                            value={this.state.complements[index]['description']} />

                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <div className="form-group mt-2">
                                                                <label htmlFor=""
                                                                    className="control-label">Preço <small
                                                                        className="text-muted"> Obrigatório</small></label>
                                                                <Form.Control
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="00,00"
                                                                    name="price"
                                                                    as={InputMask}
                                                                    mask={"99,99"}
                                                                    disabled={this.state.complements[index]['isCreated']}
                                                                    onChange={(event) => {
                                                                        // this.state.complements[index]['price'] = value
                                                                        const { name, value } = event.target
                                                                        let complements = [...this.state.complements];
                                                                        let item = { ...complements[index] };
                                                                        item[name] = value;
                                                                        complements[index] = item;
                                                                        this.setState({ complements });
                                                                    }}
                                                                    value={this.state.complements[index]['price']} />

                                                            </div>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className="btn-group mt-4">
                                                                <input

                                                                    className="react-switch-checkbox mt-3"
                                                                    id={this.state.complements[index]['id']}
                                                                    name={'status'}
                                                                    checked={this.state.complements[index]['status']}
                                                                    type="checkbox"
                                                                    onChange={(event) => {

                                                                        let complements = [...this.state.complements];
                                                                        let item = { ...complements[index] };
                                                                        item['status'] = parseInt(item['status']) === 1 ? 0 : 1;
                                                                        complements[index] = item;
                                                                        this.setState({ complements });

                                                                    }}
                                                                />
                                                                <label
                                                                    style={{ background: parseInt(this.state.complements[index]['status']) === 1 ? '#06D6A0' : '#e6e8e7' }}
                                                                    className="react-switch-label"
                                                                    htmlFor={this.state.complements[index]['id']}
                                                                >
                                                                    <span
                                                                        className={`react-switch-button`} />
                                                                </label>




                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>


                                                {
                                                    this.state.complements[index]['isEdited'] && this.state.complements[index]['isCreated'] &&
                                                    (
                                                        <Col sm={12}>
                                                            <Row>
                                                                <Col sm={6}></Col>
                                                                <Col sm={6}>
                                                                    <button
                                                                        disabled={!this.state.complements[index]['name'].length >= 1}
                                                                        className="btn btn-dark btn-sm text-light text-right ml-lg-5"
                                                                        onClick={(e) => this.handleSaveNewComplement(e, index)}>
                                                                        Salvar
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                }
                                                {
                                                    this.state.complements[index]['isEdited'] &&
                                                    (
                                                        <Col sm={12}>
                                                            <Row>
                                                                <Col sm={6}></Col>
                                                                <Col sm={6}>
                                                                    <button
                                                                        className="mt-3 btn btn-danger w-md text-md-right"
                                                                        onClick={(e) => this.handleUpdateComplement(e, index)}>
                                                                        Atualizar
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    )
                                                }
                                            </Col>
                                        </Row>))
                                    }
                                    <button
                                        disabled={!this.state.isEnableButtonNew}
                                        className="btn btn-dark btn-sm text-light text-right ml-lg-5"
                                        onClick={(e) => this.handleAddNewComplement(e)}>
                                        Adicionar Novo Item
                                    </button>
                                </div>
                            </PerfectScrollbar>
                        </TabPane>
                    </TabContent>
                    <Row className="text-center fixed-bottom">
                        <Col className="p-2 mb-2">
                            <button
                                className="btn btn-danger w-md"
                                onClick={() => {
                                    if (this.state.activeTab === "2") {
                                        this.toggleTab("1")
                                    } else {
                                        this.props.closeLayoutDrawer()
                                    }
                                }}>
                                {this.state.activeTab === "2" ? 'Voltar' : 'Cancelar'}
                            </button>
                        </Col>
                        <Col className="d-inline">
                            <ButtonCustom
                                isLoading={false}
                                size="sm"
                                clazzContainer="text-center"
                                clazz="btn btn-dark w-md"
                                message_loaging="">
                                <button
                                    disabled={!this.state.name.length >= 1}
                                    className="btn btn-dark w-md"
                                    onClick={() => {

                                        if (this.state.activeTab === "2") {

                                            // this.handleValidSubmit()
                                            this.props.onCreatedSub(this.mountDataForm());
                                            console.log("Complementos ", this.mountDataForm())

                                        } else {
                                            this.toggleTab("2")
                                        }


                                    }}>
                                    {this.state.activeTab === "2" ? 'Salvar' : 'Continuar'}
                                </button>
                            </ButtonCustom>

                        </Col>


                    </Row>
                </CardBody>
            </Card>

        )
    }


}

FormEditProductComplement.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,





    createProduct: PropTypes.func,
    createProductSize: PropTypes.func,
    findSizesByIdCategories: PropTypes.func,
    list_complements: PropTypes.any,

    findProductComplements: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.any,
    mandatory: PropTypes.any,
    min_select: PropTypes.any,
    max_select: PropTypes.any,
    type: PropTypes.any,
    products: PropTypes.any,
    status: PropTypes.any,

    funceditEntity: PropTypes.func,
    isEditing: PropTypes.any,
    entity_id: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh,
        id,
        name,
        mandatory,
        min_select,
        max_select,
        type,
        complements
    } = state.CreateOrEditProductsComplementsGroup


    return {
        loading,
        refresh,
        id,
        name,
        mandatory,
        min_select,
        max_select,
        type,
        complements
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createProduct,
    createProductSize,
    findSizesByIdCategories,
    findProductComplements,
    uploadProducts

})(
    (FormEditProductComplement)
)))
