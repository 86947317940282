export const CREATE_CLASSIFICACAO_PRODUTO = "CREATE_CLASSIFICACAO_PRODUTO"
export const CREATE_CLASSIFICACAO_PRODUTO_SUCCESS = "CREATE_CLASSIFICACAO_PRODUTO_SUCCESS"

export const EDIT_CLASSIFICACAO_PRODUTO = "EDIT_CLASSIFICACAO_PRODUTO"
export const EDIT_CLASSIFICACAO_PRODUTO_SUCCESS = "EDIT_CLASSIFICACAO_PRODUTO_SUCCESS"

export const PAGINATE_CLASSIFICACAO_PRODUTO = "PAGINATE_CLASSIFICACAO_PRODUTO"
export const PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS = "PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS"

export const IMPORT_CLASSIFICACAO_PRODUTO = "IMPORT_CLASSIFICACAO_PRODUTO"
export const IMPORT_CLASSIFICACAO_PRODUTO_SUCCESS = "IMPORT_CLASSIFICACAO_PRODUTO_SUCCESS"

export const FIND_BY_ID_CLASSIFICACAO_PRODUTO = "FIND_BY_ID_CLASSIFICACAO_PRODUTO"
export const FIND_BY_ID_CLASSIFICACAO_PRODUTO_SUCCESS = "FIND_BY_ID_CLASSIFICACAO_PRODUTO_SUCCESS"

export const FILTER_PAGINATE_CLASSIFICACAO_PRODUTO = "FILTER_PAGINATE_CLASSIFICACAO_PRODUTO"
export const FILTER_PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS = "FILTER_PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS"

export const DELETE_CLASSIFICACAO_PRODUTO = "DELETE_CLASSIFICACAO_PRODUTO"
export const DELETE_CLASSIFICACAO_PRODUTO_SUCCESS = "DELETE_CLASSIFICACAO_PRODUTO_SUCCESS"

export const API_ERROR_CLASSIFICACAO_PRODUTO = "PAGINATE_CLASSIFICACAO_PRODUTO_API_ERROR"
