import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_CITY,
    FILTER_PAGINATE_CITY,
    DELETE_CITY,
} from "./actionTypes"

import {
    allCityPaginateSuccess,
    filtersAllCityPaginateSuccess,
    deleteCitySuccess,
    apiPaginateCityError
} from "./actions"


import {
    getCitiesPaginate,
    getFilterCitiesPaginate,
    delCity
} from "../../../helpers/city_helper"
import {NotificationManager} from "react-notifications";

function* deleteCity({payload: {id, history}}) {
    try {

        const response = yield call(delCity, id)

        yield put(deleteCitySuccess(response))

        const { message } = response;

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateCityError(error))
    }
}

function* paginateCities({payload: {params}}) {
    try {

        const response = yield call(getCitiesPaginate, params)

        yield put(allCityPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCityError(error))
    }
}

function* filtersPaginateCities({payload: {params}}) {
    try {

        const response = yield call(getFilterCitiesPaginate, params)

        yield put(filtersAllCityPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCityError(error))
    }
}


function* CitySaga() {
    yield takeEvery(PAGINATE_CITY, paginateCities)
    yield takeEvery(FILTER_PAGINATE_CITY, filtersPaginateCities)
    yield takeEvery(DELETE_CITY, deleteCity)
}

export default CitySaga
