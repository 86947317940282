import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_MODELS_SEGUIMENTS,
    FILTER_PAGINATE_MODELS_SEGUIMENTS,
    DELETE_MODELS_SEGUIMENTS,
} from "./actionTypes"

import {
    allModelsSeguimentsPaginateSuccess,
    filtersAllModelsSeguimentsPaginateSuccess,
    deleteModelsSeguimentsSuccess,
    apiPaginateModelsSeguimentsError
} from "./actions"


import {
    getCompaniesPaginate,
    getFilterCompaniesPaginate,
    delModelsSeguiments
} from "../../../helpers/models_seguiments_helper"
import {NotificationManager} from "react-notifications";

function* deleteModelsSeguiments({payload: {id, history}}) {
    try {

        const response = yield call(delModelsSeguiments, id)

        yield put(deleteModelsSeguimentsSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateModelsSeguimentsError(error))
    }
}

function* paginateCompanies({payload: {params}}) {
    try {

        const response = yield call(getCompaniesPaginate, params)

        yield put(allModelsSeguimentsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateModelsSeguimentsError(error))
    }
}

function* filtersPaginateCompanies({payload: {params}}) {
    try {

        const response = yield call(getFilterCompaniesPaginate, params)

        yield put(filtersAllModelsSeguimentsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateModelsSeguimentsError(error))
    }
}


function* ModelsSeguimentsSaga() {
    yield takeEvery(PAGINATE_MODELS_SEGUIMENTS, paginateCompanies)
    yield takeEvery(FILTER_PAGINATE_MODELS_SEGUIMENTS, filtersPaginateCompanies)
    yield takeEvery(DELETE_MODELS_SEGUIMENTS, deleteModelsSeguiments)
}

export default ModelsSeguimentsSaga
