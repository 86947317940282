import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Table from "./table/index";
import ButtonCustom from "../../../../components/ButtonCustom";
import {connect} from "react-redux";
import {createSchedulerCompany} from "../../../../store/companies/options/scheduler-company/actions"
import {AvField, AvForm} from "availity-reactstrap-validation";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company_id: 0,
            day_week: 0,
            columns: [
                {
                    id: 1,
                    name: 'DIA SEMANA',
                    status: 1
                },
                {
                    id: 2,
                    name: 'ABERTURA / ENCERRAMENTO',
                    status: 1
                },
                {
                    id: 3,
                    name: 'AÇÕES',
                    status: 1
                },
            ],
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)

    }


    async componentDidMount() {
        this.handleFields = this.handleFields.bind(this);

    }

    handleFields(event) {

        const {value} = event.target
        console.log('TARGET ', value)

        this.setState({day_week: value})
    }

    handleValidSubmit(event, values) {
        const {entity_id} = this.props.match.params
        this.props.createSchedulerCompany({
            company_id: entity_id,
            day_week: this.state.day_week,
            opening: values.opening,
            closure: values.closure}, this.props.history)

    }


    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Horários de Funcionamento</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Horários de Funcionamento"
                                     breadcrumbItem="Cadastro"/>
                        <Row>
                            <Col sm={8}>
                                <Card>
                                    <CardBody>

                                        <Table
                                            refresh={this.props.refresh}
                                            route_edit="/edit-category"
                                            columns={this.state.columns}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center ">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos
                                                        abaixo, para incluir os horários de funcionamento.</p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <span> Voltar</span>
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>
                                                    <Row>
                                                        <Col sm={12} className="mb-2">
                                                            <AvField
                                                                value={this.props.day_week}
                                                                onChange={this.handleFields}
                                                                type="select" name="select"
                                                                label="Dias da Semana">
                                                                <option value="0">Segunda-Feira</option>
                                                                <option value="1">Terça-Feira</option>
                                                                <option value="2">Quarta-Feira</option>
                                                                <option value="3">Quinta-Feira</option>
                                                                <option value="4">Sexta-Feira</option>
                                                                <option value="5">Sabado</option>
                                                                <option value="6">Domingo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={6} className="mb-2">
                                                            <AvField
                                                                type="time"
                                                                className="form-control"
                                                                name="opening"
                                                                label={this.props.t("Opening")}
                                                                value={this.props.opening}/>
                                                        </Col>
                                                        <Col sm={6} className="mb-2">
                                                            <AvField
                                                                type="time"
                                                                className="form-control"
                                                                name="closure"
                                                                label={this.props.t("Closure")}
                                                                value={this.props.closure}/>
                                                        </Col>
                                                        <Col sm={12} className="pt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Adicionar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh
    } = state.CreateOrEditCompaniesScheduler
    return {
        loading,
        refresh,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createSchedulerCompany,

})(
    (Index)
)))
