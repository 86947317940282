import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_DISCOUNT_COUPONS_COMPANY,
    EDIT_DISCOUNT_COUPONS_COMPANY,
} from "./actionTypes"


import {
    createDiscountCouponsCompanySuccess,
    editDiscountCouponsCompanySuccess,
    apiCreateOrEditDiscountCouponsCompanyError
} from "./actions"


import {
    putEditDiscountCouponsCompany,
    postCreateDiscountCouponsCompany,
} from "../../../../helpers/coupons_discount_company_helper"


function* createDiscountCouponsCompany({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateDiscountCouponsCompany, params)

        yield put(createDiscountCouponsCompanySuccess(response))
        const {message} = response;

        NotificationManager.info(message, 'Aviso');


    } catch (error) {
        yield put(apiCreateOrEditDiscountCouponsCompanyError(error))
    }
}

function* editDiscountCoupons({payload: {params,   history}}) {
    try {

        const response = yield call(putEditDiscountCouponsCompany, params)

        yield put(editDiscountCouponsCompanySuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditDiscountCouponsCompanyError(error))
    }
}



function* createOrEditDiscountCouponsCompanySaga() {
    yield takeEvery(CREATE_DISCOUNT_COUPONS_COMPANY, createDiscountCouponsCompany)
    yield takeEvery(EDIT_DISCOUNT_COUPONS_COMPANY, editDiscountCoupons)
}

export default createOrEditDiscountCouponsCompanySaga
