import {
    PAGINATE_NF_PIS,
    PAGINATE_NF_PIS_SUCCESS,
    FILTER_PAGINATE_NF_PIS,
    FILTER_PAGINATE_NF_PIS_SUCCESS,
    DELETE_NF_PIS_SUCCESS,
    DELETE_NF_PIS,
    API_ERROR_NF_PIS,
} from "./actionTypes"


export const filtersAllNFPISPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_PIS,
        payload: {params},
    }
}

export const filtersAllNFPISPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_PIS_SUCCESS,
        payload: {params},
    }
}

export const allNFPISPaginate = params => {
    return {
        type: PAGINATE_NF_PIS,
        payload: {params},
    }
}

export const allNFPISPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_PIS_SUCCESS,
        payload: {result},
    }
}

export const deleteNFPIS = (id, history) => {
  return {
    type: DELETE_NF_PIS,
    payload: {id, history} ,
  }
}

export const deleteNFPISSuccess = message => {
    return {
        type: DELETE_NF_PIS_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFPISError = error => {
  return {
    type: API_ERROR_NF_PIS,
    payload: error,
  }
}

