import {
    CREATE_CLASSIFICACAO_PRODUTO,
    CREATE_CLASSIFICACAO_PRODUTO_SUCCESS,
    EDIT_CLASSIFICACAO_PRODUTO,
    EDIT_CLASSIFICACAO_PRODUTO_SUCCESS,
    PAGINATE_CLASSIFICACAO_PRODUTO,
    PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS,
    FILTER_PAGINATE_CLASSIFICACAO_PRODUTO,
    FILTER_PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS,
    DELETE_CLASSIFICACAO_PRODUTO_SUCCESS,
    DELETE_CLASSIFICACAO_PRODUTO,
    FIND_BY_ID_CLASSIFICACAO_PRODUTO,
    FIND_BY_ID_CLASSIFICACAO_PRODUTO_SUCCESS,
    API_ERROR_CLASSIFICACAO_PRODUTO,
} from "./actionTypes"


export const findByIdClassificacaoProduto = id => {
    return {
        type: FIND_BY_ID_CLASSIFICACAO_PRODUTO,
        payload: {id},
    }
}

export const findClassificacaoProdutoIdSuccess = result => {
    return {
    
        type: FIND_BY_ID_CLASSIFICACAO_PRODUTO_SUCCESS,
        payload: result,
    }
}

export const createClassificacaoProduto = (params, history) => {
    return {
        type: CREATE_CLASSIFICACAO_PRODUTO,
        payload: { params, history },
    }
}

export const createClassificacaoProdutoSuccess = params => {
    return {
        type: CREATE_CLASSIFICACAO_PRODUTO_SUCCESS,
        payload: { params },
    }
}

export const editClassificacaoProduto = (params, history) => {
    return {
        type: EDIT_CLASSIFICACAO_PRODUTO,
        payload: { params, history },
    }
}

export const editClassificacaoProdutoSuccess = result => {
    return {
        type: EDIT_CLASSIFICACAO_PRODUTO_SUCCESS,
        payload: { result },
    }
}
export const filtersAllClassificacaoProdutoPaginate = params => {
    return {
        type: FILTER_PAGINATE_CLASSIFICACAO_PRODUTO,
        payload: { params },
    }
}

export const filtersAllClassificacaoProdutoPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS,
        payload: { params },
    }
}

export const allClassificacaoProdutoPaginate = params => {
    return {
        type: PAGINATE_CLASSIFICACAO_PRODUTO,
        payload: { params },
    }
}

export const allClassificacaoProdutoPaginateSuccess = result => {
    return {
        type: PAGINATE_CLASSIFICACAO_PRODUTO_SUCCESS,
        payload: { result },
    }
}

export const deleteClassificacaoProduto = (id, history) => {
    return {
        type: DELETE_CLASSIFICACAO_PRODUTO,
        payload: { id, history },
    }
}

export const deleteClassificacaoProdutoSuccess = message => {
    return {
        type: DELETE_CLASSIFICACAO_PRODUTO_SUCCESS,
        payload: message,
    }
}

export const apiPaginateClassificacaoProdutoError = error => {
    return {
        type: API_ERROR_CLASSIFICACAO_PRODUTO,
        payload: error,
    }
}

