import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_EDGES,
    FILTER_PAGINATE_EDGES,
    DELETE_EDGES,
} from "./actionTypes"

import {
    allEdgePaginateSuccess,
    filtersAllEdgePaginateSuccess,
    deleteEdgeSuccess,
    apiPaginateEdgeError
} from "./actions"


import {
    getEdgesPaginate,
    getFilterEdgesPaginate,
    delEdges
} from "../../../../../helpers/edges_helper"
import {NotificationManager} from "react-notifications";

function* deleteEdge({payload: {id, history}}) {
    try {

        const response = yield call(delEdges, id)

        yield put(deleteEdgeSuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateEdgeError(error))
    }
}

function* paginateEdge({payload: {params}}) {
    try {

        const response = yield call(getEdgesPaginate, params)

        yield put(allEdgePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateEdgeError(error))
    }
}

function* filtersPaginateEdge({payload: {params}}) {
    try {

        const response = yield call(getFilterEdgesPaginate, params)

        yield put(filtersAllEdgePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateEdgeError(error))
    }
}


function* EdgesSaga() {
    yield takeEvery(PAGINATE_EDGES, paginateEdge)
    yield takeEvery(FILTER_PAGINATE_EDGES, filtersPaginateEdge)
    yield takeEvery(DELETE_EDGES, deleteEdge)
}

export default EdgesSaga
