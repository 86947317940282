import {
    PAGINATE_NF_TRIB_ICMSCST,
    PAGINATE_NF_TRIB_ICMSCST_SUCCESS,
    FILTER_PAGINATE_NF_TRIB_ICMSCST,
    FILTER_PAGINATE_NF_TRIB_ICMSCST_SUCCESS,
    DELETE_NF_TRIB_ICMSCST_SUCCESS,
    DELETE_NF_TRIB_ICMSCST,
    API_ERROR_NF_TRIB_ICMSCST,
} from "./actionTypes"


export const filtersAllNFTribICMSCSTPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_ICMSCST,
        payload: {params},
    }
}

export const filtersAllNFTribICMSCSTPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_ICMSCST_SUCCESS,
        payload: {params},
    }
}

export const allNFTribICMSCSTPaginate = params => {
    return {
        type: PAGINATE_NF_TRIB_ICMSCST,
        payload: {params},
    }
}

export const allNFTribICMSCSTPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TRIB_ICMSCST_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTribICMSCST = (id, history) => {
  return {
    type: DELETE_NF_TRIB_ICMSCST,
    payload: {id, history} ,
  }
}

export const deleteNFTribICMSCSTSuccess = message => {
    return {
        type: DELETE_NF_TRIB_ICMSCST_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTribICMSCSTError = error => {
  return {
    type: API_ERROR_NF_TRIB_ICMSCST,
    payload: error,
  }
}

