export const PAGINATE_NF_CRT = "PAGINATE_NF_CRT"
export const PAGINATE_NF_CRT_SUCCESS = "PAGINATE_NF_CRT_SUCCESS"

export const FILTER_PAGINATE_NF_CRT = "FILTER_PAGINATE_NF_CRT"
export const FILTER_PAGINATE_NF_CRT_SUCCESS = "FILTER_PAGINATE_NF_CRT_SUCCESS"

export const DELETE_NF_CRT = "DELETE_NF_CRT"
export const DELETE_NF_CRT_SUCCESS = "DELETE_NF_CRT_SUCCESS"

export const API_ERROR_NF_CRT = "PAGINATE_NF_CRT_API_ERROR"
