import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { createPaymentMethod } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: null,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
    }

    componentDidMount() {


    }


    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }


    handleValidSubmit(event, values) {
        this.props.createPaymentMethod({
            name: values.name,
            code: values.code,
            description: values.description,
            status: this.state.status ? this.state.status : this.props.status,
        }, this.props.history)


    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Metódos de pagamento</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Métodos de pagamento"
                            breadcrumbItem="Cadastro" />
                        <Row>
                            <Col sm={12}>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={this.handleValidSubmit}>
                                    <Row>
                                        <Col sm={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Form")}</span>
                                                                <p className="card-title-desc mt-2">Preencha os campos
                                                                    abaixo, para
                                                                    concluir o cadastro.</p>
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <a
                                                                    onClick={() => {
                                                                        this.props.history.goBack()
                                                                    }}
                                                                    className="btn btn-dark btn-sm text-light"
                                                                >
                                                                    <i className="bx bx-left-arrow-circle" />
                                                                    <span> Voltar</span>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>

                                                            <Row>
                                                                <Col sm={1} className="mb-2">
                                                                    <AvField
                                                                        name="code"
                                                                        label="Indicador"
                                                                        className="form-control"
                                                                        placeholder="00"
                                                                        type="number"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={12}></Col>
                                                                <Col sm={8} className="mb-2">
                                                                    <AvField
                                                                        name="name"
                                                                        label={this.props.t("Name")}
                                                                        className="form-control"
                                                                        placeholder="Digite nome"
                                                                        type="text"
                                                                        required
                                                                    />
                                                                </Col>
                                                                <Col sm={4} className="mb-2">
                                                                    <AvField
                                                                        value={this.state.status}
                                                                        onChange={this.handleFields}
                                                                        type="select" name="select"
                                                                        label={this.props.t("Status")}>
                                                                        <option value="1">Ativo</option>
                                                                        <option value="0">Inativo</option>
                                                                    </AvField>
                                                                </Col>

                                                                <Col sm={12} className="mt-2">
                                                                    <AvField
                                                                        name="description"
                                                                        label={this.props.t("Description")}
                                                                        className="form-control"
                                                                        placeholder="Digite uma descrição"
                                                                        type="textarea"
                                                                        rows={5}
                                                                        required
                                                                    />
                                                                </Col>

                                                                <Col sm={12} className="pt-3">
                                                                    <ButtonCustom
                                                                        isLoading={this.props.loading}
                                                                        size="sm"
                                                                        clazzContainer="text-center"
                                                                        clazz="btn btn-info w-md"
                                                                        message_loaging="">
                                                                        <button
                                                                            className="btn btn-info w-md"
                                                                            type="submit">
                                                                            Salvar
                                                                        </button>
                                                                    </ButtonCustom>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {

    t: PropTypes.any,
    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,


    name: PropTypes.any,
    description: PropTypes.any,
    status: PropTypes.any,

    id: PropTypes.any,
    createPaymentMethod: PropTypes.func,
}


const mapStateToProps = state => {
    const {
        loading,
        loading_form,
        error,
        message,
        status,
        name,
        description,
        image,
        id
    } = state.CreateOrEditPaymentMethod
    return {
        name,
        loading_form,
        description,
        loading,
        error,
        message,
        status,
        image,
        id
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    createPaymentMethod,
})(
    (Index)
)))


