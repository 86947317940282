import {
    CREATE_PAYMENT_METHOD,
    CREATE_PAYMENT_METHOD_SUCCESS,
    EDIT_PAYMENT_METHOD,
    EDIT_PAYMENT_METHOD_SUCCESS,
    FIND_BY_ID_PAYMENT_METHOD,
    FIND_BY_ID_PAYMENT_METHOD_SUCCESS,
    UPLOAD_PAYMENT_METHOD,
    UPLOAD_PAYMENT_METHOD_SUCCESS,
    CREATE_OR_EDIT_PAYMENT_METHOD_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    status: 1,
    description: '',
    name: '',
    code: '',
    image: null,
    id: 0,
}

const createOrEditPaymentMethod = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_PAYMENT_METHOD:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_PAYMENT_METHOD_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case EDIT_PAYMENT_METHOD:
            state = {...state, loading: true}
            break
        case EDIT_PAYMENT_METHOD_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case UPLOAD_PAYMENT_METHOD:
            state = {...state, loading: true}
            break
        case UPLOAD_PAYMENT_METHOD_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_PAYMENT_METHOD:
            state = {...state, loading: false, loading_form: true}
            break
        case FIND_BY_ID_PAYMENT_METHOD_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                status: action.payload.result.status,
                description: action.payload.result.description,
                name: action.payload.result.name,
                image: action.payload.result.image,
                id: action.payload.result.id,
                code: action.payload.result.code,

            }
            break
        case CREATE_OR_EDIT_PAYMENT_METHOD_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditPaymentMethod
