import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";
 

const getNFModeloById = data => get(url.GET_NF_MODELO_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFModelo = data => del(url.DELETE_NF_MODELO.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFModelo = data => post(url.POST_CREATE_NF_MODELO, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFModelo = data => put(url.PUT_EDIT_NF_MODELO, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFModeloPaginate = ({page, limit}) => get(url.GET_NF_MODELO_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFModeloPaginate = data => get(url.GET_NF_MODELO_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFModelo = params => get(url.GET_SEARCH_NF_MODELO, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFModeloPaginate,
    getFilterNFModeloPaginate,
    putEditNFModelo,
    postCreateNFModelo,
    delNFModelo,
    getNFModeloById,
    getSearchNFModelo
}
