import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../../components/Breadcrumb";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../../components/ButtonCustom";
import Stack from "react-bootstrap/Form";
import { createTipoNF } from "../../../../store/actions"

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            tpEmis: 0,
            tpImp: 0,

            procEmi: null,
            indFinal: 0,
            idDest: null,
            indPres: null,
            mod: null,
            tpAmb: null,
            finNFe: null,
            tpNF: null,
            serie: 1,
            natOp: ''
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)

    }

    componentDidMount() {


    }

    handleValidSubmit(event, values) {
        this.props.createTipoNF({
            natOp: values.natOp,
            mod: this.state.mod,
            tpNF: this.state.tpNF,
            idDest: this.state.idDest,
            tpImp: this.state.tpImp,
            tpEmis: this.state.tpEmis,
            tpAmb: this.state.tpAmb,
            finNFe: this.state.finNFe,
            indFinal: this.state.indFinal,
            indPres: this.state.indPres,
            procEmi: this.state.procEmi,
            serie: this.state.serie
        }, this.props.history)

    }

    handleFields(event) {

        const { name, id, value } = event.target
        console.log('radio buttons ', { name, id, value });

        let tempState = {}
        tempState[id] = value

        this.setState(tempState)

    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Tipo de Nota Fiscal</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Tipo de Nota Fiscal"
                            breadcrumbItem="Cadastro" />
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center  mb-3">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos abaixo, para concluir o cadastro.</p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <i className="bx bx-left-arrow-circle" />
                                                        <span> Voltar</span>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col sm={12}>
                                                <AvForm
                                                    className="form-horizontal"
                                                    onValidSubmit={this.handleValidSubmit}>

                                                    <Row>
                                                        <Col sm={7} className="mb-2">
                                                            <AvField
                                                                name="natOp"
                                                                label="Natureza Operação"
                                                                className="form-control"
                                                                type="text"
                                                                required
                                                            />
                                                        </Col>
                                                        <Col sm={1} className="mb-2">
                                                            <AvField
                                                                value={this.state.serie} onChange={this.handleFields}
                                                                type="select" name="select" id="serie"
                                                                label="Serie NF" >
                                                                <option value="1">1</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={3} className="mb-2"></Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                value={this.state.tpNF} onChange={this.handleFields}
                                                                type="select" name="select" id="tpNF"
                                                                label="Tipo Op. NF" >
                                                                <option value="0">Entrada</option>
                                                                <option value="1">Saída</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                value={this.state.finNFe} onChange={this.handleFields}
                                                                type="select" name="select" id="finNFe"
                                                                label="Finalidade da NF" >
                                                                <option value="1">NF-e normal</option>
                                                                <option value="2">NF-e complementar</option>
                                                                <option value="3">NF-e de ajuste</option>
                                                                <option value="4">Devolução de mercadoria</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                value={this.state.tpAmb} onChange={this.handleFields}
                                                                type="select" name="select" id="tpAmb"
                                                                label="Ambiente" >
                                                                <option value="2">Homologação</option>
                                                                <option value="1">Produção</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                value={this.state.mod} onChange={this.handleFields}
                                                                type="select" name="select" id="mod"
                                                                label="Modelo" >
                                                                <option value="55">NFe (55)</option>
                                                                <option value="65">NFCe (65)</option>
                                                            </AvField>
                                                        </Col>


                                                        <Col sm={7} className="mb-2">
                                                            <AvField
                                                                value={this.state.indPres} onChange={this.handleFields}
                                                                type="select" name="select" id="indPres"
                                                                label="Indicador de presença do comprador no estabelecimento comercial" >
                                                                <option value="0">Não se aplica</option>
                                                                <option value="1">Operação presencial</option>
                                                                <option value="2">Operação não presencial, pela Internet</option>
                                                                <option value="3">Operação não presencial, Teleatendimento</option>
                                                                <option value="4">NFC-e em operação com entrega a domicílio</option>
                                                                <option value="5">Operação presencial, fora do estabelecimento</option>
                                                                <option value="9">Operação não presencial, outros</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col className="mb-2">
                                                            <AvField
                                                                value={this.state.idDest} onChange={this.handleFields}
                                                                type="select" name="select" id="idDest"
                                                                label="Ident. de Operação" >
                                                                <option value="1">Operação interna</option>
                                                                <option value="2">Operação Interestadual</option>
                                                                <option value="3">Operação Exterior</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={2} className="mb-2">
                                                            <AvField
                                                                value={this.state.status} onChange={this.handleFields}
                                                                type="select" name="select"
                                                                label={this.props.t("Status")} >
                                                                <option value="1">Ativo</option>
                                                                <option value="0">Inativo</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={3} className="mb-2">
                                                            <AvField
                                                                value={this.state.indFinal} onChange={this.handleFields}
                                                                type="select" name="select" id="indFinal"
                                                                label=" Ind. de op. Consumidor" >
                                                                <option value="0">Consumidor Normal</option>
                                                                <option value="1">Consumidor Final</option>
                                                            </AvField>
                                                        </Col>
                                                        <Col className="mb-2">
                                                            <AvField
                                                                value={this.state.procEmi} onChange={this.handleFields}
                                                                type="select" name="select" id="procEmi"
                                                                label=" Ind. de op. Consumidor" >
                                                                <option value="0">Emissão de NF-e com aplicativo do contribuinte</option>
                                                                <option value="1">Emissão de NF-e avulsa pelo Fisco</option>
                                                                <option value="2">Emissão de NF-e avulsa, pelo contribuinte com seu certificado digital, através do site do Fisco</option>
                                                                <option value="3">Emissão NF-e pelo contribuinte com aplicativo fornecido pelo Fisco</option>
                                                            </AvField>
                                                        </Col>

                                                        <Col sm={12} className="mb-2"></Col>

                                                        <Col sm={4} className="mb-2">
                                                            <div className="mt-2" onChange={this.handleFields}>
                                                                <label htmlFor=""
                                                                    className="control-label">Tipo de impressão DANFE</label>

                                                                <Stack direction="horizontal" gap={3}>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpImp"
                                                                            id="tpImp"
                                                                            value="0"
                                                                            checked={parseInt(this.state.tpImp) === 0 ? true : false || this.props.tpImp ? parseInt(this.props.tpImp) === 0 ? true : false : true}

                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpImp"
                                                                        >
                                                                            Sem geração de DANFE
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpImp"
                                                                            id="tpImp"
                                                                            value="1"
                                                                            checked={parseInt(this.state.tpImp) === 1 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpImp"
                                                                        >
                                                                            Normal, Retrato
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpImp"
                                                                            id="tpImp"
                                                                            value="2"
                                                                            checked={parseInt(this.state.tpImp) === 2 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpImp"
                                                                        >
                                                                            Normal, Paisagem
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpImp"
                                                                            id="tpImp"
                                                                            value="3"
                                                                            checked={parseInt(this.state.tpImp) === 3 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpImp"
                                                                        >
                                                                            Simplificado
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpImp"
                                                                            id="tpImp"
                                                                            value="4"
                                                                            checked={parseInt(this.state.tpImp) === 4 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpImp"
                                                                        >
                                                                            NFC-e
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpImp"
                                                                            id="tpImp"
                                                                            value="5"
                                                                            checked={parseInt(this.state.tpImp) === 5 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpImp"
                                                                        >
                                                                            NFC-e em msg eletronica
                                                                        </label>
                                                                    </div>
                                                                </Stack>

                                                            </div>
                                                        </Col>


                                                        <Col className="mb-2">
                                                            <div className="mt-2" onChange={this.handleFields}>
                                                                <label htmlFor=""
                                                                    className="control-label">Tipo de Emissão da NF</label>

                                                                <Stack direction="horizontal" gap={3}>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpEmis"
                                                                            id="tpEmis"
                                                                            value="1"
                                                                            checked={parseInt(this.state.tpEmis) === 1 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 0 ? true : false : true}

                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpEmis"
                                                                        >
                                                                            Emissão normal (não em contingência)
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpEmis"
                                                                            id="tpEmis"
                                                                            value="2"
                                                                            checked={parseInt(this.state.tpEmis) === 2 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpImp"
                                                                        >
                                                                            Contingência FS-IA, com impressão do DANFE em formulário de segurança
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpEmis"
                                                                            id="tpEmis"
                                                                            value="3"
                                                                            checked={parseInt(this.state.tpEmis) === 3 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpEmis"
                                                                        >
                                                                            Contingência SCAN (Sistema de Contingência do Ambiente Nacional)
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpEmis"
                                                                            id="tpEmis"
                                                                            value="4"
                                                                            checked={parseInt(this.state.tpEmis) === 4 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpEmis"
                                                                        >
                                                                            Contingência DPEC (Declaração Prévia da Emissão em Contingência)
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpEmis"
                                                                            id="tpEmis"
                                                                            value="5"
                                                                            checked={parseInt(this.state.tpEmis) === 5 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpEmis"
                                                                        >
                                                                            Contingência FS-DA, com impressão do DANFE em formulário de segurança
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpEmis"
                                                                            id="tpEmis"
                                                                            value="6"
                                                                            checked={parseInt(this.state.tpEmis) === 6 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpEmis"
                                                                        >
                                                                            Contingência SVC-AN (SEFAZ Virtual de Contingência do AN)
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="tpEmis"
                                                                            id="tpEmis"
                                                                            value="7"
                                                                            checked={parseInt(this.state.tpEmis) === 7 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="tpEmis"
                                                                        >
                                                                            Contingência SVC-RS (SEFAZ Virtual de Contingência do RS)
                                                                        </label>
                                                                    </div>
                                                                </Stack>

                                                            </div>
                                                        </Col>



                                                        <Col sm={12} className="mt-3">
                                                            <ButtonCustom
                                                                isLoading={this.props.loading}
                                                                size="sm"
                                                                clazzContainer="text-center"
                                                                clazz="btn btn-info w-md"
                                                                message_loaging="">
                                                                <button
                                                                    className="btn btn-info w-md"
                                                                    type="submit">
                                                                    Salvar
                                                                </button>
                                                            </ButtonCustom>
                                                        </Col>
                                                    </Row>
                                                </AvForm>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}


Index.propTypes = {

    t: PropTypes.any,
    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    natOp: PropTypes.any,
    mod: PropTypes.any,
    tpNF: PropTypes.any,
    idDest: PropTypes.any,
    tpImp: PropTypes.any,
    tpEmis: PropTypes.any,
    tpAmb: PropTypes.any,
    finNFe: PropTypes.any,
    indFinal: PropTypes.any,
    indPres: PropTypes.any,
    procEmi: PropTypes.any,
    serie: PropTypes.any,
    id: PropTypes.any,
    createTipoNF: PropTypes.func,
}


const mapStateToProps = state => {
    const {
        loading,
        loading_form,
        error,
        message,
        natOp,
        mod,
        tpNF,
        idDest,
        tpImp,
        tpEmis,
        tpAmb,
        finNFe,
        indFinal,
        indPres,
        procEmi,
        serie,
        id
    } = state.CreateOrEditCategories
    return {
        loading,
        loading_form,
        error,
        message,
        natOp,
        mod,
        tpNF,
        idDest,
        tpImp,
        tpEmis,
        tpAmb,
        finNFe,
        indFinal,
        indPres,
        procEmi,
        serie,
        id
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    createTipoNF,
})(
    (Index)
)))


