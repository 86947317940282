import {
    CREATE_PRODUCTS,
    CREATE_PRODUCTS_SUCCESS,
    EDIT_PRODUCTS,
    EDIT_PRODUCTS_SUCCESS,
    FIND_PRODUCT_SIZES_BY_ID,
    FIND_PRODUCT_SIZES_BY_ID_SUCCESS,
    FIND_PRODUCT_GENERIC_BY_ID_CATEGORY,
    FIND_PRODUCT_GENERIC_BY_ID_CATEGORY_SUCCESS,
    UPLOAD_PRODUCTS,
    UPLOAD_PRODUCTS_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    refresh: false,
    error: false,
    message: '',

    name: '',
    sku: '',
    description: '',
    image: '',
    status: 1,
    menu_category_id: 0,
    sizes: [],
    complements: [],
    type: 0,
    is_product: 0,
    ean: '',
    price: 0,
    perc_discount: 0,
    serve: 0,
    weight: 0,
    has_addons: 0,
    id: 0,
    product_id: 0,

    url_image: '',
    type_dir_file: '',
}

const createOrEditProducts = (state = initialState, action) => {

    switch (action.type) {
        case UPLOAD_PRODUCTS:
            state = { ...state, loading: true }
            break
        case UPLOAD_PRODUCTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break

        case CREATE_PRODUCTS:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case CREATE_PRODUCTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                product_id: action.payload.id,
                refresh: false,
                loading: false
            }

            break
        case FIND_PRODUCT_GENERIC_BY_ID_CATEGORY:
            state = { ...state, loading: true, loading_form: true }
            break
        case FIND_PRODUCT_GENERIC_BY_ID_CATEGORY_SUCCESS:
           // console.log("payload has_addons ", action.payload.data.has_addons)
            state = {
                ...state,
                loading: false,
                loading_form: false,

                name: action.payload.data.name,
                sku: action.payload.data.sku,
                description: action.payload.data.description,
                status: action.payload.data.status,
                menu_category_id: action.payload.data.menu_category_id,
                complements: action.payload.data.complements,

                id: action.payload.data.id,
                product_id: action.payload.data.product_id,

                is_product: action.payload.data.is_product,
                type: action.payload.data.type,
                ean: action.payload.data.ean,
                price: action.payload.data.price,
                perc_discount: action.payload.data.perc_discount,
                serve: action.payload.data.serve,
                weight: action.payload.data.weight,
                has_addons: action.payload.data.has_addons,

                image: action.payload.data.image,
                url_image: action.payload.data.url_image,
                type_dir_file: action.payload.data.type_dir_file,

            }
            break
        case FIND_PRODUCT_SIZES_BY_ID:
            state = { ...state, loading: true, loading_form: true }
            break
        case FIND_PRODUCT_SIZES_BY_ID_SUCCESS:
            //console.log("payload ", action.payload)
            state = {
                ...state,
                loading: false,
                loading_form: false,

                name: action.payload.result.name,
                sku: action.payload.result.sku,
                description: action.payload.result.description,
                status: action.payload.result.status,
                menu_category_id: action.payload.result.menu_category_id,
                sizes: action.payload.result.sizes,

                id: action.payload.result.id,
                product_id: action.payload.result.product_id,
                is_product: action.payload.result.is_product,
                image: action.payload.result.image,
                url_image: action.payload.result.url_image,
                type_dir_file: action.payload.result.type_dir_file,

            }
            break
        case EDIT_PRODUCTS:
            state = { ...state, refresh: false, loading: true }
            break
        case EDIT_PRODUCTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                refresh: false,
                loading: false
            }
            break
        case CREATE_OR_EDIT_PRODUCTS_API_ERROR:
            state = { ...state, error: action.payload, loading: false, refresh: false, }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default createOrEditProducts
