import {
    CREATE_PASTAS,
    CREATE_PASTAS_SUCCESS,
    EDIT_PASTAS,
    EDIT_PASTAS_SUCCESS,
    CREATE_OR_EDIT_PASTAS_API_ERROR
} from "./actionTypes"


export const createPasta = (params, history) => {
    return {
        type: CREATE_PASTAS,
        payload: {params, history},
    }
}

export const createPastaSuccess = params => {
    return {
        type: CREATE_PASTAS_SUCCESS,
        payload: params,
    }
}

export const editPasta = (params, history) => {
    return {
        type: EDIT_PASTAS,
        payload: {params, history},
    }
}

export const editPastaSuccess = result => {
    return {
        type: EDIT_PASTAS_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditPastaError = error => {
    return {
        type: CREATE_OR_EDIT_PASTAS_API_ERROR,
        payload: error,
    }
}

