import {
    PAGINATE_TAB_IBPT,
    PAGINATE_TAB_IBPT_SUCCESS,
    IMPORT_TAB_IBPT,
    IMPORT_TAB_IBPT_SUCCESS,
    FILTER_PAGINATE_TAB_IBPT,
    FILTER_PAGINATE_TAB_IBPT_SUCCESS,
    DELETE_TAB_IBPT_SUCCESS,
    DELETE_TAB_IBPT,
    API_ERROR_TAB_IBPT,
} from "./actionTypes"

export const importTabIBPT = params => {
    return {
        type: IMPORT_TAB_IBPT,
        payload: {params},
    }
}

export const importTabIBPTSuccess = params => {
    return {
        type: IMPORT_TAB_IBPT_SUCCESS,
        payload: {params},
    }
}

export const filtersAllTabIBPTPaginate = params => {
    return {
        type: FILTER_PAGINATE_TAB_IBPT,
        payload: {params},
    }
}

export const filtersAllTabIBPTPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_TAB_IBPT_SUCCESS,
        payload: {params},
    }
}

export const allTabIBPTPaginate = params => {
    return {
        type: PAGINATE_TAB_IBPT,
        payload: {params},
    }
}

export const allTabIBPTPaginateSuccess = result => {
    return {
        type: PAGINATE_TAB_IBPT_SUCCESS,
        payload: {result},
    }
}

export const deleteTabIBPT = (id, history) => {
  return {
    type: DELETE_TAB_IBPT,
    payload: {id, history} ,
  }
}

export const deleteTabIBPTSuccess = message => {
    return {
        type: DELETE_TAB_IBPT_SUCCESS,
        payload: message,
    }
}

export const apiPaginateTabIBPTError = error => {
  return {
    type: API_ERROR_TAB_IBPT,
    payload: error,
  }
}

