export const PAGINATE_NF_TRIB_ICMSCST = "PAGINATE_NF_TRIB_ICMSCST"
export const PAGINATE_NF_TRIB_ICMSCST_SUCCESS = "PAGINATE_NF_TRIB_ICMSCST_SUCCESS"

export const FILTER_PAGINATE_NF_TRIB_ICMSCST = "FILTER_PAGINATE_NF_TRIB_ICMSCST"
export const FILTER_PAGINATE_NF_TRIB_ICMSCST_SUCCESS = "FILTER_PAGINATE_NF_TRIB_ICMSCST_SUCCESS"

export const DELETE_NF_TRIB_ICMSCST = "DELETE_NF_TRIB_ICMSCST"
export const DELETE_NF_TRIB_ICMSCST_SUCCESS = "DELETE_NF_TRIB_ICMSCST_SUCCESS"

export const API_ERROR_NF_TRIB_ICMSCST = "PAGINATE_NF_TRIB_ICMSCST_API_ERROR"
