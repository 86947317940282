import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

// Login Redux States
import {
    SEARCH_CATEGORIES,
} from "./actionTypes"

import {
    searchCategoriesSuccess,
    apiCategoriesError
} from "./actions"

import {
    getSearchCategories,
} from "../../../helpers/categories_helper"


function* searchCategories({payload: {params}}) {
    try {

        const {results} = yield call(getSearchCategories, params)

        yield put(searchCategoriesSuccess({
            data: results
        }))

    } catch (error) {
        yield put(apiCategoriesError(error))
    }
}


function* CategoriesSearchSaga() {
    yield takeEvery(SEARCH_CATEGORIES, searchCategories)
}

export default CategoriesSearchSaga
