import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

 
const getNFTribIPIById = data => get(url.GET_NF_TRIB_IPI_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFTribIPI = data => del(url.DELETE_NF_TRIB_IPI.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFTribIPI = data => post(url.POST_CREATE_NF_TRIB_IPI, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFTribIPI = data => put(url.PUT_EDIT_NF_TRIB_IPI, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFTribIPIPaginate = ({page, limit}) => get(url.GET_NF_TRIB_IPI_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFTribIPIPaginate = data => get(url.GET_NF_TRIB_IPI_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFTribIPI = params => get(url.GET_SEARCH_NF_TRIB_IPI, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFTribIPIPaginate,
    getFilterNFTribIPIPaginate,
    putEditNFTribIPI,
    postCreateNFTribIPI,
    delNFTribIPI,
    getNFTribIPIById,
    getSearchNFTribIPI
}
