export const CREATE_PRODUCTS = "CREATE_PRODUCTS"
export const CREATE_PRODUCTS_SUCCESS = "CREATE_PRODUCTS_SUCCESS"

export const EDIT_PRODUCTS = "EDIT_PRODUCTS"
export const EDIT_PRODUCTS_SUCCESS = "EDIT_PRODUCTS_SUCCESS"

export const FIND_PRODUCT_SIZES_BY_ID = "FIND_PRODUCT_SIZES_BY_ID"
export const FIND_PRODUCT_SIZES_BY_ID_SUCCESS = "FIND_PRODUCT_SIZES_BY_ID_SUCCESS"

export const FIND_PRODUCT_GENERIC_BY_ID_CATEGORY = "FIND_PRODUCT_GENERIC_BY_ID_CATEGORY"
export const FIND_PRODUCT_GENERIC_BY_ID_CATEGORY_SUCCESS = "FIND_PRODUCT_GENERIC_BY_ID_CATEGORY_SUCCESS"

export const UPLOAD_PRODUCTS = "UPLOAD_PRODUCTS"
export const UPLOAD_PRODUCTS_SUCCESS = "UPLOAD_PRODUCTS_SUCCESS"


export const CREATE_OR_EDIT_PRODUCTS_API_ERROR = "CREATE_OR_EDIT_PRODUCTS_API_ERROR"
