import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_SERVICE_RADIUS_COMPANY,
    FILTER_PAGINATE_SERVICE_RADIUS_COMPANY,
    DELETE_SERVICE_RADIUS_COMPANY,
} from "./actionTypes"

import {
    allServiceRadiusCompanyPaginateSuccess,
    filtersAllServiceRadiusCompanyPaginateSuccess,
    deleteServiceRadiusCompanySuccess,
    apiPaginateServiceRadiusCompanyError
} from "./actions"


import {
    getServiceRadiusCompanyPaginate,
    getFilterServiceRadiusCompanyPaginate,
    delServiceRadiusCompany
} from "../../../../../helpers/service_radius_company_helper"
import {NotificationManager} from "react-notifications";

function* deleteServiceRadiusCompany({payload: {id, history}}) {
    try {

        const response = yield call(delServiceRadiusCompany, id)

        yield put(deleteServiceRadiusCompanySuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateServiceRadiusCompanyError(error))
    }
}

function* paginateServiceRadiusCompany({payload: {params}}) {
    try {

        const response = yield call(getServiceRadiusCompanyPaginate, params)

        yield put(allServiceRadiusCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateServiceRadiusCompanyError(error))
    }
}

function* filtersPaginateServiceRadiusCompany({payload: {params}}) {
    try {

        const response = yield call(getFilterServiceRadiusCompanyPaginate, params)

        yield put(filtersAllServiceRadiusCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateServiceRadiusCompanyError(error))
    }
}


function* ServiceRadiusCompanySaga() {
    yield takeEvery(PAGINATE_SERVICE_RADIUS_COMPANY, paginateServiceRadiusCompany)
    yield takeEvery(FILTER_PAGINATE_SERVICE_RADIUS_COMPANY, filtersPaginateServiceRadiusCompany)
    yield takeEvery(DELETE_SERVICE_RADIUS_COMPANY, deleteServiceRadiusCompany)
}

export default ServiceRadiusCompanySaga
