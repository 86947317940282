
import API from "./API";


export default {

    async  upload(formData, url) {



        return new Promise((resolve, reject) => {

            API.post(url, formData, {
                headers: {
                    NameApplication: 'BOT',
                    Version: 1.0,
                    AccesType: 2,
                    'Authorization': localStorage.getItem("authUser") != null ? 'Bearer '.concat(JSON.parse(localStorage.getItem("authUser")).result.token) : '',
                }
            })
                .then(response => {

                    const {message, data} = response.data
                    resolve({message, data});

                })
                .catch(err => {
                    const {message} = err.response.data
                    reject(message);

                }).then(response => {
            });

        });
    },

    async  all(url) {


        return new Promise((resolve, reject) => {

            API.get(url, {
                headers: {
                    NameApplication: 'BOT',
                    Version: 1.0,
                    AccesType: 2,
                    'Authorization': localStorage.getItem("authUser") != null ? 'Bearer '.concat(JSON.parse(localStorage.getItem("authUser")).result.token) : '',
                }
            })
                .then(response => {

                    const {message, results} = response.data
                    resolve({message, results});

                })
                .catch(err => {
                    const {message} = err.response.data
                    reject(message);

                }).then(response => {
            });

        });
    },

};
