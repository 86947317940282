import { all, fork } from "redux-saga/effects"

//public
import LayoutSaga from "./layout/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"

//private
import CategoriesSaga from "./categories/table/saga"
import CreateOrEditCategoriesSaga from "./categories/saga"
import CompaniesSaga from "./companies/table/saga"
import CreateOrEditCompaniesSaga from "./companies/saga"
import CompaniesDiscountCouponsSaga from "./companies/options/discount-coupons/table/saga.js"
import CreateOrEditCompaniesDiscountCouponsSaga from "./companies/options/discount-coupons/saga"
import CompaniesPaymentMethodsSaga from "./companies/options/payment-methods/table/saga.js"
import CreateOrEditCompaniesPaymentMethodsSaga from "./companies/options/payment-methods/saga"

import CompaniesSchedulerSaga from "./companies/options/scheduler-company/table/saga.js"
import CreateOrEditCompaniesSchedulerSaga from "./companies/options/scheduler-company/saga"

import CompaniesServiceRadiusSaga from "./companies/options/service-radius/table/saga.js"
import CreateOrEditCompaniesServiceRadiusSaga from "./companies/options/service-radius/saga"

import CompaniesDriversSaga from "./companies/options/drivers/table/saga.js"
import CreateOrEditCompaniesDriversSaga from "./companies/options/drivers/saga"

//COUNTRY
import CountrySaga from "./country/table/saga"
import CreateOrEditCountrySaga from "./country/saga"

// STATE
import StateSaga from "./state/table/saga"
import CreateOrEditStateSaga from "./state/saga"

// CITY
import CitySaga from "./city/table/saga"
import CreateOrEditCitySaga from "./city/saga"

import ModelsSeguimentsSaga from "./models-seguiments/table/saga"
import CreateOrEditModelsSeguimentsSaga from "./models-seguiments/saga"
import LocationSaga from "./search/location/saga"
import SearchCompaniesSaga from "./search/companies/saga"
import SearchCategoriesSaga from "./search/categories/saga"

import EdgesSaga from "./categories/options/edges/table/saga"
import CreateOrEditEdgesSaga from "./categories/options/edges/saga"
import SizesSaga from "./categories/options/sizes/table/saga"
import CreateOrEditSizesSaga from "./categories/options/sizes/saga"
import PastasSaga from "./categories/options/pastas/table/saga"
import CreateOrEditPastasSaga from "./categories/options/pastas/saga"
import ProductsSaga from "./categories/options/products/table/saga"
import ProductsSizesSaga from "./categories/options/products/sizes/saga"
import CreateOrEditProductsSaga from "./categories/options/products/saga"
import CreateOrEditProductsComplementsGroupSaga from "./categories/options/products/complements/saga"


import PaymentMethodsSaga from "./payments/methods/table/saga"
import CreateOrEditPaymentMethodsSaga from "./payments/methods/saga"

import DiscountCouponsSaga from "./discount-coupons/table/saga"
import CreateOrEditDiscountCouponsSaga from "./discount-coupons/saga"


import PromotionsSaga from "./promotions/table/saga"
import CreateOrEditPromotionsSaga from "./promotions/saga"


import ConfinsSaga from "./fiscal/cofins/saga"
import CsosnSaga from "./fiscal/csosn/saga"
import IcmsCstSaga from "./fiscal/icmscst/saga"
import IpiSaga from "./fiscal/ipi/saga"
import ModBcIcmsSaga from "./fiscal/modbcicms/saga"
import ModBcIcmStSaga from "./fiscal/modbcicmsst/saga"
import NfModeloSaga from "./fiscal/modelo/saga"
import NfOrigemSaga from "./fiscal/origem/saga"
import PisSaga from "./fiscal/pis/saga"
import CFOPSaga from "./fiscal/cfop/saga"
import IBPTSaga from "./fiscal/ibpt/saga"
import TipoOperacaoSaga from "./fiscal/tipooperacao/saga"
import CRTSaga from "./fiscal/crt/saga"
import CNAESaga from "./fiscal/cnae/saga"
import CESTSaga from "./fiscal/cest/saga"
import SPEDITEMSaga from "./fiscal/speditem/saga"
import ClassificacaoProdutosSaga from "./fiscal/classificacao/produtos/saga"
// SETTING
import SettingsSaga from "./settings/table/saga"
import CreateOrEditSettingsSaga from "./settings/saga"

import CreateOrEditTipoNFSaga from "./fiscal/tiponf/saga"

import CATEGORYMETASaga from "./social-midia/categoryMeta/saga"
import CATEGORYGOOGLESaga from "./social-midia/categoryGoogle/saga"


export default function* rootSaga() {
    yield all([

        //public
        fork(AuthSaga),
        fork(ForgetSaga),
        fork(LayoutSaga),

        //private
        fork(CountrySaga),
        fork(CreateOrEditCountrySaga),
        fork(StateSaga),
        fork(CreateOrEditStateSaga),
        fork(CitySaga),
        fork(CreateOrEditCitySaga),
        fork(DiscountCouponsSaga),
        fork(CreateOrEditDiscountCouponsSaga),
        fork(PaymentMethodsSaga),
        fork(CreateOrEditPaymentMethodsSaga),
        fork(LocationSaga),
        fork(SearchCompaniesSaga),
        fork(SearchCategoriesSaga),
        fork(CompaniesSaga),
        fork(CreateOrEditCompaniesSaga),
        fork(CompaniesServiceRadiusSaga),
        fork(CreateOrEditCompaniesServiceRadiusSaga),
        fork(CompaniesDriversSaga),
        fork(CreateOrEditCompaniesDriversSaga),
        fork(CompaniesDiscountCouponsSaga),
        fork(CreateOrEditCompaniesDiscountCouponsSaga),
        fork(CompaniesPaymentMethodsSaga),
        fork(CreateOrEditCompaniesPaymentMethodsSaga),
        fork(CompaniesSchedulerSaga),
        fork(CreateOrEditCompaniesSchedulerSaga),
        fork(CategoriesSaga),
        fork(CreateOrEditCategoriesSaga),
        fork(ModelsSeguimentsSaga),
        fork(CreateOrEditModelsSeguimentsSaga),

        fork(EdgesSaga),
        fork(CreateOrEditEdgesSaga),
        fork(SizesSaga),
        fork(CreateOrEditSizesSaga),
        fork(PastasSaga),
        fork(CreateOrEditPastasSaga),
        fork(ProductsSaga),
        fork(ProductsSizesSaga),
        fork(CreateOrEditProductsSaga),
        fork(CreateOrEditProductsComplementsGroupSaga),

        fork(PromotionsSaga),
        fork(CreateOrEditPromotionsSaga),

        fork(ConfinsSaga),
        fork(CsosnSaga),
        fork(IcmsCstSaga),
        fork(IpiSaga),
        fork(ModBcIcmsSaga),
        fork(ModBcIcmStSaga),
        fork(NfModeloSaga),
        fork(NfOrigemSaga),
        fork(PisSaga),
        fork(CFOPSaga),
        fork(IBPTSaga),
        fork(TipoOperacaoSaga),
        fork(CRTSaga),
        fork(CNAESaga),
        fork(CESTSaga),
        fork(SPEDITEMSaga),
        fork(ClassificacaoProdutosSaga),

        fork(SettingsSaga),
        fork(CreateOrEditSettingsSaga),
        fork(CreateOrEditTipoNFSaga),
        fork(CATEGORYMETASaga),
        fork(CATEGORYGOOGLESaga),
    ])
}
