import {
    PAGINATE_COMPANY,
    PAGINATE_COMPANY_SUCCESS,
    FILTER_PAGINATE_COMPANY,
    FILTER_PAGINATE_COMPANY_SUCCESS,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY,
    API_ERROR_COMPANY,
} from "./actionTypes"


export const filtersAllCompanyPaginate = params => {
    return {
        type: FILTER_PAGINATE_COMPANY,
        payload: {params},
    }
}

export const filtersAllCompanyPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_COMPANY_SUCCESS,
        payload: {params},
    }
}

export const allCompanyPaginate = params => {
    return {
        type: PAGINATE_COMPANY,
        payload: {params},
    }
}

export const allCompanyPaginateSuccess = result => {
    return {
        type: PAGINATE_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const deleteCompany = (id, history) => {
  return {
    type: DELETE_COMPANY,
    payload: {id, history} ,
  }
}

export const deleteCompanySuccess = message => {
    return {
        type: DELETE_COMPANY_SUCCESS,
        payload: message,
    }
}

export const apiPaginateCompanyError = error => {
  return {
    type: API_ERROR_COMPANY,
    payload: error,
  }
}

