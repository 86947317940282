import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    CREATE_SETTINGS,
    CREATE_OR_EDIT_SETTINGS,
    EDIT_SETTINGS,
    FIND_BY_KEY_SETTINGS,
    FIND_BY_ID_SETTINGS
} from "./actionTypes"


import {
    createOrEditSettingsSuccess,
    createSettingsSuccess,
    editSettingsSuccess,
    findSettingsIdSuccess,
    findSettingsKeySuccess,
    apiCreateOrEditSettingsError
} from "./actions"


import {
    postCreateSetting,
    putEditSetting,
    postCreateOrEditSetting,
    getSettingById,
    getSettingByKey
} from "../../helpers/settings_helper"

function* findByKeySettings({ payload: { id } }) {
    try {

        const response = yield call(getSettingByKey, id)

        yield put(findSettingsKeySuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditSettingsError(error))
    }
}

function* findByIdSettings({ payload: { id } }) {
    try {

        const response = yield call(getSettingById, id)

        yield put(findSettingsIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditSettingsError(error))
    }
}
function* createOrEditSettings({ payload: { params, history } }) {
    try {

        //let values = {"title": params.title, "description": params.description};
        var data = new FormData();
        data.append('key', params.key);
        data.append('type', params.type);
        data.append('status', params.status);
        if (params.files !== undefined) {
            data.append('files', params.files);
        }
        if (params.id !== undefined && params.id > 0) {
            data.append('id', params.id);
        }
        if (params.company_id !== undefined && params.company_id > 0) {
            data.append('company_id', params.company_id);
        }
        if (params.password !== undefined) {
            data.append('password', params.password);
        }
        data.append('value', JSON.stringify(params.value));
        // const headers = { 'Content-Type': 'multipart/form-data' };

        const response = yield call(postCreateOrEditSetting, data)

        yield put(createOrEditSettingsSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');


    } catch (error) {
        yield put(apiCreateOrEditSettingsError(error))
    }
}
function* createSettings({ payload: { params, history } }) {
    try {

        //let values = {"title": params.title, "description": params.description};
        var data = new FormData();
        data.append('key', params.key);
        data.append('type', params.type);
        data.append('status', params.status);
        if (params.files !== undefined) {
            data.append('files', params.files);
        }
        data.append('value', JSON.stringify(params.value));
        // const headers = { 'Content-Type': 'multipart/form-data' };

        const response = yield call(postCreateSetting, data)

        yield put(createSettingsSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditSettingsError(error))
    }
}

function* editSettings({ payload: { params, history } }) {
    try {

        const response = yield call(putEditSetting, params)

        yield put(editSettingsSuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditSettingsError(error))
    }
}


function* createOrEditSettingsSaga() {
    yield takeEvery(CREATE_OR_EDIT_SETTINGS, createOrEditSettings)
    yield takeEvery(CREATE_SETTINGS, createSettings)
    yield takeEvery(EDIT_SETTINGS, editSettings)
    yield takeEvery(FIND_BY_ID_SETTINGS, findByIdSettings)
    yield takeEvery(FIND_BY_KEY_SETTINGS, findByKeySettings)
}

export default createOrEditSettingsSaga
