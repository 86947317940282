export const PAGINATE_NF_MODELO = "PAGINATE_NF_MODELO"
export const PAGINATE_NF_MODELO_SUCCESS = "PAGINATE_NF_MODELO_SUCCESS"

export const FILTER_PAGINATE_NF_MODELO = "FILTER_PAGINATE_NF_MODELO"
export const FILTER_PAGINATE_NF_MODELO_SUCCESS = "FILTER_PAGINATE_NF_MODELO_SUCCESS"

export const DELETE_NF_MODELO = "DELETE_NF_MODELO"
export const DELETE_NF_MODELO_SUCCESS = "DELETE_NF_MODELO_SUCCESS"

export const API_ERROR_NF_MODELO = "PAGINATE_NF_MODELO_API_ERROR"
