import {
    CREATE_PAYMENT_METHODS_COMPANY,
    CREATE_PAYMENT_METHODS_COMPANY_SUCCESS,
    EDIT_PAYMENT_METHODS_COMPANY,
    EDIT_PAYMENT_METHODS_COMPANY_SUCCESS,
    CREATE_OR_EDIT_PAYMENT_METHODS_COMPANY_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    refresh: false,
    error: false,
    message: '',

    company_id: null,

    id: 0,
}

const createOrEditPaymentMethodsCompany = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_PAYMENT_METHODS_COMPANY:
            state = {
                ...state,
                refresh: false,
                loading_form: true,
                loading: true
            }
            break
        case CREATE_PAYMENT_METHODS_COMPANY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                company_id: action.payload.company_id,
                loading_form: false,
                refresh: true,
                loading: false
            }
            break
        case EDIT_PAYMENT_METHODS_COMPANY:
            state = {...state, loading: true}
            break
        case EDIT_PAYMENT_METHODS_COMPANY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case CREATE_OR_EDIT_PAYMENT_METHODS_COMPANY_API_ERROR:
            state = {...state, error: action.payload, loading: false,  refresh: false,}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditPaymentMethodsCompany
