import {
    CREATE_COUNTRY,
    CREATE_COUNTRY_SUCCESS,
    EDIT_COUNTRY,
    EDIT_COUNTRY_SUCCESS,
    FIND_BY_ID_COUNTRY,
    FIND_BY_ID_COUNTRY_SUCCESS,
    CREATE_OR_EDIT_COUNTRY_API_ERROR
} from "./actionTypes"


export const createCountry = (params, history) => {
    return {
        type: CREATE_COUNTRY,
        payload: {params, history},
    }
}

export const createCountrySuccess = params => {
    return {
        type: CREATE_COUNTRY_SUCCESS,
        payload: {params},
    }
}

export const editCountry = (params, history) => {
    return {
        type: EDIT_COUNTRY,
        payload: {params, history},
    }
}

export const editCountrySuccess = result => {
    return {
        type: EDIT_COUNTRY_SUCCESS,
        payload: {result},
    }
}

export const findByIdCountry = id => {
    return {
        type: FIND_BY_ID_COUNTRY,
        payload: {id} ,
    }
}

export const findCountryIdSuccess = result => {
    return {
        type: FIND_BY_ID_COUNTRY_SUCCESS,
        payload: result ,
    }
}

export const apiCreateOrEditCountryError = error => {
  return {
    type: CREATE_OR_EDIT_COUNTRY_API_ERROR,
    payload: error,
  }
}

