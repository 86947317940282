import {
    PAGINATE_DISCOUNT_COUPONS,
    PAGINATE_DISCOUNT_COUPONS_SUCCESS,
    FILTER_PAGINATE_DISCOUNT_COUPONS,
    FILTER_PAGINATE_DISCOUNT_COUPONS_SUCCESS,
    DELETE_DISCOUNT_COUPONS_SUCCESS,
    DELETE_DISCOUNT_COUPONS,
    API_ERROR_DISCOUNT_COUPONS,
} from "./actionTypes"


export const filtersAllDiscountCouponsPaginate = params => {
    return {
        type: FILTER_PAGINATE_DISCOUNT_COUPONS,
        payload: {params},
    }
}

export const filtersAllDiscountCouponsPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_DISCOUNT_COUPONS_SUCCESS,
        payload: {params},
    }
}

export const allDiscountCouponsPaginate = params => {
    return {
        type: PAGINATE_DISCOUNT_COUPONS,
        payload: {params},
    }
}

export const allDiscountCouponsPaginateSuccess = result => {
    return {
        type: PAGINATE_DISCOUNT_COUPONS_SUCCESS,
        payload: {result},
    }
}

export const deleteDiscountCoupons = (id, history) => {
  return {
    type: DELETE_DISCOUNT_COUPONS,
    payload: {id, history} ,
  }
}

export const deleteDiscountCouponsSuccess = message => {
    return {
        type: DELETE_DISCOUNT_COUPONS_SUCCESS,
        payload: message,
    }
}

export const apiPaginateDiscountCouponsError = error => {
  return {
    type: API_ERROR_DISCOUNT_COUPONS,
    payload: error,
  }
}

