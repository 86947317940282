import {
    SEARCH_COMPANIES,
    SEARCH_COMPANIES_SUCCESS,
    API_COMPANIES_ERROR,
} from "./actionTypes"

export const searchCompanies = params => {
    return {
        type: SEARCH_COMPANIES,
        payload: {params},
    }
}

export const searchCompaniesSuccess = params => {
    return {
        type: SEARCH_COMPANIES_SUCCESS,
        payload: params,
    }
}

export const apiCompaniesError = error => {
    return {
        type: API_COMPANIES_ERROR,
        payload: error,
    }
}

