import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody,  Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Table from "./table/index";
import {connect} from "react-redux";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    id: 1,
                    name: 'SKU',
                    status: 1
                },
                {
                    id: 2,
                    name: 'NOME',
                    status: 1
                },
                {
                    id: 3,
                    name: 'AÇÕES',
                    status: 1
                },
            ],
        }

    }


    render() {

        return (
           <React.Fragment>
               <div className="page-content">
                   <MetaTags>
                       <title>Produtos</title>
                   </MetaTags>
                   <Container fluid>
                       <Breadcrumbs title={'Produtos'} breadcrumbItem={this.props.t("Register")}/>
                       <Row>
                           <Col sm={12}>
                               <Card>
                                   <CardBody>

                                       <Table
                                           refresh={this.props.refresh}
                                           route_edit=""
                                           columns={this.state.columns}
                                       />
                                   </CardBody>
                               </Card>
                           </Col>
                       </Row>
                   </Container>

               </div>
           </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    product_id: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,


}
const mapStateToProps = state => {}
export default withTranslation()(withRouter(connect(mapStateToProps, {


})(
    (Index)
)))
