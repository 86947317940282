import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const importTabGMerchantCategoryAPI = data => post(url.IMPORT_GOOGLE_MERCHANT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getTabGMerchantCategoryById = data => get(url.GET_GOOGLE_MERCHANT_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delTabGMerchantCategory = data => del(url.DELETE_GOOGLE_MERCHANT.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateTabGMerchantCategory = data => post(url.POST_CREATE_GOOGLE_MERCHANT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditTabGMerchantCategory = data => put(url.PUT_EDIT_GOOGLE_MERCHANT, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getTabGMerchantCategoryPaginate = ({ page, limit }) => get(url.GET_GOOGLE_MERCHANT_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterTabGMerchantCategoryPaginate = data => get(url.GET_GOOGLE_MERCHANT_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchTabGMerchantCategory = params => get(url.GET_SEARCH_GOOGLE_MERCHANT, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getTabGMerchantCategoryPaginate,
    getFilterTabGMerchantCategoryPaginate,
    putEditTabGMerchantCategory,
    postCreateTabGMerchantCategory,
    delTabGMerchantCategory,
    getTabGMerchantCategoryById,
    getSearchTabGMerchantCategory,
    importTabGMerchantCategoryAPI
}
