import {
    CREATE_PAYMENT_METHOD,
    CREATE_PAYMENT_METHOD_SUCCESS,
    EDIT_PAYMENT_METHOD,
    EDIT_PAYMENT_METHOD_SUCCESS,
    FIND_BY_ID_PAYMENT_METHOD,
    FIND_BY_ID_PAYMENT_METHOD_SUCCESS,
    UPLOAD_PAYMENT_METHOD,
    UPLOAD_PAYMENT_METHOD_SUCCESS,
    CREATE_OR_EDIT_PAYMENT_METHOD_API_ERROR
} from "./actionTypes"


export const createPaymentMethod = (params,  history) => {
    return {
        type: CREATE_PAYMENT_METHOD,
        payload: {params,history},
    }
}

export const createPaymentMethodSuccess = params => {
    return {
        type: CREATE_PAYMENT_METHOD_SUCCESS,
        payload: params,
    }
}

export const editPaymentMethod = (params, history) => {
    return {
        type: EDIT_PAYMENT_METHOD,
        payload: {params, history},
    }
}

export const editPaymentMethodSuccess = result => {
    return {
        type: EDIT_PAYMENT_METHOD_SUCCESS,
        payload: {result},
    }
}

export const findByIdPaymentMethod = id => {
    return {
        type: FIND_BY_ID_PAYMENT_METHOD,
        payload: {id},
    }
}

export const findPaymentMethodIdSuccess = result => {
    return {
        type: FIND_BY_ID_PAYMENT_METHOD_SUCCESS,
        payload: result,
    }
}

export const uploadPaymentMethod = ( dataForm,  history) => {
    return {
        type: UPLOAD_PAYMENT_METHOD,
        payload: {dataForm, history},
    }
}

export const uploadPaymentMethodSuccess = result => {
    return {
        type: UPLOAD_PAYMENT_METHOD_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditPaymentMethodError = error => {
    return {
        type: CREATE_OR_EDIT_PAYMENT_METHOD_API_ERROR,
        payload: error,
    }
}

