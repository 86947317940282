import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_DRIVERS_COMPANY,
    FILTER_PAGINATE_DRIVERS_COMPANY,
    DELETE_DRIVERS_COMPANY,
} from "./actionTypes"

import {
    allDriversCompanyPaginateSuccess,
    filtersAllDriversCompanyPaginateSuccess,
    deleteDriversCompanySuccess,
    apiPaginateDriversCompanyError
} from "./actions"


import {
    getDriversCompanyPaginate,
    getFilterDriversCompanyPaginate,
    delDriversCompany
} from "../../../../../helpers/drivers_company_helper"
import {NotificationManager} from "react-notifications";

function* deleteDriversCompany({payload: {id, history}}) {
    try {

        const response = yield call(delDriversCompany, id)

        yield put(deleteDriversCompanySuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateDriversCompanyError(error))
    }
}

function* paginateDriversCompany({payload: {params}}) {
    try {

        const response = yield call(getDriversCompanyPaginate, params)

        yield put(allDriversCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateDriversCompanyError(error))
    }
}

function* filtersPaginateDriversCompany({payload: {params}}) {
    try {

        const response = yield call(getFilterDriversCompanyPaginate, params)

        yield put(filtersAllDriversCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateDriversCompanyError(error))
    }
}


function* DriversCompanySaga() {
    yield takeEvery(PAGINATE_DRIVERS_COMPANY, paginateDriversCompany)
    yield takeEvery(FILTER_PAGINATE_DRIVERS_COMPANY, filtersPaginateDriversCompany)
    yield takeEvery(DELETE_DRIVERS_COMPANY, deleteDriversCompany)
}

export default DriversCompanySaga
