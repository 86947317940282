import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getProductSizeById = data => get(url.GET_PRODUCTS_SIZE_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delProductsSize = data => del(url.DELETE_PRODUCTS_SIZE.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateProductSize = data => post(url.POST_CREATE_PRODUCTS_SIZE, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditProductSize = data => put(url.PUT_EDIT_PRODUCTS_SIZE, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getProductsSizePaginate = ({menu_category_id,page, limit}) => get(url.GET_PRODUCTS_SIZE_PAGINATE, { params: { menu_category_id,page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


export {
    getProductsSizePaginate,
    putEditProductSize,
    postCreateProductSize,
    delProductsSize,
}
