import {
    CREATE_MODELS_SEGUIMENTS,
    CREATE_MODELS_SEGUIMENTS_SUCCESS,
    EDIT_MODELS_SEGUIMENTS,
    EDIT_MODELS_SEGUIMENTS_SUCCESS,
    FIND_BY_ID_MODELS_SEGUIMENTS,
    FIND_BY_ID_MODELS_SEGUIMENTS_SUCCESS,
    UPLOAD_MODELS_SEGUIMENTS,
    UPLOAD_MODELS_SEGUIMENTS_SUCCESS,
    CREATE_OR_EDIT_MODELS_SEGUIMENTS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    existsFiles: false,
    error: false,
    message: '',

    files: null,
    company_id: '',
    name: '',
    description: '',
    status: 1,
    image: '',
    id: 0,

    url_image: '',
    type_dir_file: '',
}

const createOrEditModelsSeguiments = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_MODELS_SEGUIMENTS:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_MODELS_SEGUIMENTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                existsFiles: action.payload.existsFiles,
                loading: false
            }
            break
        case EDIT_MODELS_SEGUIMENTS:
            state = {...state, loading: true}
            break
        case EDIT_MODELS_SEGUIMENTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case UPLOAD_MODELS_SEGUIMENTS:
            state = {...state, loading: true}
            break
        case UPLOAD_MODELS_SEGUIMENTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                existsFiles: false,
                loading: false
            }
            break
        case FIND_BY_ID_MODELS_SEGUIMENTS:
            state = {...state, loading: false, loading_form: true}
            break
        case FIND_BY_ID_MODELS_SEGUIMENTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                company_id: action.payload.result.company_id,
                name: action.payload.result.name,
                company_name: action.payload.result.company_name,
                description: action.payload.result.description,
                status: action.payload.result.status,
                image: action.payload.result.image,
                id: action.payload.result.id,

                url_image: action.payload.result.url_image,
                type_dir_file:  action.payload.result.type_dir_file,




            }
            break
        case CREATE_OR_EDIT_MODELS_SEGUIMENTS_API_ERROR:
            state = {...state, error: action.payload, loading: false,  existsFiles: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditModelsSeguiments
