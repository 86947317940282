import {
    PAGINATE_NF_TRIB_CSOSN,
    PAGINATE_NF_TRIB_CSOSN_SUCCESS,
    FILTER_PAGINATE_NF_TRIB_CSOSN,
    FILTER_PAGINATE_NF_TRIB_CSOSN_SUCCESS,
    DELETE_NF_TRIB_CSOSN_SUCCESS,
    DELETE_NF_TRIB_CSOSN,
    API_ERROR_NF_TRIB_CSOSN,
} from "./actionTypes"


export const filtersAllNFTribCSOSNPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_CSOSN,
        payload: {params},
    }
}

export const filtersAllNFTribCSOSNPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_CSOSN_SUCCESS,
        payload: {params},
    }
}

export const allNFTribCSOSNPaginate = params => {
    return {
        type: PAGINATE_NF_TRIB_CSOSN,
        payload: {params},
    }
}

export const allNFTribCSOSNPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TRIB_CSOSN_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTribCSOSN = (id, history) => {
  return {
    type: DELETE_NF_TRIB_CSOSN,
    payload: {id, history} ,
  }
}

export const deleteNFTribCSOSNSuccess = message => {
    return {
        type: DELETE_NF_TRIB_CSOSN_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTribCSOSNError = error => {
  return {
    type: API_ERROR_NF_TRIB_CSOSN,
    payload: error,
  }
}

