import {
    PAGINATE_SIZES,
    PAGINATE_SIZES_SUCCESS,
    FILTER_PAGINATE_SIZES,
    FILTER_PAGINATE_SIZES_SUCCESS,
    DELETE_SIZES_SUCCESS,
    DELETE_SIZES,
    API_ERROR_LIST_SIZES,
} from "./actionTypes"


export const filtersAllSizePaginate = params => {
    return {
        type: FILTER_PAGINATE_SIZES,
        payload: {params},
    }
}

export const filtersAllSizePaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_SIZES_SUCCESS,
        payload: {params},
    }
}

export const allSizePaginate = params => {
    return {
        type: PAGINATE_SIZES,
        payload: {params},
    }
}

export const allSizePaginateSuccess = result => {
    return {
        type: PAGINATE_SIZES_SUCCESS,
        payload: {result},
    }
}

export const deleteSize = (id, history) => {
  return {
    type: DELETE_SIZES,
    payload: {id, history} ,
  }
}

export const deleteSizeSuccess = message => {
    return {
        type: DELETE_SIZES_SUCCESS,
        payload: message,
    }
}

export const apiPaginateSizeError = error => {
  return {
    type: API_ERROR_LIST_SIZES,
    payload: error,
  }
}

