import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_COUNTRY,
    EDIT_COUNTRY,
    FIND_BY_ID_COUNTRY
} from "./actionTypes"


import {
    createCountrySuccess,
    editCountrySuccess,
    findCountryIdSuccess,
    apiCreateOrEditCountryError
} from "./actions"


import {
    putEditCountry,
    postCreateCountry,
    getCountryById
} from "../../helpers/country_helper"

function* findByIdCountry({payload: {id}}) {
    try {

        const response = yield call(getCountryById, id)

        yield put(findCountryIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditCountryError(error))
    }
}

function* createCountry({payload: {params, history}}) {
    try {

        const response = yield call(postCreateCountry, params)

        yield put(createCountrySuccess(response))

        const { message } = response;
        NotificationManager.info( message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditCountryError(error))
    }
}

function* editCountry({payload: {params, history}}) {
    try {

        const response = yield call(putEditCountry, params)

        yield put(editCountrySuccess(response))

        const { message } = response;
        NotificationManager.info( message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditCountryError(error))
    }
}


function* createOrEditCountrySaga() {
    yield takeEvery(CREATE_COUNTRY, createCountry)
    yield takeEvery(EDIT_COUNTRY, editCountry)
    yield takeEvery(FIND_BY_ID_COUNTRY, findByIdCountry)
}

export default createOrEditCountrySaga
