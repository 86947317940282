export const PAGINATE_MODELS_SEGUIMENTS = "PAGINATE_MODELS_SEGUIMENTS"
export const PAGINATE_MODELS_SEGUIMENTS_SUCCESS = "PAGINATE_MODELS_SEGUIMENTS_SUCCESS"

export const FILTER_PAGINATE_MODELS_SEGUIMENTS = "FILTER_PAGINATE_MODELS_SEGUIMENTS"
export const FILTER_PAGINATE_MODELS_SEGUIMENTS_SUCCESS = "FILTER_PAGINATE_MODELS_SEGUIMENTS_SUCCESS"

export const DELETE_MODELS_SEGUIMENTS = "DELETE_MODELS_SEGUIMENTS"
export const DELETE_MODELS_SEGUIMENTS_SUCCESS = "DELETE_MODELS_SEGUIMENTS_SUCCESS"

export const API_ERROR_MODELS_SEGUIMENTS = "PAGINATE_MODELS_SEGUIMENTS_API_ERROR"
