import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getNFPISById = data => get(url.GET_NF_PIS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFPIS = data => del(url.DELETE_NF_PIS.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFPIS = data => post(url.POST_CREATE_NF_PIS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFPIS = data => put(url.PUT_EDIT_NF_PIS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFPISPaginate = ({page, limit}) => get(url.GET_NF_PIS_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFPISPaginate = data => get(url.GET_NF_PIS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFPIS = params => get(url.GET_SEARCH_NF_PIS, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFPISPaginate,
    getFilterNFPISPaginate,
    putEditNFPIS,
    postCreateNFPIS,
    delNFPIS,
    getNFPISById,
    getSearchNFPIS
}
