export const PAGINATE_NF_ORIGEM = "PAGINATE_NF_ORIGEM"
export const PAGINATE_NF_ORIGEM_SUCCESS = "PAGINATE_NF_ORIGEM_SUCCESS"

export const FILTER_PAGINATE_NF_ORIGEM = "FILTER_PAGINATE_NF_ORIGEM"
export const FILTER_PAGINATE_NF_ORIGEM_SUCCESS = "FILTER_PAGINATE_NF_ORIGEM_SUCCESS"

export const DELETE_NF_ORIGEM = "DELETE_NF_ORIGEM"
export const DELETE_NF_ORIGEM_SUCCESS = "DELETE_NF_ORIGEM_SUCCESS"

export const API_ERROR_NF_ORIGEM = "PAGINATE_NF_ORIGEM_API_ERROR"
