import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getModelsSeguimentsById = data => get(url.GET_MODELS_SEGUIMENTS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delModelsSeguiments = data => del(url.DELETE_MODELS_SEGUIMENTS.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateModelsSeguiments = data => post(url.POST_CREATE_MODELS_SEGUIMENTS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadModelsSeguiments = data => post(url.POST_UPLOAD_MODELS_SEGUIMENTS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditModelsSeguiments = data => put(url.PUT_EDIT_MODELS_SEGUIMENTS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getCompaniesPaginate = ({page, limit}) => get(url.GET_MODELS_SEGUIMENTS_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterCompaniesPaginate = data => get(url.GET_MODELS_SEGUIMENTS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getCompaniesPaginate,
    getFilterCompaniesPaginate,
    putEditModelsSeguiments,
    postCreateModelsSeguiments,
    delModelsSeguiments,
    postUploadModelsSeguiments,
    getModelsSeguimentsById
}
