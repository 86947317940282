export const PAGINATE_PAYMENT_METHODS_COMPANY = "PAGINATE_PAYMENT_METHODS_COMPANY"
export const PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS = "PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS"

export const FILTER_PAGINATE_PAYMENT_METHODS_COMPANY = "FILTER_PAGINATE_PAYMENT_METHODS_COMPANY"
export const FILTER_PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS = "FILTER_PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS"

export const DELETE_PAYMENT_METHODS_COMPANY = "DELETE_PAYMENT_METHODS_COMPANY"
export const DELETE_PAYMENT_METHODS_COMPANY_SUCCESS = "DELETE_PAYMENT_METHODS_COMPANY_SUCCESS"

export const API_ERROR_LIST_PAYMENT_METHODS_COMPANY = "PAGINATE_PAYMENT_METHODS_COMPANY_API_ERROR"
