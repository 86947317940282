import {
    CREATE_PRODUCTS_COMPLEMENTS,
    CREATE_PRODUCTS_COMPLEMENTS_SUCCESS,
    EDIT_PRODUCTS_COMPLEMENTS,
    EDIT_PRODUCTS_COMPLEMENTS_SUCCESS,
    FIND_PRODUCTS_COMPLEMENTS_BY_PROD,
    FIND_PRODUCTS_COMPLEMENTS_BY_PROD_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_COMPLEMENTS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    refresh: false,
    error: false,
    message: '',

    name: '',
    sku: '',
    description: '',
    image: '',
    status: 1,
    menu_category_id: 0,
    complements: [],
    id: 0,
    product_id: 0,
}

const createOrEditProductComplement = (state = initialState, action) => {

    switch (action.type) {
        case FIND_PRODUCTS_COMPLEMENTS_BY_PROD:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case FIND_PRODUCTS_COMPLEMENTS_BY_PROD_SUCCESS:
            console.log("payload ", action.payload.data)
            state = {
                ...state,
                loading: false,
                loading_form: false,
                message: action.payload.message,
                error: action.payload.error, 

                id: action.payload.data.id,
                name: action.payload.data.name,
                mandatory: action.payload.data.mandatory,
                min_select: action.payload.data.min_select,
                max_select: action.payload.data.max_select,
                type: action.payload.data.type,
                complements: action.payload.data.products,

            }
        case CREATE_PRODUCTS_COMPLEMENTS:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case CREATE_PRODUCTS_COMPLEMENTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                product_id: action.payload.id,
                refresh: true,
                loading: false
            }
            break
        case EDIT_PRODUCTS_COMPLEMENTS:
            state = { ...state, loading: true }
            break
        case EDIT_PRODUCTS_COMPLEMENTS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case CREATE_OR_EDIT_PRODUCTS_COMPLEMENTS_API_ERROR:
            state = { ...state, error: action.payload, loading: false, refresh: false, }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default createOrEditProductComplement
