import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getCategoryById = data => get(url.GET_CATEGORIES_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delCategory = data => del(url.DELETE_CATEGORIES.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateCategory = data => post(url.POST_CREATE_CATEGORIES, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadCategory = data => post(url.POST_UPLOAD_CATEGORIES, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditCategory = data => put(url.PUT_EDIT_CATEGORIES, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getCategoriesPaginate = ({page, limit}) => get(url.GET_CATEGORIES_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterCategoriesPaginate = data => get(url.GET_CATEGORIES_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchCategories = params => get(url.GET_SEARCH_CATEGORY, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getCategoriesPaginate,
    getFilterCategoriesPaginate,
    putEditCategory,
    postCreateCategory,
    delCategory,
    postUploadCategory,
    getCategoryById,
    getSearchCategories
}
