export const PAGINATE_NF_TRIB_CSOSN = "PAGINATE_NF_TRIB_CSOSN"
export const PAGINATE_NF_TRIB_CSOSN_SUCCESS = "PAGINATE_NF_TRIB_CSOSN_SUCCESS"

export const FILTER_PAGINATE_NF_TRIB_CSOSN = "FILTER_PAGINATE_NF_TRIB_CSOSN"
export const FILTER_PAGINATE_NF_TRIB_CSOSN_SUCCESS = "FILTER_PAGINATE_NF_TRIB_CSOSN_SUCCESS"

export const DELETE_NF_TRIB_CSOSN = "DELETE_NF_TRIB_CSOSN"
export const DELETE_NF_TRIB_CSOSN_SUCCESS = "DELETE_NF_TRIB_CSOSN_SUCCESS"

export const API_ERROR_NF_TRIB_CSOSN = "PAGINATE_NF_TRIB_CSOSN_API_ERROR"
