import {
    PAGINATE_TIPO_NF,
    PAGINATE_TIPO_NF_SUCCESS,
    FILTER_PAGINATE_TIPO_NF,
    FILTER_PAGINATE_TIPO_NF_SUCCESS,
    DELETE_TIPO_NF_SUCCESS,
    DELETE_TIPO_NF,
    CREATE_TIPO_NF,
    CREATE_TIPO_NF_SUCCESS,
    EDIT_TIPO_NF,
    EDIT_TIPO_NF_SUCCESS,
    FIND_BY_ID_TIPO_NF,
    FIND_BY_ID_TIPO_NF_SUCCESS,
    API_ERROR_TIPO_NF,
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_delete: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0,

    natOp: null,
    mod: 0,
    tpNF: 0,
    idDest: 0,
    tpImp: 0,
    tpEmis: 0,
    tpAmb: 0,
    finNFe: 0,
    indFinal: 0,
    indPres: 0,
    procEmi: 0,
    serie: 0,
    id: 0,
}

const tipoNF = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_TIPO_NF:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_TIPO_NF_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case EDIT_TIPO_NF:
            state = { ...state, loading: true }
            break
        case FIND_BY_ID_TIPO_NF_SUCCESS:
            console.log('NAT OP SUC ', action.payload.result )
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false,

                natOp: action.payload.result.natOp,
                mod: action.payload.result.mod,
                tpNF: action.payload.result.tpNF,
                idDest: action.payload.result.idDest,
                tpImp: action.payload.result.tpImp,
                tpEmis: action.payload.result.tpEmis,
                tpAmb: action.payload.result.tpAmb,
                finNFe: action.payload.result.finNFe,
                indFinal: action.payload.result.indFinal,
                indPres: action.payload.result.indPres,
                procEmi: action.payload.result.procEmi,
                serie: action.payload.result.serie,
                id: action.payload.result.id,

            }
            break
        case EDIT_TIPO_NF_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_TIPO_NF:
        case PAGINATE_TIPO_NF:
            state = {
                ...state,
                loading: true
            }
            break
        case PAGINATE_TIPO_NF_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.result.current_page,
                last_page: action.payload.result.last_page,
                total: action.payload.result.total,
                from: action.payload.result.from,
                to: action.payload.result.to,
                rows: action.payload.result.data,
                loading: false
            }
            break
        case FILTER_PAGINATE_TIPO_NF:
            state = { ...state, loading: true }
            break
        case FILTER_PAGINATE_TIPO_NF_SUCCESS:
            state = { ...state, loading: false }
            break
        case DELETE_TIPO_NF:
            state = { ...state, loading: false, loading_delete: true }
            break
        case DELETE_TIPO_NF_SUCCESS:
            state = { ...state, message: action.payload, loading: false, loading_delete: false }
            break
        case API_ERROR_TIPO_NF:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default tipoNF
