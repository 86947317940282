import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TRIB_MOD_BC_ICMS_ST,
    FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST,
    DELETE_NF_TRIB_MOD_BC_ICMS_ST,
} from "./actionTypes"

import {
    allNFTribModBCICMSSTPaginateSuccess,
    filtersAllNFTribModBCICMSSTPaginateSuccess,
    deleteNFTribModBCICMSSTSuccess,
    apiPaginateNFTribModBCICMSSTError
} from "./actions"


import {
    getNFTribModBCICMSSTPaginate,
    getFilterNFTribModBCICMSSTPaginate,
    delNFTribModBCICMSST
} from "../../../helpers/nf_mod_bc_icm_st_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTribModBCICMSST({payload: {id, history}}) {
    try {

        const response = yield call(delNFTribModBCICMSST, id)

        yield put(deleteNFTribModBCICMSSTSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTribModBCICMSSTError(error))
    }
}

function* paginateNFTribModBCICMSST({payload: {params}}) {
    try {

        const response = yield call(getNFTribModBCICMSSTPaginate, params)

        yield put(allNFTribModBCICMSSTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribModBCICMSSTError(error))
    }
}

function* filtersPaginateNFTribModBCICMSST({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTribModBCICMSSTPaginate, params)

        yield put(filtersAllNFTribModBCICMSSTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribModBCICMSSTError(error))
    }
}


function* NFTribModBCICMSSTSaga() {
    yield takeEvery(PAGINATE_NF_TRIB_MOD_BC_ICMS_ST, paginateNFTribModBCICMSST)
    yield takeEvery(FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST, filtersPaginateNFTribModBCICMSST)
    yield takeEvery(DELETE_NF_TRIB_MOD_BC_ICMS_ST, deleteNFTribModBCICMSST)
}

export default NFTribModBCICMSSTSaga
