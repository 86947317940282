import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const importTabCESTAPI = data => post(url.IMPORT_TAB_CEST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getTabCESTById = data => get(url.GET_TAB_CEST_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delTabCEST = data => del(url.DELETE_TAB_CEST.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateTabCEST = data => post(url.POST_CREATE_TAB_CEST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditTabCEST = data => put(url.PUT_EDIT_TAB_CEST, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getTabCESTPaginate = ({ page, limit }) => get(url.GET_TAB_CEST_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterTabCESTPaginate = data => get(url.GET_TAB_CEST_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchTabCEST = params => get(url.GET_SEARCH_TAB_CEST, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getTabCESTPaginate,
    getFilterTabCESTPaginate,
    putEditTabCEST,
    postCreateTabCEST,
    delTabCEST,
    getTabCESTById,
    getSearchTabCEST,
    importTabCESTAPI
}
