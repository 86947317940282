import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

 
const getNFTipoOperacaoById = data => get(url.GET_NF_TIPO_OPERACAO_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFTipoOperacao = data => del(url.DELETE_NF_TIPO_OPERACAO.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFTipoOperacao = data => post(url.POST_CREATE_NF_TIPO_OPERACAO, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFTipoOperacao = data => put(url.PUT_EDIT_NF_TIPO_OPERACAO, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFTipoOperacaoPaginate = ({page, limit}) => get(url.GET_NF_TIPO_OPERACAO_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFTipoOperacaoPaginate = data => get(url.GET_NF_TIPO_OPERACAO_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFTipoOperacao = params => get(url.GET_SEARCH_NF_TIPO_OPERACAO, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFTipoOperacaoPaginate,
    getFilterNFTipoOperacaoPaginate,
    putEditNFTipoOperacao,
    postCreateNFTipoOperacao,
    delNFTipoOperacao,
    getNFTipoOperacaoById,
    getSearchNFTipoOperacao
}
