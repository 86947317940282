import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TRIB_COFINS,
    FILTER_PAGINATE_NF_TRIB_COFINS,
    DELETE_NF_TRIB_COFINS,
} from "./actionTypes"

import {
    allNFTribConfinsPaginateSuccess,
    filtersAllNFTribConfinsPaginateSuccess,
    deleteNFTribConfinsSuccess,
    apiPaginateNFTribConfinsError
} from "./actions"


import {
    getNFTribConfinsPaginate,
    getFilterNFTribConfinsPaginate,
    delNFTribConfins
} from "../../../helpers/nf_cofins_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTribConfins({payload: {id, history}}) {
    try {

        const response = yield call(delNFTribConfins, id)

        yield put(deleteNFTribConfinsSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTribConfinsError(error))
    }
}

function* paginateNFTribConfins({payload: {params}}) {
    try {

        const response = yield call(getNFTribConfinsPaginate, params)

        yield put(allNFTribConfinsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribConfinsError(error))
    }
}

function* filtersPaginateNFTribConfins({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTribConfinsPaginate, params)

        yield put(filtersAllNFTribConfinsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribConfinsError(error))
    }
}


function* NFTribConfinsSaga() {
    yield takeEvery(PAGINATE_NF_TRIB_COFINS, paginateNFTribConfins)
    yield takeEvery(FILTER_PAGINATE_NF_TRIB_COFINS, filtersPaginateNFTribConfins)
    yield takeEvery(DELETE_NF_TRIB_COFINS, deleteNFTribConfins)
}

export default NFTribConfinsSaga
