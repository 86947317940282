import {
    PAGINATE_NF_TRIB_CFOP,
    PAGINATE_NF_TRIB_CFOP_SUCCESS,
    FILTER_PAGINATE_NF_TRIB_CFOP,
    FILTER_PAGINATE_NF_TRIB_CFOP_SUCCESS,
    DELETE_NF_TRIB_CFOP_SUCCESS,
    DELETE_NF_TRIB_CFOP,
    API_ERROR_NF_TRIB_CFOP,
} from "./actionTypes"


export const filtersAllNFTribCFOPPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_CFOP,
        payload: {params},
    }
}

export const filtersAllNFTribCFOPPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_CFOP_SUCCESS,
        payload: {params},
    }
}

export const allNFTribCFOPPaginate = params => {
    return {
        type: PAGINATE_NF_TRIB_CFOP,
        payload: {params},
    }
}

export const allNFTribCFOPPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TRIB_CFOP_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTribCFOP = (id, history) => {
  return {
    type: DELETE_NF_TRIB_CFOP,
    payload: {id, history} ,
  }
}

export const deleteNFTribCFOPSuccess = message => {
    return {
        type: DELETE_NF_TRIB_CFOP_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTribCFOPError = error => {
  return {
    type: API_ERROR_NF_TRIB_CFOP,
    payload: error,
  }
}

