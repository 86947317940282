export const PAGINATE_NF_TRIB_MOD_BC_ICMS_ST = "PAGINATE_NF_TRIB_MOD_BC_ICMS_ST"
export const PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS = "PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS"

export const FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST = "FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST"
export const FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS = "FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS"

export const DELETE_NF_TRIB_MOD_BC_ICMS_ST = "DELETE_NF_TRIB_MOD_BC_ICMS_ST"
export const DELETE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS = "DELETE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS"

export const API_ERROR_NF_TRIB_MOD_BC_ICMS_ST = "PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_API_ERROR"
