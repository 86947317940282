import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import {connect} from "react-redux";
import {editModelsSeguiments, findByIdModelsSeguiments} from "../../../store/actions"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import Dropzone from "react-dropzone";
import GenericsServiceAPI from "../../../helpers/GenericsServicesAPI";
import {NotificationManager} from "react-notifications";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: null,
            image: '',

            file: null,
            existsFile: false,
            path_file_before_image: '',
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleDrop = this.handleDrop.bind(this);
        this.sendUpload = this.sendUpload.bind(this)

    }

    componentDidMount() {
        const {entity_id} = this.props.match.params
        this.props.findByIdModelsSeguiments(entity_id)



    }

    sendUpload(formData, url) {

        GenericsServiceAPI.upload(formData, url)
            .then(response => {
                const {message} = response;
                const {url_image, image, path_dir_file} = response.data;
                this.setState({image: url_image, path_file_before_image: path_dir_file});
                //this.props.existsFiles = false
                //this.props.loading = false
                NotificationManager.info(message, 'Aviso!');


            })
            .catch(err => {
               // this.props.loading = false
                NotificationManager.warning(err, 'Ops!', 3000);
            })
            .then(response => {

            });
    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)
        const {entity_id} = this.props.match.params
        var data = new FormData();
        data.append('id', entity_id);
        data.append('type_dir_file', 'image');
        data.append('files', file);
        data.append('path_file_before', this.state.path_file_before_image !== '' ? this.state.path_file_before_image : this.props.image );
        this.sendUpload(data, 'upload/categories')

    }

    handleValidSubmit(event, values) {
        this.props.editModelsSeguiments({
            id: this.props.id,
            name: values.name,
            description: values.description,
            status: this.state.status ? this.state.status : this.props.status,

        }, this.props.history)

    }

    render() {
        const {url_image, url_front_cover, loading_form} = this.props;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Models Seguiments Edit")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Models Seguiments")}
                                     breadcrumbItem={this.props.t("Edit")}/>
                        <Row>
                            {
                                this.props.loading_form
                                    ?
                                    <Col className="col-12">
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            paddingBottom: 20
                                                        }}>
                                                            <Spinner size="sm" animation="border"
                                                                     style={{marginBottom: 20}}
                                                                     variant="success"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <Col sm={12}>
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={this.handleValidSubmit}>
                                            <Row>
                                                <Col sm={9}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-md-center  mb-3">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Form")}</span>
                                                                        <p className="card-title-desc mt-2">Preencha os campos
                                                                            abaixo, para
                                                                            concluir o cadastro.</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12}>


                                                                    <Row>
                                                                        <Col sm={8} className="mb-2">
                                                                            <AvField
                                                                                name="name"
                                                                                label={this.props.t("Name")}
                                                                                value={this.props.name}
                                                                                className="form-control"
                                                                                placeholder="Digite o nome"
                                                                                type="text"
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={4} className="mb-2">
                                                                            <AvField
                                                                                value={this.props.status}
                                                                                onChange={this.handleFields}
                                                                                type="select" name="select"
                                                                                label={this.props.t("Status")}>
                                                                                <option value="1">Ativo</option>
                                                                                <option value="0">Inativo</option>
                                                                            </AvField>
                                                                        </Col>

                                                                        <Col sm={12} className="mb-2">
                                                                            <AvField
                                                                                name="description"
                                                                                label={this.props.t("Description")}
                                                                                className="form-control"
                                                                                value={this.props.description}
                                                                                placeholder="Digite uma descrição"
                                                                                type="textarea"
                                                                                rows={5}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={12} className="pt-3">
                                                                            <ButtonCustom
                                                                                isLoading={this.props.loading}
                                                                                size="sm"
                                                                                clazzContainer="text-center"
                                                                                clazz="btn btn-info w-md"
                                                                                message_loaging="">
                                                                                <button
                                                                                    className="btn btn-info w-md"
                                                                                    type="submit">
                                                                                    Salvar
                                                                                </button>
                                                                            </ButtonCustom>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col sm={3}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-md-center  mb-3">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Image")}</span>
                                                                        <p className="card-title-desc mt-3">Informe o Icone</p>
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <a
                                                                            onClick={() => {
                                                                                this.props.history.goBack()
                                                                            }}
                                                                            className="btn btn-dark btn-sm text-light"
                                                                        >
                                                                            <i className="bx bx-left-arrow-circle"/>
                                                                            <span> Voltar</span>
                                                                        </a>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <Dropzone onDrop={this.handleDrop}>

                                                                        {({getRootProps, getInputProps}) => (

                                                                            <div {...getRootProps({className: "dropzone"})}>
                                                                                <input {...getInputProps()} />
                                                                                {url_image !== ''
                                                                                    ?
                                                                                    <img className="thumbnail img-responsive img-icon-categ"
                                                                                         src={url_image ?  this.state.image !== '' ? this.state.image : url_image : this.state.image}/>
                                                                                    : <p style={{marginTop: '30%'}}
                                                                                         className="text-center">Arraste e
                                                                                        solte ou
                                                                                        clique
                                                                                        para adicionar a imagem</p>
                                                                                }

                                                                            </div>

                                                                        )}

                                                                    </Dropzone>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </Col>
                            }

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,


    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,


    name: PropTypes.any,
    description: PropTypes.any,
    company_id: PropTypes.any,
    status: PropTypes.any,

    image: PropTypes.any,
    id: PropTypes.any,

    url_image: PropTypes.any,
    type_dir_file: PropTypes.any,

    editModelsSeguiments: PropTypes.func,
    findByIdModelsSeguiments: PropTypes.func,

}


const mapStateToProps = state => {

    const {
        loading,
        loading_form,
        error,
        message,
        status,
        name,
        company_id,
        description,
        image,
        id,
        url_image,
        type_dir_file
    } = state.CreateOrEditModelsSeguiments
    return {
        name,
        loading_form,
        description,
        loading,
        company_id,
        error,
        message,
        status,
        image,
        id,
        url_image,
        type_dir_file
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    editModelsSeguiments,
    findByIdModelsSeguiments
})(
    (Index)
)))


