import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getClassificacaoProdutoById = data => get(url.GET_CLASSIFICACAO_PRODUTO_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delClassificacaoProduto = data => del(url.DELETE_CLASSIFICACAO_PRODUTO.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateClassificacaoProduto = data => post(url.POST_CREATE_CLASSIFICACAO_PRODUTO, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditClassificacaoProduto = data => put(url.PUT_EDIT_CLASSIFICACAO_PRODUTO, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getClassificacaoProdutoPaginate = ({ page, limit }) => get(url.GET_CLASSIFICACAO_PRODUTO_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterClassificacaoProdutoPaginate = data => get(url.GET_CLASSIFICACAO_PRODUTO_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchClassificacaoProduto = params => get(url.GET_SEARCH_CLASSIFICACAO_PRODUTO, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getClassificacaoProdutoPaginate,
    getFilterClassificacaoProdutoPaginate,
    putEditClassificacaoProduto,
    postCreateClassificacaoProduto,
    delClassificacaoProduto,
    getClassificacaoProdutoById,
    getSearchClassificacaoProduto
}
