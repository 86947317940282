import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import { connect } from "react-redux";
import { editPromotions, findByIdPromotions } from "../../../store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: null,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
    }

    componentDidMount() {
        const { entity_id } = this.props.match.params
        this.props.findByIdPromotions(entity_id)


    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    handleValidSubmit(event, values) {
        this.props.editPromotions({
            id: this.props.id,
            name: values.name,
            description: values.description,
            status: this.state.status ? this.state.status : this.props.status,
            type: this.state.type ? this.state.type : this.props.type,
        }, this.props.history)

    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Editar Regra Promocional</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Regra Promoção"
                            breadcrumbItem="Editar" />
                        <Row>
                            {
                                this.props.loading_form
                                    ?
                                    <Col className="col-12">
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            paddingBottom: 20
                                                        }}>
                                                            <Spinner size="sm" animation="border"
                                                                style={{ marginBottom: 20 }}
                                                                variant="success" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <Col sm={12}>
                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={this.handleValidSubmit}>
                                            <Row>
                                                <Col sm={12}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row className="align-items-md-center  mb-3">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <span
                                                                            className="h4 card-title">{this.props.t("Form")}</span>
                                                                        <p className="card-title-desc mt-2">Preencha os
                                                                            campos
                                                                            abaixo, para
                                                                            concluir o cadastro.</p>
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <a
                                                                            onClick={() => {
                                                                                this.props.history.goBack()
                                                                            }}
                                                                            className="btn btn-dark btn-sm text-light"
                                                                        >
                                                                            <i className="bx bx-left-arrow-circle" />
                                                                            <span> Voltar</span>
                                                                        </a>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={12}>

                                                                    <Row>
                                                                        <Col sm={8} className="mb-2">
                                                                            <AvField
                                                                                name="name"
                                                                                label={this.props.t("Name")}
                                                                                value={this.props.name}
                                                                                className="form-control"
                                                                                placeholder="Digite o nome"
                                                                                type="text"
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={4} className="mb-2">
                                                                            <AvField
                                                                                value={this.props.status}
                                                                                onChange={this.handleFields}
                                                                                type="select" name="select"
                                                                                label={this.props.t("Status")}>
                                                                                <option value="1">Ativo</option>
                                                                                <option value="0">Inativo</option>
                                                                            </AvField>
                                                                        </Col>

                                                                        <Col sm={12} className="mt-2">
                                                                            <AvField
                                                                                name="description"
                                                                                label={this.props.t("Description")}
                                                                                className="form-control"
                                                                                value={this.props.description}
                                                                                placeholder="Digite uma descrição"
                                                                                type="textarea"
                                                                                rows={5}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col xl={3} sm={6}>
                                                                            <div className="mt-4" onChange={this.handleRadioButtom}>
                                                                                <h5 className="font-size-14 mb-4">Tipo da campanha</h5>
                                                                                <div className="form-check ">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="type"
                                                                                        id="type"
                                                                                        value="1"
                                                                                        checked={(this.props.type === 1 || this.state.type === 1)}

                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="type"
                                                                                    >
                                                                                        Desconto em produtos
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="type"
                                                                                        id="type"
                                                                                        value="2"
                                                                                        checked={(this.props.type === 2 || this.state.type === 2)}

                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="type"
                                                                                    >
                                                                                        Cupons de descontos
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name="type"
                                                                                        id="type"
                                                                                        value="3"
                                                                                        checked={(this.props.type === 3 || this.state.type === 3)}

                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="type"
                                                                                    >
                                                                                        Desconto na entrega
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm={12} className="pt-3">
                                                                            <ButtonCustom
                                                                                isLoading={this.props.loading}
                                                                                size="sm"
                                                                                clazzContainer="text-center"
                                                                                clazz="btn btn-info w-md"
                                                                                message_loaging="">
                                                                                <button
                                                                                    className="btn btn-info w-md"
                                                                                    type="submit">
                                                                                    Salvar
                                                                                </button>
                                                                            </ButtonCustom>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </Col>
                            }

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    t: PropTypes.any,


    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,


    name: PropTypes.any,
    description: PropTypes.any,
    status: PropTypes.any,
    type: PropTypes.any,
    id: PropTypes.any,

    editPromotions: PropTypes.func,
    findByIdPromotions: PropTypes.func,

}


const mapStateToProps = state => {

    const {
        loading,
        loading_form,
        error,
        message,
        status,
        type,
        name,
        description,
        image,
        id
    } = state.CreateOrEditPromotions
    return {
        name,
        type,
        loading_form,
        description,
        loading,
        error,
        message,
        status,
        image,
        id
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    editPromotions,
    findByIdPromotions
})(
    (Index)
)))


