import {
    CREATE_STATE,
    CREATE_STATE_SUCCESS,
    EDIT_STATE,
    EDIT_STATE_SUCCESS,
    FIND_BY_ID_STATE,
    FIND_BY_ID_STATE_SUCCESS,
    CREATE_OR_EDIT_STATE_API_ERROR
} from "./actionTypes"


export const createState = (params, history) => {
    return {
        type: CREATE_STATE,
        payload: {params, history},
    }
}

export const createStateSuccess = params => {
    return {
        type: CREATE_STATE_SUCCESS,
        payload: {params},
    }
}

export const editState = (params, history) => {
    return {
        type: EDIT_STATE,
        payload: {params, history},
    }
}

export const editStateSuccess = result => {
    return {
        type: EDIT_STATE_SUCCESS,
        payload: {result},
    }
}

export const findByIdState = id => {
    return {
        type: FIND_BY_ID_STATE,
        payload: {id},
    }
}

export const findStateIdSuccess = result => {
    return {
        type: FIND_BY_ID_STATE_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditStateError = error => {
    return {
        type: CREATE_OR_EDIT_STATE_API_ERROR,
        payload: error,
    }
}

