import {
    CREATE_SERVICE_RADIUS_COMPANY,
    CREATE_SERVICE_RADIUS_COMPANY_SUCCESS,
    EDIT_SERVICE_RADIUS_COMPANY,
    EDIT_SERVICE_RADIUS_COMPANY_SUCCESS,
    CREATE_OR_EDIT_SERVICE_RADIUS_COMPANY_API_ERROR
} from "./actionTypes"


export const createServiceRadiusCompany = (params, history) => {
    return {
        type: CREATE_SERVICE_RADIUS_COMPANY,
        payload: {params, history},
    }
}

export const createServiceRadiusCompanySuccess = params => {
    return {
        type: CREATE_SERVICE_RADIUS_COMPANY_SUCCESS,
        payload: params,
    }
}

export const editServiceRadiusCompany = (params, history) => {
    return {
        type: EDIT_SERVICE_RADIUS_COMPANY,
        payload: {params, history},
    }
}

export const editServiceRadiusCompanySuccess = result => {
    return {
        type: EDIT_SERVICE_RADIUS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditServiceRadiusCompanyError = error => {
    return {
        type: CREATE_OR_EDIT_SERVICE_RADIUS_COMPANY_API_ERROR,
        payload: error,
    }
}

