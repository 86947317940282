import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getPromotionsById = data => get(url.GET_PROMOTIONS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delPromotions = data => del(url.DELETE_PROMOTIONS.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreatePromotions = data => post(url.POST_CREATE_PROMOTIONS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postUploadPromotions = data => post(url.POST_UPLOAD_PROMOTIONS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditPromotions = data => put(url.PUT_EDIT_PROMOTIONS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getPromotionsPaginate = ({page, limit}) => get(url.GET_PROMOTIONS_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterPromotionsPaginate = data => get(url.GET_PROMOTIONS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchPromotions = params => get(url.GET_SEARCH_PROMOTIONS, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getPromotionsPaginate,
    getFilterPromotionsPaginate,
    putEditPromotions,
    postCreatePromotions,
    delPromotions,
    postUploadPromotions,
    getPromotionsById,
    getSearchPromotions
}
