import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_TRIB_CSOSN,
    FILTER_PAGINATE_NF_TRIB_CSOSN,
    DELETE_NF_TRIB_CSOSN,
} from "./actionTypes"

import {
    allNFTribCSOSNPaginateSuccess,
    filtersAllNFTribCSOSNPaginateSuccess,
    deleteNFTribCSOSNSuccess,
    apiPaginateNFTribCSOSNError
} from "./actions"

 
import {
    getNFTribCSOSNPaginate,
    getFilterNFTribCSOSNPaginate,
    delNFTribCSOSN
} from "../../../helpers/nf_csosn_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFTribCSOSN({payload: {id, history}}) {
    try {

        const response = yield call(delNFTribCSOSN, id)

        yield put(deleteNFTribCSOSNSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFTribCSOSNError(error))
    }
}

function* paginateNFTribCSOSN({payload: {params}}) {
    try {

        const response = yield call(getNFTribCSOSNPaginate, params)

        yield put(allNFTribCSOSNPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribCSOSNError(error))
    }
}

function* filtersPaginateNFTribCSOSN({payload: {params}}) {
    try {

        const response = yield call(getFilterNFTribCSOSNPaginate, params)

        yield put(filtersAllNFTribCSOSNPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFTribCSOSNError(error))
    }
}


function* NFTribCSOSNSaga() {
    yield takeEvery(PAGINATE_NF_TRIB_CSOSN, paginateNFTribCSOSN)
    yield takeEvery(FILTER_PAGINATE_NF_TRIB_CSOSN, filtersPaginateNFTribCSOSN)
    yield takeEvery(DELETE_NF_TRIB_CSOSN, deleteNFTribCSOSN)
}

export default NFTribCSOSNSaga
