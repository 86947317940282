import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getSizesById = data => get(url.GET_SIZES_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSizesByCategoryId = data => get(url.GET_SIZES_BY_CATEGORY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delSizes = data => del(url.DELETE_SIZES.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateSizes = data => post(url.POST_CREATE_SIZES, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditSizes = data => put(url.PUT_EDIT_SIZES, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSizesPaginate = ({menu_category_id,page, limit}) => get(url.GET_SIZES_PAGINATE, { params: { menu_category_id,page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterSizesPaginate = data => get(url.GET_SIZES_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getSizesPaginate,
    getFilterSizesPaginate,
    putEditSizes,
    postCreateSizes,
    delSizes,
    getSizesById,
    getSizesByCategoryId
}
