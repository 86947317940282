import {
    CREATE_SIZES,
    CREATE_SIZES_SUCCESS,
    EDIT_SIZES,
    EDIT_SIZES_SUCCESS,
    CREATE_OR_EDIT_SIZES_API_ERROR, FIND_SIZES_BY_ID_CATEGORIES, FIND_SIZES_BY_ID_CATEGORIES_SUCCESS
} from "./actionTypes"
import {FIND_BY_ID_CATEGORIES, FIND_BY_ID_CATEGORIES_SUCCESS} from "../../actionTypes";


const initialState = {
    loading: false,
    loading_form: false,
    refresh: false,
    error: false,
    message: '',
    rows: [],

    name: '',
    sku: '',
    qtd_flavors: 0,
    qtd_pieces: 0,
    status: 1,
    menu_category_id: 0,

    id: 0,
}

const createOrEditSizes = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_SIZES:
            state = {
                ...state,
                refresh: false,
                loading: true
            }
            break
        case CREATE_SIZES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                refresh: true,
                loading: false
            }
            break
        case EDIT_SIZES:
            state = {...state, loading: true}
            break
        case EDIT_SIZES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_SIZES_BY_ID_CATEGORIES:
            state = {...state,  refresh: false,loading: false, loading_form: true}
            break
        case FIND_SIZES_BY_ID_CATEGORIES_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,
                refresh: false,
                rows: action.payload.results.length > 0 ? action.payload.results : [],

               /* name: action.payload.result.name,
                sku: action.payload.result.sku,
                qtd_flavors: action.payload.result.qtd_flavors,
                qtd_pieces: action.payload.result.qtd_pieces,
                status: action.payload.result.status,
                menu_category_id: action.payload.result.menu_category_id,*/

            }
            break
        case CREATE_OR_EDIT_SIZES_API_ERROR:
            state = {...state, error: action.payload, loading: false,  refresh: false,}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditSizes
