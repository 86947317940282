import {
    PAGINATE_DRIVERS_COMPANY,
    PAGINATE_DRIVERS_COMPANY_SUCCESS,
    FILTER_PAGINATE_DRIVERS_COMPANY,
    FILTER_PAGINATE_DRIVERS_COMPANY_SUCCESS,
    DELETE_DRIVERS_COMPANY_SUCCESS,
    DELETE_DRIVERS_COMPANY,
    API_ERROR_LIST_DRIVERS_COMPANY,
} from "./actionTypes"


export const filtersAllDriversCompanyPaginate = params => {
    return {
        type: FILTER_PAGINATE_DRIVERS_COMPANY,
        payload: {params},
    }
}

export const filtersAllDriversCompanyPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_DRIVERS_COMPANY_SUCCESS,
        payload: {params},
    }
}

export const allDriversCompanyPaginate = params => {
    return {
        type: PAGINATE_DRIVERS_COMPANY,
        payload: {params},
    }
}

export const allDriversCompanyPaginateSuccess = result => {
    return {
        type: PAGINATE_DRIVERS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const deleteDriversCompany = (id, history) => {
  return {
    type: DELETE_DRIVERS_COMPANY,
    payload: {id, history} ,
  }
}

export const deleteDriversCompanySuccess = message => {
    return {
        type: DELETE_DRIVERS_COMPANY_SUCCESS,
        payload: message,
    }
}

export const apiPaginateDriversCompanyError = error => {
  return {
    type: API_ERROR_LIST_DRIVERS_COMPANY,
    payload: error,
  }
}

