import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getPaymentMethodsCompanyById = data => get(url.GET_PAYMENT_METHODS_COMPANY_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delPaymentMethodsCompany = data => del(url.DELETE_PAYMENT_METHODS_COMPANY.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreatePaymentMethodsCompany = data => post(url.POST_CREATE_PAYMENT_METHODS_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditPaymentMethodsCompany = data => put(url.PUT_EDIT_PAYMENT_METHODS_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getPaymentMethodsCompanyPaginate = ({company_id, page, limit}) => get(url.GET_PAYMENT_METHODS_COMPANY_PAGINATE, { params: {company_id, page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterPaymentMethodsCompanyPaginate = data => get(url.GET_PAYMENT_METHODS_COMPANY_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getPaymentMethodsCompanyPaginate,
    getFilterPaymentMethodsCompanyPaginate,
    putEditPaymentMethodsCompany,
    postCreatePaymentMethodsCompany,
    delPaymentMethodsCompany,
    getPaymentMethodsCompanyById
}
