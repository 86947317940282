import {
    PAGINATE_NF_TIPO_OPERACAO,
    PAGINATE_NF_TIPO_OPERACAO_SUCCESS,
    FILTER_PAGINATE_NF_TIPO_OPERACAO,
    FILTER_PAGINATE_NF_TIPO_OPERACAO_SUCCESS,
    DELETE_NF_TIPO_OPERACAO_SUCCESS,
    DELETE_NF_TIPO_OPERACAO,
    API_ERROR_NF_TIPO_OPERACAO,
} from "./actionTypes"


export const filtersAllNFTipoOperacaoPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TIPO_OPERACAO,
        payload: {params},
    }
}

export const filtersAllNFTipoOperacaoPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TIPO_OPERACAO_SUCCESS,
        payload: {params},
    }
}

export const allNFTipoOperacaoPaginate = params => {
    return {
        type: PAGINATE_NF_TIPO_OPERACAO,
        payload: {params},
    }
}

export const allNFTipoOperacaoPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TIPO_OPERACAO_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTipoOperacao = (id, history) => {
  return {
    type: DELETE_NF_TIPO_OPERACAO,
    payload: {id, history} ,
  }
}

export const deleteNFTipoOperacaoSuccess = message => {
    return {
        type: DELETE_NF_TIPO_OPERACAO_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTipoOperacaoError = error => {
  return {
    type: API_ERROR_NF_TIPO_OPERACAO,
    payload: error,
  }
}

