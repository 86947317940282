import {
    PAGINATE_NF_TRIB_MOD_BC_ICMS_ST,
    PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS,
    FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST,
    FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS,
    DELETE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS,
    DELETE_NF_TRIB_MOD_BC_ICMS_ST,
    API_ERROR_NF_TRIB_MOD_BC_ICMS_ST,
} from "./actionTypes"


export const filtersAllNFTribModBCICMSSTPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST,
        payload: {params},
    }
}

export const filtersAllNFTribModBCICMSSTPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS,
        payload: {params},
    }
}

export const allNFTribModBCICMSSTPaginate = params => {
    return {
        type: PAGINATE_NF_TRIB_MOD_BC_ICMS_ST,
        payload: {params},
    }
}

export const allNFTribModBCICMSSTPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS,
        payload: {result},
    }
}

export const deleteNFTribModBCICMSST = (id, history) => {
  return {
    type: DELETE_NF_TRIB_MOD_BC_ICMS_ST,
    payload: {id, history} ,
  }
}

export const deleteNFTribModBCICMSSTSuccess = message => {
    return {
        type: DELETE_NF_TRIB_MOD_BC_ICMS_ST_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFTribModBCICMSSTError = error => {
  return {
    type: API_ERROR_NF_TRIB_MOD_BC_ICMS_ST,
    payload: error,
  }
}

