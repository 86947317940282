import {
    PAGINATE_PRODUCTS,
    PAGINATE_PRODUCTS_SUCCESS,
    FILTER_PAGINATE_PRODUCTS,
    FILTER_PAGINATE_PRODUCTS_SUCCESS,
    DELETE_PRODUCTS_SUCCESS,
    DELETE_PRODUCTS,
    API_ERROR_LIST_PRODUCTS,
} from "./actionTypes"


const initialState = {
    loading: false,
    refresh: false,
    loading_delete: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0
}

const Products = (state = initialState, action) => {

    switch (action.type) {
        case PAGINATE_PRODUCTS:
            state = {
                ...state,
                rows: [],
                loading: true,
                refresh: false,
                loading_delete: false
            }
            break
        case PAGINATE_PRODUCTS_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.result.current_page,
                last_page: action.payload.result.last_page,
                total: action.payload.result.total,
                from: action.payload.result.from,
                to: action.payload.result.to,
                rows: action.payload.result.data.length > 0 ? action.payload.result.data : [],
                refresh: false,
                loading_delete: false,
                loading: false
            }
            break
        case FILTER_PAGINATE_PRODUCTS:
            state = {...state, loading: true}
            break
        case FILTER_PAGINATE_PRODUCTS_SUCCESS:
            state = {...state, loading: false}
            break
        case DELETE_PRODUCTS:
            state = {...state, loading: false, loading_delete: false}
            break
        case DELETE_PRODUCTS_SUCCESS:
            state = {...state,
                message: action.payload,
                loading: false,
                loading_delete: true,
                refresh: true,
                current_page: 0,
                last_page: 0,
                total: 0,
                from: 0,
                to: 0,
                rows: [],
            }
            break
        case API_ERROR_LIST_PRODUCTS:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default Products
