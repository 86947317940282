export const CREATE_COMPANIES = "CREATE_COMPANIES"
export const CREATE_COMPANIES_SUCCESS = "CREATE_COMPANIES_SUCCESS"

export const EDIT_COMPANIES = "EDIT_COMPANIES"
export const EDIT_COMPANIES_SUCCESS = "EDIT_COMPANIES_SUCCESS"

export const FIND_BY_ID_COMPANIES = "FIND_BY_ID_COMPANIES"
export const FIND_BY_ID_COMPANIES_SUCCESS = "FIND_BY_ID_COMPANIES_SUCCESS"

export const FIND_ABOUT_US_COMPANIES = "FIND_ABOUT_US_COMPANIES"
export const FIND_ABOUT_US_COMPANIES_SUCCESS = "FIND_ABOUT_US_COMPANIES_SUCCESS"

export const FIND_CRT_COMPANIES = "FIND_CRT_COMPANIES"
export const FIND_CRT_COMPANIES_SUCCESS = "FIND_CRT_COMPANIES_SUCCESS"

export const CREATE_CRT_COMPANIES = "CREATE_CRT_COMPANIES"
export const CREATE_CRT_COMPANIES_SUCCESS = "CREATE_CRT_COMPANIES_SUCCESS"

export const UPLOAD_COMPANIES = "UPLOAD_COMPANIES"
export const UPLOAD_COMPANIES_SUCCESS = "UPLOAD_COMPANIES_SUCCESS"

export const CREATE_OR_EDIT_COMPANIES_API_ERROR = "CREATE_OR_EDIT_COMPANIES_API_ERROR"
