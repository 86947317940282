import {
    CREATE_CATEGORIES,
    CREATE_CATEGORIES_SUCCESS,
    EDIT_CATEGORIES,
    EDIT_CATEGORIES_SUCCESS,
    FIND_BY_ID_CATEGORIES,
    FIND_BY_ID_CATEGORIES_SUCCESS,
    UPLOAD_CATEGORIES,
    UPLOAD_CATEGORIES_SUCCESS,
    CREATE_OR_EDIT_CATEGORIES_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    promotions: 0,
    status: 1,
    model_menu_id: 0,
    price_type: 0,
    model_menu: null,
    availability: 1,
    description: '',
    name: '',
    company_id: null,
    company: null,
    id: 0,
}

const createOrEditCategories = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_CATEGORIES:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_CATEGORIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case EDIT_CATEGORIES:
            state = {...state, loading: true}
            break
        case EDIT_CATEGORIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case UPLOAD_CATEGORIES:
            state = {...state, loading: true}
            break
        case UPLOAD_CATEGORIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_CATEGORIES:
            state = {...state, loading: false, loading_form: true}
            break
        case FIND_BY_ID_CATEGORIES_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                status: action.payload.result.status,
                model_menu_id: action.payload.result.model_menu.id,
                model_menu: action.payload.result.model_menu.text,
                availability: action.payload.result.availability,
                description: action.payload.result.description,
                name: action.payload.result.name,
                company_id: action.payload.result.company.id,
                company: action.payload.result.company.text,
                id: action.payload.result.id,
                price_type: action.payload.result.price_type,
                promotions: action.payload.result.promotions,


            }
            break
        case CREATE_OR_EDIT_CATEGORIES_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditCategories
