import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_DISCOUNT_COUPONS,
    FILTER_PAGINATE_DISCOUNT_COUPONS,
    DELETE_DISCOUNT_COUPONS,
} from "./actionTypes"

import {
    allDiscountCouponsPaginateSuccess,
    filtersAllDiscountCouponsPaginateSuccess,
    deleteDiscountCouponsSuccess,
    apiPaginateDiscountCouponsError
} from "./actions"


import {
    getDiscountCouponsPaginate,
    getFilterDiscountCouponsPaginate,
    delDiscountCoupons
} from "../../../helpers/discount_coupons_helper"
import {NotificationManager} from "react-notifications";

function* deleteDiscountCoupons({payload: {id, history}}) {
    try {

        const response = yield call(delDiscountCoupons, id)

        yield put(deleteDiscountCouponsSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateDiscountCouponsError(error))
    }
}

function* paginateDiscountCoupons({payload: {params}}) {
    try {

        const response = yield call(getDiscountCouponsPaginate, params)

        yield put(allDiscountCouponsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateDiscountCouponsError(error))
    }
}

function* filtersPaginateDiscountCoupons({payload: {params}}) {
    try {

        const response = yield call(getFilterDiscountCouponsPaginate, params)

        yield put(filtersAllDiscountCouponsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateDiscountCouponsError(error))
    }
}


function* DiscountCouponsSaga() {
    yield takeEvery(PAGINATE_DISCOUNT_COUPONS, paginateDiscountCoupons)
    yield takeEvery(FILTER_PAGINATE_DISCOUNT_COUPONS, filtersPaginateDiscountCoupons)
    yield takeEvery(DELETE_DISCOUNT_COUPONS, deleteDiscountCoupons)
}

export default DiscountCouponsSaga
