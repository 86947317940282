export const CREATE_SETTINGS = "CREATE_SETTINGS"
export const CREATE_SETTINGS_SUCCESS = "CREATE_SETTINGS_SUCCESS"

export const CREATE_OR_EDIT_SETTINGS = "CREATE_OR_EDIT_SETTINGS"
export const CREATE_OR_EDIT_SETTINGS_SUCCESS = "CREATE_OR_EDIT_SSSETTINGS_SUCCESS"

export const EDIT_SETTINGS = "EDIT_SETTINGS"
export const EDIT_SETTINGS_SUCCESS = "EDIT_SETTINGS_SUCCESS"

export const FIND_BY_ID_SETTINGS = "FIND_BY_ID_SETTINGS"
export const FIND_BY_ID_SETTINGS_SUCCESS = "FIND_BY_ID_SETTINGS_SUCCESS"

export const FIND_BY_KEY_SETTINGS = "FIND_BY_KEY_SETTINGS"
export const FIND_BY_KEY_SETTINGS_SUCCESS = "FIND_BY_KEY_SETTINGS_SUCCESS"

export const CREATE_OR_EDIT_SETTINGS_API_ERROR = "CREATE_OR_EDIT_SETTINGS_API_ERROR"
