import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

 
const getNFTribConfinsById = data => get(url.GET_NF_TRIB_COFINS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFTribConfins = data => del(url.DELETE_NF_TRIB_COFINS.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFTribConfins = data => post(url.POST_CREATE_NF_TRIB_COFINS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFTribConfins = data => put(url.PUT_EDIT_NF_TRIB_COFINS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFTribConfinsPaginate = ({page, limit}) => get(url.GET_NF_TRIB_COFINS_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFTribConfinsPaginate = data => get(url.GET_NF_TRIB_COFINS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFTribConfins = params => get(url.GET_SEARCH_NF_TRIB_COFINS, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFTribConfinsPaginate,
    getFilterNFTribConfinsPaginate,
    putEditNFTribConfins,
    postCreateNFTribConfins,
    delNFTribConfins,
    getNFTribConfinsById,
    getSearchNFTribConfins
}
