import {
    CREATE_CITY,
    CREATE_CITY_SUCCESS,
    EDIT_CITY,
    EDIT_CITY_SUCCESS,
    FIND_BY_ID_CITY,
    FIND_BY_ID_CITY_SUCCESS,
    CREATE_OR_EDIT_CITY_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    name: '',
    ibge: '',
    ddd: '',
    code: '',
    state_id: null,
    state_name: null,
    starting_track: null,
    final_track: null,
    siafi: '',
    woeid: '',
    gia: '',
    status: 1,
    id: 0,
    lat: 0,
    lng: 0
}

const createOrEditCity = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_CITY:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_CITY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case EDIT_CITY:
            state = {...state, loading: true}
            break
        case EDIT_CITY_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_CITY:
            state = {...state, loading: false, loading_form: true}
            break
        case FIND_BY_ID_CITY_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                name: action.payload.result.name,
                ibge: action.payload.result.ibge,
                state_id: action.payload.result.state_id,
                siafi: action.payload.result.siafi,
                woeid: action.payload.result.woeid,
                gia: action.payload.result.gia,
                status: action.payload.result.status,
                id: action.payload.result.id,
                lat: action.payload.result.lat,
                lng: action.payload.result.lng,
                state_name: action.payload.result.state.name,
                code: action.payload.result.code,
                ddd: action.payload.result.ddd,


            }
            break
        case CREATE_OR_EDIT_CITY_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditCity
