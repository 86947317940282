import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_SCHEDULER_COMPANY,
    FILTER_PAGINATE_SCHEDULER_COMPANY,
    DELETE_SCHEDULER_COMPANY,
} from "./actionTypes"

import {
    allSchedulerCompanyPaginateSuccess,
    filtersAllSchedulerCompanyPaginateSuccess,
    deleteSchedulerCompanySuccess,
    apiPaginateSchedulerCompanyError
} from "./actions"


import {
    getSchedulerCompanyPaginate,
    getFilterSchedulerCompanyPaginate,
    delSchedulerCompany
} from "../../../../../helpers/scheduler_company_helper"
import {NotificationManager} from "react-notifications";

function* deleteSchedulerCompany({payload: {id, history}}) {
    try {

        const response = yield call(delSchedulerCompany, id)

        yield put(deleteSchedulerCompanySuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateSchedulerCompanyError(error))
    }
}

function* paginateSchedulerCompany({payload: {params}}) {
    try {

        const response = yield call(getSchedulerCompanyPaginate, params)

        yield put(allSchedulerCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSchedulerCompanyError(error))
    }
}

function* filtersPaginateSchedulerCompany({payload: {params}}) {
    try {

        const response = yield call(getFilterSchedulerCompanyPaginate, params)

        yield put(filtersAllSchedulerCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSchedulerCompanyError(error))
    }
}


function* SchedulerCompanySaga() {
    yield takeEvery(PAGINATE_SCHEDULER_COMPANY, paginateSchedulerCompany)
    yield takeEvery(FILTER_PAGINATE_SCHEDULER_COMPANY, filtersPaginateSchedulerCompany)
    yield takeEvery(DELETE_SCHEDULER_COMPANY, deleteSchedulerCompany)
}

export default SchedulerCompanySaga
