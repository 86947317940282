import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getProductComplementById = data => get(url.GET_PRODUCTS_COMPLEMENT_BY_PRODUCT_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const getProductComplementByProductId = data => get(url.GET_PRODUCTS_COMPLEMENT_BY_PRODUCT_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delProductsComplement = data => del(url.DELETE_PRODUCTS_SIZE.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateProductComplement = data => post(url.POST_CREATE_PRODUCTS_SIZE, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditProductComplement = data => put(url.PUT_EDIT_PRODUCTS_SIZE, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getProductsComplementPaginate = ({ menu_category_id, page, limit }) => get(url.GET_PRODUCTS_SIZE_PAGINATE, { params: { menu_category_id, page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })


export {
    getProductsComplementPaginate,
    putEditProductComplement,
    postCreateProductComplement,
    getProductComplementById,
    getProductComplementByProductId,
    delProductsComplement,
}
