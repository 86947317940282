export const PAGINATE_CATEGORY = "PAGINATE_CATEGORY"
export const PAGINATE_CATEGORY_SUCCESS = "PAGINATE_CATEGORY_SUCCESS"

export const FILTER_PAGINATE_CATEGORY = "FILTER_PAGINATE_CATEGORY"
export const FILTER_PAGINATE_CATEGORY_SUCCESS = "FILTER_PAGINATE_CATEGORY_SUCCESS"

export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"

export const API_ERROR_CATEGORY = "PAGINATE_CATEGORY_API_ERROR"
