import {
    PAGINATE_CATEGORY,
    PAGINATE_CATEGORY_SUCCESS,
    FILTER_PAGINATE_CATEGORY,
    FILTER_PAGINATE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY,
    API_ERROR_CATEGORY,
} from "./actionTypes"


export const filtersAllCategoriesPaginate = params => {
    return {
        type: FILTER_PAGINATE_CATEGORY,
        payload: {params},
    }
}

export const filtersAllCategoriesPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_CATEGORY_SUCCESS,
        payload: {params},
    }
}

export const allCategoriesPaginate = params => {
    return {
        type: PAGINATE_CATEGORY,
        payload: {params},
    }
}

export const allCategoriesPaginateSuccess = result => {
    return {
        type: PAGINATE_CATEGORY_SUCCESS,
        payload: {result},
    }
}

export const deleteCategories = (id, history) => {
  return {
    type: DELETE_CATEGORY,
    payload: {id, history} ,
  }
}

export const deleteCategoriesSuccess = message => {
    return {
        type: DELETE_CATEGORY_SUCCESS,
        payload: message,
    }
}

export const apiPaginateCategoriesError = error => {
  return {
    type: API_ERROR_CATEGORY,
    payload: error,
  }
}

