import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getServiceRadiusCompanyById = data => get(url.GET_SERVICE_RADIUS_COMPANY_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delServiceRadiusCompany = data => del(url.DELETE_SERVICE_RADIUS_COMPANY.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateServiceRadiusCompany = data => post(url.POST_CREATE_SERVICE_RADIUS_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditServiceRadiusCompany = data => put(url.PUT_EDIT_SERVICE_RADIUS_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getServiceRadiusCompanyPaginate = ({company_id, page, limit}) => get(url.GET_SERVICE_RADIUS_COMPANY_PAGINATE, { params: {company_id, page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterServiceRadiusCompanyPaginate = data => get(url.GET_SERVICE_RADIUS_COMPANY_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getServiceRadiusCompanyPaginate,
    getFilterServiceRadiusCompanyPaginate,
    putEditServiceRadiusCompany,
    postCreateServiceRadiusCompany,
    delServiceRadiusCompany,
    getServiceRadiusCompanyById
}
