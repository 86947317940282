import {
    CREATE_PROMOTIONS,
    CREATE_PROMOTIONS_SUCCESS,
    EDIT_PROMOTIONS,
    EDIT_PROMOTIONS_SUCCESS,
    FIND_BY_ID_PROMOTIONS,
    FIND_BY_ID_PROMOTIONS_SUCCESS,
    UPLOAD_PROMOTIONS,
    UPLOAD_PROMOTIONS_SUCCESS,
    CREATE_OR_EDIT_PROMOTIONS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',

    status: 1,
    description: '',
    name: '',
    image: null,
    id: 0,
    type: 0,
}

const createOrEditPromotion = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_PROMOTIONS:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_PROMOTIONS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                loading: false
            }
            break
        case EDIT_PROMOTIONS:
            state = {...state, loading: true}
            break
        case EDIT_PROMOTIONS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case UPLOAD_PROMOTIONS:
            state = {...state, loading: true}
            break
        case UPLOAD_PROMOTIONS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_PROMOTIONS:
            state = {...state, loading: false, loading_form: true}
            break
        case FIND_BY_ID_PROMOTIONS_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,
                type: action.payload.result.type,
               
                status: action.payload.result.status,
                description: action.payload.result.description,
                name: action.payload.result.name,
                image: action.payload.result.image,
                id: action.payload.result.id,


            }
            break
        case CREATE_OR_EDIT_PROMOTIONS_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditPromotion
