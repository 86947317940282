import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

// Login Redux States
import {
    SEARCH_COMPANIES,
} from "./actionTypes"

import {
    searchCompaniesSuccess,
    apiCompaniesError
} from "./actions"

import {
    getSearchCompanies,
} from "../../../helpers/companies_helper"


function* searchCompanies({payload: {params}}) {
    try {

        const {results} = yield call(getSearchCompanies, params)

        yield put(searchCompaniesSuccess({
            data: results
        }))

    } catch (error) {
        yield put(apiCompaniesError(error))
    }
}


function* companiesSearchSaga() {
    yield takeEvery(SEARCH_COMPANIES, searchCompanies)
}

export default companiesSearchSaga
