import {
    PAGINATE_SPED_ITEM,
    PAGINATE_SPED_ITEM_SUCCESS,
    IMPORT_SPED_ITEM,
    IMPORT_SPED_ITEM_SUCCESS,
    FILTER_PAGINATE_SPED_ITEM,
    FILTER_PAGINATE_SPED_ITEM_SUCCESS,
    DELETE_SPED_ITEM_SUCCESS,
    DELETE_SPED_ITEM,
    API_ERROR_SPED_ITEM,
} from "./actionTypes"

export const importSpedItem = params => {
    return {
        type: IMPORT_SPED_ITEM,
        payload: {params},
    }
}

export const importSpedItemSuccess = params => {
    return {
        type: IMPORT_SPED_ITEM_SUCCESS,
        payload: {params},
    }
}

export const filtersAllSpedItemPaginate = params => {
    return {
        type: FILTER_PAGINATE_SPED_ITEM,
        payload: {params},
    }
}

export const filtersAllSpedItemPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_SPED_ITEM_SUCCESS,
        payload: {params},
    }
}

export const allSpedItemPaginate = params => {
    return {
        type: PAGINATE_SPED_ITEM,
        payload: {params},
    }
}

export const allSpedItemPaginateSuccess = result => {
    return {
        type: PAGINATE_SPED_ITEM_SUCCESS,
        payload: {result},
    }
}

export const deleteSpedItem = (id, history) => {
  return {
    type: DELETE_SPED_ITEM,
    payload: {id, history} ,
  }
}

export const deleteSpedItemSuccess = message => {
    return {
        type: DELETE_SPED_ITEM_SUCCESS,
        payload: message,
    }
}

export const apiPaginateSpedItemError = error => {
  return {
    type: API_ERROR_SPED_ITEM,
    payload: error,
  }
}

