import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_DISCOUNT_COUPONS,
    EDIT_DISCOUNT_COUPONS,
    FIND_BY_ID_DISCOUNT_COUPONS,
    UPLOAD_DISCOUNT_COUPONS
} from "./actionTypes"


import {
    createDiscountCouponsSuccess,
    editDiscountCouponsSuccess,
    findDiscountCouponsIdSuccess,
    uploadDiscountCouponsSuccess,
    apiCreateOrEditDiscountCouponsError
} from "./actions"


import {
    putEditDiscountCoupons,
    postCreateDiscountCoupons,
    getDiscountCouponsById,
    postUploadDiscountCoupons
} from "../../helpers/discount_coupons_helper"

function* findByIdDiscountCoupons({payload: {id}}) {
    try {

        const response = yield call(getDiscountCouponsById, id)

        yield put(findDiscountCouponsIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditDiscountCouponsError(error))
    }
}

function* createDiscountCoupons({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateDiscountCoupons, params)
        const {message, id, error} = response;

        yield put(createDiscountCouponsSuccess({message, id, error}))

        NotificationManager.info(message, 'Aviso');

        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditDiscountCouponsError(error))
    }
}

function* editDiscountCoupons({payload: {params,  history}}) {
    try {

        const response = yield call(putEditDiscountCoupons, params)

        yield put(editDiscountCouponsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditDiscountCouponsError(error))
    }
}

function* uploadDiscountCoupons({payload: {dataForm,  history}}) {
    try {

        const response = yield call(postUploadDiscountCoupons, dataForm)

        yield put(uploadDiscountCouponsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditDiscountCouponsError(error))
    }
}


function* createOrEditDiscountCouponsSaga() {
    yield takeEvery(CREATE_DISCOUNT_COUPONS, createDiscountCoupons)
    yield takeEvery(EDIT_DISCOUNT_COUPONS, editDiscountCoupons)
    yield takeEvery(FIND_BY_ID_DISCOUNT_COUPONS, findByIdDiscountCoupons)
    yield takeLatest(UPLOAD_DISCOUNT_COUPONS, uploadDiscountCoupons)
}

export default createOrEditDiscountCouponsSaga
