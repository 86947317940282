import {
    PAGINATE_PROMOTIONS,
    PAGINATE_PROMOTIONS_SUCCESS,
    FILTER_PAGINATE_PROMOTIONS,
    FILTER_PAGINATE_PROMOTIONS_SUCCESS,
    DELETE_PROMOTIONS_SUCCESS,
    DELETE_PROMOTIONS,
    API_ERROR_PROMOTIONS,
} from "./actionTypes"


export const filtersAllPromotionsPaginate = params => {
    return {
        type: FILTER_PAGINATE_PROMOTIONS,
        payload: {params},
    }
}

export const filtersAllPromotionsPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_PROMOTIONS_SUCCESS,
        payload: {params},
    }
}

export const allPromotionsPaginate = params => {
    return {
        type: PAGINATE_PROMOTIONS,
        payload: {params},
    }
}

export const allPromotionsPaginateSuccess = result => {
    return {
        type: PAGINATE_PROMOTIONS_SUCCESS,
        payload: {result},
    }
}

export const deletePromotions = (id, history) => {
  return {
    type: DELETE_PROMOTIONS,
    payload: {id, history} ,
  }
}

export const deletePromotionsSuccess = message => {
    return {
        type: DELETE_PROMOTIONS_SUCCESS,
        payload: message,
    }
}

export const apiPaginatePromotionsError = error => {
  return {
    type: API_ERROR_PROMOTIONS,
    payload: error,
  }
}

