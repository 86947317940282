import {
    CREATE_COMPANIES,
    CREATE_COMPANIES_SUCCESS,
    CREATE_CRT_COMPANIES,
    CREATE_CRT_COMPANIES_SUCCESS,
    EDIT_COMPANIES,
    EDIT_COMPANIES_SUCCESS,
    FIND_BY_ID_COMPANIES,
    FIND_BY_ID_COMPANIES_SUCCESS,
    FIND_CRT_COMPANIES,
    FIND_CRT_COMPANIES_SUCCESS,
    FIND_ABOUT_US_COMPANIES,
    FIND_ABOUT_US_COMPANIES_SUCCESS,
    UPLOAD_COMPANIES,
    UPLOAD_COMPANIES_SUCCESS,
    CREATE_OR_EDIT_COMPANIES_API_ERROR
} from "./actionTypes"

export const createCrtCompany = (params, existsFiles,   history) => {
    return {
        type: CREATE_CRT_COMPANIES,
        payload: {params,existsFiles,  history},
    }
}

export const createCrtCompanySuccess = params => {
    return {
        type: CREATE_CRT_COMPANIES_SUCCESS,
        payload: params,
    }
}

export const createCompany = (params, existsFiles,   history) => {
    return {
        type: CREATE_COMPANIES,
        payload: {params,existsFiles,  history},
    }
}

export const createCompanySuccess = params => {
    return {
        type: CREATE_COMPANIES_SUCCESS,
        payload: params,
    }
}

export const editCompany = (params, history) => {
    return {
        type: EDIT_COMPANIES,
        payload: {params, history},
    }
}

export const editCompanySuccess = result => {
    return {
        type: EDIT_COMPANIES_SUCCESS,
        payload: {result},
    }
}

export const findByIdCompany = id => {
    return {
        type: FIND_BY_ID_COMPANIES,
        payload: {id},
    }
}

export const findCompanyIdSuccess = result => {
    return {
        type: FIND_BY_ID_COMPANIES_SUCCESS,
        payload: result,
    }
}

export const findAboutUSCompany = id => {
    return {
        type: FIND_ABOUT_US_COMPANIES,
        payload: {id},
    }
}

export const findCompanyAboutUSSuccess = result => {
    return {
        type: FIND_ABOUT_US_COMPANIES_SUCCESS,
        payload: result,
    }
}

export const findCrtCompany = id => {
    return {
        type: FIND_CRT_COMPANIES,
        payload: {id},
    }
}

export const findCrtCompanySuccess = result => {
    return {
        type: FIND_CRT_COMPANIES_SUCCESS,
        payload: result,
    }
}

export const uploadCompany = ( dataForm,  history) => {
    return {
        type: UPLOAD_COMPANIES,
        payload: {dataForm, history},
    }
}

export const uploadCompanySuccess = result => {
    return {
        type: UPLOAD_COMPANIES_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditCompanyError = error => {
    return {
        type: CREATE_OR_EDIT_COMPANIES_API_ERROR,
        payload: error,
    }
}

