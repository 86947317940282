import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_CATEGORIES,
    EDIT_CATEGORIES,
    FIND_BY_ID_CATEGORIES,
    UPLOAD_CATEGORIES
} from "./actionTypes"


import {
    createCategoriesSuccess,
    editCategoriesSuccess,
    findCategoriesIdSuccess,
    uploadCategoriesSuccess,
    apiCreateOrEditCategoriesError
} from "./actions"


import {
    putEditCategory,
    postCreateCategory,
    getCategoryById,
    postUploadCategory
} from "../../helpers/categories_helper"

function* findByIdCategories({payload: {id}}) {
    try {

        const response = yield call(getCategoryById, id)

        yield put(findCategoriesIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditCategoriesError(error))
    }
}

function* createCategories({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateCategory, params)
        const {message, id, error} = response;

        yield put(createCategoriesSuccess({message, id, error}))

        NotificationManager.info(message, 'Aviso');

        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditCategoriesError(error))
    }
}

function* editCategories({payload: {params,  history}}) {
    try {

        const response = yield call(putEditCategory, params)

        yield put(editCategoriesSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditCategoriesError(error))
    }
}

function* uploadCategories({payload: {dataForm,  history}}) {
    try {



        console.log('uploadCategories Data EXISTS ID ',dataForm )

        const response = yield call(postUploadCategory, dataForm)

        yield put(uploadCategoriesSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditCategoriesError(error))
    }
}


function* createOrEditCategoriesSaga() {
    yield takeEvery(CREATE_CATEGORIES, createCategories)
    yield takeEvery(EDIT_CATEGORIES, editCategories)
    yield takeEvery(FIND_BY_ID_CATEGORIES, findByIdCategories)
    yield takeLatest(UPLOAD_CATEGORIES, uploadCategories)
}

export default createOrEditCategoriesSaga
