import {
    PAGINATE_TAB_CEST,
    PAGINATE_TAB_CEST_SUCCESS,
    IMPORT_TAB_CEST,
    IMPORT_TAB_CEST_SUCCESS,
    FILTER_PAGINATE_TAB_CEST,
    FILTER_PAGINATE_TAB_CEST_SUCCESS,
    DELETE_TAB_CEST_SUCCESS,
    DELETE_TAB_CEST,
    API_ERROR_TAB_CEST,
} from "./actionTypes"

export const importTabCEST = params => {
    return {
        type: IMPORT_TAB_CEST,
        payload: {params},
    }
}

export const importTabCESTSuccess = params => {
    return {
        type: IMPORT_TAB_CEST_SUCCESS,
        payload: {params},
    }
}

export const filtersAllTabCESTPaginate = params => {
    return {
        type: FILTER_PAGINATE_TAB_CEST,
        payload: {params},
    }
}

export const filtersAllTabCESTPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_TAB_CEST_SUCCESS,
        payload: {params},
    }
}

export const allTabCESTPaginate = params => {
    return {
        type: PAGINATE_TAB_CEST,
        payload: {params},
    }
}

export const allTabCESTPaginateSuccess = result => {
    return {
        type: PAGINATE_TAB_CEST_SUCCESS,
        payload: {result},
    }
}

export const deleteTabCEST = (id, history) => {
  return {
    type: DELETE_TAB_CEST,
    payload: {id, history} ,
  }
}

export const deleteTabCESTSuccess = message => {
    return {
        type: DELETE_TAB_CEST_SUCCESS,
        payload: message,
    }
}

export const apiPaginateTabCESTError = error => {
  return {
    type: API_ERROR_TAB_CEST,
    payload: error,
  }
}

