import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    IMPORT_GOOGLE_MERCHANT,
    PAGINATE_GOOGLE_MERCHANT,
    FILTER_PAGINATE_GOOGLE_MERCHANT,
    DELETE_GOOGLE_MERCHANT,
} from "./actionTypes"

import {
    allTabGMerchantCategoryPaginateSuccess,
    filtersAllTabGMerchantCategoryPaginateSuccess,
    deleteTabGMerchantCategorySuccess,
    importTabGMerchantCategorySuccess,
    apiPaginateTabGMerchantCategoryError
} from "./actions"


import {
    getTabGMerchantCategoryPaginate,
    getFilterTabGMerchantCategoryPaginate,
    importTabGMerchantCategoryAPI,
    delTabGMerchantCategory
} from "../../../helpers/tab_category_google_merchant_helper"
import { NotificationManager } from "react-notifications";

function* importTabGMerchantCategory({ payload: { params } }) {
    try {

        const response = yield call(importTabGMerchantCategoryAPI, params)

        yield put(importTabGMerchantCategorySuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabGMerchantCategoryError(error))
    }
}

function* deleteTabGMerchantCategory({ payload: { id, history } }) {
    try {

        const response = yield call(delTabGMerchantCategory, id)

        yield put(deleteTabGMerchantCategorySuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabGMerchantCategoryError(error))
    }
}

function* paginateTabGMerchantCategory({ payload: { params } }) {
    try {

        const response = yield call(getTabGMerchantCategoryPaginate, params)

        yield put(allTabGMerchantCategoryPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabGMerchantCategoryError(error))
    }
}

function* filtersPaginateTabGMerchantCategory({ payload: { params } }) {
    try {

        const response = yield call(getFilterTabGMerchantCategoryPaginate, params)

        yield put(filtersAllTabGMerchantCategoryPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabGMerchantCategoryError(error))
    }
}


function* TabGMerchantCategorySaga() {
    yield takeEvery(PAGINATE_GOOGLE_MERCHANT, paginateTabGMerchantCategory)
    yield takeEvery(FILTER_PAGINATE_GOOGLE_MERCHANT, filtersPaginateTabGMerchantCategory)
    yield takeEvery(DELETE_GOOGLE_MERCHANT, deleteTabGMerchantCategory)
    yield takeEvery(IMPORT_GOOGLE_MERCHANT, importTabGMerchantCategory)
}

export default TabGMerchantCategorySaga
