import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getDriversCompanyById = data => get(url.GET_DRIVERS_COMPANY_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delDriversCompany = data => del(url.DELETE_DRIVERS_COMPANY.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateDriversCompany = data => post(url.POST_CREATE_DRIVERS_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditDriversCompany = data => put(url.PUT_EDIT_DRIVERS_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getDriversCompanyPaginate = ({company_id, page, limit}) => get(url.GET_DRIVERS_COMPANY_PAGINATE, { params: {company_id, page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterDriversCompanyPaginate = data => get(url.GET_DRIVERS_COMPANY_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getDriversCompanyPaginate,
    getFilterDriversCompanyPaginate,
    putEditDriversCompany,
    postCreateDriversCompany,
    delDriversCompany,
    getDriversCompanyById
}
