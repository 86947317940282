export const PAGINATE_COMPANY = "PAGINATE_COMPANY"
export const PAGINATE_COMPANY_SUCCESS = "PAGINATE_COMPANY_SUCCESS"

export const FILTER_PAGINATE_COMPANY = "FILTER_PAGINATE_COMPANY"
export const FILTER_PAGINATE_COMPANY_SUCCESS = "FILTER_PAGINATE_COMPANY_SUCCESS"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"

export const API_ERROR_COMPANY = "PAGINATE_COMPANY_API_ERROR"
