import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_CNAE,
    FILTER_PAGINATE_NF_CNAE,
    DELETE_NF_CNAE,
} from "./actionTypes"

import {
    allNFCnaePaginateSuccess,
    filtersAllNFCnaePaginateSuccess,
    deleteNFCnaeSuccess,
    apiPaginateNFCnaeError
} from "./actions"


import {
    getNFCnaePaginate,
    getFilterNFCnaePaginate,
    delNFCnae
} from "../../../helpers/nf_cnae_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFCnae({payload: {id, history}}) {
    try {

        const response = yield call(delNFCnae, id)

        yield put(deleteNFCnaeSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFCnaeError(error))
    }
}

function* paginateNFCnae({payload: {params}}) {
    try {

        const response = yield call(getNFCnaePaginate, params)

        yield put(allNFCnaePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFCnaeError(error))
    }
}

function* filtersPaginateNFCnae({payload: {params}}) {
    try {

        const response = yield call(getFilterNFCnaePaginate, params)

        yield put(filtersAllNFCnaePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFCnaeError(error))
    }
}


function* NFCnaeSaga() {
    yield takeEvery(PAGINATE_NF_CNAE, paginateNFCnae)
    yield takeEvery(FILTER_PAGINATE_NF_CNAE, filtersPaginateNFCnae)
    yield takeEvery(DELETE_NF_CNAE, deleteNFCnae)
}

export default NFCnaeSaga
