export const PAGINATE_DISCOUNT_COUPONS_COMPANY = "PAGINATE_DISCOUNT_COUPONS_COMPANY"
export const PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS = "PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS"

export const FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY = "FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY"
export const FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS = "FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS"

export const DELETE_DISCOUNT_COUPONS_COMPANY = "DELETE_DISCOUNT_COUPONS_COMPANY"
export const DELETE_DISCOUNT_COUPONS_COMPANY_SUCCESS = "DELETE_DISCOUNT_COUPONS_COMPANY_SUCCESS"

export const API_ERROR_LIST_DISCOUNT_COUPONS_COMPANY = "PAGINATE_DISCOUNT_COUPONS_COMPANY_API_ERROR"
