import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_STATE,
    FILTER_PAGINATE_STATE,
    DELETE_STATE,
} from "./actionTypes"

import {
    allStatePaginateSuccess,
    filtersAllStatePaginateSuccess,
    deleteStateSuccess,
    apiPaginateStateError
} from "./actions"


import {
    getStatesPaginate,
    getFilterStatesPaginate,
    delState
} from "../../../helpers/state_helper"
import {NotificationManager} from "react-notifications";

function* deleteState({payload: {id, history}}) {
    try {

        const response = yield call(delState, id)

        yield put(deleteStateSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateStateError(error))
    }
}

function* paginateStates({payload: {params}}) {
    try {

        const response = yield call(getStatesPaginate, params)

        yield put(allStatePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateStateError(error))
    }
}

function* filtersPaginateStates({payload: {params}}) {
    try {

        const response = yield call(getFilterStatesPaginate, params)

        yield put(filtersAllStatePaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateStateError(error))
    }
}


function* StateSaga() {
    yield takeEvery(PAGINATE_STATE, paginateStates)
    yield takeEvery(FILTER_PAGINATE_STATE, filtersPaginateStates)
    yield takeEvery(DELETE_STATE, deleteState)
}

export default StateSaga
