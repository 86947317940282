import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_PROMOTIONS,
    FILTER_PAGINATE_PROMOTIONS,
    DELETE_PROMOTIONS,
} from "./actionTypes"

import {
    allPromotionsPaginateSuccess,
    filtersAllPromotionsPaginateSuccess,
    deletePromotionsSuccess,
    apiPaginatePromotionsError
} from "./actions"


import {
    getPromotionsPaginate,
    getFilterPromotionsPaginate,
    delPromotions
} from "../../../helpers/promotions_helper"
import {NotificationManager} from "react-notifications";

function* deletePromotions({payload: {id, history}}) {
    try {

        const response = yield call(delPromotions, id)

        yield put(deletePromotionsSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginatePromotionsError(error))
    }
}

function* paginatePromotions({payload: {params}}) {
    try {

        const response = yield call(getPromotionsPaginate, params)

        yield put(allPromotionsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePromotionsError(error))
    }
}

function* filtersPaginatePromotions({payload: {params}}) {
    try {

        const response = yield call(getFilterPromotionsPaginate, params)

        yield put(filtersAllPromotionsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePromotionsError(error))
    }
}


function* PromotionsSaga() {
    yield takeEvery(PAGINATE_PROMOTIONS, paginatePromotions)
    yield takeEvery(FILTER_PAGINATE_PROMOTIONS, filtersPaginatePromotions)
    yield takeEvery(DELETE_PROMOTIONS, deletePromotions)
}

export default PromotionsSaga
