import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_MODELO,
    FILTER_PAGINATE_NF_MODELO,
    DELETE_NF_MODELO,
} from "./actionTypes"

import {
    allNFModeloPaginateSuccess,
    filtersAllNFModeloPaginateSuccess,
    deleteNFModeloSuccess,
    apiPaginateNFModeloError
} from "./actions"


import {
    getNFModeloPaginate,
    getFilterNFModeloPaginate,
    delNFModelo
} from "../../../helpers/nf_modelo_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFModelo({payload: {id, history}}) {
    try {

        const response = yield call(delNFModelo, id)

        yield put(deleteNFModeloSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFModeloError(error))
    }
}

function* paginateNFModelo({payload: {params}}) {
    try {

        const response = yield call(getNFModeloPaginate, params)

        yield put(allNFModeloPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFModeloError(error))
    }
}

function* filtersPaginateNFModelo({payload: {params}}) {
    try {

        const response = yield call(getFilterNFModeloPaginate, params)

        yield put(filtersAllNFModeloPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFModeloError(error))
    }
}


function* NFModeloSaga() {
    yield takeEvery(PAGINATE_NF_MODELO, paginateNFModelo)
    yield takeEvery(FILTER_PAGINATE_NF_MODELO, filtersPaginateNFModelo)
    yield takeEvery(DELETE_NF_MODELO, deleteNFModelo)
}

export default NFModeloSaga
