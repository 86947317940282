import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    IMPORT_SPED_ITEM,
    PAGINATE_SPED_ITEM,
    FILTER_PAGINATE_SPED_ITEM,
    DELETE_SPED_ITEM,
} from "./actionTypes"

import {
    allSpedItemPaginateSuccess,
    filtersAllSpedItemPaginateSuccess,
    deleteSpedItemSuccess,
    importSpedItemSuccess,
    apiPaginateSpedItemError
} from "./actions"


import {
    getSpedItemPaginate,
    getFilterSpedItemPaginate,
    importSpedItemAPI,
    delSpedItem
} from "../../../helpers/sped_item_helper"
import { NotificationManager } from "react-notifications";

function* importSpedItem({ payload: { params } }) {
    try {

        const response = yield call(importSpedItemAPI, params)

        yield put(importSpedItemSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateSpedItemError(error))
    }
}

function* deleteSpedItem({ payload: { id, history } }) {
    try {

        const response = yield call(delSpedItem, id)

        yield put(deleteSpedItemSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateSpedItemError(error))
    }
}

function* paginateSpedItem({ payload: { params } }) {
    try {

        const response = yield call(getSpedItemPaginate, params)

        yield put(allSpedItemPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSpedItemError(error))
    }
}

function* filtersPaginateSpedItem({ payload: { params } }) {
    try {

        const response = yield call(getFilterSpedItemPaginate, params)

        yield put(filtersAllSpedItemPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSpedItemError(error))
    }
}


function* SpedItemSaga() {
    yield takeEvery(PAGINATE_SPED_ITEM, paginateSpedItem)
    yield takeEvery(FILTER_PAGINATE_SPED_ITEM, filtersPaginateSpedItem)
    yield takeEvery(DELETE_SPED_ITEM, deleteSpedItem)
    yield takeEvery(IMPORT_SPED_ITEM, importSpedItem)
}

export default SpedItemSaga
