import React from 'react';
import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createProduct } from "../../../../store/categories/options/products/actions"
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames"
//import IntlCurrencyInput from "react-intl-currency-input"
import InputMask from 'react-input-mask';
import { NotificationManager } from "react-notifications";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Form, Stack } from "react-bootstrap";

import Dropzone from "react-dropzone";
import ButtonCustom from "../../../../components/ButtonCustom";
import ReactDrawer from "react-drawer";
import FormProductComplement from "./complements/FormProductComplement";


/*const currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};*/

class FormProductGeneric extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            components: [],
            name: '',
            description: '',
            sku: '',
            price: null,
            image: '',
            product_id: 0,
            is_product: 0,
            activeTab: "1",
            has_addons: 0,
            file: null,
            existsFile: false,

            open: false,
            position: 'right',
            noOverlay: false,

            isValidName: false,
            isValidPrice: false,

        }
        // this.handleRadioButtom = this.handleRadioButtom.bind(this);
        this.handleFields = this.handleFields.bind(this);
        // this.handleRadioFields = this.handleRadioFields.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleValidFields = this.handleValidFields.bind(this)

        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.setComponents = this.setComponents.bind(this);
        this.setNoOverlay = this.setNoOverlay.bind(this);

    }


    handleValidFields() {

        console.log("TAB ", this.state.activeTab)
        if (parseInt(this.state.activeTab) === 1) {
            if (this.state.name === '' || this.state.name === null) {

                this.setState({ isValidName: !this.state.isValidName });
                return false;
            } else {
                this.setState({ isValidName: !this.state.isValidName });
                return true;
            }
        }
        if (parseInt(this.state.activeTab) === 2) {
            if (this.state.price === '' || this.state.price === null) {
                this.setState({ isValidPrice: !this.state.isValidPrice });
                return false;
            } else {
                this.setState({ isValidPrice: !this.state.isValidPrice });
                return true;
            }
        }

        return false;
    }

    setPosition(e) {
        this.setState({ position: e.target.value });
    }

    setNoOverlay(e) {
        this.setState({ noOverlay: e.target.checked });
    }

    toggleDrawer() {
        this.setState({ open: !this.state.open });
    }

    closeDrawer() {
        this.setState({ open: false });
    }

    onDrawerClose() {
        this.setState({ open: false });
    }
    /* handleRadioButtom(event) {
 
         this.setState({ is_product: event.target.value })
 
     }*/
    handleValidSubmit() {
        const { entity_id } = this.props.match.params

        if (parseInt(this.state.has_addons) === 1 && this.state.components.length === 0) {
            NotificationManager.info('Informe os complementos do produto obrigatório, para continuar.', 'Aviso!');

            return;
        }

        this.props.createProduct({
            sku: this.state.sku,
            description: this.state.description,
            menu_category_id: entity_id,
            has_addons: this.state.has_addons,
            is_product: this.state.is_product,
            name: this.state.name,
            price: this.state.price,
            files: this.state.file,
            components: this.state.components,
        }, this.props.history)



    }

    setComponents(data) {
        this.setState({ components: data })
        console.log("Chegou Aqui ", data)
        this.onDrawerClose()
    }


    /* handleRadioFields(event) {
         const { name, value } = event.target
         this.setState({ has_addons: parseInt(value) })
 
     }*/

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        console.log('handlefield ', tempState)

        this.setState(tempState)
    }

    handleDrop(e) {

        let reader = new FileReader();
        let file = e[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)

    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            })
        }
    }


    render() {

        return (
            <div>
                <Card>
                    <CardBody>
                        <Row className="align-items-md-center  mb-3">
                            <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                    <h4 className="card-title mb-4">{this.state.name ? this.state.name : 'Sabor ou nome do produto'}</h4>
                                </div>
                                <div className="text-md-right ms-auto p-2">
                                    <i onClick={this.props.closeLayoutDrawer} className="bx bx-x"></i>
                                </div>
                            </Col>
                        </Row>

                        <ul className="nav nav-tabs nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: parseInt(this.state.activeTab) === 1,
                                    })}
                                >
                                    Detalhes
                                </NavLink>
                            </NavItem>
                            {/*<NavItem>
                                <NavLink
                                    className={classnames({
                                        active: this.state.activeTab === "2",
                                    })}
                                >
                                    {this.props.t("Price")}
                                </NavLink>
                            </NavItem>*/}
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active: parseInt(this.state.activeTab) === 2,
                                    })}
                                >
                                    Complementos
                                </NavLink>
                            </NavItem>
                        </ul>
                        <TabContent
                            activeTab={this.state.activeTab}
                            className="py-4"
                        >
                            <TabPane tabId="1">
                                <PerfectScrollbar style={{ height: "410px" }}>
                                    <Row>
                                        <Col sm={7}>
                                            <Row>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2">
                                                        <label htmlFor=""
                                                            className="control-label">Sabor ou Nome</label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Informe o sabor"
                                                            name="name"
                                                            isInvalid={this.state.isValidName}
                                                            onChange={this.handleFields}
                                                            value={this.state.name} />

                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2 mb-2">
                                                        <label htmlFor=""
                                                            className="control-label">Descrição</label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={8}
                                                            className="form-control"
                                                            placeholder="Descrição do produto"
                                                            name="description"
                                                            onChange={this.handleFields}
                                                            value={this.state.description} />

                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className="form-group">
                                                        <label htmlFor=""
                                                            className="control-label">Preço<small
                                                                className="text-muted"> (Obrigatório)</small></label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="00,00"
                                                            name="price"
                                                            as={InputMask}
                                                            mask={"99,99"}
                                                            isInvalid={this.state.isValidPrice}
                                                            onChange={(event) => {

                                                                const { name, value } = event.target
                                                                console.log('prod ', { name, value })
                                                                this.setState({ price: value })

                                                            }}
                                                            value={this.state.price} />
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mt-2"  >
                                                        <label htmlFor=""
                                                            className="control-label">É um produto?</label>

                                                        <Stack direction="horizontal" gap={2}>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="is_product"
                                                                    id="is_product"
                                                                    onChange={(event) => {
                                                                        this.handleFields(event)
                                                                    }}
                                                                    value="1"


                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="is_product"
                                                                >
                                                                    Sim
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="is_product"
                                                                    id="is_product"
                                                                    value="0"
                                                                    onChange={(event) => {
                                                                        this.handleFields(event)
                                                                    }}
                                                                    checked={this.state.is_product == 0}

                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="is_product"
                                                                >
                                                                    Não
                                                                </label>
                                                            </div>
                                                        </Stack>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={5}>
                                            <Row>
                                                <Col className="inner-custom-pagination d-flex">
                                                    <div className="d-inline">
                                                        <label
                                                            className="control-label">{this.props.t("Item image")}</label>

                                                    </div>
                                                </Col>
                                                <Col sm={12}>
                                                    <Dropzone onDrop={this.handleDrop}>

                                                        {({ getRootProps, getInputProps }) => (

                                                            <div {...getRootProps({ className: "dropzone" })}>
                                                                <input {...getInputProps()} />
                                                                {this.state.image !== ''
                                                                    ?
                                                                    <img className="thumbnail img-responsive img-icon-prod"
                                                                        src={this.state.image} />
                                                                    : <p style={{ marginTop: '40%' }}
                                                                        className="text-center">Arraste e
                                                                        solte ou
                                                                        clique
                                                                        para adicionar a imagem</p>
                                                                }

                                                            </div>

                                                        )}

                                                    </Dropzone>
                                                </Col>
                                                <Col sm={12}>
                                                    <div className="form-group mt-2 mb-2">
                                                        <label htmlFor=""
                                                            className="control-label">{this.props.t("SKU")}</label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="123"
                                                            name="sku"
                                                            onChange={this.handleFields}
                                                            value={this.state.sku} />

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </PerfectScrollbar>
                            </TabPane>
                            {/*<TabPane tabId="2">
                                <Row>

                                    <Col sm={12}>
                                        <div className="form-group">
                                            <label htmlFor=""
                                                className="control-label">{this.props.t("Price")} <small
                                                    className="text-muted"> ({this.props.t("Mandatory")})</small></label>
                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="00,00"
                                                        name="price"
                                                        as={InputMask}
                                                        mask={"99,99"}
                                                        isInvalid={this.state.isValidPrice}
                                                        onChange={(e, value, maskedValue) => {

                                                            const { name } = e.target
                                                            this.setState({ price: value })

                                                        }}
                                                        value={parseFloat(this.state.price).toFixed(2)} />
                                                    <IntlCurrencyInput
                                                        className="mb-2 form-control"
                                                        currency="BRL"
                                                        config={currencyConfig}
                                                        name="price"
                                                        isInvalid={this.state.isValidPrice}
                                                        onChange={(e, value, maskedValue) => {
                                                                  
                                                                    const {name} = e.target
                                                                    this.setState({price: value})

                                                                }}
                                                        value={this.state.price}/>
                                                </Col>
                                                <Col className="p-1">
                                                    <button
                                                        className="btn-outline-dark w-md rounded"
                                                        onClick={() => {


                                                        }}>
                                                        {this.props.t("Apply Discount")}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>
                            </TabPane>*/}
                            <TabPane tabId="2">
                                <PerfectScrollbar style={{ height: "410px" }}>
                                    <Row>

                                        <Col sm={12}>
                                            <div className="d-inline">
                                                <span className="h3 card-title">{this.props.t("Complements")}</span>
                                                <p className="card-title-desc mt-2">Seu item tem complementos pra ficar
                                                    ainda mais gostoso? Indique aqui.</p>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="card-header rounded">
                                                <span className="h3 card-title">{this.props.t("Options Select")}</span>
                                                <div className="form-group mt-4">
                                                    <Form.Check
                                                        type="radio"
                                                        checked={(this.state.has_addons === 0 ? true : false)}
                                                        label="Não, este item não tem complementos"
                                                        name="has_addons"
                                                        className="card-title-desc"
                                                        onChange={(event) => {

                                                            const { name, value } = event.target
                                                            console.log('handle add ', { name, value })

                                                            let tempState = {}
                                                            tempState[name] = parseInt(value)
                                                            this.setState(tempState)
                                                        }}
                                                        value="0" />

                                                </div>
                                                <div className="form-group">
                                                    <Form.Check
                                                        type="radio"
                                                        checked={(this.state.has_addons === 1 ? true : false)}
                                                        label="Sim, este item tem complementos"
                                                        className="card-title-desc"
                                                        name="has_addons"
                                                        onChange={(event) => {

                                                            const { name, value } = event.target
                                                            console.log('handle add ', { name, value })

                                                            let tempState = {}
                                                            tempState[name] = parseInt(value)
                                                            this.setState(tempState)
                                                        }}
                                                        value="1" />

                                                </div>
                                            </div>

                                        </Col>
                                        {
                                            parseInt(this.state.has_addons) === 1 &&
                                            <Col sm={12} className="mt-2">
                                                <button
                                                    onClick={this.toggleDrawer}
                                                    disabled={this.state.open && !this.state.noOverlay}
                                                    className="btn-dark btn-sm rounded text-center">
                                                    <i className="bx bx-plus"></i>
                                                    Cadastrar complementos do produto
                                                </button>
                                            </Col>
                                        }


                                    </Row>
                                </PerfectScrollbar>
                            </TabPane>
                        </TabContent>
                        <Row className="text-center fixed-bottom">
                            <Col className="p-2 mb-2">
                                <button
                                    className="btn btn-danger w-md"
                                    onClick={() => {
                                        if (parseInt(this.state.activeTab) === 2) {
                                            this.toggleTab((parseInt(this.state.activeTab) - 1).toString())
                                        } else {
                                            this.props.closeLayoutDrawer()
                                        }
                                    }}>
                                    {parseInt(this.state.activeTab) === 2 ? 'Voltar' : 'Cancelar'}
                                </button>
                            </Col>
                            <Col className="d-inline">

                                <ButtonCustom
                                    isLoading={false}
                                    size="sm"
                                    clazzContainer="text-center"
                                    clazz="btn btn-dark w-md"
                                    message_loaging="">
                                    <button
                                        disabled={!this.state.name.length >= 1}
                                        className="btn btn-dark w-md"
                                        onClick={() => {
                                            if (parseInt(this.state.activeTab) === 2) {
                                                this.handleValidSubmit()
                                            } else {
                                                if (this.handleValidFields()) {
                                                    this.toggleTab((parseInt(this.state.activeTab) + 1).toString())
                                                }

                                            }

                                        }}>
                                        {parseInt(this.state.activeTab) === 2 ? 'Salvar' : 'Continuar'}
                                    </button>
                                </ButtonCustom>

                            </Col>


                        </Row>
                    </CardBody>
                </Card>
                <ReactDrawer
                    open={this.state.open}
                    position={this.state.position}
                    onClose={this.onDrawerClose}
                    noOverlay={this.state.noOverlay}>

                    <FormProductComplement
                        onCreatedSub={this.setComponents}
                        closeLayoutDrawer={this.closeDrawer}
                    />
                </ReactDrawer>
            </div>

        )
    }


}

FormProductGeneric.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,

    rows: PropTypes.array,
    product_id: PropTypes.any,
    name: PropTypes.any,
    sku: PropTypes.any,
    qtd_flavors: PropTypes.any,
    qtd_pieces: PropTypes.any,
    status: PropTypes.any,
    id: PropTypes.any,
    menu_category_id: PropTypes.any,
    createProduct: PropTypes.func,

}
const mapStateToProps = state => {


    const {
        name,
        sku,
        qtd_flavors,
        qtd_pieces,
        status,
        menu_category_id,
        rows
    } = state.CreateOrEditSizes

    const {
        loading,
        refresh,
        product_id,
        id
    } = state.CreateOrEditProducts
    return {
        loading,
        refresh,
        name,
        product_id,
        id,
        sku,
        qtd_flavors,
        qtd_pieces,
        status,
        menu_category_id,
        rows
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createProduct,

})(
    (FormProductGeneric)
)))
