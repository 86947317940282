import {
    CREATE_PROMOTIONS,
    CREATE_PROMOTIONS_SUCCESS,
    EDIT_PROMOTIONS,
    EDIT_PROMOTIONS_SUCCESS,
    FIND_BY_ID_PROMOTIONS,
    FIND_BY_ID_PROMOTIONS_SUCCESS,
    UPLOAD_PROMOTIONS,
    UPLOAD_PROMOTIONS_SUCCESS,
    CREATE_OR_EDIT_PROMOTIONS_API_ERROR
} from "./actionTypes"


export const createPromotions = (params,  history) => {
    return {
        type: CREATE_PROMOTIONS,
        payload: {params,history},
    }
}

export const createPromotionsSuccess = params => {
    return {
        type: CREATE_PROMOTIONS_SUCCESS,
        payload: params,
    }
}

export const editPromotions = (params, history) => {
    return {
        type: EDIT_PROMOTIONS,
        payload: {params, history},
    }
}

export const editPromotionsSuccess = result => {
    return {
        type: EDIT_PROMOTIONS_SUCCESS,
        payload: {result},
    }
}

export const findByIdPromotions = id => {
    return {
        type: FIND_BY_ID_PROMOTIONS,
        payload: {id},
    }
}

export const findPromotionsIdSuccess = result => {
    return {
        type: FIND_BY_ID_PROMOTIONS_SUCCESS,
        payload: result,
    }
}

export const uploadPromotions = ( dataForm,  history) => {
    return {
        type: UPLOAD_PROMOTIONS,
        payload: {dataForm, history},
    }
}

export const uploadPromotionsSuccess = result => {
    return {
        type: UPLOAD_PROMOTIONS_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditPromotionsError = error => {
    return {
        type: CREATE_OR_EDIT_PROMOTIONS_API_ERROR,
        payload: error,
    }
}

