import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_PAYMENT_METHOD,
    EDIT_PAYMENT_METHOD,
    FIND_BY_ID_PAYMENT_METHOD,
    UPLOAD_PAYMENT_METHOD
} from "./actionTypes"


import {
    createPaymentMethodSuccess,
    editPaymentMethodSuccess,
    findPaymentMethodIdSuccess,
    uploadPaymentMethodSuccess,
    apiCreateOrEditPaymentMethodError
} from "./actions"


import {
    putEditPaymentMethod,
    postCreatePaymentMethod,
    getPaymentMethodById,
    postUploadPaymentMethod
} from "../../../helpers/payment_method_helper"

function* findByIdPaymentMethod({payload: {id}}) {
    try {

        const response = yield call(getPaymentMethodById, id)

        yield put(findPaymentMethodIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditPaymentMethodError(error))
    }
}

function* createPaymentMethod({payload: {params,  history}}) {
    try {

        const response = yield call(postCreatePaymentMethod, params)
        const {message, id, error} = response;

        yield put(createPaymentMethodSuccess({message, id, error}))

        NotificationManager.info(message, 'Aviso');

        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditPaymentMethodError(error))
    }
}

function* editPaymentMethod({payload: {params,  history}}) {
    try {

        const response = yield call(putEditPaymentMethod, params)

        yield put(editPaymentMethodSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditPaymentMethodError(error))
    }
}

function* uploadPaymentMethod({payload: {dataForm,  history}}) {
    try {

        const response = yield call(postUploadPaymentMethod, dataForm)

        yield put(uploadPaymentMethodSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditPaymentMethodError(error))
    }
}


function* createOrEditPaymentMethodSaga() {
    yield takeEvery(CREATE_PAYMENT_METHOD, createPaymentMethod)
    yield takeEvery(EDIT_PAYMENT_METHOD, editPaymentMethod)
    yield takeEvery(FIND_BY_ID_PAYMENT_METHOD, findByIdPaymentMethod)
    yield takeLatest(UPLOAD_PAYMENT_METHOD, uploadPaymentMethod)
}

export default createOrEditPaymentMethodSaga
