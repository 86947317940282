import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_PAYMENT_METHODS_COMPANY,
    EDIT_PAYMENT_METHODS_COMPANY,
} from "./actionTypes"


import {
    createPaymentMethodsCompanySuccess,
    editPaymentMethodsCompanySuccess,
    apiCreateOrEditPaymentMethodsCompanyError
} from "./actions"


import {
    putEditPaymentMethodsCompany,
    postCreatePaymentMethodsCompany,
} from "../../../../helpers/payment_methods_company_helper"


function* createPaymentMethodsCompany({payload: {params,  history}}) {
    try {

        const response = yield call(postCreatePaymentMethodsCompany, params)

        yield put(createPaymentMethodsCompanySuccess(response))
        const {message} = response;

        NotificationManager.info(message, 'Aviso');


    } catch (error) {
        yield put(apiCreateOrEditPaymentMethodsCompanyError(error))
    }
}

function* editPaymentMethodsCompany({payload: {params,   history}}) {
    try {

        const response = yield call(putEditPaymentMethodsCompany, params)

        yield put(editPaymentMethodsCompanySuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditPaymentMethodsCompanyError(error))
    }
}



function* createOrEditPaymentMethodsCompanySaga() {
    yield takeEvery(CREATE_PAYMENT_METHODS_COMPANY, createPaymentMethodsCompany)
    yield takeEvery(EDIT_PAYMENT_METHODS_COMPANY, editPaymentMethodsCompany)
}

export default createOrEditPaymentMethodsCompanySaga
