import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_PRODUCTS,
    EDIT_PRODUCTS,
    FIND_PRODUCT_GENERIC_BY_ID_CATEGORY,
    FIND_PRODUCT_SIZES_BY_ID,
    UPLOAD_PRODUCTS
} from "./actionTypes"


import {
    createProductSuccess,
    editProductSuccess,
    findProductGenericSuccess,
    findProductSizesSuccess,
    uploadProductsSuccess,
    apiCreateOrEditProductError
} from "./actions"


import {
    putEditProduct,
    postCreateProduct,
    postUploadProduct,
    getProductPizzaById,
    getProductGenericById
} from "../../../../helpers/products_helper"


function* uploadProducts({payload: {dataForm,  history}}) {
    try {


        const response = yield call(postUploadProduct, dataForm)

        yield put(uploadProductsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditCategoriesError(error))
    }
}

function* findProductGeneric({payload: {id}}) {
    try {

        const response = yield call(getProductGenericById, id)

        yield put(findProductGenericSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditProductError(error))
    }
}
function* findProduct({payload: {id}}) {
    try {

        const response = yield call(getProductPizzaById, id)

        yield put(findProductSizesSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditProductError(error))
    }
}
function* createProducts({payload: {params}}) {
    try {


        var data = new FormData();
        data.append('sku', params.sku);
        data.append('description', params.description);
        data.append('menu_category_id', params.menu_category_id);
        data.append('name', params.name);

        if(params.has_addons !== undefined) {
            data.append('has_addons', params.has_addons);
        }
        if(params.is_product !== undefined) {
            data.append('is_product', params.is_product);
        }
        if(params.price !== undefined) {
            data.append('price', params.price);
        }
        if(params.components !== undefined) {
            for (let i = 0; i < params.components.length; i++) {
                for (let key of Object.keys(params.components[i])) {
                    data.append(`components[${i}][${key}]`, JSON.stringify(params.components[i][key]));
                    
                }
            }
        }

      if(params.files !== undefined) {
            data.append('files', params.files);
        }

        if(params.sizes !== undefined){
            for (let i = 0; i < params.sizes.length; i++) {
                for (let key of Object.keys(params.sizes[i])) {
                    data.append(`sizes[${i}][${key}]`, params.sizes[i][key]);
                }
            }
        }



        const response = yield call(postCreateProduct, data)
        const {message, id, error} = response;

        yield put(createProductSuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        //history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditProductError(error))
    }
}

function* editProducts({payload: {params}}) {
    try {
        /*console.log("Params ", params)
        let data = new FormData();
        data.append('id', params.id);
        data.append('sku', params.sku);
        data.append('description', params.description);
        data.append('menu_category_id', params.menu_category_id);
        data.append('name', params.name);

        if(params.has_addons !== undefined) {
            data.append('has_addons', params.has_addons);
        }
        if(params.price !== undefined) {
            data.append('price', params.price);
        }
        if(params.components !== undefined) {
            for (let i = 0; i < params.components.length; i++) {
                for (let key of Object.keys(params.components[i])) {
                    data.append(`components[${i}][${key}]`, params.components[i][key]);
                }
            }
        }

        if(params.files !== undefined) {
            data.append('files', params.files);
        }

        if(params.sizes !== undefined){
            for (let i = 0; i < params.sizes.length; i++) {
                for (let key of Object.keys(params.sizes[i])) {
                    data.append(`sizes[${i}][${key}]`, params.sizes[i][key]);
                }
            }
        }*/

        const response = yield call(putEditProduct, params)

        yield put(editProductSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        //history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditProductError(error))
    }
}



function* createOrEditProductsSaga() {
    yield takeEvery(CREATE_PRODUCTS, createProducts)
    yield takeEvery(EDIT_PRODUCTS, editProducts)
    yield takeEvery(FIND_PRODUCT_SIZES_BY_ID, findProduct)
    yield takeEvery(FIND_PRODUCT_GENERIC_BY_ID_CATEGORY, findProductGeneric)
    yield takeLatest(UPLOAD_PRODUCTS, uploadProducts)
}

export default createOrEditProductsSaga
