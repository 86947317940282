import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_SERVICE_RADIUS_COMPANY,
    EDIT_SERVICE_RADIUS_COMPANY,
} from "./actionTypes"


import {
    createServiceRadiusCompanySuccess,
    editServiceRadiusCompanySuccess,
    apiCreateOrEditServiceRadiusCompanyError
} from "./actions"


import {
    putEditServiceRadiusCompany,
    postCreateServiceRadiusCompany,
} from "../../../../helpers/service_radius_company_helper"


function* createServiceRadiusCompany({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateServiceRadiusCompany, params)
        const {message, id, error} = response;

        yield put(createServiceRadiusCompanySuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditServiceRadiusCompanyError(error))
    }
}

function* editServiceRadiusCompany({payload: {params,   history}}) {
    try {

        const response = yield call(putEditServiceRadiusCompany, params)

        yield put(editServiceRadiusCompanySuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditServiceRadiusCompanyError(error))
    }
}



function* createOrEditSchedulerCompanySaga() {
    yield takeEvery(CREATE_SERVICE_RADIUS_COMPANY, createServiceRadiusCompany)
    yield takeEvery(EDIT_SERVICE_RADIUS_COMPANY, editServiceRadiusCompany)
}

export default createOrEditSchedulerCompanySaga
