import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_CATEGORY,
    FILTER_PAGINATE_CATEGORY,
    DELETE_CATEGORY,
} from "./actionTypes"

import {
    allCategoriesPaginateSuccess,
    filtersAllCategoriesPaginateSuccess,
    deleteCategoriesSuccess,
    apiPaginateCategoriesError
} from "./actions"


import {
    getCategoriesPaginate,
    getFilterCategoriesPaginate,
    delCategory
} from "../../../helpers/categories_helper"
import {NotificationManager} from "react-notifications";

function* deleteCategory({payload: {id, history}}) {
    try {

        const response = yield call(delCategory, id)

        yield put(deleteCategoriesSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateCategoriesError(error))
    }
}

function* paginateCategories({payload: {params}}) {
    try {

        const response = yield call(getCategoriesPaginate, params)

        yield put(allCategoriesPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCategoriesError(error))
    }
}

function* filtersPaginateCategories({payload: {params}}) {
    try {

        const response = yield call(getFilterCategoriesPaginate, params)

        yield put(filtersAllCategoriesPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCategoriesError(error))
    }
}


function* CategorySaga() {
    yield takeEvery(PAGINATE_CATEGORY, paginateCategories)
    yield takeEvery(FILTER_PAGINATE_CATEGORY, filtersPaginateCategories)
    yield takeEvery(DELETE_CATEGORY, deleteCategory)
}

export default CategorySaga
