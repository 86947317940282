import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_EDGES,
    EDIT_EDGES,
} from "./actionTypes"


import {
    createEdgeSuccess,
    editEdgeSuccess,
    apiCreateOrEditEdgeError
} from "./actions"


import {
    putEditEdges,
    postCreateEdges,
} from "../../../../helpers/edges_helper"


function* createEdges({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateEdges, params)
        const {message, id, error} = response;

        yield put(createEdgeSuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditEdgeError(error))
    }
}

function* editEdges({payload: {params,   history}}) {
    try {

        const response = yield call(putEditEdges, params)

        yield put(editEdgeSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditEdgeError(error))
    }
}



function* createOrEditEdgesSaga() {
    yield takeEvery(CREATE_EDGES, createEdges)
    yield takeEvery(EDIT_EDGES, editEdges)
}

export default createOrEditEdgesSaga
