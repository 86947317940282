export const PAGINATE_PASTA = "PAGINATE_PASTA"
export const PAGINATE_PASTAS_SUCCESS = "PAGINATE_PASTA_SUCCESS"

export const FILTER_PAGINATE_PASTAS = "FILTER_PAGINATE_PASTA"
export const FILTER_PAGINATE_PASTAS_SUCCESS = "FILTER_PAGINATE_PASTA_SUCCESS"

export const DELETE_PASTAS = "DELETE_PASTA"
export const DELETE_PASTAS_SUCCESS = "DELETE_PASTA_SUCCESS"

export const API_ERROR_LIST_PASTAS = "PAGINATE_PASTA_API_ERROR"
