export const CREATE_DISCOUNT_COUPONS = "CREATE_DISCOUNT_COUPONS"
export const CREATE_DISCOUNT_COUPONS_SUCCESS = "CREATE_DISCOUNT_COUPONS_SUCCESS"

export const EDIT_DISCOUNT_COUPONS = "EDIT_DISCOUNT_COUPONS"
export const EDIT_DISCOUNT_COUPONS_SUCCESS = "EDIT_DISCOUNT_COUPONS_SUCCESS"

export const FIND_BY_ID_DISCOUNT_COUPONS = "FIND_BY_ID_DISCOUNT_COUPONS"
export const FIND_BY_ID_DISCOUNT_COUPONS_SUCCESS = "FIND_BY_ID_DISCOUNT_COUPONS_SUCCESS"

export const UPLOAD_DISCOUNT_COUPONS = "UPLOAD_DISCOUNT_COUPONS"
export const UPLOAD_DISCOUNT_COUPONS_SUCCESS = "UPLOAD_DISCOUNT_COUPONS_SUCCESS"

export const CREATE_OR_EDIT_DISCOUNT_COUPONS_API_ERROR = "CREATE_OR_EDIT_DISCOUNT_COUPONS_API_ERROR"
