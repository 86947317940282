import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

const getSettingById = data => get(url.GET_SETTINGS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSettingByKey = data => get(url.GET_SETTINGS_BY_KEY.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delSetting = data => del(url.DELETE_SETTINGS.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateSetting = data => post(url.POST_CREATE_SETTINGS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateOrEditSetting = data => post(url.POST_CREATE_OR_EDIT_SETTINGS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })
const putEditSetting = data => put(url.PUT_EDIT_SETTINGS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSettingsPaginate = ({page, limit, type}) => get(url.GET_SETTINGS_PAGINATE, { params: { page, limit, type} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterSettingsPaginate = data => get(url.GET_SETTINGS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getSettingsPaginate,
    getFilterSettingsPaginate,
    putEditSetting,
    postCreateOrEditSetting,
    postCreateSetting,
    delSetting,
    getSettingById,
    getSettingByKey
}
