import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumb";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Table from "./table/index";

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    id: 1,
                    name: 'Nome',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Status',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Ações',
                    status: 1
                },
            ],
        }

    }

    componentDidMount() {

    }

    render() {

        return (
           <React.Fragment>
               <div className="page-content">
                   <MetaTags>
                       <title>{this.props.t("Countries")}</title>
                   </MetaTags>
                   <Container fluid>
                       <Breadcrumbs title={this.props.t("Countries")} breadcrumbItem={this.props.t("List")}/>
                       <Row>
                           <Col sm={12}>
                               <Card>
                                   <CardBody>

                                       <Table
                                           route_edit="/edit-country"
                                           columns={this.state.columns}
                                       />
                                   </CardBody>
                               </Card>
                           </Col>
                       </Row>
                   </Container>

               </div>
           </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    type: PropTypes.string,
}
export default withRouter(withTranslation()(Index))
