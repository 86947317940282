import {
    CREATE_STATE,
    CREATE_STATE_SUCCESS,
    EDIT_STATE,
    EDIT_STATE_SUCCESS,
    FIND_BY_ID_STATE,
    FIND_BY_ID_STATE_SUCCESS,
    CREATE_OR_EDIT_STATE_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',
    name: '',
    prefix: '',
    ddd: '',
    country_id: null,
    country: null,
    status: 1,
    id: 0,
    lat: 0,
    lng: 0
}

const createOrEditState = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_STATE:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_STATE_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case EDIT_STATE:
            state = {...state, loading: true}
            break
        case EDIT_STATE_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_ID_STATE:
            state = {...state, loading: false, loading_form: true}
            break
        case FIND_BY_ID_STATE_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                country: action.payload.result.country.name,
                ddd: action.payload.result.ddd,
                country_id: action.payload.result.country_id,
                name: action.payload.result.name,
                prefix: action.payload.result.prefix,
                status: action.payload.result.status,
                lat: action.payload.result.lat,
                lng: action.payload.result.lng,
                id: action.payload.result.id,
            }
            break
        case CREATE_OR_EDIT_STATE_API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditState
