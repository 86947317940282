import {
    PAGINATE_NF_ORIGEM,
    PAGINATE_NF_ORIGEM_SUCCESS,
    FILTER_PAGINATE_NF_ORIGEM,
    FILTER_PAGINATE_NF_ORIGEM_SUCCESS,
    DELETE_NF_ORIGEM_SUCCESS,
    DELETE_NF_ORIGEM,
    API_ERROR_NF_ORIGEM,
} from "./actionTypes"


export const filtersAllNFOrigemPaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_ORIGEM,
        payload: {params},
    }
}

export const filtersAllNFOrigemPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_ORIGEM_SUCCESS,
        payload: {params},
    }
}

export const allNFOrigemPaginate = params => {
    return {
        type: PAGINATE_NF_ORIGEM,
        payload: {params},
    }
}

export const allNFOrigemPaginateSuccess = result => {
    return {
        type: PAGINATE_NF_ORIGEM_SUCCESS,
        payload: {result},
    }
}

export const deleteNFOrigem = (id, history) => {
  return {
    type: DELETE_NF_ORIGEM,
    payload: {id, history} ,
  }
}

export const deleteNFOrigemSuccess = message => {
    return {
        type: DELETE_NF_ORIGEM_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFOrigemError = error => {
  return {
    type: API_ERROR_NF_ORIGEM,
    payload: error,
  }
}

