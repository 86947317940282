import {
    PAGINATE_GOOGLE_MERCHANT,
    PAGINATE_GOOGLE_MERCHANT_SUCCESS,
    IMPORT_GOOGLE_MERCHANT,
    IMPORT_GOOGLE_MERCHANT_SUCCESS,
    FILTER_PAGINATE_GOOGLE_MERCHANT,
    FILTER_PAGINATE_GOOGLE_MERCHANT_SUCCESS,
    DELETE_GOOGLE_MERCHANT_SUCCESS,
    DELETE_GOOGLE_MERCHANT,
    API_ERROR_GOOGLE_MERCHANT,
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_delete: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0
}

const categoryGMerchant = (state = initialState, action) => {

    switch (action.type) {
        case PAGINATE_GOOGLE_MERCHANT:
            state = {
                ...state,
                loading: true
            }
            break
        case PAGINATE_GOOGLE_MERCHANT_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.result.current_page,
                last_page: action.payload.result.last_page,
                total: action.payload.result.total,
                from: action.payload.result.from,
                to: action.payload.result.to,
                rows: action.payload.result.data,
                loading: false
            }
            break
        case IMPORT_GOOGLE_MERCHANT:
            state = { ...state, loading: true }
            break
        case IMPORT_GOOGLE_MERCHANT_SUCCESS:
            state = { ...state, loading: false }
            break
        case FILTER_PAGINATE_GOOGLE_MERCHANT:
            state = { ...state, loading: true }
            break
        case FILTER_PAGINATE_GOOGLE_MERCHANT_SUCCESS:
            state = { ...state, loading: false }
            break
        case DELETE_GOOGLE_MERCHANT:
            state = { ...state, loading: false, loading_delete: true }
            break
        case DELETE_GOOGLE_MERCHANT_SUCCESS:
            state = { ...state, message: action.payload, loading: false, loading_delete: false }
            break
        case API_ERROR_GOOGLE_MERCHANT:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default categoryGMerchant
