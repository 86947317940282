import {
    CREATE_CATEGORIES,
    CREATE_CATEGORIES_SUCCESS,
    EDIT_CATEGORIES,
    EDIT_CATEGORIES_SUCCESS,
    FIND_BY_ID_CATEGORIES,
    FIND_BY_ID_CATEGORIES_SUCCESS,
    UPLOAD_CATEGORIES,
    UPLOAD_CATEGORIES_SUCCESS,
    CREATE_OR_EDIT_CATEGORIES_API_ERROR
} from "./actionTypes"


export const createCategories = (params,  history) => {
    return {
        type: CREATE_CATEGORIES,
        payload: {params,history},
    }
}

export const createCategoriesSuccess = params => {
    return {
        type: CREATE_CATEGORIES_SUCCESS,
        payload: params,
    }
}

export const editCategories = (params, history) => {
    return {
        type: EDIT_CATEGORIES,
        payload: {params, history},
    }
}

export const editCategoriesSuccess = result => {
    return {
        type: EDIT_CATEGORIES_SUCCESS,
        payload: {result},
    }
}

export const findByIdCategories = id => {
    return {
        type: FIND_BY_ID_CATEGORIES,
        payload: {id},
    }
}

export const findCategoriesIdSuccess = result => {
    return {
        type: FIND_BY_ID_CATEGORIES_SUCCESS,
        payload: result,
    }
}

export const uploadCategories = ( dataForm,  history) => {
    return {
        type: UPLOAD_CATEGORIES,
        payload: {dataForm, history},
    }
}

export const uploadCategoriesSuccess = result => {
    return {
        type: UPLOAD_CATEGORIES_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditCategoriesError = error => {
    return {
        type: CREATE_OR_EDIT_CATEGORIES_API_ERROR,
        payload: error,
    }
}

