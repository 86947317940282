import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const importSpedItemAPI = data => post(url.IMPORT_SPED_ITEM, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSpedItemById = data => get(url.GET_SPED_ITEM_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delSpedItem = data => del(url.DELETE_SPED_ITEM.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateSpedItem = data => post(url.POST_CREATE_SPED_ITEM, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditSpedItem = data => put(url.PUT_EDIT_SPED_ITEM, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSpedItemPaginate = ({ page, limit }) => get(url.GET_SPED_ITEM_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterSpedItemPaginate = data => get(url.GET_SPED_ITEM_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchSpedItem = params => get(url.GET_SEARCH_SPED_ITEM, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getSpedItemPaginate,
    getFilterSpedItemPaginate,
    putEditSpedItem,
    postCreateSpedItem,
    delSpedItem,
    getSpedItemById,
    getSearchSpedItem,
    importSpedItemAPI
}
