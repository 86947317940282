import {
    PAGINATE_PROMOTIONS,
    PAGINATE_PROMOTIONS_SUCCESS,
    FILTER_PAGINATE_PROMOTIONS,
    FILTER_PAGINATE_PROMOTIONS_SUCCESS,
    DELETE_PROMOTIONS_SUCCESS,
    DELETE_PROMOTIONS,
    API_ERROR_PROMOTIONS,
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_delete: false,
    rows: [],
    current_page: 0,
    last_page: 0,
    total: 0,
    from: 0,
    to: 0
}

const Promotion = (state = initialState, action) => {

    switch (action.type) {
        case PAGINATE_PROMOTIONS:
            state = {
                ...state,
                loading: true
            }
            break
        case PAGINATE_PROMOTIONS_SUCCESS:
            state = {
                ...state,
                current_page: action.payload.result.current_page,
                last_page: action.payload.result.last_page,
                total: action.payload.result.total,
                from: action.payload.result.from,
                to: action.payload.result.to,
                rows: action.payload.result.data,
                loading: false
            }
            break
        case FILTER_PAGINATE_PROMOTIONS:
            state = {...state, loading: true}
            break
        case FILTER_PAGINATE_PROMOTIONS_SUCCESS:
            state = {...state, loading: false}
            break
        case DELETE_PROMOTIONS:
            state = {...state, loading: false, loading_delete: true}
            break
        case DELETE_PROMOTIONS_SUCCESS:
            state = {...state, message: action.payload, loading: false, loading_delete: false}
            break
        case API_ERROR_PROMOTIONS:
            state = {...state, error: action.payload, loading: false}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default Promotion
