import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getNFTribCSOSNById = data => get(url.GET_NF_TRIB_CSOSN_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFTribCSOSN = data => del(url.DELETE_NF_TRIB_CSOSN.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFTribCSOSN = data => post(url.POST_CREATE_NF_TRIB_CSOSN, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFTribCSOSN = data => put(url.PUT_EDIT_NF_TRIB_CSOSN, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFTribCSOSNPaginate = ({page, limit}) => get(url.GET_NF_TRIB_CSOSN_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFTribCSOSNPaginate = data => get(url.GET_NF_TRIB_CSOSN_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFTribCSOSN = params => get(url.GET_SEARCH_NF_TRIB_CSOSN, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFTribCSOSNPaginate,
    getFilterNFTribCSOSNPaginate,
    putEditNFTribCSOSN,
    postCreateNFTribCSOSN,
    delNFTribCSOSN,
    getNFTribCSOSNById,
    getSearchNFTribCSOSN
}
