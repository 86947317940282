import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_COMPANY,
    FILTER_PAGINATE_COMPANY,
    DELETE_COMPANY,
} from "./actionTypes"

import {
    allCompanyPaginateSuccess,
    filtersAllCompanyPaginateSuccess,
    deleteCompanySuccess,
    apiPaginateCompanyError
} from "./actions"


import {
    getCompaniesPaginate,
    getFilterCompaniesPaginate,
    delCompany
} from "../../../helpers/companies_helper"
import {NotificationManager} from "react-notifications";

function* deleteCompany({payload: {id, history}}) {
    try {

        const response = yield call(delCompany, id)

        yield put(deleteCompanySuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateCompanyError(error))
    }
}

function* paginateCompanies({payload: {params}}) {
    try {

        const response = yield call(getCompaniesPaginate, params)

        yield put(allCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCompanyError(error))
    }
}

function* filtersPaginateCompanies({payload: {params}}) {
    try {

        const response = yield call(getFilterCompaniesPaginate, params)

        yield put(filtersAllCompanyPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateCompanyError(error))
    }
}


function* CompanySaga() {
    yield takeEvery(PAGINATE_COMPANY, paginateCompanies)
    yield takeEvery(FILTER_PAGINATE_COMPANY, filtersPaginateCompanies)
    yield takeEvery(DELETE_COMPANY, deleteCompany)
}

export default CompanySaga
