import {
    PAGINATE_DISCOUNT_COUPONS_COMPANY,
    PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
    FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY,
    FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
    DELETE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
    DELETE_DISCOUNT_COUPONS_COMPANY,
    API_ERROR_LIST_DISCOUNT_COUPONS_COMPANY,
} from "./actionTypes"


export const filtersAllCouponsDiscountCompanyPaginate = params => {
    return {
        type: FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY,
        payload: {params},
    }
}

export const filtersAllCouponsDiscountCompanyPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
        payload: {params},
    }
}

export const allCouponsDiscountCompanyPaginate = params => {
    return {
        type: PAGINATE_DISCOUNT_COUPONS_COMPANY,
        payload: {params},
    }
}

export const allCouponsDiscountCompanyPaginateSuccess = result => {
    return {
        type: PAGINATE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const deleteCouponsDiscountCompany = (id, history) => {
  return {
    type: DELETE_DISCOUNT_COUPONS_COMPANY,
    payload: {id, history} ,
  }
}

export const deleteCouponsDiscountCompanySuccess = message => {
    return {
        type: DELETE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
        payload: message,
    }
}

export const apiPaginateCouponsDiscountCompanyError = error => {
  return {
    type: API_ERROR_LIST_DISCOUNT_COUPONS_COMPANY,
    payload: error,
  }
}

