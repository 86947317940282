import {
    PAGINATE_GOOGLE_MERCHANT,
    PAGINATE_GOOGLE_MERCHANT_SUCCESS,
    IMPORT_GOOGLE_MERCHANT,
    IMPORT_GOOGLE_MERCHANT_SUCCESS,
    FILTER_PAGINATE_GOOGLE_MERCHANT,
    FILTER_PAGINATE_GOOGLE_MERCHANT_SUCCESS,
    DELETE_GOOGLE_MERCHANT_SUCCESS,
    DELETE_GOOGLE_MERCHANT,
    API_ERROR_GOOGLE_MERCHANT,
} from "./actionTypes"

export const importTabGMerchantCategory = params => {
    return {
        type: IMPORT_GOOGLE_MERCHANT,
        payload: {params},
    }
}

export const importTabGMerchantCategorySuccess = params => {
    return {
        type: IMPORT_GOOGLE_MERCHANT_SUCCESS,
        payload: {params},
    }
}

export const filtersAllTabGMerchantCategoryPaginate = params => {
    return {
        type: FILTER_PAGINATE_GOOGLE_MERCHANT,
        payload: {params},
    }
}

export const filtersAllTabGMerchantCategoryPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_GOOGLE_MERCHANT_SUCCESS,
        payload: {params},
    }
}

export const allTabGMerchantCategoryPaginate = params => {
    return {
        type: PAGINATE_GOOGLE_MERCHANT,
        payload: {params},
    }
}

export const allTabGMerchantCategoryPaginateSuccess = result => {
    return {
        type: PAGINATE_GOOGLE_MERCHANT_SUCCESS,
        payload: {result},
    }
}

export const deleteTabGMerchantCategory = (id, history) => {
  return {
    type: DELETE_GOOGLE_MERCHANT,
    payload: {id, history} ,
  }
}

export const deleteTabGMerchantCategorySuccess = message => {
    return {
        type: DELETE_GOOGLE_MERCHANT_SUCCESS,
        payload: message,
    }
}

export const apiPaginateTabGMerchantCategoryError = error => {
  return {
    type: API_ERROR_GOOGLE_MERCHANT,
    payload: error,
  }
}

