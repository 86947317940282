import {
    CREATE_SIZES,
    CREATE_SIZES_SUCCESS,
    EDIT_SIZES,
    EDIT_SIZES_SUCCESS,
    CREATE_OR_EDIT_SIZES_API_ERROR,
    FIND_SIZES_BY_ID_CATEGORIES,
    FIND_SIZES_BY_ID_CATEGORIES_SUCCESS
} from "./actionTypes"


export const createSize = (params, history) => {
    return {
        type: CREATE_SIZES,
        payload: {params, history},
    }
}

export const createSizeSuccess = params => {
    return {
        type: CREATE_SIZES_SUCCESS,
        payload: params,
    }
}

export const editSize = (params, history) => {
    return {
        type: EDIT_SIZES,
        payload: {params, history},
    }
}

export const editSizeSuccess = result => {
    return {
        type: EDIT_SIZES_SUCCESS,
        payload: {result},
    }
}

export const findSizesByIdCategories = id => {
    return {
        type: FIND_SIZES_BY_ID_CATEGORIES,
        payload: {id},
    }
}

export const findSizesByIdCategoriesSuccess = result => {
    return {
        type: FIND_SIZES_BY_ID_CATEGORIES_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditSizeError = error => {
    return {
        type: CREATE_OR_EDIT_SIZES_API_ERROR,
        payload: error,
    }
}

