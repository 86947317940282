export const PAGINATE_NF_TRIB_IPI = "PAGINATE_NF_TRIB_IPI"
export const PAGINATE_NF_TRIB_IPI_SUCCESS = "PAGINATE_NF_TRIB_IPI_SUCCESS"

export const FILTER_PAGINATE_NF_TRIB_IPI = "FILTER_PAGINATE_NF_TRIB_IPI"
export const FILTER_PAGINATE_NF_TRIB_IPI_SUCCESS = "FILTER_PAGINATE_NF_TRIB_IPI_SUCCESS"

export const DELETE_NF_TRIB_IPI = "DELETE_NF_TRIB_IPI"
export const DELETE_NF_TRIB_IPI_SUCCESS = "DELETE_NF_TRIB_IPI_SUCCESS"

export const API_ERROR_NF_TRIB_IPI = "PAGINATE_NF_TRIB_IPI_API_ERROR"
