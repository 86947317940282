export const CREATE_COUNTRY = "CREATE_COUNTRY"
export const CREATE_COUNTRY_SUCCESS = "CREATE_COUNTRY_SUCCESS"

export const EDIT_COUNTRY = "EDIT_COUNTRY"
export const EDIT_COUNTRY_SUCCESS = "EDIT_COUNTRY_SUCCESS"

export const FIND_BY_ID_COUNTRY = "FIND_BY_ID_COUNTRY"
export const FIND_BY_ID_COUNTRY_SUCCESS = "FIND_BY_ID_COUNTRY_SUCCESS"

export const CREATE_OR_EDIT_COUNTRY_API_ERROR = "CREATE_OR_EDIT_COUNTRY_API_ERROR"
