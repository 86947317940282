import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    IMPORT_TAB_CEST,
    PAGINATE_TAB_CEST,
    FILTER_PAGINATE_TAB_CEST,
    DELETE_TAB_CEST,
} from "./actionTypes"

import {
    allTabCESTPaginateSuccess,
    filtersAllTabCESTPaginateSuccess,
    deleteTabCESTSuccess,
    importTabCESTSuccess,
    apiPaginateTabCESTError
} from "./actions"


import {
    getTabCESTPaginate,
    getFilterTabCESTPaginate,
    importTabCESTAPI,
    delTabCEST
} from "../../../helpers/tab_cest_helper"
import { NotificationManager } from "react-notifications";

function* importTabCEST({ payload: { params } }) {
    try {

        const response = yield call(importTabCESTAPI, params)

        yield put(importTabCESTSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabCESTError(error))
    }
}

function* deleteTabCEST({ payload: { id, history } }) {
    try {

        const response = yield call(delTabCEST, id)

        yield put(deleteTabCESTSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabCESTError(error))
    }
}

function* paginateTabCEST({ payload: { params } }) {
    try {

        const response = yield call(getTabCESTPaginate, params)

        yield put(allTabCESTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabCESTError(error))
    }
}

function* filtersPaginateTabCEST({ payload: { params } }) {
    try {

        const response = yield call(getFilterTabCESTPaginate, params)

        yield put(filtersAllTabCESTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabCESTError(error))
    }
}


function* TabCESTSaga() {
    yield takeEvery(PAGINATE_TAB_CEST, paginateTabCEST)
    yield takeEvery(FILTER_PAGINATE_TAB_CEST, filtersPaginateTabCEST)
    yield takeEvery(DELETE_TAB_CEST, deleteTabCEST)
    yield takeEvery(IMPORT_TAB_CEST, importTabCEST)
}

export default TabCESTSaga
