export const PAGINATE_PAYMENT_METHOD = "PAGINATE_PAYMENT_METHOD"
export const PAGINATE_PAYMENT_METHOD_SUCCESS = "PAGINATE_PAYMENT_METHOD_SUCCESS"

export const FILTER_PAGINATE_PAYMENT_METHOD = "FILTER_PAGINATE_PAYMENT_METHOD"
export const FILTER_PAGINATE_PAYMENT_METHOD_SUCCESS = "FILTER_PAGINATE_PAYMENT_METHOD_SUCCESS"

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD"
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS"

export const API_ERROR_PAYMENT_METHOD = "PAGINATE_PAYMENT_METHOD_API_ERROR"
