export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD"
export const CREATE_PAYMENT_METHOD_SUCCESS = "CREATE_PAYMENT_METHOD_SUCCESS"

export const EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD"
export const EDIT_PAYMENT_METHOD_SUCCESS = "EDIT_PAYMENT_METHOD_SUCCESS"

export const FIND_BY_ID_PAYMENT_METHOD = "FIND_BY_ID_PAYMENT_METHOD"
export const FIND_BY_ID_PAYMENT_METHOD_SUCCESS = "FIND_BY_ID_PAYMENT_METHOD_SUCCESS"

export const UPLOAD_PAYMENT_METHOD = "UPLOAD_PAYMENT_METHOD"
export const UPLOAD_PAYMENT_METHOD_SUCCESS = "UPLOAD_PAYMENT_METHOD_SUCCESS"

export const CREATE_OR_EDIT_PAYMENT_METHOD_API_ERROR = "CREATE_OR_EDIT_PAYMENT_METHOD_API_ERROR"
