import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getCountryById = data => get(url.GET_COUNTRIES_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delCountry = data => del(url.DELETE_COUNTRY.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateCountry = data => post(url.POST_CREATE_COUNTRY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditCountry = data => put(url.PUT_EDIT_COUNTRY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getCountriesPaginate = ({page, limit}) => get(url.GET_COUNTRIES_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterCountriesPaginate = data => get(url.GET_COUNTRIES_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getCountriesPaginate,
    getFilterCountriesPaginate,
    putEditCountry,
    postCreateCountry,
    delCountry,
    getCountryById
}
