export const PAGINATE_GOOGLE_MERCHANT = "PAGINATE_GOOGLE_MERCHANT"
export const PAGINATE_GOOGLE_MERCHANT_SUCCESS = "PAGINATE_GOOGLE_MERCHANT_SUCCESS"

export const IMPORT_GOOGLE_MERCHANT = "IMPORT_GOOGLE_MERCHANT"
export const IMPORT_GOOGLE_MERCHANT_SUCCESS = "IMPORT_GOOGLE_MERCHANT_SUCCESS"


export const FILTER_PAGINATE_GOOGLE_MERCHANT = "FILTER_PAGINATE_GOOGLE_MERCHANT"
export const FILTER_PAGINATE_GOOGLE_MERCHANT_SUCCESS = "FILTER_PAGINATE_GOOGLE_MERCHANT_SUCCESS"

export const DELETE_GOOGLE_MERCHANT = "DELETE_GOOGLE_MERCHANT"
export const DELETE_GOOGLE_MERCHANT_SUCCESS = "DELETE_GOOGLE_MERCHANT_SUCCESS"

export const API_ERROR_GOOGLE_MERCHANT = "PAGINATE_GOOGLE_MERCHANT_API_ERROR"
