export const PAGINATE_EDGES = "PAGINATE_EDGES"
export const PAGINATE_EDGES_SUCCESS = "PAGINATE_EDGES_SUCCESS"

export const FILTER_PAGINATE_EDGES = "FILTER_PAGINATE_EDGES"
export const FILTER_PAGINATE_EDGES_SUCCESS = "FILTER_PAGINATE_EDGES_SUCCESS"

export const DELETE_EDGES = "DELETE_EDGES"
export const DELETE_EDGES_SUCCESS = "DELETE_EDGES_SUCCESS"

export const API_ERROR_LIST_EDGES = "PAGINATE_EDGES_API_ERROR"
