export const PAGINATE_NF_PIS = "PAGINATE_NF_PIS"
export const PAGINATE_NF_PIS_SUCCESS = "PAGINATE_NF_PIS_SUCCESS"

export const FILTER_PAGINATE_NF_PIS = "FILTER_PAGINATE_NF_PIS"
export const FILTER_PAGINATE_NF_PIS_SUCCESS = "FILTER_PAGINATE_NF_PIS_SUCCESS"

export const DELETE_NF_PIS = "DELETE_NF_PIS"
export const DELETE_NF_PIS_SUCCESS = "DELETE_NF_PIS_SUCCESS"

export const API_ERROR_NF_PIS = "PAGINATE_NF_PIS_API_ERROR"
