import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../../components/Breadcrumb";
import {connect} from "react-redux";
import {createOrEditSettings, findByKeySettings} from "../../../../store/actions"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../../components/ButtonCustom";
import EmailEditor from 'react-email-editor';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
           
        }

    

    }

    componentDidMount() {
     

    }

 
    onLoad = () => {
        // this.editor.addEventListener('onDesignLoad', this.onDesignLoad)
      //  this.editor.loadDesign(sample)
      }
      onDesignLoad = (data) => {
        console.log('onDesignLoad', data)
      }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("E-mail Templates")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("E-mail ")}
                                     breadcrumbItem={this.props.t("Config")}/>
                       
                    </Container>
                    <EmailEditor 
                     ref={editor => this.editor = editor}
                     onLoad={this.onLoad}
                     onDesignLoad={this.onDesignLoad}
                    
                    
                    />
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    

}


const mapStateToProps = state => {

    return {}
}

export default withTranslation()(withRouter(
    connect(mapStateToProps, {
     

    })(Index)
))

