import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getPastasById = data => get(url.GET_PASTAS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delPastas = data => del(url.DELETE_PASTAS.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreatePastas = data => post(url.POST_CREATE_PASTAS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditPastas = data => put(url.PUT_EDIT_PASTAS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getPastasPaginate = ({menu_category_id,page, limit}) => get(url.GET_PASTAS_PAGINATE, { params: { menu_category_id,page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterPastasPaginate = data => get(url.GET_PASTAS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getPastasPaginate,
    getFilterPastasPaginate,
    putEditPastas,
    postCreatePastas,
    delPastas,
    getPastasById
}
