import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { NotificationManager } from "react-notifications";


const getTipoNFById = data => get(url.GET_TIPO_NF_BY_ID.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delTipoNF = data => del(url.DELETE_TIPO_NF.concat('/').concat(data))
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateTipoNF = data => post(url.POST_CREATE_TIPO_NF, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditTipoNF = data => put(url.PUT_EDIT_TIPO_NF, data)
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getTipoNFPaginate = ({ page, limit }) => get(url.GET_TIPO_NF_PAGINATE, { params: { page, limit } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterTipoNFPaginate = data => get(url.GET_TIPO_NF_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const { title, message } = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchTipoNF = params => get(url.GET_SEARCH_TIPO_NF, { params: { query: params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getTipoNFPaginate,
    getFilterTipoNFPaginate,
    putEditTipoNF,
    postCreateTipoNF,
    delTipoNF,
    getTipoNFById,
    getSearchTipoNF
}
