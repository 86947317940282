import {
    SEARCH_CATEGORIES,
    SEARCH_CATEGORIES_SUCCESS,
    API_CATEGORIES_ERROR,
} from "./actionTypes"

export const searchCategories = params => {
    return {
        type: SEARCH_CATEGORIES,
        payload: {params},
    }
}

export const searchCategoriesSuccess = params => {
    return {
        type: SEARCH_CATEGORIES_SUCCESS,
        payload: params,
    }
}

export const apiCategoriesError = error => {
    return {
        type: API_CATEGORIES_ERROR,
        payload: error,
    }
}

