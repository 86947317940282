export const PAGINATE_SIZES = "PAGINATE_SIZES"
export const PAGINATE_SIZES_SUCCESS = "PAGINATE_SIZES_SUCCESS"

export const FILTER_PAGINATE_SIZES = "FILTER_PAGINATE_SIZES"
export const FILTER_PAGINATE_SIZES_SUCCESS = "FILTER_PAGINATE_SIZES_SUCCESS"

export const DELETE_SIZES = "DELETE_SIZES"
export const DELETE_SIZES_SUCCESS = "DELETE_SIZES_SUCCESS"

export const API_ERROR_LIST_SIZES = "PAGINATE_SIZES_API_ERROR"
