import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getEdgesById = data => get(url.GET_EDGES_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delEdges = data => del(url.DELETE_EDGES.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateEdges = data => post(url.POST_CREATE_EDGES, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditEdges = data => put(url.PUT_EDIT_EDGES, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getEdgesPaginate = ({menu_category_id, page, limit}) => get(url.GET_EDGES_PAGINATE, { params: {menu_category_id, page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterEdgesPaginate = data => get(url.GET_EDGES_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getEdgesPaginate,
    getFilterEdgesPaginate,
    putEditEdges,
    postCreateEdges,
    delEdges,
    getEdgesById
}
