export const PAGINATE_PRODUCTS = "PAGINATE_PRODUCTS"
export const PAGINATE_PRODUCTS_SUCCESS = "PAGINATE_PRODUCTS_SUCCESS"

export const FILTER_PAGINATE_PRODUCTS = "FILTER_PAGINATE_PRODUCTS"
export const FILTER_PAGINATE_PRODUCTS_SUCCESS = "FILTER_PAGINATE_PRODUCTS_SUCCESS"

export const DELETE_PRODUCTS = "DELETE_PRODUCTS"
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS"

export const API_ERROR_LIST_PRODUCTS = "PAGINATE_PRODUCTS_API_ERROR"
