export const PAGINATE_NF_TIPO_OPERACAO = "PAGINATE_NF_TIPO_OPERACAO"
export const PAGINATE_NF_TIPO_OPERACAO_SUCCESS = "PAGINATE_NF_TIPO_OPERACAO_SUCCESS"

export const FILTER_PAGINATE_NF_TIPO_OPERACAO = "FILTER_PAGINATE_NF_TIPO_OPERACAO"
export const FILTER_PAGINATE_NF_TIPO_OPERACAO_SUCCESS = "FILTER_PAGINATE_NF_TIPO_OPERACAO_SUCCESS"

export const DELETE_NF_TIPO_OPERACAO = "DELETE_NF_TIPO_OPERACAO"
export const DELETE_NF_TIPO_OPERACAO_SUCCESS = "DELETE_NF_TIPO_OPERACAO_SUCCESS"

export const API_ERROR_NF_TIPO_OPERACAO = "PAGINATE_NF_TIPO_OPERACAO_API_ERROR"
