import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_SIZES,
    EDIT_SIZES,
    FIND_SIZES_BY_ID_CATEGORIES
} from "./actionTypes"


import {
    createSizeSuccess,
    editSizeSuccess,
    apiCreateOrEditSizeError, findSizesByIdCategoriesSuccess
} from "./actions"


import {
    putEditSizes,
    postCreateSizes,
    getSizesByCategoryId
} from "../../../../helpers/sizes_helper"

function* findSizeByIdCategories({payload: {id}}) {
    try {

        const response = yield call(getSizesByCategoryId, id)

        yield put(findSizesByIdCategoriesSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditSizeError(error))
    }
}

function* createSizes({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateSizes, params)
        const {message, id, error} = response;

        yield put(createSizeSuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditSizeError(error))
    }
}

function* editSizes({payload: {params,   history}}) {
    try {

        const response = yield call(putEditSizes, params)

        yield put(editSizeSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditSizeError(error))
    }
}



function* createOrEditSizesSaga() {
    yield takeEvery(CREATE_SIZES, createSizes)
    yield takeEvery(EDIT_SIZES, editSizes)
    yield takeEvery(FIND_SIZES_BY_ID_CATEGORIES, findSizeByIdCategories)
}

export default createOrEditSizesSaga
