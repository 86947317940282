import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_PASTA,
    FILTER_PAGINATE_PASTAS,
    DELETE_PASTAS,
} from "./actionTypes"

import {
    allPastaPaginateSuccess,
    filtersAllPastaPaginateSuccess,
    deletePastaSuccess,
    apiPaginatePastaError
} from "./actions"


import {
    getPastasPaginate,
    getFilterPastasPaginate,
    delPastas
} from "../../../../../helpers/pastas_helper"
import {NotificationManager} from "react-notifications";

function* deletePasta({payload: {id, history}}) {
    try {

        const response = yield call(delPastas, id)

        yield put(deletePastaSuccess(response))

        const { message } = response;


        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginatePastaError(error))
    }
}

function* paginatePasta({payload: {params}}) {
    try {

        const response = yield call(getPastasPaginate, params)

        yield put(allPastaPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePastaError(error))
    }
}

function* filtersPaginatePasta({payload: {params}}) {
    try {

        const response = yield call(getFilterPastasPaginate, params)

        yield put(filtersAllPastaPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginatePastaError(error))
    }
}


function* PastasSaga() {
    yield takeEvery(PAGINATE_PASTA, paginatePasta)
    yield takeEvery(FILTER_PAGINATE_PASTAS, filtersPaginatePasta)
    yield takeEvery(DELETE_PASTAS, deletePasta)
}

export default PastasSaga
