import {
    PAGINATE_PAYMENT_METHOD,
    PAGINATE_PAYMENT_METHOD_SUCCESS,
    FILTER_PAGINATE_PAYMENT_METHOD,
    FILTER_PAGINATE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD,
    API_ERROR_PAYMENT_METHOD,
} from "./actionTypes"


export const filtersAllPaymentMethodsPaginate = params => {
    return {
        type: FILTER_PAGINATE_PAYMENT_METHOD,
        payload: {params},
    }
}

export const filtersAllPaymentMethodsPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_PAYMENT_METHOD_SUCCESS,
        payload: {params},
    }
}

export const allPaymentMethodsPaginate = params => {
    return {
        type: PAGINATE_PAYMENT_METHOD,
        payload: {params},
    }
}

export const allPaymentMethodsPaginateSuccess = result => {
    return {
        type: PAGINATE_PAYMENT_METHOD_SUCCESS,
        payload: {result},
    }
}

export const deletePaymentMethods = (id, history) => {
  return {
    type: DELETE_PAYMENT_METHOD,
    payload: {id, history} ,
  }
}

export const deletePaymentMethodsSuccess = message => {
    return {
        type: DELETE_PAYMENT_METHOD_SUCCESS,
        payload: message,
    }
}

export const apiPaginatePaymentMethodsError = error => {
  return {
    type: API_ERROR_PAYMENT_METHOD,
    payload: error,
  }
}

