import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_DRIVERS_COMPANY,
    EDIT_DRIVERS_COMPANY,
} from "./actionTypes"


import {
    createDriversCompanySuccess,
    editDriversCompanySuccess,
    apiCreateOrEditDriversCompanyError
} from "./actions"


import {
    putEditDriversCompany,
    postCreateDriversCompany,
} from "../../../../helpers/drivers_company_helper"


function* createDriversCompany({payload: {params,  history}}) {
    try {

        const response = yield call(postCreateDriversCompany, params)
        const {message, id, error} = response;

        yield put(createDriversCompanySuccess({message, id, error}))


        NotificationManager.info(message, 'Aviso');
        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditDriversCompanyError(error))
    }
}

function* editDriversCompany({payload: {params,   history}}) {
    try {

        const response = yield call(putEditDriversCompany, params)

        yield put(editDriversCompanySuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditDriversCompanyError(error))
    }
}



function* createOrEditDriversCompanySaga() {
    yield takeEvery(CREATE_DRIVERS_COMPANY, createDriversCompany)
    yield takeEvery(EDIT_DRIVERS_COMPANY, editDriversCompany)
}

export default createOrEditDriversCompanySaga
