import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_CRT,
    FILTER_PAGINATE_NF_CRT,
    DELETE_NF_CRT,
} from "./actionTypes"

import {
    allNFCrtPaginateSuccess,
    filtersAllNFCrtPaginateSuccess,
    deleteNFCrtSuccess,
    apiPaginateNFCrtError
} from "./actions"


import {
    getNFCrtPaginate,
    getFilterNFCrtPaginate,
    delNFCrt
} from "../../../helpers/nf_crt_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFCrt({payload: {id, history}}) {
    try {

        const response = yield call(delNFCrt, id)

        yield put(deleteNFCrtSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFCrtError(error))
    }
}

function* paginateNFCrt({payload: {params}}) {
    try {

        const response = yield call(getNFCrtPaginate, params)

        yield put(allNFCrtPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFCrtError(error))
    }
}

function* filtersPaginateNFCrt({payload: {params}}) {
    try {

        const response = yield call(getFilterNFCrtPaginate, params)

        yield put(filtersAllNFCrtPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFCrtError(error))
    }
}


function* NFCrtSaga() {
    yield takeEvery(PAGINATE_NF_CRT, paginateNFCrt)
    yield takeEvery(FILTER_PAGINATE_NF_CRT, filtersPaginateNFCrt)
    yield takeEvery(DELETE_NF_CRT, deleteNFCrt)
}

export default NFCrtSaga
