import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_PIS,
    FILTER_PAGINATE_NF_PIS,
    DELETE_NF_PIS,
} from "./actionTypes"

import {
    allNFPISPaginateSuccess,
    filtersAllNFPISPaginateSuccess,
    deleteNFPISSuccess,
    apiPaginateNFPISError
} from "./actions"


import {
    getNFPISPaginate,
    getFilterNFPISPaginate,
    delNFPIS
} from "../../../helpers/nf_pis_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFPIS({payload: {id, history}}) {
    try {

        const response = yield call(delNFPIS, id)

        yield put(deleteNFPISSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFPISError(error))
    }
}

function* paginateNFPIS({payload: {params}}) {
    try {

        const response = yield call(getNFPISPaginate, params)

        yield put(allNFPISPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFPISError(error))
    }
}

function* filtersPaginateNFPIS({payload: {params}}) {
    try {

        const response = yield call(getFilterNFPISPaginate, params)

        yield put(filtersAllNFPISPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFPISError(error))
    }
}


function* NFPISSaga() {
    yield takeEvery(PAGINATE_NF_PIS, paginateNFPIS)
    yield takeEvery(FILTER_PAGINATE_NF_PIS, filtersPaginateNFPIS)
    yield takeEvery(DELETE_NF_PIS, deleteNFPIS)
}

export default NFPISSaga
