import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";


const getDiscountCouponsCompanyById = data => get(url.GET_DISCOUNT_COUPOM_COMPANY_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delDiscountCouponsCompany = data => del(url.DELETE_DISCOUNT_COUPOM_COMPANY.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateDiscountCouponsCompany = data => post(url.POST_CREATE_DISCOUNT_COUPOM_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditDiscountCouponsCompany = data => put(url.PUT_EDIT_DISCOUNT_COUPOM_COMPANY, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getDiscountCouponsCompanyPaginate = ({company_id, page, limit}) => get(url.GET_DISCOUNT_COUPOM_COMPANY_PAGINATE, { params: {company_id, page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterDiscountCouponsCompanyPaginate = data => get(url.GET_DISCOUNT_COUPOM_COMPANY_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

export {
    getDiscountCouponsCompanyPaginate,
    getFilterDiscountCouponsCompanyPaginate,
    putEditDiscountCouponsCompany,
    postCreateDiscountCouponsCompany,
    delDiscountCouponsCompany,
    getDiscountCouponsCompanyById
}
