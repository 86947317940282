import {
    PAGINATE_CITY,
    PAGINATE_CITY_SUCCESS,
    FILTER_PAGINATE_CITY,
    FILTER_PAGINATE_CITY_SUCCESS,
    DELETE_CITY_SUCCESS,
    DELETE_CITY,
    API_ERROR_CITY,
} from "./actionTypes"


export const filtersAllCityPaginate = params => {
    return {
        type: FILTER_PAGINATE_CITY,
        payload: {params},
    }
}

export const filtersAllCityPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_CITY_SUCCESS,
        payload: {params},
    }
}

export const allCityPaginate = params => {
    return {
        type: PAGINATE_CITY,
        payload: {params},
    }
}

export const allCityPaginateSuccess = result => {
    return {
        type: PAGINATE_CITY_SUCCESS,
        payload: {result},
    }
}

export const deleteCity = (id, history) => {
  return {
    type: DELETE_CITY,
    payload: {id, history} ,
  }
}

export const deleteCitySuccess = message => {
    return {
        type: DELETE_CITY_SUCCESS,
        payload: message,
    }
}

export const apiPaginateCityError = error => {
  return {
    type: API_ERROR_CITY,
    payload: error,
  }
}

