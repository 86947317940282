import {
    PAGINATE_EDGES,
    PAGINATE_EDGES_SUCCESS,
    FILTER_PAGINATE_EDGES,
    FILTER_PAGINATE_EDGES_SUCCESS,
    DELETE_EDGES_SUCCESS,
    DELETE_EDGES,
    API_ERROR_LIST_EDGES,
} from "./actionTypes"


export const filtersAllEdgePaginate = params => {
    return {
        type: FILTER_PAGINATE_EDGES,
        payload: {params},
    }
}

export const filtersAllEdgePaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_EDGES_SUCCESS,
        payload: {params},
    }
}

export const allEdgePaginate = params => {
    return {
        type: PAGINATE_EDGES,
        payload: {params},
    }
}

export const allEdgePaginateSuccess = result => {
    return {
        type: PAGINATE_EDGES_SUCCESS,
        payload: {result},
    }
}

export const deleteEdge = (id, history) => {
  return {
    type: DELETE_EDGES,
    payload: {id, history} ,
  }
}

export const deleteEdgeSuccess = message => {
    return {
        type: DELETE_EDGES_SUCCESS,
        payload: message,
    }
}

export const apiPaginateEdgeError = error => {
  return {
    type: API_ERROR_LIST_EDGES,
    payload: error,
  }
}

