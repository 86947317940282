import {
    PAGINATE_PASTA,
    PAGINATE_PASTAS_SUCCESS,
    FILTER_PAGINATE_PASTAS,
    FILTER_PAGINATE_PASTAS_SUCCESS,
    DELETE_PASTAS_SUCCESS,
    DELETE_PASTAS,
    API_ERROR_LIST_PASTAS,
} from "./actionTypes"


export const filtersAllPastaPaginate = params => {
    return {
        type: FILTER_PAGINATE_PASTAS,
        payload: {params},
    }
}

export const filtersAllPastaPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_PASTAS_SUCCESS,
        payload: {params},
    }
}

export const allPastaPaginate = params => {
    return {
        type: PAGINATE_PASTA,
        payload: {params},
    }
}

export const allPastaPaginateSuccess = result => {
    return {
        type: PAGINATE_PASTAS_SUCCESS,
        payload: {result},
    }
}

export const deletePasta = (id, history) => {
  return {
    type: DELETE_PASTAS,
    payload: {id, history} ,
  }
}

export const deletePastaSuccess = message => {
    return {
        type: DELETE_PASTAS_SUCCESS,
        payload: message,
    }
}

export const apiPaginatePastaError = error => {
  return {
    type: API_ERROR_LIST_PASTAS,
    payload: error,
  }
}

