import React from 'react';
import {Col, Row, Spinner} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    allCouponsDiscountCompanyPaginate,
    createDiscountCouponsCompany,
    deleteCouponsDiscountCompany,
    apiPaginateCouponsDiscountCompanyError
} from "../../../../../store/actions";
import PropTypes from "prop-types";
import "./../../../../../routes/style_switch.css";
import CompaniesDiscountCoupons from "../../../../../store/companies/options/discount-coupons/table/reducer";
import CreateOrEditCompaniesDiscountCoupons from "../../../../../store/companies/options/discount-coupons/reducer";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this._delete_item = this._delete_item.bind(this);
        this._showingpaginate = this._showingpaginate.bind(this);
        this.handleClickPagination = this.handleClickPagination.bind(this);
    }

    componentDidMount() {
        this._all_by_paginate(1, 10)
    }

    async handleClickPagination(value) {
        await this._all_by_paginate(value, 10)
    }

    async _delete_item(id) {
        this.props.deleteCouponsDiscountCompany(id, this.props.history)

    }

    async _all_by_paginate(page, limit) {
        const {entity_id} = this.props.match.params

        this.props.allCouponsDiscountCompanyPaginate({
            page, limit, company_id: entity_id
        })
    }

    _showingpaginate(current_page, last_page, total, from, to) {
        let pages = [];

        if (to <= total) {

            for (let i = 1; i <= last_page; i++) {

                pages.push(i)

            }

        }
        if (current_page <= last_page) {
            return (
                <div className="clearfix">
                    <ul className="pagination pagination-sm m-0 float-right">
                        <li className="page-item" key={11 + 'ss'}>
                            <button disabled={1 === current_page}
                                    className={`page-link ${1 === current_page ? 'text-light bg-dark' : ''}`}
                                    onClick={() => this.handleClickPagination(1)}>Primeiro
                            </button>
                        </li>
                        {
                            pages.map((page, index) => {
                                return (<li key={index} className="page-item">
                                    <button disabled={page === current_page}
                                            className={`page-link ${page === current_page ? 'text-light bg-dark' : ''}`}
                                            onClick={() => this.handleClickPagination(page)}>{page}</button>
                                </li>)
                            })
                        }
                        <li key={12 + 'ss'} className="page-item ">
                            <button disabled={last_page === current_page}
                                    className={`page-link ${last_page === current_page ? 'text-light bg-dark' : ''}`}
                                    onClick={() => this.handleClickPagination(last_page)}>Último
                            </button>
                        </li>
                    </ul>
                </div>
            )
        }
    }

    render() {
        const {columns} = this.props;

        if (this.props.loading_form) {
            this._all_by_paginate(1, 10)

        }
        if (this.props.loading_delete) {
            this._all_by_paginate(1, 10)
        }
        return (
            <div>
                {
                    this.props.loading
                        ?
                        <div className="text-center" style={{width: '100%', position: 'absolute', paddingBottom: 20}}>
                            <Spinner size="sm" animation="border"
                                     style={{marginBottom: 20}}
                                     variant="success"/>
                        </div>
                        :
                        <>
                            <Row className="align-items-md-center  mb-3">
                                <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">

                                        <p className="card-title-desc mt-2">Escolha os cupons Abaixo:</p>
                                    </div>
                                    <div className="text-md-right ms-auto">
                                        <a
                                            onClick={() => {
                                                this.props.history.goBack()
                                            }}
                                            className="btn btn-dark btn-sm text-light"
                                        >
                                            <i className="bx bx-left-arrow-circle"/>
                                            <span> Voltar</span>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            {columns.map(
                                                (column, index) => {
                                                    return (
                                                        <th key={index} className="text-center">{column.name}</th>
                                                    )
                                                })
                                            }

                                        </tr>
                                        </thead>
                                        <tbody>

                                        {

                                            this.props.rows &&
                                            this.props.rows.length > 0
                                                ?
                                                this.props.rows.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center">{row.title}</td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <Row>
                                                                        <Col sm={2} className="m-1">
                                                                            <input
                                                                                onChange={() => {

                                                                                    if (row.exists === true) {
                                                                                        this._delete_item(row.id)
                                                                                    } else {
                                                                                        this.props.createDiscountCouponsCompany(
                                                                                            {
                                                                                                discount_coupons_id: row.id,
                                                                                                company_id: this.props.match.params.entity_id
                                                                                            },
                                                                                            this.props.history
                                                                                        )
                                                                                    }

                                                                                }}
                                                                                className="react-switch-checkbox"
                                                                                id={row.id}
                                                                                checked={row.exists}
                                                                                type="checkbox"
                                                                            />
                                                                            <label
                                                                                style={{background: row.exists && '#06D6A0'}}
                                                                                className="react-switch-label"
                                                                                htmlFor={row.id}
                                                                            >
                                                                                <span
                                                                                    className={`react-switch-button`}/>
                                                                            </label>
                                                                        </Col>
                                                                    </Row>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    {columns.map(item => <td></td>)}
                                                </tr>
                                        }


                                        </tbody>
                                    </table>
                                </Col>

                            </Row>

                        </>
                }
            </div>


        )
    }


}

Index.propTypes = {
    apiPaginateCouponsDiscountCompanyError: PropTypes.any,
    error: PropTypes.any,
    loading: PropTypes.any,
    refresh: PropTypes.any,
    loading_delete: PropTypes.any,
    rows: PropTypes.any,
    current_page: PropTypes.number,
    last_page: PropTypes.number,
    total: PropTypes.number,
    from: PropTypes.number,
    to: PropTypes.number,
    allCouponsDiscountCompanyPaginate: PropTypes.func,
    deleteCouponsDiscountCompany: PropTypes.func
}

const mapStateToProps = state => {
    const {
        loading,
        refresh,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_delete
    } = state.CompaniesDiscountCoupons
    const {
        loading_form
    } = state.CreateOrEditCompaniesDiscountCoupons
    return {
        loading_form,
        loading,
        refresh,
        rows,
        current_page,
        last_page,
        total,
        from,
        to,
        loading_delete
    }
}

export default withRouter(
    connect(mapStateToProps, {
        allCouponsDiscountCompanyPaginate,
        deleteCouponsDiscountCompany,
        apiPaginateCouponsDiscountCompanyError,
        createDiscountCouponsCompany

    })(Index)
)
