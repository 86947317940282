export const CREATE_TIPO_NF = "CREATE_TIPO_NF"
export const CREATE_TIPO_NF_SUCCESS = "CREATE_TIPO_NF_SUCCESS"

export const EDIT_TIPO_NF = "EDIT_TIPO_NF"
export const EDIT_TIPO_NF_SUCCESS = "EDIT_TIPO_NF_SUCCESS"

export const PAGINATE_TIPO_NF = "PAGINATE_TIPO_NF"
export const PAGINATE_TIPO_NF_SUCCESS = "PAGINATE_TIPO_NF_SUCCESS"

export const IMPORT_TIPO_NF = "IMPORT_TIPO_NF"
export const IMPORT_TIPO_NF_SUCCESS = "IMPORT_TIPO_NF_SUCCESS"

export const FIND_BY_ID_TIPO_NF = "FIND_BY_ID_TIPO_NF"
export const FIND_BY_ID_TIPO_NF_SUCCESS = "FIND_BY_ID_TIPO_NF_SUCCESS"

export const FILTER_PAGINATE_TIPO_NF = "FILTER_PAGINATE_TIPO_NF"
export const FILTER_PAGINATE_TIPO_NF_SUCCESS = "FILTER_PAGINATE_TIPO_NF_SUCCESS"

export const DELETE_TIPO_NF = "DELETE_TIPO_NF"
export const DELETE_TIPO_NF_SUCCESS = "DELETE_TIPO_NF_SUCCESS"

export const API_ERROR_TIPO_NF = "PAGINATE_TIPO_NF_API_ERROR"
