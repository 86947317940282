export const CREATE_CATEGORIES = "CREATE_CATEGORIES"
export const CREATE_CATEGORIES_SUCCESS = "CREATE_CATEGORIES_SUCCESS"

export const EDIT_CATEGORIES = "EDIT_CATEGORIES"
export const EDIT_CATEGORIES_SUCCESS = "EDIT_CATEGORIES_SUCCESS"

export const FIND_BY_ID_CATEGORIES = "FIND_BY_ID_CATEGORIES"
export const FIND_BY_ID_CATEGORIES_SUCCESS = "FIND_BY_ID_CATEGORIES_SUCCESS"

export const UPLOAD_CATEGORIES = "UPLOAD_CATEGORIES"
export const UPLOAD_CATEGORIES_SUCCESS = "UPLOAD_CATEGORIES_SUCCESS"

export const CREATE_OR_EDIT_CATEGORIES_API_ERROR = "CREATE_OR_EDIT_CATEGORIES_API_ERROR"
