export const PAGINATE_PROMOTIONS = "PAGINATE_PROMOTIONS"
export const PAGINATE_PROMOTIONS_SUCCESS = "PAGINATE_PROMOTIONS_SUCCESS"

export const FILTER_PAGINATE_PROMOTIONS = "FILTER_PAGINATE_PROMOTIONS"
export const FILTER_PAGINATE_PROMOTIONS_SUCCESS = "FILTER_PAGINATE_PROMOTIONS_SUCCESS"

export const DELETE_PROMOTIONS = "DELETE_PROMOTIONS"
export const DELETE_PROMOTIONS_SUCCESS = "DELETE_PROMOTIONS_SUCCESS"

export const API_ERROR_PROMOTIONS = "PAGINATE_PROMOTIONS_API_ERROR"
