import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";
 

const getNFTribModBCICMSById = data => get(url.GET_NF_TRIB_MOD_BC_ICMS_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFTribModBCICMS = data => del(url.DELETE_NF_TRIB_MOD_BC_ICMS.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFTribModBCICMS = data => post(url.POST_CREATE_NF_TRIB_MOD_BC_ICMS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFTribModBCICMS = data => put(url.PUT_EDIT_NF_TRIB_MOD_BC_ICMS, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFTribModBCICMSPaginate = ({page, limit}) => get(url.GET_NF_TRIB_MOD_BC_ICMS_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFTribModBCICMSPaginate = data => get(url.GET_NF_TRIB_MOD_BC_ICMS_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFTribModBCICMS = params => get(url.GET_SEARCH_NF_TRIB_MOD_BC_ICMS, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFTribModBCICMSPaginate,
    getFilterNFTribModBCICMSPaginate,
    putEditNFTribModBCICMS,
    postCreateNFTribModBCICMS,
    delNFTribModBCICMS,
    getNFTribModBCICMSById,
    getSearchNFTribModBCICMS
}
