import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import {NotificationContainer} from "react-notifications";
import toast, { Toaster } from 'react-hot-toast';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter } from "react-router-dom"
import "./i18n"

import { Provider } from "react-redux"

import store from "./store"
import './custom_theme.css'

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
        <NotificationContainer/>
        <Toaster
            position="bottom-center"
            reverseOrder={false}
        />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
