export const PAGINATE_DISCOUNT_COUPONS = "PAGINATE_DISCOUNT_COUPONS"
export const PAGINATE_DISCOUNT_COUPONS_SUCCESS = "PAGINATE_DISCOUNT_COUPONS_SUCCESS"

export const FILTER_PAGINATE_DISCOUNT_COUPONS = "FILTER_PAGINATE_DISCOUNT_COUPONS"
export const FILTER_PAGINATE_DISCOUNT_COUPONS_SUCCESS = "FILTER_PAGINATE_DISCOUNT_COUPONS_SUCCESS"

export const DELETE_DISCOUNT_COUPONS = "DELETE_DISCOUNT_COUPONS"
export const DELETE_DISCOUNT_COUPONS_SUCCESS = "DELETE_DISCOUNT_COUPONS_SUCCESS"

export const API_ERROR_DISCOUNT_COUPONS = "PAGINATE_DISCOUNT_COUPONS_API_ERROR"
