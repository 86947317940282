import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

import {
    IMPORT_TAB_IBPT,
    PAGINATE_TAB_IBPT,
    FILTER_PAGINATE_TAB_IBPT,
    DELETE_TAB_IBPT,
} from "./actionTypes"

import {
    allTabIBPTPaginateSuccess,
    filtersAllTabIBPTPaginateSuccess,
    deleteTabIBPTSuccess,
    importTabIBPTSuccess,
    apiPaginateTabIBPTError
} from "./actions"


import {
    getTabIBPTPaginate,
    getFilterTabIBPTPaginate,
    importTabIBPTAPI,
    delTabIBPT
} from "../../../helpers/tab_ibpt_helper"
import { NotificationManager } from "react-notifications";

function* importTabIBPT({ payload: { params } }) {
    try {

        const response = yield call(importTabIBPTAPI, params)

        yield put(importTabIBPTSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabIBPTError(error))
    }
}

function* deleteTabIBPT({ payload: { id, history } }) {
    try {

        const response = yield call(delTabIBPT, id)

        yield put(deleteTabIBPTSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info(message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateTabIBPTError(error))
    }
}

function* paginateTabIBPT({ payload: { params } }) {
    try {

        const response = yield call(getTabIBPTPaginate, params)

        yield put(allTabIBPTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabIBPTError(error))
    }
}

function* filtersPaginateTabIBPT({ payload: { params } }) {
    try {

        const response = yield call(getFilterTabIBPTPaginate, params)

        yield put(filtersAllTabIBPTPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateTabIBPTError(error))
    }
}


function* TabIBPTSaga() {
    yield takeEvery(PAGINATE_TAB_IBPT, paginateTabIBPT)
    yield takeEvery(FILTER_PAGINATE_TAB_IBPT, filtersPaginateTabIBPT)
    yield takeEvery(DELETE_TAB_IBPT, deleteTabIBPT)
    yield takeEvery(IMPORT_TAB_IBPT, importTabIBPT)
}

export default TabIBPTSaga
