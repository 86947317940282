import {
    PAGINATE_STATE,
    PAGINATE_STATE_SUCCESS,
    FILTER_PAGINATE_STATE,
    FILTER_PAGINATE_STATE_SUCCESS,
    DELETE_STATE_SUCCESS,
    DELETE_STATE,
    API_ERROR_STATE,
} from "./actionTypes"


export const filtersAllStatePaginate = params => {
    return {
        type: FILTER_PAGINATE_STATE,
        payload: {params},
    }
}

export const filtersAllStatePaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_STATE_SUCCESS,
        payload: {params},
    }
}

export const allStatePaginate = params => {
    return {
        type: PAGINATE_STATE,
        payload: {params},
    }
}

export const allStatePaginateSuccess = result => {
    return {
        type: PAGINATE_STATE_SUCCESS,
        payload: {result},
    }
}

export const deleteState = (id, history) => {
  return {
    type: DELETE_STATE,
    payload: {id, history} ,
  }
}

export const deleteStateSuccess = message => {
    return {
        type: DELETE_STATE_SUCCESS,
        payload: message,
    }
}

export const apiPaginateStateError = error => {
  return {
    type: API_ERROR_STATE,
    payload: error,
  }
}

