export const PAGINATE_NF_CNAE = "PAGINATE_NF_CNAE"
export const PAGINATE_NF_CNAE_SUCCESS = "PAGINATE_NF_CNAE_SUCCESS"

export const FILTER_PAGINATE_NF_CNAE = "FILTER_PAGINATE_NF_CNAE"
export const FILTER_PAGINATE_NF_CNAE_SUCCESS = "FILTER_PAGINATE_NF_CNAE_SUCCESS"

export const DELETE_NF_CNAE = "DELETE_NF_CNAE"
export const DELETE_NF_CNAE_SUCCESS = "DELETE_NF_CNAE_SUCCESS"

export const API_ERROR_NF_CNAE = "PAGINATE_NF_CNAE_API_ERROR"
