import {
    CREATE_COMPANIES,
    CREATE_COMPANIES_SUCCESS,
    CREATE_CRT_COMPANIES,
    CREATE_CRT_COMPANIES_SUCCESS,
    EDIT_COMPANIES,
    EDIT_COMPANIES_SUCCESS,
    FIND_BY_ID_COMPANIES,
    FIND_BY_ID_COMPANIES_SUCCESS,
    FIND_ABOUT_US_COMPANIES,
    FIND_ABOUT_US_COMPANIES_SUCCESS,
    FIND_CRT_COMPANIES,
    FIND_CRT_COMPANIES_SUCCESS,
    UPLOAD_COMPANIES,
    UPLOAD_COMPANIES_SUCCESS,
    CREATE_OR_EDIT_COMPANIES_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    existsFiles: false,
    error: false,
    message: '',

    lat: 0, lng: 0,
    files: null,
    code: '',
    fantasy_name: '',
    company_name: '',
    cnpj: '',
    email: '',
    phone: '',
    status: 1,
    postal_code: '',
    image: '',
    address: '',
    district: '',
    number: '',
    region: '',
    id: 0,


    front_cover: '',
    url_image: '',
    url_front_cover: '',
    type_dir_file: '',

    cnae_id: 0,
    crt_id: 0,
    ie: '',
    im: '',
    cnae: null,
    crt: null,
    data64: null

}

const createOrEditCompanies = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_CRT_COMPANIES:
        case CREATE_COMPANIES:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_CRT_COMPANIES_SUCCESS:
        case CREATE_COMPANIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                existsFiles: action.payload.existsFiles,
                loading: false
            }
            break
        case EDIT_COMPANIES:
            state = { ...state, loading: true }
            break
        case EDIT_COMPANIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case UPLOAD_COMPANIES:
            state = { ...state, loading: true }
            break
        case UPLOAD_COMPANIES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                existsFiles: false,
                loading: false
            }
            break
        case FIND_BY_ID_COMPANIES:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_ID_COMPANIES_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                code: action.payload.result.code,
                fantasy_name: action.payload.result.fantasy_name,
                company_name: action.payload.result.company_name,
                cnpj: action.payload.result.cnpj,
                email: action.payload.result.email,
                phone: action.payload.result.phone,
                status: action.payload.result.status,
                postal_code: action.payload.result.postal_code,
                address: action.payload.result.address,
                district: action.payload.result.neighborhood,
                number: action.payload.result.number,
                region: action.payload.result.city + "-" + action.payload.result.state,
                image: action.payload.result.image,
                id: action.payload.result.id,
                lat: action.payload.result.lat,
                lng: action.payload.result.lng,
                data64: btoa(JSON.stringify({
                    id: action.payload.result.id,
                    cnpj: action.payload.result.cnpj,
                    name: action.payload.result.company_name,
                    uf: 'SP'
                }))



            }
            break
        case FIND_CRT_COMPANIES:
        case FIND_ABOUT_US_COMPANIES:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_ABOUT_US_COMPANIES_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,


                id: action.payload.result.id,
                image: action.payload.result.image,
                front_cover: action.payload.result.front_cover,
                url_image: action.payload.result.url_image,
                url_front_cover: action.payload.result.url_front_cover,
                type_dir_file: action.payload.result.type_dir_file,




            }
            break
        case FIND_CRT_COMPANIES_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                id: action.payload.result.id,
                cnae_id: action.payload.result.cnae_id,
                crt_id: action.payload.result.crt_id,
                ie: action.payload.result.ie,
                im: action.payload.result.im,
                cnae: action.payload.result.cnae.text,
                crt: action.payload.result.crt.text,
                aliqIpi: action.payload.result.aliqIpi,
                aliqIssqn: action.payload.result.aliqIssqn,
                aliqPis: action.payload.result.aliqPis,
                aliqCofins: action.payload.result.aliqCofins,
                aliqIcms: action.payload.result.aliqIcms,


            }
            break
        case CREATE_OR_EDIT_COMPANIES_API_ERROR:
            state = { ...state, loading_form: false, error: action.payload, loading: false, existsFiles: false }
            break
        default:
            state = { ...state, loading_form: false, loading: false }
            break
    }

    return state
}
export default createOrEditCompanies
