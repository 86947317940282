import {
    CREATE_DISCOUNT_COUPONS,
    CREATE_DISCOUNT_COUPONS_SUCCESS,
    EDIT_DISCOUNT_COUPONS,
    EDIT_DISCOUNT_COUPONS_SUCCESS,
    FIND_BY_ID_DISCOUNT_COUPONS,
    FIND_BY_ID_DISCOUNT_COUPONS_SUCCESS,
    UPLOAD_DISCOUNT_COUPONS,
    UPLOAD_DISCOUNT_COUPONS_SUCCESS,
    CREATE_OR_EDIT_DISCOUNT_COUPONS_API_ERROR
} from "./actionTypes"


export const createDiscountCoupons = (params,  history) => {
    return {
        type: CREATE_DISCOUNT_COUPONS,
        payload: {params,history},
    }
}

export const createDiscountCouponsSuccess = params => {
    return {
        type: CREATE_DISCOUNT_COUPONS_SUCCESS,
        payload: params,
    }
}

export const editDiscountCoupons = (params, history) => {
    return {
        type: EDIT_DISCOUNT_COUPONS,
        payload: {params, history},
    }
}

export const editDiscountCouponsSuccess = result => {
    return {
        type: EDIT_DISCOUNT_COUPONS_SUCCESS,
        payload: {result},
    }
}

export const findByIdDiscountCoupons = id => {
    return {
        type: FIND_BY_ID_DISCOUNT_COUPONS,
        payload: {id},
    }
}

export const findDiscountCouponsIdSuccess = result => {
    return {
        type: FIND_BY_ID_DISCOUNT_COUPONS_SUCCESS,
        payload: result,
    }
}

export const uploadDiscountCoupons = ( dataForm,  history) => {
    return {
        type: UPLOAD_DISCOUNT_COUPONS,
        payload: {dataForm, history},
    }
}

export const uploadDiscountCouponsSuccess = result => {
    return {
        type: UPLOAD_DISCOUNT_COUPONS_SUCCESS,
        payload: result,
    }
}

export const apiCreateOrEditDiscountCouponsError = error => {
    return {
        type: CREATE_OR_EDIT_DISCOUNT_COUPONS_API_ERROR,
        payload: error,
    }
}

