import {
    PAGINATE_MODELS_SEGUIMENTS,
    PAGINATE_MODELS_SEGUIMENTS_SUCCESS,
    FILTER_PAGINATE_MODELS_SEGUIMENTS,
    FILTER_PAGINATE_MODELS_SEGUIMENTS_SUCCESS,
    DELETE_MODELS_SEGUIMENTS_SUCCESS,
    DELETE_MODELS_SEGUIMENTS,
    API_ERROR_MODELS_SEGUIMENTS,
} from "./actionTypes"


export const filtersAllModelsSeguimentsPaginate = params => {
    return {
        type: FILTER_PAGINATE_MODELS_SEGUIMENTS,
        payload: {params},
    }
}

export const filtersAllModelsSeguimentsPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_MODELS_SEGUIMENTS_SUCCESS,
        payload: {params},
    }
}

export const allModelsSeguimentsPaginate = params => {
    return {
        type: PAGINATE_MODELS_SEGUIMENTS,
        payload: {params},
    }
}

export const allModelsSeguimentsPaginateSuccess = result => {
    return {
        type: PAGINATE_MODELS_SEGUIMENTS_SUCCESS,
        payload: {result},
    }
}

export const deleteModelsSeguiments = (id, history) => {
  return {
    type: DELETE_MODELS_SEGUIMENTS,
    payload: {id, history} ,
  }
}

export const deleteModelsSeguimentsSuccess = message => {
    return {
        type: DELETE_MODELS_SEGUIMENTS_SUCCESS,
        payload: message,
    }
}

export const apiPaginateModelsSeguimentsError = error => {
  return {
    type: API_ERROR_MODELS_SEGUIMENTS,
    payload: error,
  }
}

