import {
    CREATE_PRODUCTS_SIZES,
    CREATE_PRODUCTS_SIZES_SUCCESS,
    EDIT_PRODUCTS_SIZES,
    EDIT_PRODUCTS_SIZES_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_SIZES_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    prod_size_created: false,
    refresh: false,
    error: false,
    message: '',

    name: '',
    sku: '',
    description: '',
    image: '',
    status: 1,
    menu_category_id: 0,

    id: 0,
    product_id: 0,
}

const createOrEditProductsSize = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_PRODUCTS_SIZES:
            state = {
                ...state,
                refresh: false,
                prod_size_created: false,
                loading: true
            }
            break
        case CREATE_PRODUCTS_SIZES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                id: action.payload.id,
                product_id: action.payload.id,
                prod_size_created: true,
                refresh: true,
                loading: false
            }
            break
        case EDIT_PRODUCTS_SIZES:
            state = {...state, loading: true}
            break
        case EDIT_PRODUCTS_SIZES_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case CREATE_OR_EDIT_PRODUCTS_SIZES_API_ERROR:
            state = {...state, error: action.payload, loading: false,  refresh: false,}
            break
        default:
            state = {...state}
            break
    }

    return state
}
export default createOrEditProductsSize
