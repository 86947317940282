import {
    CREATE_PRODUCTS_SIZES,
    CREATE_PRODUCTS_SIZES_SUCCESS,
    EDIT_PRODUCTS_SIZES,
    EDIT_PRODUCTS_SIZES_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_SIZES_API_ERROR
} from "./actionTypes"


export const createProductSize = (params, history) => {
    return {
        type: CREATE_PRODUCTS_SIZES,
        payload: {params, history},
    }
}

export const createProductSizeSuccess = params => {
    return {
        type: CREATE_PRODUCTS_SIZES_SUCCESS,
        payload: {params},
    }
}

export const editProductSize = (params, history) => {
    return {
        type: EDIT_PRODUCTS_SIZES,
        payload: {params, history},
    }
}

export const editProductSizeSuccess = result => {
    return {
        type: EDIT_PRODUCTS_SIZES_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditProductSizeError = error => {
    return {
        type: CREATE_OR_EDIT_PRODUCTS_SIZES_API_ERROR,
        payload: error,
    }
}

