import {
    CREATE_DISCOUNT_COUPONS_COMPANY,
    CREATE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
    EDIT_DISCOUNT_COUPONS_COMPANY,
    EDIT_DISCOUNT_COUPONS_COMPANY_SUCCESS,
    CREATE_OR_EDIT_DISCOUNT_COUPONS_COMPANY_API_ERROR
} from "./actionTypes"


export const createDiscountCouponsCompany = (params, history) => {
    return {
        type: CREATE_DISCOUNT_COUPONS_COMPANY,
        payload: {params, history},
    }
}

export const createDiscountCouponsCompanySuccess = params => {
    return {
        type: CREATE_DISCOUNT_COUPONS_COMPANY_SUCCESS,
        payload: params,
    }
}

export const editDiscountCouponsCompany = (params, history) => {
    return {
        type: EDIT_DISCOUNT_COUPONS_COMPANY,
        payload: {params, history},
    }
}

export const editDiscountCouponsCompanySuccess = result => {
    return {
        type: EDIT_DISCOUNT_COUPONS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const apiCreateOrEditDiscountCouponsCompanyError = error => {
    return {
        type: CREATE_OR_EDIT_DISCOUNT_COUPONS_COMPANY_API_ERROR,
        payload: error,
    }
}

