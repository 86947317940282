export const CREATE_STATE = "CREATE_STATE"
export const CREATE_STATE_SUCCESS = "CREATE_STATE_SUCCESS"

export const EDIT_STATE = "EDIT_STATE"
export const EDIT_STATE_SUCCESS = "EDIT_STATE_SUCCESS"

export const FIND_BY_ID_STATE = "FIND_BY_ID_STATE"
export const FIND_BY_ID_STATE_SUCCESS = "FIND_BY_ID_STATE_SUCCESS"

export const CREATE_OR_EDIT_STATE_API_ERROR = "CREATE_OR_EDIT_STATE_API_ERROR"
