import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { NotificationManager } from "react-notifications";
import {
    CREATE_CRT_COMPANIES,
    CREATE_COMPANIES,
    EDIT_COMPANIES,
    FIND_BY_ID_COMPANIES,
    FIND_ABOUT_US_COMPANIES,
    FIND_CRT_COMPANIES,
    UPLOAD_COMPANIES
} from "./actionTypes"


import {
    createCrtCompanySuccess,
    createCompanySuccess,
    editCompanySuccess,
    findCompanyIdSuccess,
    findCrtCompanySuccess,
    findCompanyAboutUSSuccess,
    uploadCompanySuccess,
    apiCreateOrEditCompanyError
} from "./actions"


import {
    putEditCompany,
    postCreateCompany,
    postCreateCrtCompany,
    getCompanyById,
    postUploadCompany,
    getCompanyAboutUS,
    getCompanyCrt
} from "../../helpers/companies_helper"

function* findByIdCompany({ payload: { id } }) {
    try {

        const response = yield call(getCompanyById, id)

        yield put(findCompanyIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditCompanyError(error))
    }
}

function* findAboutUSCompany({ payload: { id } }) {
    try {

        const response = yield call(getCompanyAboutUS, id)

        yield put(findCompanyAboutUSSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditCompanyError(error))
    }
}

function* findCrtCompany({ payload: { id } }) {
    try {

        const response = yield call(getCompanyCrt, id)

        yield put(findCrtCompanySuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditCompanyError(error))
    }
}

function* createCompany({ payload: { params, existsFiles, history } }) {
    try {

        const response = yield call(postCreateCompany, params)
        const { message, id, error } = response;

        yield put(createCompanySuccess({ message, id, error, existsFiles }))


        NotificationManager.info(message, 'Aviso');

        if (!existsFiles) {
            history.goBack()
        }

    } catch (error) {
        yield put(apiCreateOrEditCompanyError(error))
    }
}

function* createCrtCompany({ payload: { params,  history } }) {
    try {

        const response = yield call(postCreateCrtCompany, params)
        const { message, id, error } = response;

        yield put(createCrtCompanySuccess({ message, id, error }))


        NotificationManager.info(message, 'Aviso');


    } catch (error) {
        yield put(apiCreateOrEditCompanyError(error))
    }
}


function* editCompany({ payload: { params, existsFiles, files, history } }) {
    try {

        const response = yield call(putEditCompany, params)

        yield put(editCompanySuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        if (!existsFiles) {
            history.goBack()
        }


    } catch (error) {
        yield put(apiCreateOrEditCompanyError(error))
    }
}

function* uploadCompany({ payload: { dataForm, history } }) {
    try {



        console.log('uploadCompany Data EXISTS ID ', dataForm)

        const response = yield call(postUploadCompany, dataForm)

        yield put(uploadCompanySuccess(response))

        const { message } = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditCompanyError(error))
    }
}


function* createOrEditCompaniesSaga() {
    yield takeEvery(CREATE_CRT_COMPANIES, createCrtCompany)
    yield takeEvery(CREATE_COMPANIES, createCompany)
    yield takeEvery(EDIT_COMPANIES, editCompany)
    yield takeEvery(FIND_BY_ID_COMPANIES, findByIdCompany)
    yield takeEvery(FIND_ABOUT_US_COMPANIES, findAboutUSCompany)
    yield takeLatest(UPLOAD_COMPANIES, uploadCompany)
    yield takeLatest(FIND_CRT_COMPANIES, findCrtCompany)
}

export default createOrEditCompaniesSaga
