import {
    CREATE_SETTINGS,
    CREATE_SETTINGS_SUCCESS,
    CREATE_OR_EDIT_SETTINGS,
    CREATE_OR_EDIT_SETTINGS_SUCCESS,
    EDIT_SETTINGS,
    EDIT_SETTINGS_SUCCESS,
    FIND_BY_ID_SETTINGS,
    FIND_BY_ID_SETTINGS_SUCCESS,
    FIND_BY_KEY_SETTINGS,
    FIND_BY_KEY_SETTINGS_SUCCESS,
    CREATE_OR_EDIT_SETTINGS_API_ERROR
} from "./actionTypes"


const initialState = {
    loading: false,
    loading_form: false,
    error: false,
    message: '',
    type: 0,
    status: 0,
    key: '',
    id: 0,
    id_settings: 0,
    value: '',
    image: null,
    url_image: null,
    type_dir_file: null
}

const createOrEditSettings = (state = initialState, action) => {

    switch (action.type) {
        case CREATE_OR_EDIT_SETTINGS:
        case CREATE_SETTINGS:
            state = {
                ...state,
                loading: true
            }
            break
        case CREATE_OR_EDIT_SETTINGS_SUCCESS:
        case CREATE_SETTINGS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case EDIT_SETTINGS:
            state = { ...state, loading: true }
            break
        case EDIT_SETTINGS_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                error: action.payload.error,
                loading: false
            }
            break
        case FIND_BY_KEY_SETTINGS:
        case FIND_BY_ID_SETTINGS:
            state = { ...state, loading: false, loading_form: true }
            break
        case FIND_BY_KEY_SETTINGS_SUCCESS:
        case FIND_BY_ID_SETTINGS_SUCCESS:
            state = {
                ...state,
                loading: false,
                loading_form: false,

                id_settings: action.payload.result.id,
                id: action.payload.result.id,
                type: action.payload.result.type,
                status: action.payload.result.status,
                key: action.payload.result.key,
                value: JSON.parse(action.payload.result.value),
                image: action.payload.result.image,
                type_dir_file: action.payload.result.type_dir_file,
                url_image: action.payload.result.url_image
            }
            break
        case CREATE_OR_EDIT_SETTINGS_API_ERROR:
            state = { ...state, id_settings: 0, error: action.payload, loading_form: false, loading: false }
            break
        default:
            state = { ...state }
            break
    }

    return state
}
export default createOrEditSettings
