import React from 'react';
import MetaTags from 'react-meta-tags'
import { Card, CardBody, NavItem, Col, Container, Row, Spinner, NavLink, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ButtonCustom from "../../../../components/ButtonCustom";
import { findCrtCompany, createCrtCompany } from "../../../../store/companies/actions";
import {
    createOrEditSettings,
    findByKeySettings
} from "../../../../store/settings/actions";
import Autocomplete from "../../../../components/Autocomplete";
import classnames from "classnames"
import moment from 'moment';
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Form";
class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            status: 0,
            tpAmb: 2,
            company_id: null,
            cnae: null,
            crt: null,
            cnpj: '',
            tabs: [
                {
                    id: 1,
                    name: 'Classificação',
                    status: 1
                },
                {
                    id: 2,
                    name: 'Emissor Fiscal',
                    status: 1
                },
                {
                    id: 3,
                    name: 'Certificado Digital (PFX)',
                    status: 1
                },

            ]

        }
        this.handleUpload = this.handleUpload.bind(this);
        this.handleFields = this.handleFields.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)
        this.handleRadioButtom = this.handleRadioButtom.bind(this)
    }
    handleUpload(e) {
        const { name, value, files } = e.target


        let reader = new FileReader();
        let file = files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                existsFile: true,
                image: reader.result
            });
        }

        reader.readAsDataURL(file)

    }
    handleRadioButtom(event) {

        console.log(event.target.value);
        this.setState({ tpAmb: event.target.value })

    }

    componentDidMount() {
        const { entity } = this.props.match.params
        const param_64 = atob(entity)
        const { id, name, uf, cnpj } = JSON.parse(param_64)
        console.log('data settings ', { id, name, uf, cnpj })
        this.setState({ cnpj, company_id: id, company_name: name, uf })
        this.props.findCrtCompany(id)

    }

    handleAutocomplete(_data, name_entity) {

        if (_data) {
            const { value, name, code, data } = _data

            let newState = {}

            newState[name] = value
            newState[name_entity] = code

            this.setState(newState)
        }
    }

    handleFields(event) {

        const { name, value } = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }

    render() {
        console.log('crto l ', this.props.cnae)
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Classificação Fiscal</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Classificação Fiscal" breadcrumbItem="Classificação Fiscal" />
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <div className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <span
                                                    className="h4 card-title">Formulário</span>
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <a
                                                    onClick={() => {
                                                        this.props.history.goBack()
                                                    }}
                                                    className="btn btn-dark btn-sm text-light"
                                                >
                                                    <span> Voltar</span>
                                                </a>
                                            </div>
                                        </div>

                                        <ul className="nav nav-tabs nav-tabs-custom">
                                            {
                                                this.state.tabs.map((item, index) => {
                                                    return (
                                                        <NavItem key={index}>
                                                            <NavLink

                                                                onClick={(e) => {
                                                                    if (item.id === 2) {
                                                                        this.props.findByKeySettings('class_fiscal_' + this.state.company_id)
                                                                        this.setState({ activeTab: 1 })
                                                                    } else if (item.id === 3) {
                                                                        this.props.findByKeySettings('cert_fiscal_' + this.state.company_id)
                                                                        this.setState({ activeTab: 2 })
                                                                    } else {
                                                                        this.setState({ activeTab: index })
                                                                    }

                                                                }}
                                                                className={classnames({
                                                                    active: this.state.activeTab === index,
                                                                })}
                                                            >
                                                                {item.name}
                                                            </NavLink>
                                                        </NavItem>
                                                    )
                                                })
                                            }


                                        </ul>
                                        <div style={{ paddingBottom: 20 }}>
                                            <TabContent
                                                activeTab={this.state.activeTab}
                                                className="p-2 "
                                            >
                                                {
                                                    this.props.loading_form
                                                        ?
                                                        <Col className="col-12">
                                                            <Card>
                                                                <CardBody>
                                                                    <Row className="align-items-md-center  mb-3">
                                                                        <Col className="inner-custom-pagination d-flex">
                                                                            <div className="text-center" style={{
                                                                                width: '100%',
                                                                                position: 'absolute',
                                                                                paddingBottom: 20
                                                                            }}>
                                                                                <Spinner size="sm" animation="border"
                                                                                    style={{ marginBottom: 20 }}
                                                                                    variant="success" />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>

                                                        </Col>
                                                        :
                                                        <>
                                                            <TabPane tabId={0}>
                                                                <AvForm
                                                                    className="form-horizontal"
                                                                    onValidSubmit={(event, values) => {
                                                                        this.props.createCrtCompany({
                                                                            id: this.props.id,
                                                                            company_id: this.state.company_id,
                                                                            crt_id: this.state.crt_id,
                                                                            cnae_id: this.state.cnae_id,
                                                                            ie: values.ie,
                                                                            im: values.im
                                                                        }, this.props.history)
                                                                    }}>
                                                                    <Row className="align-items-md-center ">
                                                                        <Col sm={12} className="inner-custom-pagination d-flex">
                                                                            <div className="d-inline">
                                                                                <p className="card-title-desc mt-3">Informe a classificão fiscal, de acordo com o regime tributário</p>
                                                                            </div>

                                                                        </Col>
                                                                        <Col sm={6} className="mb-2">
                                                                            <Autocomplete
                                                                                onSourceSelect={this.handleAutocomplete}
                                                                                nameFieldSelect={'crt_id'}
                                                                                limit={10}
                                                                                inputProps={{
                                                                                    id: 'crt',
                                                                                    label: "Regime Tributário (Crt)",
                                                                                    placeholder: "Pesquisar regime tributário",
                                                                                    value: this.state.crt ? this.state.crt : this.props.crt,
                                                                                    url: 'search/tributacao/crt'
                                                                                }} />
                                                                        </Col>
                                                                        <Col sm={3} className="mb-2 mt-2">
                                                                            <AvField
                                                                                type="number"
                                                                                className="form-control"
                                                                                name="ie"
                                                                                label="Ins. Estadual"
                                                                                value={this.props.ie} />
                                                                        </Col>
                                                                        <Col sm={3} className="mb-2 mt-2">
                                                                            <AvField
                                                                                type="number"
                                                                                className="form-control"
                                                                                name="im"
                                                                                label="Insc. Municipal"
                                                                                value={this.props.im} />
                                                                        </Col>
                                                                        <Col sm={12} className="mb-2">
                                                                            <Autocomplete
                                                                                onSourceSelect={this.handleAutocomplete}
                                                                                nameFieldSelect={'cnae_id'}
                                                                                limit={10}
                                                                                inputProps={{
                                                                                    id: 'cnae',
                                                                                    label: "CNAE",
                                                                                    placeholder: "Pesquisar o CNAE",
                                                                                    value: this.state.cnae ? this.state.cnae : this.props.cnae,
                                                                                    url: 'search/cnae'
                                                                                }} />
                                                                        </Col>

                                                                        <Col sm={2} className="mb-2">
                                                                            <AvField
                                                                                name="aliqIpi"
                                                                                label="Aliq. IPI"
                                                                                type="text"
                                                                                placeholder="0%"
                                                                                value={this.props.aliqIpi}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={2} className="mb-2">
                                                                            <AvField
                                                                                name="aliqIssqn"
                                                                                label="Aliq. ISSQN"
                                                                                type="text"
                                                                                placeholder="0%"
                                                                                value={this.props.aliqIssqn}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={2} className="mb-2">
                                                                            <AvField
                                                                                name="aliqPis"
                                                                                label="Aliq. PIS"
                                                                                type="text"
                                                                                value={this.props.aliqPis}
                                                                                placeholder="0%"
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={2} className="mb-2">
                                                                            <AvField
                                                                                name="aliqCofins"
                                                                                label="Aliq. COFINS"
                                                                                type="text"
                                                                                value={this.props.aliqCofins}
                                                                                placeholder="0%"
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={2} className="mb-2">
                                                                            <AvField
                                                                                name="aliqIcms"
                                                                                label="Aliq. ICMS"
                                                                                type="text"
                                                                                placeholder="0%"
                                                                                value={this.props.aliqIcms}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={12} className="pt-3">
                                                                            <ButtonCustom
                                                                                isLoading={false}
                                                                                size="sm"
                                                                                clazzContainer="text-center"
                                                                                clazz="btn btn-info w-md"
                                                                                message_loaging="">
                                                                                <button
                                                                                    className="btn btn-info w-md"
                                                                                    type="submit">
                                                                                    Salvar
                                                                                </button>
                                                                            </ButtonCustom>
                                                                        </Col>
                                                                    </Row>
                                                                </AvForm>
                                                            </TabPane>
                                                            <TabPane tabId={1}>
                                                                <AvForm
                                                                    className="form-horizontal"
                                                                    onValidSubmit={(event, values) => {
                                                                        const format1 = "YYYY-MM-DD HH:mm:ss"
                                                                        const cur_date_time = moment(new Date()).format(format1);
                                                                        this.props.createOrEditSettings({
                                                                            id: this.props.id_settings ? this.props.id_settings : 0,
                                                                            company_id: this.state.company_id,
                                                                            type: 2,
                                                                            status: this.state.status,
                                                                            key: 'class_fiscal_' + this.state.company_id,
                                                                            value: {
                                                                                "atualizacao": cur_date_time,
                                                                                "tpAmb": this.state.tpAmb,
                                                                                "razaosocial": this.state.company_name,
                                                                                "siglaUF": this.state.uf,
                                                                                "cnpj": (this.state.cnpj).replace(/[^0-9]/g, ''),
                                                                                "schemes": "PL_009_V4",
                                                                                "versao": "4.00",
                                                                                "tokenIBPT": values.tokenIBPT,
                                                                                "CSC": values.CSC,
                                                                                "CSCid": values.CSCid,
                                                                                "aProxyConf": {
                                                                                    "proxyIp": "",
                                                                                    "proxyPort": "",
                                                                                    "proxyUser": "",
                                                                                    "proxyPass": ""
                                                                                },
                                                                            },
                                                                        }, this.props.history)
                                                                    }}>
                                                                    <Row>
                                                                        <Col sm={2} className="mb-2">
                                                                            <div className="mt-2" onChange={this.handleRadioButtom}>
                                                                                <label htmlFor=""
                                                                                    className="control-label">Ambiente</label>

                                                                                <Stack direction="horizontal" gap={2}>
                                                                                    <div className="form-check mb-3">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="tpAmb"
                                                                                            id="tpAmb"
                                                                                            value="2"
                                                                                            checked={parseInt(this.state.tpAmb) === 2 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 2 ? true : false : true}

                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="tpAmb"
                                                                                        >
                                                                                            Homologação
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check mb-3">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="tpAmb"
                                                                                            id="tpAmb"
                                                                                            value="1"
                                                                                            checked={parseInt(this.state.tpAmb) === 1 ? true : false || this.props.value ? parseInt(this.props.value.tpAmb) === 1 ? true : false : false}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label"
                                                                                            htmlFor="tpAmb"
                                                                                        >
                                                                                            Produção
                                                                                        </label>
                                                                                    </div>
                                                                                </Stack>

                                                                            </div>
                                                                        </Col>
                                                                        <Col sm={12} className="mb-2"></Col>
                                                                        <Col sm={2} className="mb-2">
                                                                            <AvField
                                                                                name="CSCid"
                                                                                label="ID do CSC."
                                                                                type="text"
                                                                                placeholder="informe o CSCid"
                                                                                value={this.props.value ? this.props.value.CSCid : null}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={4} className="mb-2">
                                                                            <AvField
                                                                                name="CSC"
                                                                                label="Código de Segurança do Contribuinte (CSC)"
                                                                                type="text"
                                                                                placeholder="informe o CSC"
                                                                                value={this.props.value ? this.props.value.CSC : null}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={6} className="mb-2">
                                                                            <AvField
                                                                                name="tokenIBPT"
                                                                                label="Token API IBPT"
                                                                                type="text"
                                                                                placeholder="informe o token"
                                                                                value={this.props.value ? this.props.value.tokenIBPT : null}
                                                                                required
                                                                            />
                                                                        </Col>
                                                                        <Col sm={12} className="pt-3">
                                                                            <ButtonCustom
                                                                                isLoading={false}
                                                                                size="sm"
                                                                                clazzContainer="text-center"
                                                                                clazz="btn btn-info w-md"
                                                                                message_loaging="">
                                                                                <button
                                                                                    className="btn btn-info w-md"
                                                                                    type="submit">
                                                                                    Salvar
                                                                                </button>
                                                                            </ButtonCustom>
                                                                        </Col>
                                                                    </Row>


                                                                </AvForm>
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <AvForm
                                                                    className="form-horizontal"
                                                                    onValidSubmit={(event, values) => {

                                                                        this.props.createOrEditSettings({
                                                                            id: this.props.id_settings ? this.props.id_settings : 0,
                                                                            company_id: this.state.company_id,
                                                                            type: 44,
                                                                            status: this.state.status,
                                                                            key: 'cert_fiscal_' + this.state.company_id,
                                                                            password: values.password,
                                                                            value: values.password,
                                                                            files: this.state.file,
                                                                        }, this.props.history)
                                                                    }}>
                                                                    <Col sm={2} className="mb-2">
                                                                        <AvField
                                                                            name="password"
                                                                            label="Senha Certificado"
                                                                            type="password"
                                                                            placeholder="senha"
                                                                            value={this.props.value ? this.props.value.password : null}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={12} className="mb-2"></Col>
                                                                    <Col l sm={6} className="inner-custom-pagination d-flex">
                                                                        <Form.Group controlId="formFile" className="mb-3">
                                                                            <Form.Label>Certificado digital</Form.Label>
                                                                            <Form.Control type="file" onChange={this.handleUpload} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col sm={12} className="pt-3">
                                                                        <ButtonCustom
                                                                            isLoading={false}
                                                                            size="sm"
                                                                            clazzContainer="text-center"
                                                                            clazz="btn btn-info w-md"
                                                                            message_loaging="">
                                                                            <button
                                                                                className="btn btn-info w-md"
                                                                                type="submit">
                                                                                Salvar
                                                                            </button>
                                                                        </ButtonCustom>
                                                                    </Col>
                                                                </AvForm>
                                                            </TabPane>
                                                        </>
                                                }

                                            </TabContent>

                                        </div>


                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,
    loading_form: PropTypes.any,

    findCrtCompany: PropTypes.func,
    createCrtCompany: PropTypes.func,
    id: PropTypes.any,
    cnae_id: PropTypes.any,
    crt_id: PropTypes.any,
    ie: PropTypes.any,
    im: PropTypes.any,
    cnae: PropTypes.any,
    crt: PropTypes.any,

    type: PropTypes.any,
    status: PropTypes.any,
    key: PropTypes.any,
    value: PropTypes.any,
    image: PropTypes.any,
    id_settings: PropTypes.any,
    url_image: PropTypes.any,
    type_dir_file: PropTypes.any,

}
const mapStateToProps = state => {
    const {
        loading,
        loading_form,

        id,
        cnae_id,
        crt_id,
        ie,
        im,
        cnae,
        crt,
        aliqIpi,
        aliqIssqn,
        aliqPis,
        aliqCofins,
        aliqIcms,
    } = state.CreateOrEditCompanies
    const {
        error,
        message,
        type,
        status,
        key,
        value,
        image,
        id_settings,
        url_image,
        type_dir_file } = state.CreateOrEditSetting
    return {
        loading,
        loading_form,
        id,
        cnae_id,
        crt_id,
        ie,
        im,
        cnae,
        crt,
        aliqIpi,
        aliqIssqn,
        aliqPis,
        aliqCofins,
        aliqIcms,
        type,
        status,
        key,
        value,
        image,
        id_settings,
        url_image,
        type_dir_file
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    findCrtCompany,
    createCrtCompany,
    createOrEditSettings,
    findByKeySettings
})(
    (Index)
)))
