import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_NF_ORIGEM,
    FILTER_PAGINATE_NF_ORIGEM,
    DELETE_NF_ORIGEM,
} from "./actionTypes"

import {
    allNFOrigemPaginateSuccess,
    filtersAllNFOrigemPaginateSuccess,
    deleteNFOrigemSuccess,
    apiPaginateNFOrigemError
} from "./actions"


import {
    getNFOrigemPaginate,
    getFilterNFOrigemPaginate,
    delNFOrigem
} from "../../../helpers/nf_origem_helper"
import {NotificationManager} from "react-notifications";

function* deleteNFOrigem({payload: {id, history}}) {
    try {

        const response = yield call(delNFOrigem, id)

        yield put(deleteNFOrigemSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateNFOrigemError(error))
    }
}

function* paginateNFOrigem({payload: {params}}) {
    try {

        const response = yield call(getNFOrigemPaginate, params)

        yield put(allNFOrigemPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFOrigemError(error))
    }
}

function* filtersPaginateNFOrigem({payload: {params}}) {
    try {

        const response = yield call(getFilterNFOrigemPaginate, params)

        yield put(filtersAllNFOrigemPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateNFOrigemError(error))
    }
}


function* NFOrigemSaga() {
    yield takeEvery(PAGINATE_NF_ORIGEM, paginateNFOrigem)
    yield takeEvery(FILTER_PAGINATE_NF_ORIGEM, filtersPaginateNFOrigem)
    yield takeEvery(DELETE_NF_ORIGEM, deleteNFOrigem)
}

export default NFOrigemSaga
