import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import {connect} from "react-redux";
import {createOrEditSettings, findByKeySettings} from "../../../store/actions"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleFieldsSelect = this.handleFieldsSelect.bind(this)

    }

    componentDidMount() {
        this.props.findByKeySettings('mail_config')

    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)

    }
    handleFieldsSelect(event) {

        const { value} = event.target

        this.setState({status: value})
    }

    handleValidSubmit(event, values) {
        this.props.createOrEditSettings({
            id: this.props.id,
            type: 2,
            status: this.state.status,
            key: 'mail_config',
            value: {
                "name": values.name,
                "host": values.host, 
                "port": values.port, 
                "driver": values.driver,
                "username": values.username,
                "email_id": values.email_id,
                "encryption": values.encryption,
                "password": values.password,
            },
        }, this.props.history)

    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("E-mail Config")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("E-mail ")}
                                     breadcrumbItem={this.props.t("Config")}/>
                        <Row>
                            {
                                this.props.loading_form
                                ?
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{width: '100%', position: 'absolute', paddingBottom: 20}}>
                                                            <Spinner size="sm" animation="border"
                                                                     style={{marginBottom: 20}}
                                                                     variant="success"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <>
                                        <Col sm={7}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span className="h4 card-title">{this.props.t("Form")}</span>
                                                                <p className="card-title-desc mt-2">Informe as configurações do seu servidor de e-mail</p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <AvForm
                                                                className="form-horizontal"
                                                                onValidSubmit={this.handleValidSubmit}>

                                                                <Row>
                                                                    <Col sm={8} className="mb-2">
                                                                        <AvField
                                                                            name="name"
                                                                            label={this.props.t("Name")}
                                                                            className="form-control"
                                                                            placeholder="Digite o nome"
                                                                            type="text"
                                                                            value={this.props.value.name}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={4} className="mb-2">
                                                                        <AvField
                                                                            value={this.props.status !== this.state.status ? this.props.status : this.state.status} onChange={this.handleFieldsSelect}
                                                                            type="select" name="select"
                                                                            label={this.props.t("Status")} >
                                                                            <option value="1">Ativo</option>
                                                                            <option value="0">Inativo</option>
                                                                        </AvField>
                                                                    </Col>
                                                                    <Col sm={6} className="mb-2">
                                                                        <AvField
                                                                            name="host"
                                                                            label={this.props.t("Host")}
                                                                            className="form-control"
                                                                            placeholder="Digite o host"
                                                                            type="text"
                                                                            value={this.props.value.host}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={2} className="mb-2">
                                                                        <AvField
                                                                            name="port"
                                                                            label={this.props.t("Port")}
                                                                            className="form-control"
                                                                            placeholder="Porta"
                                                                            type="number"
                                                                            value={this.props.value.port}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={4} className="mb-2">
                                                                        <AvField
                                                                            name="driver"
                                                                            label={this.props.t("Driver")}
                                                                            className="form-control"
                                                                            placeholder="Driver smtp/imap"
                                                                            type="text"
                                                                            value={this.props.value.driver}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} className="mb-2">
                                                                        <AvField
                                                                            name="username"
                                                                            label={this.props.t("Username")}
                                                                            className="form-control"
                                                                            placeholder="Ex.: eu@gmail.com"
                                                                            type="text"
                                                                            value={this.props.value.username}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} className="mb-2">
                                                                        <AvField
                                                                            name="email_id"
                                                                            label={this.props.t("Email id")}
                                                                            className="form-control"
                                                                            placeholder="Ex.: eu@gmail.com"
                                                                            type="text"
                                                                            value={this.props.value.email_id}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} className="mb-2">
                                                                        <AvField
                                                                            name="encryption"
                                                                            label={this.props.t("Encryption")}
                                                                            className="form-control"
                                                                            placeholder="Ex.: tls"
                                                                            type="text"
                                                                            value={this.props.value.encryption}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} className="mb-2">
                                                                        <AvField
                                                                            name="password"
                                                                            label={this.props.t("Password")}
                                                                            className="form-control"
                                                                            type="password"
                                                                            value={this.props.value.password}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={12} className="mt-3">
                                                                        <ButtonCustom
                                                                            isLoading={this.props.loading}
                                                                            size="sm"
                                                                            clazzContainer="text-center"
                                                                            clazz="btn btn-info w-md"
                                                                            message_loaging="">
                                                                            <button
                                                                                className="btn btn-info w-md"
                                                                                type="submit">
                                                                                Salvar
                                                                            </button>
                                                                        </ButtonCustom>
                                                                    </Col>
                                                                </Row>
                                                            </AvForm>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={5}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span className="h4 card-title">{this.props.t("Test your email integration")}</span>
                                                                <p className="card-title-desc mt-3">Teste suas configurações de e-mail</p>
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <button
                                                                    onClick={() => {
                                                                        this.props.history.goBack()
                                                                    }}
                                                                    className="btn btn-dark btn-sm text-light"
                                                                >
                                                                    <i className="bx bx-left-arrow-circle"/>
                                                                    <span> Voltar</span>
                                                                </button>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>

                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </>
                            }

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    loading_map: PropTypes.any,

    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    id: PropTypes.any,
    type: PropTypes.any,
    status: PropTypes.any,
    key: PropTypes.any,
    value: PropTypes.any,
    image: PropTypes.any,
    url_image: PropTypes.any,
    type_dir_file: PropTypes.any,

    createOrEditSettings: PropTypes.func,
    findByKeySettings: PropTypes.func,
}


const mapStateToProps = state => {
    const {loading,
        error,
        message,
        type,
        status,
        key,
        value,
        image,
        id,
        url_image,
        type_dir_file,
        loading_form} = state.CreateOrEditSetting
    return {loading,
        error,
        id,
        message,
        type,
        status,
        key,
        value,
        image,
        url_image,
        type_dir_file,
        loading_form}
}

export default withTranslation()(withRouter(
    connect(mapStateToProps, {
        createOrEditSettings,
        findByKeySettings

    })(Index)
))

