import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

 
const getNFOrigemById = data => get(url.GET_NF_ORIGEM_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFOrigem = data => del(url.DELETE_NF_ORIGEM.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFOrigem = data => post(url.POST_CREATE_NF_ORIGEM, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFOrigem = data => put(url.PUT_EDIT_NF_ORIGEM, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFOrigemPaginate = ({page, limit}) => get(url.GET_NF_ORIGEM_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFOrigemPaginate = data => get(url.GET_NF_ORIGEM_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFOrigem = params => get(url.GET_SEARCH_NF_ORIGEM, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFOrigemPaginate,
    getFilterNFOrigemPaginate,
    putEditNFOrigem,
    postCreateNFOrigem,
    delNFOrigem,
    getNFOrigemById,
    getSearchNFOrigem
}
