export const CREATE_SIZES = "CREATE_SIZES"
export const CREATE_SIZES_SUCCESS = "CREATE_SIZES_SUCCESS"

export const EDIT_SIZES = "EDIT_SIZES"
export const EDIT_SIZES_SUCCESS = "EDIT_SIZES_SUCCESS"

export const FIND_SIZES_BY_ID_CATEGORIES = "FIND_SIZES_BY_ID_CATEGORIES"
export const FIND_SIZES_BY_ID_CATEGORIES_SUCCESS = "FIND_SIZES_BY_ID_CATEGORIES_SUCCESS"



export const CREATE_OR_EDIT_SIZES_API_ERROR = "CREATE_OR_EDIT_SIZES_API_ERROR"
