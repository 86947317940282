import {
    CREATE_PRODUCTS,
    CREATE_PRODUCTS_SUCCESS,
    EDIT_PRODUCTS,
    EDIT_PRODUCTS_SUCCESS,
    FIND_PRODUCT_SIZES_BY_ID,
    FIND_PRODUCT_SIZES_BY_ID_SUCCESS,
    FIND_PRODUCT_GENERIC_BY_ID_CATEGORY,
    FIND_PRODUCT_GENERIC_BY_ID_CATEGORY_SUCCESS,
    UPLOAD_PRODUCTS,
    UPLOAD_PRODUCTS_SUCCESS,
    CREATE_OR_EDIT_PRODUCTS_API_ERROR
} from "./actionTypes"

export const uploadProducts = (dataForm, history) => {
    return {
        type: UPLOAD_PRODUCTS,
        payload: { dataForm, history },
    }
}

export const uploadProductsSuccess = result => {
    return {
        type: UPLOAD_PRODUCTS_SUCCESS,
        payload: result,
    }
}

export const findProductGeneric = id => {
    return {
        type: FIND_PRODUCT_GENERIC_BY_ID_CATEGORY,
        payload: { id },
    }
}

export const findProductGenericSuccess = result => {
    return {
        type: FIND_PRODUCT_GENERIC_BY_ID_CATEGORY_SUCCESS,
        payload: result,
    }
}

export const findProductSizes = id => {
    return {
        type: FIND_PRODUCT_SIZES_BY_ID,
        payload: { id },
    }
}

export const findProductSizesSuccess = result => {
    return {
        type: FIND_PRODUCT_SIZES_BY_ID_SUCCESS,
        payload: result,
    }
}

export const createProduct = (params, history) => {
    return {
        type: CREATE_PRODUCTS,
        payload: { params, history },
    }
}

export const createProductSuccess = params => {
    return {
        type: CREATE_PRODUCTS_SUCCESS,
        payload: { params },
    }
}

export const editProduct = (params, history) => {
    return {
        type: EDIT_PRODUCTS,
        payload: { params, history },
    }
}

export const editProductSuccess = params => {
    return {
        type: EDIT_PRODUCTS_SUCCESS,
        payload: { params },
    }
}

export const apiCreateOrEditProductError = error => {
    return {
        type: CREATE_OR_EDIT_PRODUCTS_API_ERROR,
        payload: error,
    }
}

