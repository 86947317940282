import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

 
const getNFCrtById = data => get(url.GET_NF_CRT_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFCrt = data => del(url.DELETE_NF_CRT.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFCrt = data => post(url.POST_CREATE_NF_CRT, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFCrt = data => put(url.PUT_EDIT_NF_CRT, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFCrtPaginate = ({page, limit}) => get(url.GET_NF_CRT_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFCrtPaginate = data => get(url.GET_NF_CRT_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFCrt = params => get(url.GET_SEARCH_NF_CRT, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFCrtPaginate,
    getFilterNFCrtPaginate,
    putEditNFCrt,
    postCreateNFCrt,
    delNFCrt,
    getNFCrtById,
    getSearchNFCrt
}
