import {
    PAGINATE_PAYMENT_METHODS_COMPANY,
    PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS,
    FILTER_PAGINATE_PAYMENT_METHODS_COMPANY,
    FILTER_PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS,
    DELETE_PAYMENT_METHODS_COMPANY_SUCCESS,
    DELETE_PAYMENT_METHODS_COMPANY,
    API_ERROR_LIST_PAYMENT_METHODS_COMPANY,
} from "./actionTypes"


export const filtersAllPaymentMethodsCompanyPaginate = params => {
    return {
        type: FILTER_PAGINATE_PAYMENT_METHODS_COMPANY,
        payload: {params},
    }
}

export const filtersAllPaymentMethodsCompanyPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS,
        payload: {params},
    }
}

export const allPaymentMethodsCompanyPaginate = params => {
    return {
        type: PAGINATE_PAYMENT_METHODS_COMPANY,
        payload: {params},
    }
}

export const allPaymentMethodsCompanyPaginateSuccess = result => {
    return {
        type: PAGINATE_PAYMENT_METHODS_COMPANY_SUCCESS,
        payload: {result},
    }
}

export const deletePaymentMethodsCompany = (id, history) => {
  return {
    type: DELETE_PAYMENT_METHODS_COMPANY,
    payload: {id, history} ,
  }
}

export const deletePaymentMethodsCompanySuccess = message => {
    return {
        type: DELETE_PAYMENT_METHODS_COMPANY_SUCCESS,
        payload: message,
    }
}

export const apiPaginatePaymentMethodsCompanyError = error => {
  return {
    type: API_ERROR_LIST_PAYMENT_METHODS_COMPANY,
    payload: error,
  }
}

