export const PAGINATE_STATE = "PAGINATE_STATE"
export const PAGINATE_STATE_SUCCESS = "PAGINATE_STATE_SUCCESS"

export const FILTER_PAGINATE_STATE = "FILTER_PAGINATE_STATE"
export const FILTER_PAGINATE_STATE_SUCCESS = "FILTER_PAGINATE_STATE_SUCCESS"

export const DELETE_STATE = "DELETE_STATE"
export const DELETE_STATE_SUCCESS = "DELETE_STATE_SUCCESS"

export const API_ERROR_STATE = "PAGINATE_STATE_API_ERROR"
