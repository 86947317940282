import {takeEvery, put, call, takeLatest} from "redux-saga/effects"
import {NotificationManager} from "react-notifications";
import {
    CREATE_PROMOTIONS,
    EDIT_PROMOTIONS,
    FIND_BY_ID_PROMOTIONS,
    UPLOAD_PROMOTIONS
} from "./actionTypes"


import {
    createPromotionsSuccess,
    editPromotionsSuccess,
    findPromotionsIdSuccess,
    uploadPromotionsSuccess,
    apiCreateOrEditPromotionsError
} from "./actions"


import {
    putEditPromotions,
    postCreatePromotions,
    getPromotionsById,
    postUploadPromotions
} from "../../helpers/promotions_helper"

function* findByIdPromotions({payload: {id}}) {
    try {

        const response = yield call(getPromotionsById, id)

        yield put(findPromotionsIdSuccess(response))

    } catch (error) {
        yield put(apiCreateOrEditPromotionsError(error))
    }
}

function* createPromotions({payload: {params,  history}}) {
    try {

        const response = yield call(postCreatePromotions, params)
        const {message, id, error} = response;

        yield put(createPromotionsSuccess({message, id, error}))

        NotificationManager.info(message, 'Aviso');

        history.goBack()



    } catch (error) {
        yield put(apiCreateOrEditPromotionsError(error))
    }
}

function* editPromotions({payload: {params,  history}}) {
    try {

        const response = yield call(putEditPromotions, params)

        yield put(editPromotionsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()


    } catch (error) {
        yield put(apiCreateOrEditPromotionsError(error))
    }
}

function* uploadPromotions({payload: {dataForm,  history}}) {
    try {

        const response = yield call(postUploadPromotions, dataForm)

        yield put(uploadPromotionsSuccess(response))

        const {message} = response;
        NotificationManager.info(message, 'Aviso');
        history.goBack()

    } catch (error) {
        yield put(apiCreateOrEditPromotionsError(error))
    }
}


function* createOrEditPromotionsSaga() {
    yield takeEvery(CREATE_PROMOTIONS, createPromotions)
    yield takeEvery(EDIT_PROMOTIONS, editPromotions)
    yield takeEvery(FIND_BY_ID_PROMOTIONS, findByIdPromotions)
    yield takeLatest(UPLOAD_PROMOTIONS, uploadPromotions)
}

export default createOrEditPromotionsSaga
