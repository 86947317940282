import {
    CREATE_SETTINGS,
    CREATE_SETTINGS_SUCCESS,
    CREATE_OR_EDIT_SETTINGS,
    CREATE_OR_EDIT_SETTINGS_SUCCESS,
    EDIT_SETTINGS,
    EDIT_SETTINGS_SUCCESS,
    FIND_BY_ID_SETTINGS,
    FIND_BY_ID_SETTINGS_SUCCESS,
    CREATE_OR_EDIT_SETTINGS_API_ERROR, FIND_BY_KEY_SETTINGS
} from "./actionTypes"


export const createOrEditSettings = (params, history) => {
    return {
        type: CREATE_OR_EDIT_SETTINGS,
        payload: {params, history},
    }
}

export const createOrEditSettingsSuccess = params => {
    return {
        type: CREATE_OR_EDIT_SETTINGS_SUCCESS,
        payload: {params},
    }
}

export const createSettings = (params, history) => {
    return {
        type: CREATE_SETTINGS,
        payload: {params, history},
    }
}

export const createSettingsSuccess = params => {
    return {
        type: CREATE_SETTINGS_SUCCESS,
        payload: {params},
    }
}

export const editSetting = (params, history) => {
    return {
        type: EDIT_SETTINGS,
        payload: {params, history},
    }
}

export const editSettingsSuccess = result => {
    return {
        type: EDIT_SETTINGS_SUCCESS,
        payload: {result},
    }
}

export const findByIdSettings = id => {
    return {
        type: FIND_BY_ID_SETTINGS,
        payload: {id},
    }
}
export const findSettingsIdSuccess = result => {
    return {
        type: FIND_BY_ID_SETTINGS_SUCCESS,
        payload: result,
    }
}

export const findByKeySettings = id => {
    return {
        type: FIND_BY_KEY_SETTINGS,
        payload: {id},
    }
}
export const findSettingsKeySuccess = result => {
    return {
        type: FIND_BY_ID_SETTINGS_SUCCESS,
        payload: result,
    }
}


export const apiCreateOrEditSettingsError = error => {
    return {
        type: CREATE_OR_EDIT_SETTINGS_API_ERROR,
        payload: error,
    }
}

