import {
    PAGINATE_NF_CNAE,
    PAGINATE_NF_CNAE_SUCCESS,
    FILTER_PAGINATE_NF_CNAE,
    FILTER_PAGINATE_NF_CNAE_SUCCESS,
    DELETE_NF_CNAE_SUCCESS,
    DELETE_NF_CNAE,
    API_ERROR_NF_CNAE,
} from "./actionTypes"


export const filtersAllNFCnaePaginate = params => {
    return {
        type: FILTER_PAGINATE_NF_CNAE,
        payload: {params},
    }
}

export const filtersAllNFCnaePaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_NF_CNAE_SUCCESS,
        payload: {params},
    }
}

export const allNFCnaePaginate = params => {
    return {
        type: PAGINATE_NF_CNAE,
        payload: {params},
    }
}

export const allNFCnaePaginateSuccess = result => {
    return {
        type: PAGINATE_NF_CNAE_SUCCESS,
        payload: {result},
    }
}

export const deleteNFCnae = (id, history) => {
  return {
    type: DELETE_NF_CNAE,
    payload: {id, history} ,
  }
}

export const deleteNFCnaeSuccess = message => {
    return {
        type: DELETE_NF_CNAE_SUCCESS,
        payload: message,
    }
}

export const apiPaginateNFCnaeError = error => {
  return {
    type: API_ERROR_NF_CNAE,
    payload: error,
  }
}

