import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Breadcrumbs from "../../../components/Breadcrumb";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {connect} from "react-redux";
import {google_maps} from "../../../config";
import {searchAddress, reverseCoordinates, editState, findByIdState} from "../../../store/actions"
import {AvField, AvForm} from "availity-reactstrap-validation"
import ButtonCustom from "../../../components/ButtonCustom";
import Autocomplete from "../../../components/Autocomplete";

const LoadingContainer = () => <div>Loading...</div>

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 1,
            country_id: null,
            country: null
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this)
        this.handleFields = this.handleFields.bind(this)
        this.handleAutocomplete = this.handleAutocomplete.bind(this)

    }

    componentDidMount() {
        const {entity_id} = this.props.match.params
        this.props.findByIdState(entity_id)



    }

    handleAutocomplete(_data) {

        if (_data) {
            const {value, name, code, data} = _data


            let newState = {}

            newState[name] = value
            newState['country_id'] = code

            this.setState(newState)
        }
    }

    handleFields(event) {

        const {value} = event.target

        this.setState({status: value})
    }

    handleValidSubmit(event, values) {
        this.props.editState({
            id: this.props.id,
            name: values.name,
            prefix: values.prefix,
            country_id: this.state.country_id === null ? this.props.country_id : this.state.country_id,
            status: this.state.status,
            lat: this.props.coordinates.lat,
            lng: this.props.coordinates.lng
        }, this.props.history)

    }

    render() {
        if(this.props.coordinates.lat === 0){
            this.props.coordinates.lat = this.props.lat
            this.props.coordinates.lng = this.props.lng
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("State Edit")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("State")}
                                     breadcrumbItem={this.props.t("Edit")}/>
                        <Row>
                            {
                                this.props.loading_form
                                    ?
                                    <Col sm={12}>
                                        <Card>
                                            <CardBody>
                                                <Row className="align-items-md-center  mb-3">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="text-center" style={{
                                                            width: '100%',
                                                            position: 'absolute',
                                                            paddingBottom: 20
                                                        }}>
                                                            <Spinner size="sm" animation="border"
                                                                     style={{marginBottom: 20}}
                                                                     variant="success"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>

                                    </Col>
                                    :
                                    <>
                                        <Col sm={7}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Form")}</span>
                                                                <p className="card-title-desc mt-2">
                                                                    Altere os campos abaixo, para continuar com a atualização
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <AvForm
                                                                className="form-horizontal"
                                                                onValidSubmit={this.handleValidSubmit}>

                                                                <Row>
                                                                    <Col sm={8} className="mb-2">
                                                                        <AvField
                                                                            name="name"
                                                                            label={this.props.t("Name")}
                                                                            className="form-control"
                                                                            placeholder="Digite o nome"
                                                                            type="text"
                                                                            value={this.props.name}
                                                                            onBlur={event => {
                                                                                const {name, value} = event.target
                                                                                if (!(Object.keys(value).length === 0)) {
                                                                                    this.props.searchAddress(value)
                                                                                }
                                                                            }}
                                                                            required
                                                                        />
                                                                    </Col>
                                                                    <Col sm={4} className="mb-2">
                                                                        <AvField
                                                                            value={this.props.status !== this.state.status ? this.props.status : this.state.status}
                                                                            onChange={this.handleFields}
                                                                            type="select" name="select"
                                                                            label={this.props.t("Status")}>
                                                                            <option value="1">Ativo</option>
                                                                            <option value="0">Inativo</option>
                                                                        </AvField>
                                                                    </Col>
                                                                    <Col sm={2} className="mb-2">
                                                                        <AvField
                                                                            name="prefix"
                                                                            label={this.props.t("Prefix")}
                                                                            value={this.props.prefix}
                                                                            type="text"
                                                                            required
                                                                        />
                                                                    </Col>
                                                                   
                                                                    <Col sm={8}>
                                                                        <Autocomplete
                                                                            onSourceSelect={this.handleAutocomplete}
                                                                            inputProps={{
                                                                                id: 'country',
                                                                                label: this.props.t("Country"),
                                                                                placeholder: this.props.t("Type to search"),
                                                                                value: this.state.country === null ? this.props.country : this.state.country,
                                                                                url: 'geo/search/countries'
                                                                            }}/>
                                                                    </Col>
                                                                    <Col sm={12} className="pt-3">
                                                                        <ButtonCustom
                                                                            isLoading={this.props.loading}
                                                                            size="sm"
                                                                            clazzContainer="text-center"
                                                                            clazz="btn btn-info w-md"
                                                                            message_loaging="">
                                                                            <button
                                                                                className="btn btn-info w-md"
                                                                                type="submit">
                                                                                Salvar
                                                                            </button>
                                                                        </ButtonCustom>
                                                                    </Col>
                                                                </Row>
                                                            </AvForm>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col sm={5}>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-md-center  mb-3">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <span
                                                                    className="h4 card-title">{this.props.t("Map")}</span>
                                                                <p className="card-title-desc mt-3">Acompanhe a
                                                                    localidade no
                                                                    mapa</p>
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <button
                                                                    onClick={() => {
                                                                        this.props.history.goBack()
                                                                    }}
                                                                    className="btn btn-dark btn-sm text-light"
                                                                >
                                                                    <i className="bx bx-left-arrow-circle"/>
                                                                    <span> Voltar</span>
                                                                </button>
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <div id="gmaps-makers" className="gmaps"
                                                                 style={{position: 'relative'}}>
                                                                {
                                                                    this.props.coordinates.lat != 0 &&
                                                                    <Map
                                                                        google={this.props.google}
                                                                        style={{width: '100%', height: '100%'}}
                                                                        zoom={3}
                                                                        loaded={this.props.loading_map}
                                                                        center={this.props.coordinates}
                                                                        initialCenter={this.props.coordinates}
                                                                    >
                                                                        <Marker
                                                                            position={this.props.coordinates}
                                                                        />
                                                                    </Map>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </>
                            }

                        </Row>
                    </Container>
                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    google: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any,
    loading_map: PropTypes.any,
    coordinates: PropTypes.object,
    address: PropTypes.any,

    loading_form: PropTypes.any,
    loading: PropTypes.any,
    error: PropTypes.any,
    message: PropTypes.any,

    country_id: PropTypes.any,
    name: PropTypes.any,
    prefix: PropTypes.any,
    status: PropTypes.any,
    lat: PropTypes.any,
    lng: PropTypes.any,
    id: PropTypes.any,
    country: PropTypes.any,

    searchAddress: PropTypes.func,
    reverseCoordinates: PropTypes.func,
    editState: PropTypes.func,
    findByIdState: PropTypes.func,

}


const mapStateToProps = state => {
    const {loading_map, address, coordinates} = state.GeoLocation
    const {loading, loading_form, error, message,  country_id, name, prefix, status, lat, lng, id, country} = state.CreateOrEditState
    return {
        loading_map,
        loading_form,
        address,
        coordinates,
        loading,
        error,
        message,
        country_id,
        name,
        prefix,
        status,
        lat,
        lng,
        id,
        country
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, {
    reverseCoordinates,
    searchAddress,
    editState,
    findByIdState
})(
    GoogleApiWrapper({
        apiKey: google_maps.token,
        LoadingContainer: LoadingContainer,
        v: "3",
    })(Index)
)))

