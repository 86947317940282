import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import {NotificationManager} from "react-notifications";

 
const getNFTribICMSCSTById = data => get(url.GET_NF_TRIB_ICMSCST_BY_ID.concat('/').concat(data))
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const delNFTribICMSCST = data => del(url.DELETE_NF_TRIB_ICMSCST.concat('/').concat(data) )
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const postCreateNFTribICMSCST = data => post(url.POST_CREATE_NF_TRIB_ICMSCST, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const putEditNFTribICMSCST = data => put(url.PUT_EDIT_NF_TRIB_ICMSCST, data)
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getNFTribICMSCSTPaginate = ({page, limit}) => get(url.GET_NF_TRIB_ICMSCST_PAGINATE, { params: { page, limit} })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getFilterNFTribICMSCSTPaginate = data => get(url.GET_NF_TRIB_ICMSCST_PAGINATE_FILTERS, { params: { data } })
    .catch(err => {
        const {title, message} = err.response.data;
        NotificationManager.error(message, title);
        throw err.response.data
    })

const getSearchNFTribICMSCST = params => get(url.GET_SEARCH_NF_TRIB_ICMSCST, { params: { query:params } })
    .catch(err => {
        throw err.response.data
    })

export {
    getNFTribICMSCSTPaginate,
    getFilterNFTribICMSCSTPaginate,
    putEditNFTribICMSCST,
    postCreateNFTribICMSCST,
    delNFTribICMSCST,
    getNFTribICMSCSTById,
    getSearchNFTribICMSCST
}
