export const PAGINATE_NF_TRIB_COFINS = "PAGINATE_NF_TRIB_COFINS"
export const PAGINATE_NF_TRIB_COFINS_SUCCESS = "PAGINATE_NF_TRIB_COFINS_SUCCESS"

export const FILTER_PAGINATE_NF_TRIB_COFINS = "FILTER_PAGINATE_NF_TRIB_COFINS"
export const FILTER_PAGINATE_NF_TRIB_COFINS_SUCCESS = "FILTER_PAGINATE_NF_TRIB_COFINS_SUCCESS"

export const DELETE_NF_TRIB_COFINS = "DELETE_NF_TRIB_COFINS"
export const DELETE_NF_TRIB_COFINS_SUCCESS = "DELETE_NF_TRIB_COFINS_SUCCESS"

export const API_ERROR_NF_TRIB_COFINS = "PAGINATE_NF_TRIB_COFINS_API_ERROR"
