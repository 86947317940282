import React from 'react';
import MetaTags from 'react-meta-tags'
import {Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import Breadcrumbs from "../../../../components/Breadcrumb";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Table from "./table/index";
import ButtonCustom from "../../../../components/ButtonCustom";
import {connect} from "react-redux";
import {createSize} from "../../../../store/categories/options/sizes/actions"
import {Form, Spinner} from "react-bootstrap";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            sku: '',
            qtd_flavors: 1,
            qtd_pieces: 1,
            menu_category_id: 0,
            columns: [
                {
                    id: 1,
                    name: 'SKU',
                    status: 1
                },
                {
                    id: 2,
                    name: 'NOME',
                    status: 1
                },
                {
                    id: 3,
                    name: 'SABORES/PEDAÇOS',
                    status: 1
                },
                {
                    id: 5,
                    name: 'AÇÕES',
                    status: 1
                },
            ],
        }

        this.sendForm = this.sendForm.bind(this)
        this.handleRadioButtom = this.handleRadioButtom.bind(this)
    }


    async componentDidMount() {
        this.handleFields = this.handleFields.bind(this);

    }

    handleRadioButtom(event) {
        //event.preventDefault();
       // const {name, value} = event.target


        console.log('clicou');
      /*  this.setState({
            selectedOption: value
        });*/

    }

    handleFields(event) {

        const {name, value} = event.target

        let tempState = {}
        tempState[name] = value

        this.setState(tempState)
    }


    async sendForm() {
        const {entity_id} = this.props.match.params
        this.props.createSize({
            menu_category_id: entity_id,
            sku: this.state.sku,
            name: this.state.name,
            qtd_flavors: this.state.qtd_flavors,
            qtd_pieces: this.state.qtd_pieces,
            status: this.state.status,
        })
    }


    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{this.props.t("Sizes")}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={this.props.t("Size")} breadcrumbItem={this.props.t("Register")}/>
                        <Row>
                            <Col sm={8}>
                                <Card>
                                    <CardBody>

                                        <Table
                                            refresh={this.props.refresh}
                                            route_edit="/edit-category"
                                            columns={this.state.columns}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={4}>
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-md-center ">
                                            <Col className="inner-custom-pagination d-flex">
                                                <div className="d-inline">
                                                    <span className="h4 card-title">{this.props.t("Form")}</span>
                                                    <p className="card-title-desc mt-2">Preencha os campos
                                                        abaixo, para incluir um tamanho.</p>
                                                </div>
                                                <div className="text-md-right ms-auto">
                                                    <a
                                                        onClick={() => {
                                                            this.props.history.goBack()
                                                        }}
                                                        className="btn btn-dark btn-sm text-light"
                                                    >
                                                        <span> Voltar</span>
                                                    </a>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <Row >
                                                    <Col sm={12} className="mb-2">
                                                        <div className="form-group">
                                                            <label htmlFor=""
                                                                   className="control-label">{this.props.t("SKU")}</label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Informe o sku"
                                                                name="sku"
                                                                onChange={this.handleFields}
                                                                value={this.state.sku}/>

                                                        </div>
                                                    </Col>
                                                    <Col sm={12} className="mb-2">
                                                        <div className="form-group">
                                                            <label htmlFor=""
                                                                   className="control-label">{this.props.t("Name")}</label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Informe o nome"
                                                                name="name"
                                                                onChange={this.handleFields}
                                                                value={this.state.name}/>

                                                        </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Row>
                                                            <Col sm={6} className="mt-2">
                                                                <div className="form-group">
                                                                    <label className="control-label">{this.props.t("Flavors")}</label>
                                                                    <select  className="is-untouched is-pristine av-valid form-control"
                                                                             value={this.state.qtd_flavors}
                                                                             onChange={this.handleFields}
                                                                             name={'qtd_flavors'}>
                                                                        <option disabled defaultValue>Selecione...</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <div className="form-group">
                                                                    <label className="control-label">{this.props.t("Pieces")}</label>
                                                                    <select  className="is-untouched is-pristine av-valid form-control"
                                                                             name={'qtd_pieces'}
                                                                             value={this.state.qtd_pieces}
                                                                             onChange={this.handleFields}>
                                                                        <option disabled defaultValue>Selecione...</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                        <option value="6">6</option>
                                                                        <option value="7">7</option>
                                                                        <option value="8">8</option>
                                                                    </select>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/* <Col sm={12} className="mt-2" >
                                                        <h5 className="font-size-14 mb-4">{this.props.t("Flavors")}</h5>
                                                        <div className="btn-group mt-2 mt-xl-0" >

                                                            <input
                                                                className="btn-check"
                                                                type="button"
                                                                autoComplete="off"
                                                                name="qtd_flavors"
                                                                id="qtd_flavors_1"
                                                                value={1}
                                                                onClick={(e) => {
                                                                    console.log('qtd_flavors_1');


                                                                }
                                                                }
                                                            />
                                                            <label
                                                                className="btn btn-outline-dark"
                                                                htmlFor="qtd_flavors"
                                                            >
                                                                {this.props.t("1")}
                                                            </label>


                                                            <input
                                                                className="btn-check"
                                                                type="button"
                                                                name="qtd_flavors"
                                                                id="qtd_flavors_2"
                                                                value={2}
                                                                onClick={(e) => {
                                                                    console.log('qtd_flavors_2');


                                                                }
                                                                }
                                                            />
                                                            <label
                                                                className="btn btn-outline-dark"
                                                                htmlFor="qtd_flavors"
                                                            >
                                                                {this.props.t("2")}
                                                            </label>


                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="qtd_flavors"
                                                                id="qtd_flavors_3"
                                                                value="3"
                                                                onClick={ (e)  => console.log('3')}
                                                            />
                                                            <label
                                                                className="btn btn-outline-dark"
                                                                htmlFor="qtd_flavors"
                                                            >
                                                                {this.props.t("3")}
                                                            </label>


                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="qtd_flavors"
                                                                id="qtd_flavors_4"
                                                                value="4"
                                                                onClick={ (e)  => console.log('4')}
                                                            />
                                                            <label
                                                                className="btn btn-outline-dark"
                                                                htmlFor="qtd_flavors"
                                                            >
                                                                {this.props.t("4")}
                                                            </label>
                                                        </div>
                                                    </Col>*/}

                                                    <Col sm={12} className="pt-3">
                                                        <ButtonCustom
                                                            isLoading={this.props.loading}
                                                            size="sm"
                                                            clazzContainer="text-center"
                                                            clazz="btn btn-info w-md"
                                                            message_loaging="">
                                                            <button
                                                                onClick={() => this.sendForm()}
                                                                className="btn btn-info w-md"
                                                                type="submit">
                                                                Adicionar
                                                            </button>
                                                        </ButtonCustom>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </React.Fragment>


        )
    }


}

Index.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
    refresh: PropTypes.any,
    type: PropTypes.string,
    loading: PropTypes.any,


}
const mapStateToProps = state => {

    const {
        loading,
        refresh
    } = state.CreateOrEditSizes
    return {
        loading,
        refresh,
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, {
    createSize,

})(
    (Index)
)))
