import {takeEvery, put, call, takeLatest} from "redux-saga/effects"

import {
    PAGINATE_SETTINGS,
    FILTER_PAGINATE_SETTINGS,
    DELETE_SETTINGS,
} from "./actionTypes"

import {
    allSettingsPaginateSuccess,
    filtersAllSettingsPaginateSuccess,
    deleteSettingsSuccess,
    apiPaginateSettingsError
} from "./actions"


import {
    getSettingsPaginate,
    getFilterSettingsPaginate,
    delSetting
} from "../../../helpers/settings_helper"
import {NotificationManager} from "react-notifications";

function* deleteSettings({payload: {id, history}}) {
    try {

        const response = yield call(delSetting, id)

        yield put(deleteSettingsSuccess(response))

        const { message } = response;

        history.go(0)

        NotificationManager.info( message, 'Aviso');

    } catch (error) {
        yield put(apiPaginateSettingsError(error))
    }
}

function* paginateSettings({payload: {params}}) {
    try {

        const response = yield call(getSettingsPaginate, params)

        yield put(allSettingsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSettingsError(error))
    }
}

function* filtersPaginateSettings({payload: {params}}) {
    try {

        const response = yield call(getFilterSettingsPaginate, params)

        yield put(filtersAllSettingsPaginateSuccess(response))

    } catch (error) {
        yield put(apiPaginateSettingsError(error))
    }
}


function* SettingsSaga() {
    yield takeEvery(PAGINATE_SETTINGS, paginateSettings)
    yield takeEvery(FILTER_PAGINATE_SETTINGS, filtersPaginateSettings)
    yield takeEvery(DELETE_SETTINGS, deleteSettings)
}

export default SettingsSaga
