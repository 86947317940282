import {
    PAGINATE_META_FACEBOOK,
    PAGINATE_META_FACEBOOK_SUCCESS,
    IMPORT_META_FACEBOOK,
    IMPORT_META_FACEBOOK_SUCCESS,
    FILTER_PAGINATE_META_FACEBOOK,
    FILTER_PAGINATE_META_FACEBOOK_SUCCESS,
    DELETE_META_FACEBOOK_SUCCESS,
    DELETE_META_FACEBOOK,
    API_ERROR_META_FACEBOOK,
} from "./actionTypes"

export const importTabMetaFacebook = params => {
    return {
        type: IMPORT_META_FACEBOOK,
        payload: {params},
    }
}

export const importTabMetaFacebookSuccess = params => {
    return {
        type: IMPORT_META_FACEBOOK_SUCCESS,
        payload: {params},
    }
}

export const filtersAllTabMetaFacebookPaginate = params => {
    return {
        type: FILTER_PAGINATE_META_FACEBOOK,
        payload: {params},
    }
}

export const filtersAllTabMetaFacebookPaginateSuccess = params => {
    return {
        type: FILTER_PAGINATE_META_FACEBOOK_SUCCESS,
        payload: {params},
    }
}

export const allTabMetaFacebookPaginate = params => {
    return {
        type: PAGINATE_META_FACEBOOK,
        payload: {params},
    }
}

export const allTabMetaFacebookPaginateSuccess = result => {
    return {
        type: PAGINATE_META_FACEBOOK_SUCCESS,
        payload: {result},
    }
}

export const deleteTabMetaFacebook = (id, history) => {
  return {
    type: DELETE_META_FACEBOOK,
    payload: {id, history} ,
  }
}

export const deleteTabMetaFacebookSuccess = message => {
    return {
        type: DELETE_META_FACEBOOK_SUCCESS,
        payload: message,
    }
}

export const apiPaginateTabMetaFacebookError = error => {
  return {
    type: API_ERROR_META_FACEBOOK,
    payload: error,
  }
}

